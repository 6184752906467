import lib from 'lib';
import _ from 'lodash';
import K from 'k';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import BKey from 'helpers/b-key';
import Incrementer from 'helpers/incrementer';

import { store } from 'redux/index.js';

var ContainerLightingHelper = {
  K: {
    inset: {optimal: 10.5, min: 8, max: 14},
    spacing: {optimal: {optimal: 21, min: 18, max: 24}, min: 13, max: 26},
    precision: K.productPrecision
  },

  getLightingData: (props) => {
    var calculator = new LightingCalculator(props);

    return calculator.lightingData;
  },

  getLightingProducts({container}) {
    return _.filter(Container.get('products', {container}), product => {
      var isManaged = Product.getIsManaged({product}) && _.get(product, 'managedData.managedKey') !== 'autofilledStorage';

      //hidden panels can't get lighting
      return !_.includes([1481, 1480, 1521, 1619], product.productId) && !(isManaged) && _.get(product, 'position.y') === 0;
    });
  },

  getLightingDepth({container}) {
    var lightingDepth = 9.5;

    _.forEach(ContainerLightingHelper.getLightingProducts({container}), product => {
      var dimensions = _.clone(product.dimensions);

      if (_.includes([...K.hb.ids.verticalHiddenPanels, ...K.vp.ids.verticalHiddenPanels, ...K.hb.ids.horizontalHiddenPanels, ...K.vp.ids.horizontalHiddenPanels], product.productId)) {
        var swapDimKey = _.includes([...K.hb.ids.verticalHiddenPanels, ...K.vp.ids.verticalHiddenPanels], product.productId) ? 'width' : 'height';
        dimensions = {...product.dimensions, [swapDimKey]: product.dimensions.depth, depth: product.dimensions[swapDimKey]};
      }

      if (dimensions.depth > 4) {
        //TODO product front distance from wall - jogs
        var productDepth = dimensions.depth;
        var productLightingDepth = 9.5;

        if (productDepth < 8.5) {
          productLightingDepth = productDepth - 2;
        }
        else if (productDepth < 13 + 3 / 8) {
          productLightingDepth = productDepth - (3 + 7 / 8);
        }

        lightingDepth = _.min([lightingDepth, productLightingDepth]);
      }
    });

    return lightingDepth;
  },

  getLightRanges({container}) {
    var {width} = container.dimensions;
    var lightRanges = [];

    //deadzones
    //TODO sub-product zones
    _.forEach(ContainerLightingHelper.getLightingProducts({container}), product => {
      var x = product.position.x;
      var {width, depth} = product.dimensions;
      var x1 = x, x2 = x1 + width;

      if (!(product.productId === 1379 && depth !== 18.5) && !_.includes([169, 170, 171, 172, 173, 439, 756, 757, 780, 1149, 1177, 1178, 1187, 1190, 1015, 1016, 1014, 352, 353, 354, 355, 356, 357, 358, 359, 1356, 1612, 1641], product.productId)) {
        lightRanges.push({x1, x2});
      }
    });

    if (!lightRanges.length) {
      lightRanges.push({x1: 0, x2: width});
    }

    return lightRanges;
  },

  getComputedLightPositions({container}) {
    var {width} = container.dimensions;
    var deadzones = [];

    var state = store.getState();
    var productTypesById = state.resources.productTypes.byId;

    //deadzones
    //TODO sub-product zones
    _.forEach(ContainerLightingHelper.getLightingProducts({container}), product => {
      var productType = productTypesById[product.productId];
      var x = product.position.x;
      var {width, depth} = product.dimensions;
      var leftX = x, rightX = leftX + width;

      if ((product.productId === 1379 && depth !== 18.5) || _.includes([169, 170, 171, 172, 173, 439, 756, 757, 780, 1149, 1177, 1178, 1187, 1190, 1015, 1016, 1014, 352, 353, 354, 355, 356, 357, 358, 359, 1356, 1612, 1641], product.productId)) { //full hoods or other skip zones
        deadzones.push({from: leftX, to: rightX, skip: true});
      }
      else {
        var bayCounts = _.get(lib.json.parse(productType.dynamicProperties), 'bayCounts.x');

        if (bayCounts) { //dividers between bays
          var bayCount = _.get(_.find(bayCounts, ({maxWidth}) => width <= maxWidth), 'count', 1);

          if (bayCount === 3) {
            var doorWidth = lib.number.round(width / 3, {toNearest: K.precision});
            var partitionWidth = 3 / 4;
            var revealWidth = 1 / 8;

            //HINT one partition is centered between double doors, and other is alingned with outer edge of trapped double door
            var x1 = doorWidth;
            var x2 = doorWidth * 2 - revealWidth / 2 - partitionWidth / 2;

            if (_.get(product, 'customData.orientation', 'left') === 'left') {
              x1 = width - x1;
              x2 = width - x2;
            }

            _.forEach(([x1, x2]), x => deadzones.push({from: x - 2, to: x + 2}));
          }
          else {
            _.times(bayCount - 1, index => {
              var bayX = lib.number.round((width / bayCount) * (index + 1), {toNearest: K.precision});

              deadzones.push({from: leftX + bayX - 2, to: leftX + bayX + 2});
            });
          }
        }

        deadzones.push({from: leftX - 2, to: leftX + 2});
        deadzones.push({from: rightX - 2, to: rightX + 2});
      }
    });

    var adjacentContainers = lib.waterfall(Container.getCornerContainersData({container}), [
      [_.mapValues, containersData => _.find(containersData, ({container}) => Container.getHasLighting({container}))],
      [_.pickBy, containerData => !!containerData],
      [_.mapValues, containerData => ({..._.omit(containerData, 'container'), lightingDepth: ContainerLightingHelper.getLightingDepth({container: containerData.container})})]
    ]);

    var inlineElementsData = _.mapValues(Container.getInlineElementsData({container}), (elements, rangeKey) => {
      return _.filter(elements, element => element.model !== _.get(adjacentContainers, `${rangeKey}.container`));
    });

    var distances = {
      nearestElement: _.mapValues(inlineElementsData, inlineElementsData => _.min(_.map(inlineElementsData, 'distance')))
    };

    var adjacentElements = _.mapValues(inlineElementsData, elements => elements.length > 0);

    return ContainerLightingHelper.getLightingData({
      width, deadzones, adjacentContainers, adjacentElements, distances
    }).lightPositions;
  },

  getLightPositions({container}) {
    var positions;

    if (container.customData.customLightPositions) {
      positions = _.map(_.split(container.customData.customLightPositions, ','), position => {
        return parseFloat(_.trim(position)) || 0;
      });
    }
    else {
      positions = ContainerLightingHelper.getComputedLightPositions({container});
    }

    return positions;
  }
};

class LightingCalculator {
  constructor(props) {
    _.extend(this, _.defaultsDeep(props, {
      width: 0,
      deadzones: [],
      adjacentContainers: {}, //{from?: {lightingDepth}, to?}
      adjacentElements: {}, //{from: true|false, to}
      distances: {nearestElement: {from: 10, to: 10}}
    }));

    this.K = lib.clone(ContainerLightingHelper.K);
    this.xRange = {from: 0, to: this.width};

    this.incrementer = new Incrementer({precision: K.minPrecision});

    ['from', 'to'].forEach(rangeKey => {
      var adjacentContainer = this.adjacentContainers[rangeKey];
      var nearestElementDistance = this.distances.nearestElement[rangeKey];

      this.K.inset[rangeKey] = {
        min: 8 + (nearestElementDistance <= 1.5 ? 1.5 - nearestElementDistance : 0),
        max: this.K.inset.max
      };

      if (this.adjacentElements[rangeKey]) {
        //HINT grow range lights can fall in by distance to nearestElement
        this.xRange[rangeKey] += (rangeKey === 'from' ? -1 : 1) * nearestElementDistance;
      }

      if (adjacentContainer) {
        //HINT force inset to be lighting depth
        this.K.inset[rangeKey] = {min: adjacentContainer.lightingDepth, max: adjacentContainer.lightingDepth};
      }
    });

    this.constrainDeadzones();

    this.skipZones = _.filter(this.deadzones, 'skip');
    this.hasSkipZones = this.skipZones.length > 0;
    this.hasAdjacentContainers = _.size(this.adjacentContainers) > 0;
    this.hasAsymmetricalAdjacentElements = this.adjacentElements.from !== this.adjacentElements.to;
  }

  constrainDeadzones() {
    //sort by from, so the ranges are in order from left to right
    var deadzones = _.sortBy(this.deadzones, _.property('from'));

    //filter out completely overlapping deadzones
    deadzones = _.filter(deadzones, d1 => _.some(deadzones, d2 => d1.from >= d2.from && d1.to <= d2.to));

    deadzones = _.filter(deadzones, d1 => {
      deadzones.filter(d2 => d1 !== d2 && !d2.filtered).forEach(d2 => {
        var shouldConsolidate = d1.skip === d2.skip;
        var insideRange = {from: lib.number.inRange(d1.from, d2), to: lib.number.inRange(d1.to, d2)};

        if (insideRange.from && insideRange.to) {
          d1.filtered = true;
        }
        else {
          [new BKey('from'), new BKey('to')].forEach(rangeKey => {
            if (insideRange[rangeKey]) {
              if (shouldConsolidate) {
                d2[rangeKey.invert()] = d1[rangeKey.invert()];
                d1.filtered = true;
              }
              else if (!d1.skip) {
                d1[rangeKey] = d2[rangeKey.invert()];
              }
            }
          });
        }
      });

      return !d1.filtered;
    });

    this.deadzones = deadzones;
  }

  lightZonesFor({insets}) {
    var zoneCount = this.skipZones.length + 1;
    var zones = _.times(zoneCount, z => {
      var is = lib.array.isFirstLast(z, zoneCount);
      var zone = {is, insetKeys: {}, minInsets: {}, outerRange: {}, insets: {}};

      [new BKey('from'), new BKey('to')].forEach(rangeKey => {
        var i = rangeKey.toIndex(), r = rangeKey.toString(); //first|last, from|to

        zone.outerRange[r] = is[i] ? this.xRange[r] : this.skipZones[z - (r === 'from' ? 1 : 0)][rangeKey.invert()];
        zone.insetKeys[r] = is[i] ? r : 'default';
        zone.minInsets[r] = is[i] ? this.K.inset[r].min : this.K.inset.min;
        zone.insets[r] = insets[zone.insetKeys[r]];

        zone[r] = zone.outerRange[r] + (r === 'to' ? -1 : 1) * zone.insets[r];
      });

      return {...zone, outerWidth: zone.outerRange.to - zone.outerRange.from};
    });

    //filter out any zones under 16", because there's no way they could fit a light
    zones = zones.filter(lz => lz.outerWidth >= _.sum(_.values(lz.minInsets)));

    return zones;
  }

  candidatesFor({insets}) {
    var candidates = [];
    var lightZones = this.lightZonesFor({insets});
    var allLightZonesAreValid = lightZones.every(({from, to}) => to - from >= 0);

    if (allLightZonesAreValid) {
      lightZones.forEach((lightZone, lz) => {
        var lightingWidth = lightZone.to - lightZone.from;

        var counts = {
          min: Math.ceil(lightingWidth / this.K.spacing.max) + 1,
          max: Math.floor(lightingWidth / this.K.spacing.min) + 1
        };

        var countCandidates = counts.min > counts.max ? [1] : _.range(counts.min, counts.max + 1);

        lightZone.candidates = [];

        countCandidates.forEach(count => {
          var spacing = count === 1 ? 0 : lightingWidth / (count - 1);
          var x = lz === 0 ? lightZone.to : lightZone.from; //TODO cleanup
          var scalar = lz === 0 ? -1 : 1;
          var isMiddleZone = !(lightZone.is.first || lightZone.is.last);
          var exceedsMaxInset = _.max(_.map(lightZone.outerRange, rangeValue => Math.abs(rangeValue - x))) > this.K.inset.max;

          if (count === 1 && (isMiddleZone || exceedsMaxInset)) {
            x = lightZone.from + lightingWidth / 2; //one centered light
          }

          this.incrementer.set({initialValue: x, step: spacing * scalar});

          var lightPositions = _.map(_.range(0, count), lp => {
            return {x: this.incrementer.getValue({thenIncrement: true, last: lp === count - 1})};
          });

          //record how far into deadzones a lighting set is, so it can be compared to others
          lightPositions.forEach(lp => {
            var deltaCandidates = lib.filter(this.deadzones.map(({from, to}) => {
              if (lib.number.inRange(lp.x, from, to, {inclusive: false})) {
                return lib.array.absMin([from - lp.x, to - lp.x]);
              }
            }));

            lp.delta = lib.array.absMin(deltaCandidates) || 0;
          });

          lightZone.candidates.push({lightPositions, spacing, count});
        });
      });

      //consolidate zones & generate candidates
      var buildCandidates = ({lightPositions = [], spacings = [], l = 0} = {}) => {
        var lightZone = lightZones[l], maxDelta;

        if (lightZone && lightZone.candidates.length) {
          lightZone.candidates.forEach(lzCandidate => {
            buildCandidates({
              lightPositions: [...lightPositions, ...lzCandidate.lightPositions],
              spacings: [...spacings, lzCandidate.spacing],
              l: l + 1
            });
          });
        }
        else if (l > 0 || (lightZone && !lightZone.candidates.length && lightPositions.length)) {
          lightPositions = _.sortBy(lightPositions.filter(({x}) => x > 0 + 2 && x < this.width - 2), 'x');
          maxDelta = _.max(lightPositions.map(lp => Math.abs(lp.delta)));

          candidates.push({lightZones, lightPositions, spacings, insets, maxDelta});
        }
      };

      buildCandidates();
    }

    return candidates;
  }

  insetsFor({lightPositions, lightZones, defaultInset}) {
    var insets = {};

    if (lightZones.length > 1) insets.default = defaultInset;

    if (lightPositions.length > 0) {
      BKey.forEach(['from', 'to'], rangeKey => {
        var r = rangeKey.toString(), i = rangeKey.toIndex();
        var lightX = _[i](lightPositions).x;
        var zoneX = _.find(lightZones, zone => lib.number.inRange(lightX, zone.outerRange)).outerRange[r];

        if (this.adjacentContainers[r] && ((r === 'from' && zoneX <= 0) || (r === 'to' && zoneX >= this.width))) {
          insets[r] = _.max([this.adjacentContainers[r].lightingDepth, this.K.inset.min]);
        }
        else {
          insets[r] = Math.abs(lightX - zoneX);
        }
      });
    }

    return insets;
  }

  costMetricsFor({candidate}) {
    var {maxDelta, lightPositions, lightZones} = candidate;

    var spacings = candidate.spacings.filter(spacing => spacing > 0);
    var spacing = {max: _.max(spacings), min: _.min(spacings), overflow: 0, asymmetry: 0, suboptimal: 0, insetsAsymmetry: 0};
    var insets = this.insetsFor({...candidate, defaultInset: candidate.insets.default}); //WARNING do not extend - see asymmetry

    if (spacings.length) {
      spacing.suboptimal = lib.number.rangeOverflow({actual: spacing, target: this.K.spacing.optimal.optimal});
      spacing.overflow = lib.number.rangeOverflow({actual: spacing, target: this.K.spacing.optimal});
      spacing.asymmetry = Math.abs(spacing.max - spacing.min);
      spacing.insetsAsymmetry = Math.abs((_.mean([spacing.min, spacing.max]) / 2) - _.mean([insets.from, insets.to]));
    }

    insets.asymmetry = lib.number.maxDelta(insets);
    insets.suboptimal = _.max(_.map(_.pick(insets, ['from', 'to']), (inset, rangeKey) => {
      return this.adjacentContainers[rangeKey] ? 0 : Math.abs(this.K.inset.optimal - inset);
    }));
    insets.overflow = _.max(lib.filter(_.map(lightZones, lightZone => {
      var lzPositions = lightPositions.filter(({x}) => lib.number.inRange(x, lightZone.outerRange.from, lightZone.outerRange.to));

      if (lzPositions.length) {
        var lzInsets = {
          [lightZone.insetKeys.from]: _.first(lzPositions).x - lightZone.outerRange.from,
          [lightZone.insetKeys.to]: lightZone.outerRange.to - _.last(lzPositions).x
        };

        _.forEach({from: lightZone.is.first, to: lightZone.is.last}, (relevant, rangeKey) => {
          if (relevant && this.adjacentContainers[rangeKey]) {
            if (lzPositions.length === 1) {
              lzInsets = {};
            }
            else {
              delete lzInsets[rangeKey];
            }
          }
        });

        return _.max(lib.filter(_.map(lzInsets, (inset, rangeKey) => {
          var targetRange = this.K.inset[rangeKey] || _.pick(this.K.inset, ['max', 'min']);

          return lib.number.rangeOverflow({actual: inset, target: targetRange});
        })));
      }
    }))) || 0;

    if (candidate.insets.asymmetrical && this.hasAsymmetricalAdjacentElements) {
      var trappedRangeKey = new BKey(this.adjacentElements.from ? 'from' : 'to'), openRangeKey = trappedRangeKey.invert();

      insets.asymmetricalOverflow = _.max([0, candidate.insets[trappedRangeKey] - candidate.insets[openRangeKey]]);
    }
    else {
      insets.asymmetricalOverflow = 0;
    }

    return {spacing, insets, maxDelta};
  }

  costFor({candidate}) {
    var costMetrics = this.costMetricsFor({candidate});
    var {insets, spacing, maxDelta} = candidate.costMetrics = costMetrics;

    var costs = candidate.costs = {
      insetsSuboptimal: insets.suboptimal * 1, //least worst
      spacingSuboptimal: spacing.suboptimal * 1,
      insetsAsymmetricalOverflow: insets.asymmetricalOverflow * 5,
      spacingInsetsAsymmetry: spacing.insetsAsymmetry * 5,
      spacingOverflow: (spacing.overflow * 10) ** 1.5, //parabolic
      insetsAsymmetry: insets.asymmetry * 100, //WARNING referenced in .minCostFor()
      spacingAsymmetry: spacing.asymmetry * 1000,
      insetsOverflow: insets.overflow * 5000,
      maxDelta: maxDelta * 100000 //worst
    };

    return _.sum(_.values(costs));
  }

  minCostFor({insets}) {
    insets = _.pick(_.pickBy(insets, (_inset, key) => this.adjacentContainers[key] === undefined), ['from', 'to', 'default']);

    var asymmetry = lib.number.maxDelta(insets);

    return asymmetry * 100;
  }

  get insetsCandidates() {
    if (this.width < this.K.inset.from.min + this.K.inset.to.min + this.K.spacing.min) {
      var insetCandidates = [Math.max(this.K.inset.from.min, this.width / 2)];
    }
    else {
      var insetCandidates = _.range(this.K.inset.min, this.K.inset.max + 1 / 8 * 4, 1 / 8 * 4);
    }

    insetCandidates = _.sortBy(insetCandidates, inset => Math.abs(this.K.inset.optimal - inset.default));

    var insetsCandidates = insetCandidates.map(inset => {
      var insets = {default: inset, from: inset, to: inset};

      _.forEach(this.adjacentContainers, (ac, rangeKey) => insets[rangeKey] = ac.lightingDepth);

      return insets;
    });

    var canUseAsymmetricalInsets = (this.hasAsymmetricalAdjacentElements || this.hasSkipZones) && !this.hasAdjacentContainers;

    if (canUseAsymmetricalInsets) {
      insetCandidates.forEach(i1 => insetCandidates.forEach(i2 => insetCandidates.forEach(i3 => {
        return insetsCandidates.push({default: i1, from: i2, to: i3, asymmetrical: true});
      })));
    }

    insetsCandidates = insetsCandidates.filter(insets => {
      return _.every(['from', 'to'], rangeKey => insets[rangeKey] >= this.K.inset[rangeKey].min);
    });

    return insetsCandidates;
  }

  get lightingData() {
    var insetsCandidates = this.insetsCandidates;
    var finalCandidate = {lightPositions: [], costs: {}, cost: 1000000000};
    var insetsIndex = 0;

    while (finalCandidate.cost > 0 && insetsIndex < insetsCandidates.length) {
      if (this.minCostFor({insets: insetsCandidates[insetsIndex]}) < finalCandidate.cost) {
        this.candidatesFor({insets: insetsCandidates[insetsIndex]}).forEach(candidate => {
          var everyPositionIsValid = candidate.lightPositions.every(({x}) => x >= 0 + 2 && x <= this.width - 2);

          if (everyPositionIsValid) {
            candidate.cost = this.costFor({candidate});

            if (candidate.cost < finalCandidate.cost) {
              finalCandidate = candidate;
            }
          }
        });
      }

      insetsIndex += 1;
    }

    finalCandidate.lightPositions = finalCandidate.lightPositions.map(({x, delta}) => x + delta);

    return finalCandidate;
  }
}

export default ContainerLightingHelper;
