import _ from 'lodash';
import lib from 'lib';
import K from 'k';

var ProjectGraphic = {

  update({id, projectGraphic = {}, cachedProjectGraphic = {}, props, resourceActions, pushToUndoQueue}) {
    if (!id) id = projectGraphic.id || cachedProjectGraphic.id;

    resourceActions.updateProjectGraphic({id, props});

    if (pushToUndoQueue && cachedProjectGraphic) pushToUndoQueue({type: 'projectGraphic', eventKey: 'transformEnd', instance: cachedProjectGraphic});
  },

  destroy({projectGraphic, resourceActions, pushToUndoQueue}) {
    if (pushToUndoQueue) {
      pushToUndoQueue({type: 'projectGraphic', eventKey: 'destroy', instance: projectGraphic, data: {}});
    }

    resourceActions.destroyProjectGraphic({id: projectGraphic.id});
  },

  getZIndex({projectGraphic}) {
    return _.get(projectGraphic, 'data.z', 10000000);
  },

  getFieldGroups({projectGraphic}) {
    var fieldSetGroups = [
      {title: 'Settings', properties: []},
      {title: 'Layers', properties: [
        {
          title: 'Binding Dimensions',
          key: 'isBindingDimension',
          type: 'checkbox',
          views: ['top','front'],
        },
        {
          title: 'Layer',
          key: 'layer',
          type: 'radio',
          views: ['top','front'],
          options: [{value: 'general', title: 'Always Visible'}, {value: 'annotations', title: 'Client + Production'}, {value: 'installationNotes', title: 'Installation'}, {value: 'renderings', title: 'Renderings'}],
        },
      ]},
    ];

    fieldSetGroups[0].properties.push(...[
      ...(_.includes(['rectangle', 'polygon', 'circle'], projectGraphic.type) ? [
        ...(projectGraphic.type === 'rectangle' ? [
          {
            title: 'Height',
            key: 'data.size.height',
            type: 'number',
            views: ['top', 'front'],
          },
          {
            title: 'Width',
            key: 'data.size.width',
            type: 'number',
            views: ['top', 'front'],
          }
        ] : []),
        {
          title: 'Opacity (0-1)',
          key: 'data.opacity',
          type: 'number',
          minValue: 0,
          maxValue: 1,
          views: ['top', 'front'],
        },
        // {
        //   title: 'Z',
        //   key: 'data.z',
        //   type: 'number',
        //   views: ['top', 'front'],
        // },
        {
          title: 'Fill Type',
          key: 'data.fillType',
          type: 'select',
          options: projectGraphic.type === 'circle' ? [{title: 'Color', value: 'color'}] : [{title: 'Texture', value: 'texture'}, {title: 'Color', value: 'color'}, {title: 'Linear Gradient', value: 'linearGradient'}],
          views: ['top', 'front'],
        },
        ...(projectGraphic.type === 'circle' ? [{
          title: 'Radius',
          key: 'data.size.radius',
          type: 'number',
          views: ['top', 'front'],
        }] : []),
        ...(projectGraphic.data.fillType === 'color' ? [{
          title: 'Fill Color (color, hexcode, or rgba)',
          key: 'data.fill',
          type: 'color',
          defaultColor: 'transparent',
          views: ['top', 'front'],
        }] : []),
        ...(projectGraphic.data.fillType === 'linearGradient' ? [{
          title: 'From Color (color, hexcode, or rgba)',
          key: 'data.gradientStops[0].color',
          type: 'color',
          views: ['top', 'front'],
        },
        {
          title: 'To Color (color, hexcode, or rgba)',
          key: 'data.gradientStops[1].color',
          type: 'color',
          views: ['top', 'front'],
        },
        {
          title: 'From Color Opacity (0-1)',
          key: 'data.gradientStops[0].opacity',
          type: 'number',
          minValue: 0,
          maxValue: 1,
          views: ['top', 'front'],
        },
        {
          title: 'To Color Opacity (0-1)',
          key: 'data.gradientStops[1].opacity',
          type: 'number',
          minValue: 0,
          maxValue: 1,
          views: ['top', 'front'],
        },

      ] : []),
        ...(projectGraphic.data.fillType === 'texture' && projectGraphic.type !== 'circle' ? [
          // {
          //   title: 'Upload Texture',
          //   key: 'data.fillTexture.fileUpload',
          //   type: 'text',
          //   views: ['top', 'front'],
          // },
          {
            title: 'Pick Texture',
            key: 'data.fillTexture.mediumId',
            noThumbnail: false,
            type: 'radio',
            options: [
              {
                title: 'Bricks',
                id: 'brick',
                value: 'brick',
                thumbnail: 'https://as1.ftcdn.net/v2/jpg/01/12/44/98/1000_F_112449880_Nse0T8S25Mb2cxSk9fHPLHh7Jc9JRwHe.jpg',
              },
              {
                title: 'Marble',
                id: 'marble',
                value: 'marble',
                thumbnail: 'https://dbdzm869oupei.cloudfront.net/img/vinylrugs/preview/27849.png'
              }
            ],
            views: ['top', 'front'],
          }
        ]
          : []),
        {
          title: 'Fill Opacity (0-1)',
          key: 'data.fillOpacity',
          type: 'number',
          minValue: 0,
          maxValue: 1,
          views: ['top', 'front'],
        },
        {
          title: 'Stroke Color (color, hexcode, or rgba)',
          key: 'data.strokeColor',
          type: 'color',
          views: ['top', 'front'],
        },
        {
          title: 'Stroke Width',
          key: 'data.strokeWidth',
          type: 'number',
          allowEmptyString: true,
          views: ['top', 'front'],
        },
        {
          title: 'Scale Stroke Width',
          key: 'data.scaleStrokeWidth',
          type: 'checkbox',
          views: ['top', 'front']
        },
        {
          title: 'Stroke Opacity (0-1)',
          key: 'data.strokeOpacity',
          type: 'number',
          minValue: 0,
          maxValue: 1,
          views: ['top', 'front'],
        },

      ] : []),
      ...((projectGraphic.type === 'text' || projectGraphic.type === 'textPointer') ? [
        {
          title: 'Text',
          key: 'data.text',
          type: 'text',
          isMultiline: true,
          placeholder: 'Leave notes...',
          views: ['top', 'front']
        },
        {
          title: 'Text Color',
          key: 'data.fontColor',
          type: 'text',
          views: ['top', 'front'],
          placeholder: 'black'
        },
        {
          title: 'Text Size',
          key: 'data.fontSize',
          type: 'text',
          views: ['top', 'front'],
          placeholder: '11'
        },
        {
          title: 'Bold Text',
          key: 'data.isBold',
          type: 'checkbox',
          views: ['top', 'front'],
        },
        {
          key: 'data.text',
          type: 'radio',
          hasOptionGroups: true,
          optionGroups: [
            {
              title: 'Recessed Scribe',
              key: 'data.text',
              type: 'select',
              options: [{title: 'Recessed base wall scribe', value: 'Recessed base wall scribe'}, {title: 'Recessed upper wall scribe', value: 'Recessed upper wall scribe'}, {title: 'Recessed wall scribe', value: 'Recessed wall scribe'}, {title: 'Recessed ceiling scribe', value: 'Recessed ceiling scribe'}],
              views: ['top', 'front'],
            },
            {
              title: 'Flush Scribe',
              key: 'data.text',
              type: 'select',
              options: [{title: 'Flush base wall scribe', value: 'Flush base wall scribe'}, {title: 'Flush upper wall scribe', value: 'Flush upper wall scribe'}, {title: 'Flush wall scribe', value: 'Flush wall scribe'}, {title: 'Vanity wall scribe', value: 'Vanity wall scribe'}],
              views: ['top', 'front'],
            },
            {
              title: 'Shelves',
              key: 'data.text',
              type: 'select',
              options: [{title: 'Continuous solid blocking by others required in wall behind floating shelves', value: 'Continuous solid blocking by others required in wall behind floating shelves'}, {title: 'Hidden shelf flange', value: 'Hidden shelf flange'}, {title: 'Installation method for shelves to be determined on site by installer', value: 'Installation method for shelves to be determined on site by installer'}],
              views: ['top', 'front'],
            },
            {
              title: 'Countertop Overhangs',
              key: 'data.text',
              type: 'select',
              options: [{title: '1/8" countertop overhang', value: '1/8" countertop overhang'}, {title: '1/4" countertop overhang', value: '1/4" countertop overhang'}, {title: '1-1/2" countertop overhang', value: '1-1/2" countertop overhang'}],
              views: ['top', 'front'],
            },
            {
              title: 'End Panels',
              key: 'data.text',
              type: 'select',
              options: [{title: 'End panel is 1/8" proud of door fronts', value: 'End panel is 1/8" proud of door fronts'}, {title: 'End panel is 1-3/8" proud of door fronts', value: 'End panel is 1-3/8" proud of door fronts'}, {title: 'End panel is 1-5/8" proud of door fronts', value: 'End panel is 1-5/8" proud of door fronts'}],
              views: ['top', 'front'],
            },
            {
              title: 'Blocking',
              key: 'data.text',
              type: 'select',
              options: [{title: 'Blocking at utility chase provided by others as required', value: 'Blocking at utility chase provided by others as required'}, {title: 'Blocking behind panel as required by others', value: 'Blocking behind panel as required by others'}, {title: '', value: ''}, {title: '', value: ''}, {title: '', value: ''}, {title: '', value: ''}, {title: '', value: ''}, {title: '', value: ''}],
              views: ['top', 'front'],
            },
            {
              title: 'Outlets by HB',
              key: 'data.text',
              type: 'select',
              options: [{title: 'Electrical outlets by HB', value: 'Electrical outlets by HB'}],
              views: ['top', 'front'],
            },
            {
              title: 'Island Extension',
              key: 'data.text',
              type: 'select',
              options: [{title: 'Use included screws to attach table to end panel. adjust levelers to align base top with top of subcounter.', value: 'Use included screws to attach table to end panel. adjust levelers to align base top with top of subcounter.'}],
              views: ['top', 'front'],
            },
            {
              title: 'Island End Panels',
              key: 'data.text',
              type: 'select',
              options: [{title: 'No reveal - panels are to be pre-biscuited together to be assembled on site.', value: 'No reveal - panels are to be pre-biscuited together to be assembled on site.'}],
              views: ['top', 'front'],
            },
            {
              title: 'VBB',
              key: 'data.text',
              type: 'select',
              options: [{title: 'Blocking or platform for VBB to be built on site by others', value: 'Blocking or platform for VBB to be built on site by others'}],
              views: ['top', 'front'],
            },
            {
              title: 'LED Driver',
              key: 'data.text',
              type: 'select',
              options: [{title: 'Recommended LED driver location behind drawer', value: 'Recommended LED driver location behind drawer'}, {title: 'Recommended LED driver location behind door', value: 'Recommended LED driver location behind door'}],
              views: ['top', 'front'],
            }
          ],
          title: 'Common Annotations',
          views: ['top', 'front'],
        }
      ] : []),
      ...(_.includes(['textPointer', 'line', 'arrow', 'rectangle', 'polygon'], projectGraphic.type) ? [
        {
          title: 'Dashed',
          key: 'data.isDashed',
          type: 'checkbox',
          views: ['top','front'],
        },
        ...(_.get(projectGraphic, 'data.isDashed') ? [
          {
            title: 'Dash Size',
            key: 'data.strokeDashLength',
            type: 'select',
            options: [{value: 5, title: 'Short'}, {value: 15, title: 'Long'}],
            views: ['top','front'],
          }
        ] : [])
      ] : []),
      ...(_.includes(['line', 'arrow'], projectGraphic.type) ? [
        {
          title: 'Stroke',
          key: 'data.stroke',
          type: 'radio',
          options: [{value: 'black', title: 'Black'}, {value: 'red', title: 'Red'}, {value: '#f0f0f0', title: 'Wall'}, {value: K.colors.lighting, title: 'Lighting'}],
          views: ['top','front'],
        },
        {
          title: 'Stroke Width',
          key: 'data.strokeWidth',
          type: 'number',
          allowEmptyString: true,
          minValue: 0.25,
          placeholder: 1,
          views: ['top','front'],
        },
        {
          title: 'Scale Stroke Width',
          //HINT line and arrow have historically defaulted to being scaled, rect/circle/polygon do not
          defaultTrue: projectGraphic.data.strokeWidth,
          key: 'data.scaleStrokeWidth',
          type: 'checkbox',
          views: ['top', 'front']
        }
      ] : []),
      ...(projectGraphic.type === 'textPointer' ? [
        {
          title: 'Show Arrow',
          key: 'data.showArrow',
          type: 'checkbox',
          views: ['top', 'front']
        }
      ] : [])
    ]);

    return fieldSetGroups;
  },
};

export default ProjectGraphic;