import React, { useContext, useEffect, useState } from 'react';

import { Circle, Group, Line } from 'react-konva';

import lib from 'lib';
import _ from 'lodash';
import CanvasDataContext from 'contexts/canvas-data-context';
import PositionHelper from 'helpers/position-helper';

const CanvasCursorObject = (props) => {
  const {canvasData, lastMousePosition, cursorPositionInCanvas, siblingPoints, ...rest} = props;
  var {stage, orthoMode} = canvasData;
  const [coreSnapPositions, setCoreSnapPositions] = useState();

  useEffect(() => {
    setCoreSnapPositions(canvasData.getSnapData().candidateSnapPositions);
  }, []);

  var candidateSnapPositions = coreSnapPositions || [];

  if (!lastMousePosition && !cursorPositionInCanvas) return null;

  let snappedMousePosition = cursorPositionInCanvas || lastMousePosition;

  if (siblingPoints.length > 0) {
    candidateSnapPositions = [...candidateSnapPositions, ...siblingPoints];
  }

  const pointPosition = PositionHelper.toReal(snappedMousePosition, canvasData);

  const snappedDelta = {x: 0, y: 0};
  const lastPosition = _.clone(pointPosition);
  const {position: snappedPosition, snapped} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode}, canvasData);
  const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

  //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
  if (snapped.x !== undefined) {
    snappedDelta.x = snappedPointDelta.x;
  }
  if (snapped.y !== undefined) {
    snappedDelta.y = snappedPointDelta.y;
  }

  snappedMousePosition = PositionHelper.toCanvas(lib.object.sum(pointPosition, snappedDelta), canvasData);

  useEffect(() => {
    if (stage) stage.container().style.cursor = 'none';

    return () => {
      if (stage) stage.container().style.cursor = 'inherit';
    };
  });

  const dotPositionInCanvas = lib.object.sum(snappedMousePosition, {x: 0.5, y: 0.5});
  const positionInCanvas = cursorPositionInCanvas || lastMousePosition;
  const linePositions = [positionInCanvas.x, positionInCanvas.y, positionInCanvas.x, positionInCanvas.y];

  const linePositionTransformations = {
    top: [0, -20, 0, -5],
    bottom: [0, 5, 0, 20],
    left: [-20, 0, -5, 0],
    right: [5, 0, 20, 0],
  };

  return (
    <Group listening={false} opacity={0.75} {...rest}>
      {_.map(_.values(linePositionTransformations), (transform, key) => (
        <Line key={`cursor-line-${key}`} stroke={'black'} strokeWidth={1} points={_.map(linePositions, (value, index) => value + transform[index])} />
      ))}
      <Circle x={dotPositionInCanvas.x - 0.5} y={dotPositionInCanvas.y - 0.5} radius={1} fill={'red'} />
    </Group>
  );
};

export default function CanvasCursorObjectWithContext(props) {
  let canvasData = useContext(CanvasDataContext);

  return <CanvasCursorObject {...props} {...{canvasData}} />;
}
