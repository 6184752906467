import React from 'react';
import _ from 'lodash';
import K from 'k';

import TID from '../../../assets/TID.png'

const TIDPage = () => {
  return (
    <div style={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center'}}>
      <img src={TID} style={{objectFit: 'contain', width: '100%', height: '100%', marginLeft: K.spacing}} />
    </div>
  );
};

export default TIDPage