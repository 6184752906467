import _ from 'lodash';

var UpdatesMapsHelpers = {
  combineUpdatesMaps(updatesMap, ...updatesMaps) {
    _.forEach(updatesMaps, updatesMap1 => {
      _.forEach(updatesMap1, (updatesData, resourceKey) => {
        _.forEach(updatesData, (updateData = [], updateKey) => {
          if (!updatesMap[resourceKey]) {
            updatesMap[resourceKey] = {};
          }

          if (!updatesMap[resourceKey][updateKey]) {
            updatesMap[resourceKey][updateKey] = [...updateData];
          }
          else {
            updatesMap[resourceKey][updateKey] = [...updatesMap[resourceKey][updateKey], ...updateData];
          }
        });
      });
    });

    return updatesMap;
  },

  makeReduxUpdatesFor({updatesMap, resourceActions, hitApi}) {
    if (updatesMap) {
      _.forEach(updatesMap, (updateMap, resourceKey) => {
        if (_.some(updateMap, changes => changes && changes.length > 0)) {
          resourceActions[`modify${_.upperFirst(resourceKey)}`]({
            creations: updatesMap[resourceKey].creations || [],
            updates: updatesMap[resourceKey].updates || [],
            destructions: updatesMap[resourceKey].deletedIds || [],
            tracks: updatesMap[resourceKey].tracks || [],
            hitApi
          });
        }
      });
    }
  },
};

export default UpdatesMapsHelpers;
