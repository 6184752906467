import lib from 'lib';
import _ from 'lodash';
import cleanOldDimensionsData from 'helpers/clean-old-dimensions-data';
import K from 'k';

var loadProjectData = async ({resourceActionDispatchers, match, isDrawings=false}) => {
  var {projectId, versionId} = match.params;

  versionId = parseInt(versionId);

  var projectDataResponse = (await lib.api.request({uri: 'de-project/get-project', body: {projectId, versionId, includeMaterialPullMedia: true}, useActualErrorMessages: true})).data;
  var {
    productCategories,
    models,
    materialClasses,
    materialTypes,
    materials,
    appliances,
    archetypes,
    pricingRules,
    products: productTypes,
    productOptions: productOptionTypes,
    pulls,
    containers: containerTypes,
    partInstances: parts,
    materialPresets,
    project,
    projectVersion,
    floors,
    rooms,
    volumes,
    elevations,
    walls,
    archElementInstances: archElements,
    scopes,
    containerInstances: containers,
    productInstances: products,
    productOptionInstances,
    projectGraphics,
    isEmployee,
    userType,
    userId,
    userEmail,
    userName
  } = projectDataResponse;

  var {discount, dimensionsData, continuousElevationAutogenerationDisabled, drawingsData, title: versionTitle, priceElementConstraintType, infinitePrecision, locked
  } = projectVersion;

  _.forEach(materialClasses, materialClass => {
    materialClass.materials = _.filter(materials, material => _.includes(material.materialClassIds, `.${materialClass.id}.`));
  });

  //HINT discount coming from version
  if (project) project.discount = project.discount || discount;
  if (project) project.continuousElevationAutogenerationDisabled = continuousElevationAutogenerationDisabled;
  if (project) project.drawingsData = drawingsData || {};
  if (project) project.priceElementConstraintType = priceElementConstraintType;

  project = {...project, versionId, isEmployee, userName, userId, userType, userEmail, versionTitle, locked, infinitePrecision};

  project.dimensionsData = cleanOldDimensionsData({dimensionsData, elevations, rooms});

  // discount, drawingsData, continuousElevationAutogenerationDisabled, needsToMigrateAnnotations

  // var project = {
  //   id: projectId, versionId, floors, partInstances, productInstances, projectAnnotations, companyKey,
  //   fittingsOnly: project.fittings_only, materialPreset: project.material_preset, productionIdsLocked: project.production_ids_locked,
  //   discount: project.discount || discount,
  //   ..._.pick(project, ['status'])
  // };

  // if (project.companyKey === 'hb') K.fontFamily = 'Libre Franklin';

  //HINT filter out PT rooms
  rooms = _.filter(rooms, room => {
    return room.floorId && _.filter(scopes, {roomId: room.id}).length > 0;
  });

  //HINT reason unknown, but elevations are sometimes not deleted for deleted rooms
  //This causes errors when not filtered out
  elevations = _.filter(elevations, elevation => {
    return _.find(rooms, {id: elevation.roomId});
  });

  resourceActionDispatchers.trackProjects({projects: [project]});
  resourceActionDispatchers.trackFloors({floors});
  resourceActionDispatchers.trackRooms({rooms});
  resourceActionDispatchers.trackWalls({walls});
  resourceActionDispatchers.trackElevations({elevations});
  resourceActionDispatchers.trackScopes({scopes});
  resourceActionDispatchers.trackContainers({containers});
  resourceActionDispatchers.trackArchElements({archElements});
  resourceActionDispatchers.trackArchetypes({archetypes});
  resourceActionDispatchers.trackProducts({products});
  resourceActionDispatchers.trackProductTypes({productTypes});
  resourceActionDispatchers.trackModels({models});
  resourceActionDispatchers.trackAppliances({appliances});
  resourceActionDispatchers.trackPulls({pulls});
  resourceActionDispatchers.trackPricingRules({pricingRules});
  resourceActionDispatchers.trackContainerTypes({containerTypes});
  resourceActionDispatchers.trackMaterialClasses({materialClasses});
  resourceActionDispatchers.trackMaterials({materials});
  resourceActionDispatchers.trackMaterialTypes({materialTypes});
  resourceActionDispatchers.trackProductOptions({productOptions: productOptionInstances});
  resourceActionDispatchers.trackProductOptionTypes({productOptionTypes});
  resourceActionDispatchers.trackProjectGraphics({projectGraphics});
  resourceActionDispatchers.trackProductCategories({productCategories});
  resourceActionDispatchers.trackVolumes({volumes});
  resourceActionDispatchers.trackParts({parts});
};

export default loadProjectData;
