import _ from 'lodash';
import lib from 'lib';
import getDependencies from 'helpers/get-dependencies';
import K from 'k';
import BKey from 'helpers/b-key';

import Elevation from 'project-helpers/elevation';
import Room from 'project-helpers/room';
import Product from 'project-helpers/product';
import Wall from 'project-helpers/wall';
import Container from 'project-helpers/container';

import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import DetailsHelper from 'helpers/details-helper';
import memo from 'helpers/memo';

var Volume = {
  get(dependencyKeys, {volume, state}) {
    return getDependencies({dependencyKeys, state}, ({state, useDependency}) => {
      if (useDependency('room') || useDependency('scope')) {
        var scope = state.resources.scopes.byId[volume.scopeId];
        var room = state.resources.rooms.byId[scope.roomId];
      }

      return {
        room: () => room,
        scope: () => scope,
        project: () => state.resources.projects.byId[volume.projectId],
        companyKey: () => state.resources.projects.byId[volume.projectId] && state.resources.projects.byId[volume.projectId].companyKey,
        isEmployee: () => state.resources.projects.byId[volume.projectId] && state.resources.projects.byId[volume.projectId].isEmployee,
        dependencies: () => state.resources
      };
    });
  },

  async create({props, resourceActions}) {
    // let details = DetailsHelper.getCleanedOwnedDetailsFor({volume: props});

    let volume = await lib.api.create('volume', {props});

    resourceActions.trackVolumes({volumes: [volume]});

    setTimeout(() => {
      Room.updateManagedResources({room: Volume.get('room', {volume}), resourceActions});
    });

    return volume;
  },

  //HINT cachedVolume is the volume since last db update
  //HINT used for undo, careful, transforms are tracked in redux (but shouldn't be considered for undo)
  update({id, volume = {}, cachedVolume = {}, props, resourceActions, pushToUndoQueue, isBatched = false}) {
    if (!id) id = volume.id || cachedVolume.id;
    if (pushToUndoQueue && cachedVolume) pushToUndoQueue({type: 'volume', eventKey: 'transformEnd', instance: cachedVolume});

    if (props.eventType !== 'transform') props.eventType = undefined;

    let updatedVolume = {...cachedVolume, ...volume, ...props};

    if (!isBatched) {
      resourceActions.updateVolume({id, props});

      setTimeout(() => {
        Room.updateManagedResources({room: Volume.get('room', {volume}), resourceActions});
      });
    }
    else {
      return {volumes: {updates: [{where: {id}, props}]}};
    }
  },

  destroy({volume, resourceActions, pushToUndoQueue}) {
    if (pushToUndoQueue) pushToUndoQueue({type: 'volume', eventKey: 'destroy', instance: volume, data: {}});

    resourceActions.destroyVolume({id: volume.id});

    setTimeout(() => {
      Room.updateManagedResources({room: Volume.get('room', {volume}), resourceActions});
    });
  },

  getDistance: memo(({sourceVolume, wall, computedWall, wallSet, room, volume, element, line}) => {
    var fromFootprint = Volume.getFootprintInRoom({volume: sourceVolume});

    var distances = [];
    var f2, f1 = fromFootprint;

    if (element) {
      if (element.type === 'volume') volume = element.model;
      // else if (element.type === 'wall') wall = element.model;
    }

    if (computedWall) wallSet = computedWall.wallSet;

    if (volume) {
      f2 = Volume.getFootprintInRoom({volume});

      _.forEach(f1, p1 => {
        _.forEach(f2, p2 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toPoint: p2}));
        });
      });
    }
    else if (line) {
      _.forEach(f1, point => {
        distances.push(lib.math.trig.distance({fromPoint: point, toLine: line}));
      });
    }
    else {
      if (!room) room = Volume.get('room', {volume: sourceVolume});
      var walls = computedWall ? computedWall.walls : [wall];
      var wallLinesInRoom = _.map(walls, wall => Wall.getLine({wall, wallSet, room}));

      _.forEach(wallLinesInRoom, line => {
        _.forEach(f1, p1 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toLine: line}));
        });
      });
    }

    return _.min(distances);
  }),

  getAlpha({volume}) {
    return lib.trig.degreesToRadians(volume.rotation);
  },

  getZIndex: memo(({volume, elevation, viewKey}) => {
    var position = viewKey === 'top' ? Volume.getPositionInRoom({volume}) : Volume.getPositionInElevation({volume, elevation});
    var {dimensions} = volume;
    var zIndex = position.z;

    if (viewKey === 'top') {
      zIndex += dimensions.height;
    }
    else if (viewKey === 'front') {
      var sideKey = Volume.getSideKey({volume, elevation, viewKey});

      if (_.includes(['front'], sideKey)) {
        var rotatedDepth = _.find(lib.trig.rotate({point: {x: dimensions.depth, y: 0}, byDegrees: Volume.getElevationTheta({volume, elevation})}), point => point !== 0);

        zIndex += rotatedDepth;
      }
      else if (sideKey === 'right') {
        zIndex += dimensions.width;
      }

      if (sideKey === 'front') {
        zIndex += 0.001;
      }
    }

    return zIndex;
  }),

  getPositionInRoom({volume}) {
    return {x: volume.position.x, y: volume.position.z, z: volume.position.y}; //TODO rotate
  },

  getPositionInElevation: memo(({volume, elevation}) => {
    const sideKey = Volume.getSideKey({volume, viewKey: 'front', elevation});

    var sideKeyOffset = {
      front: 0,
      left: -lib.trig.rotate({point: {x: volume.dimensions.depth, y: 0}, byDegrees: Volume.getElevationTheta({volume, elevation})}).x,
      right: lib.trig.rotate({point: {x: volume.dimensions.depth, y: 0}, byDegrees: Volume.getElevationTheta({volume, elevation})}).y,
      back: lib.trig.rotate({point: {x: volume.dimensions.width, y: 0}, byDegrees: Volume.getElevationTheta({volume, elevation})}).x
    }[sideKey];

    return lib.object.sum(Elevation.getPosition2d({elevation, position3d: volume.position}), {y: -volume.dimensions.height, x: sideKeyOffset});
  }),

  getFootprintFor({position, dimensions, origin, rotation}) {
    var {width, depth} = dimensions, {x, z} = position;

    return _.map([
      {x: x, y: z},
      {x: x, y: z + depth},
      {x: x + width, y: z + depth},
      {x: x + width, y: z}
    ], point => lib.trig.rotate({point, aroundOrigin: origin, byDegrees: rotation}));
  },

  getFootprintInRoom({volume}) {
    var origin = Volume.getPositionInRoom({volume}), {dimensions, rotation} = volume;

    return Volume.getFootprintFor({position: {x: origin.x, z: origin.y}, dimensions, origin, rotation});
  },

  getFootprintLines({volume}) {
    var footprintInRoom = Volume.getFootprintInRoom({volume});

    return {
      left: {from: footprintInRoom[0], to: footprintInRoom[1]},
      front: {from: footprintInRoom[1], to: footprintInRoom[2]},
      right: {from: footprintInRoom[2], to: footprintInRoom[3]},
      back: {from: footprintInRoom[3], to: footprintInRoom[0]}
    };
  },

  getComputedFootprintLines({volume}) {
    var footprintLines = Volume.getFootprintLines({volume});

    return _.mapValues(footprintLines, line => ({
      normal: line,
      extended: lib.trig.extend({line}),
      fromExtended: lib.trig.extend({line, rangeKey: 'from'}),
      toExtended: lib.trig.extend({line, rangeKey: 'to'})
    }));
  },

  sharesFootprintWith({sourceVolume, volume, product, inclusive = true}) {
    var f1 = Volume.getFootprintInRoom({volume: sourceVolume});
    var f2 = product ? Product.getFootprintInRoom({product}) : Volume.getFootprintInRoom({volume});

    return lib.math.polygon.polygonsOverlap(f1, f2, {inclusive});
  },

  getSideKey: memo(({viewKey, volume, elevation}) => {
    var sideKey = 'top';

    if (viewKey === 'front') {
      var theta = lib.round(lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), volume.rotation]}), {toNearest: 5});

      sideKey = 'back';
      var sideCandidates = [
        {theta: 0, sideKey: 'front'},
        {theta: 270, sideKey: 'right'},
        {theta: 90, sideKey: 'left'}
      ];

      sideCandidates.forEach(candidate => {
        if (candidate.theta === theta) {
          sideKey = candidate.sideKey;
        }
      });
    }

    return sideKey;
  }, {name: 'volumeGetSideKey'}),

  getPoints: memo(({sideKey, volume, elevation}) => {
    let points = Volume.getPointsForSideKey({volume, sideKey});

    if (elevation) {
      var theta = Volume.getElevationTheta({volume, elevation});
      var thetaScalar = 1;//Math.cos(theta); //TODO seems like this is causing issues
      var volumeLineInRoom = Volume.getFootprintLines({volume})[sideKey];

      var extendedElevationLineInRoom = lib.trig.extend({line: elevation.lineInRoom, by: 10000});
      var pointOnLine = lib.trig.nearestPoint({point: volumeLineInRoom.from, onLine: extendedElevationLineInRoom});
      var distanceScalar = Math.abs(lib.trig.distance({fromPoint: pointOnLine, toLine: elevation.lineInRoom})) > K.precision ? -1 : 1;
      var xOffset = lib.trig.distance({fromPoint: pointOnLine, toPoint: elevation.lineInRoom.from}) * distanceScalar;

      var scaledOutlinePoints = _.map(points, point => ({x: xOffset + point.x * thetaScalar, y: point.y}));

      var elevationWidth = Elevation.getWidth({elevation});
      var elevationPolygon = [{x: 0, y: -100000}, {x: 0, y: 100000}, {x: elevationWidth, y: 100000}, {x: elevationWidth, y: -100000}];

      var maskedPolygon = lib.polygon.intersection({p1: scaledOutlinePoints, p2: elevationPolygon});

      _.forEach(scaledOutlinePoints, (point, index) => {
        if (!_.find(maskedPolygon, point) && lib.polygon.pointInsidePolygon({point, polygon: maskedPolygon})) {
          //HINT trying to add in points that aren't part of the masked polygon
          //IE {x: 0, y: 0}, {x: 50, y: 0}, {x: 100, y: 0} will remove the x=50 point because its masked
          maskedPolygon.splice(index, 0, point);
        }
      });

      var roundedPoints = _.map(maskedPolygon, point => ({x: lib.number.round(point.x, {toNearest: 1 / 16}) - xOffset, y: lib.number.round(point.y, {toNearest: 1 / 16})}));

      points = lib.polygon.makeClockwise({points: roundedPoints});
    }

    return points;
  }),

  getIsCutoffInElevation: memo(({sideKey, volume, elevation}) => {
    if (!_.includes(['front', 'back'], sideKey)) return false;

    let points = Volume.getPointsForSideKey({volume, sideKey});
    var theta = Volume.getElevationTheta({volume, elevation});
    var thetaScalar = 1;//Math.cos(theta); //TODO seems like this is causing issues
    var volumeLineInRoom = Volume.getFootprintLines({volume})[sideKey];

    var extendedElevationLineInRoom = lib.trig.extend({line: elevation.lineInRoom, by: 10000});
    var pointOnLine = lib.trig.nearestPoint({point: volumeLineInRoom.from, onLine: extendedElevationLineInRoom});
    var distanceScalar = Math.abs(lib.trig.distance({fromPoint: pointOnLine, toLine: elevation.lineInRoom})) > K.precision ? -1 : 1;
    var xOffset = lib.trig.distance({fromPoint: pointOnLine, toPoint: elevation.lineInRoom.from}) * distanceScalar;

    var scaledOutlinePoints = _.map(points, point => ({x: xOffset + point.x * thetaScalar, y: point.y}));

    var elevationWidth = Elevation.getWidth({elevation});

    return _.some(scaledOutlinePoints, point => point.x > elevationWidth);
  }),

  getPointsForSideKey({volume, sideKey}) {
    let points;
    let {width, height, depth} = volume.dimensions;

    points = _.get(volume, 'outlinesBySideKey.front');

    if (sideKey === 'front') {
      if (!_.size(points)) points = [{x: 0, y: 0}, {x: 0, y: height}, {x: width, y: height}, {x: width, y: 0}];
    }
    else if (_.includes(['left', 'right'], sideKey)) {
      points = [{x: 0, y: 0}, {x: 0, y: height}, {x: depth, y: height}, {x: depth, y: 0}];
    }
    else if (sideKey === 'back') {
      if (!_.size(points)) {
        points = [{x: 0, y: 0}, {x: 0, y: height}, {x: width, y: height}, {x: width, y: 0}];
      }
      else {
        var minMirrorX = _.min(_.map(points, point => -point.x));

        points = _.map(points, point => {
          return {y: point.y, x: -point.x - minMirrorX};
        });
      }
    }

    return points;
  },

  containsFootprintOf({volume, product}) {
    var f1 = Volume.getChildrenFootprintInRoom({volume}), f2 = Product.getFootprintInRoom({product});

    return _.every(f2, point => lib.math.polygon.pointInsidePolygon({point, polygon: f1}));
  },

  getIsBottomInFootprint({volume}) {
    var siblings = Volume.get('siblings', {volume});

    var overlappingSiblings = [volume, ..._.filter(siblings, volume1 => {
      return Volume.sharesFootprintWith({sourceVolume: volume, volume: volume1});
    })];

    var isBottom = _.minBy(overlappingSiblings, 'position.y').id === volume.id;

    return isBottom;
  },

  //< wallprint
  getWallprint({volume, elevation}) {
    return Volume.getWallprintInElevation({volume, elevation});
  },

  //TODO if elevation id undefined try to find an elevation where the volume is front facing
  getWallprintInElevation({volume, elevation}) {
    let wallPrintInElevation = [];

    if (elevation) {
      const sideKey = Volume.getSideKey({volume, elevation, viewKey: 'front'});

      var width = volume.dimensions[K.sideSizeMap[sideKey || 'front'].width];
      var height = volume.dimensions[K.sideSizeMap[sideKey || 'front'].height];
      let positionInElevation = Volume.getPositionInElevation({volume, elevation});
      let {x, y} = positionInElevation;
      y = -y;

      //HINT position in elevation origin top;
      y -= height;

      //HINT want position in wall, not overall elevation
      x -= Elevation.getMinWallX({elevation});

      wallPrintInElevation = [
        {x: x, y: y},
        {x: x, y: y + height},
        {x: x + width, y: y + height},
        {x: x + width, y: y}
      ];
    }
    //HINT not sure why we're getting wallprint when elevation isn't defined, but it's coming from getScribeData
    //HINT keeping old logic
    else {
      const sideKey = 'front';

      // var {width, height} = volume;
      var width = volume.dimensions[K.sideSizeMap[sideKey || 'front'].width];
      var height = volume.dimensions[K.sideSizeMap[sideKey || 'front'].height];
      var {x, y, z} = volume.position;
      var wallPosition = {x: 0, y: 0};

      var wall = undefined;

      if (wall) {
        wallPosition = Wall.getLine({wall}).from;
      }

      var floorPositions = {
        volume: {x, y: z},
        wall: wallPosition
      };

      const rotation = volume.rotation;

      floorPositions = _.mapValues(floorPositions, (position) => {
        return lib.math.trig.rotate({position, byDegrees: -rotation});
      });

      var normalizedVolumeFloorPosition = lib.object.difference(floorPositions.volume, floorPositions.wall);

      x = K.round(normalizedVolumeFloorPosition.x);

      wallPrintInElevation = [
        {x: x, y: y},
        {x: x, y: y + height},
        {x: x + width, y: y + height},
        {x: x + width, y: y}
      ];
    }

    return wallPrintInElevation;
  },

  sharesWallprintWith({sourceVolume, volume, inclusive}) {
    return lib.math.polygon.polygonsOverlap(Volume.getWallprint({volume: sourceVolume}), Volume.getWallprint({volume}), {inclusive});
  },

  containsWallprintOf({volume, product}) {
    var w1 = Volume.getWallprint({volume}), w2 = Product.getWallprint({product}); //TODO parent y offset

    return _.every(w2, point => lib.math.polygon.pointInsidePolygon({point, polygon: w1}));
  },
  //> wallprint

  getElevationTheta({elevation, volume}) {
    return lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), volume.rotation]});
  },
  //> rotation

  //< misc
  contains({volume, product}) {
    var isWallVolume = _.includes(['opencase', 'wall-panel', 'floatingShelves', 'backsplash'], volume.type);

    return (isWallVolume || Volume.containsFootprintOf({volume, product})) && Volume.containsWallprintOf({volume, product});
  },

  verticallyOverlapsWith({sourceVolume, volume, container}) {
    var a = Volume.getYRange({volume: sourceVolume}), b = volume ? Volume.getYRange({volume}) : Container.getYRange({container});

    return (a.from < b.to && a.to > b.from) || (a.to === b.to && a.from === b.from);
  },

  distanceTo({sourceVolume, ...args}) {
    return Volume.calcDistanceTo({fromFootprint: Volume.getFootprintInRoom({volume: sourceVolume}), ...args});
  },

  calcDistanceTo({fromFootprint, computedWall, wall, volume, element, line}) {
    var distances = [];
    var f2, f1 = fromFootprint;

    if (element) {
      if (element.type === 'volume') volume = element.model;
      else if (element.type === 'wall') wall = element.model;
    }

    if (volume) {
      f2 = Volume.getFootprintInRoom({volume});

      _.forEach(f1, p1 => {
        _.forEach(f2, p2 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toPoint: p2}));
        });
      });
    }
    else if (line) {
      _.forEach(f1, point => {
        distances.push(lib.math.trig.distance({fromPoint: point, toLine: line}));
      });
    }
    else {
      var lines = computedWall ? _.map(computedWall.walls, wall => Wall.getLine({wall}).inRoom) : [Wall.getLine({wall}).inRoom];

      _.forEach(lines, line => {
        _.forEach(f1, p1 => {
          distances.push(lib.math.trig.distance({fromPoint: p1, toLine: line}));
        });
      });
    }

    return _.min(distances);
  },

  getYPositionRange({volume}) {
    var {position, dimensions} = volume;

    return {from: position.y, to: position.y + dimensions.height};
  },

  getYRange({volume}) {
    return Volume.getYPositionRange({volume});
  },

  getXRange({volume}) {
    var {position, dimensions} = volume;

    return {from: position.x, to: position.x + dimensions.width};
  },

  // //Melded end panels are when two volumes are back to back and their end panel behavior needs to be shared/aware of both volumes
  // //- melded refers to endpanels that are combined into one end panel managed by one volume
  // //- split refers to endpanels that are together, but too long to be melded
  // //- split panels are managed by their individual volumes
  // //conditions to consider/test:
  // //1. base, base with chase behind seating, base w/ chase next to seating - there are 3 volumes being melded here, and the base w/ chase next to seating is to show that volume shouldn't be confused with the base with chase volume behind seating (3 meld)
  // //2. base, seating, base next to seating
  // //3. tall units
  // //4. base, seating on other side trapped on both sides by base
  // getMeldedVolumesData({volume}) {
  //   var c1 = volume;
  //   var meldedVolumesData = {};
  //   var getIsSameHeight = c2 => _.get(c1, 'dimensions.height', 0) === _.get(c2, 'dimensions.height', 1);

  //   //check if a volume is trapped on all 4 sides or not in a 4+ volume island, in which case it would be disqualified
  //   var getIsCenterVolume = (volume) => {
  //     var siblingFootprintLines = _.flatMap(_.filter(Volume.get('siblings', {volume}), getIsSameHeight), sibling => _.values(_.pick(Volume.getFootprintLines({volume: sibling}), ['front', 'back'])));
  //     var {front, back, left, right} = Volume.getFootprintLines({volume});

  //     //at least two points are on the other volumes front/back line
  //     return _.every([front, back, left, right], (l1) => {
  //       return !!_.find(siblingFootprintLines, l2 => {
  //         var somePointIsOnALine = _.some([[l1.from, l2], [l1.to, l2], [l2.from, l1], [l2.to, l1]], ([point, line]) => lib.math.pointIsOnLine({point, line}) && !_.isEqual(point, line.from) && !_.isEqual(point, line.to));
  //         var allPointsAreSame = _.every([[l1.from, l2], [l1.to, l2]], ([point, line]) => _.isEqual(point, line.from) || _.isEqual(point, line.to));

  //         return allPointsAreSame || somePointIsOnALine;
  //       });
  //     });
  //   };

  //   var isCenterVolume = getIsCenterVolume(c1);

  //   //make sure it's not the middle volume in a 3-meld - i.e. seating + base storage behind seating + base on other side - make sure not base storage behind seating
  //   if (!isCenterVolume) {
  //     _.forEach(['left', 'right'], sideKey => {
  //       var inverseSideKey = sideKey === 'left' ? 'right' : 'left';
  //       var netMeldedDepth = Volume.getWrapPanelWidths({volume: c1})[sideKey];

  //       var candidateSiblingsData = _.chain(Volume.get('siblings', {volume: c1}))
  //         .filter(getIsSameHeight)
  //         .map(c2 => {
  //           var isCenterVolume = getIsCenterVolume(c2);

  //           return {
  //             volume: c2,
  //             anglesAreOpposite: lib.trig.anglesAreEqual({a1: Volume.getAlpha({volume: c1}), a2: Volume.getAlpha({volume: c2}) + Math.PI, mode: 'radians'}),
  //             anglesAreSame: lib.trig.anglesAreEqual({a1: Volume.getAlpha({volume: c1}), a2: Volume.getAlpha({volume: c2}), mode: 'radians'}) && isCenterVolume,
  //             isCenterVolume
  //           };
  //         })
  //         .filter(data => data.anglesAreOpposite || data.anglesAreSame) //make sure perpendicular or same angle
  //         .value();

  //       var meldedSiblingsData = [];

  //       //HINT using recursion as an easy approach to chaining when there 3 total volumes
  //       var trackNearbySibling = (volume) => {
  //         //check if volume is touching on a corner that's relevant to end panel melding
  //         var nearbySiblingData = _.find(candidateSiblingsData, data => {
  //           var footprint1 = Volume.getFootprintInRoom({volume});
  //           var footprint2 = Volume.getFootprintInRoom({volume: data.volume});
  //           var orientation = lib.trig.anglesAreEqual({a1: Volume.getAlpha({volume}), a2: Volume.getAlpha({volume: data.volume}), mode: 'radians'}) ? 'equal' : 'opposite';

  //           //top left: 0, bottom left: 1, bottom right: 2, top right: 3 - clockwise footprint starting at top left (back left) of volume
  //           var sideAndOrientationToFootprintIndexMap = {
  //             left: {
  //               equal: [0, 1], //my top left should be equal to your bottom left
  //               opposite: [0, 3]
  //             },
  //             right: {
  //               equal: [3, 2], //my top right should be equal to your bottom right
  //               opposite: [3, 0]
  //             }
  //           };

  //           var footprintIndices = sideAndOrientationToFootprintIndexMap[sideKey][orientation];

  //           if (data.anglesAreOpposite && orientation === 'equal') footprintIndices.reverse(); //if the original volume is opposite to current one, but current one is equal to next one

  //           return lib.math.trig.distance({fromPoint: footprint1[footprintIndices[0]], toPoint: footprint2[footprintIndices[1]]}) === 0;
  //         });

  //         if (nearbySiblingData) {
  //           _.pull(candidateSiblingsData, nearbySiblingData);

  //           meldedSiblingsData.push(nearbySiblingData);

  //           trackNearbySibling(nearbySiblingData.volume);
  //         }
  //       };

  //       trackNearbySibling(c1);

  //       if (meldedSiblingsData.length) {
  //         //needs an opposite volume to be considered melded
  //         var oppositeVolume = _.get(_.find(meldedSiblingsData, data => data.anglesAreOpposite && !data.isCenterVolume), 'volume');

  //         if (oppositeVolume && Volume.getWrapSizes({volume: oppositeVolume})[inverseSideKey]) {
  //           var otherVolumesWrapPanelWidth = _.sum(_.map(meldedSiblingsData, data => {
  //             return !data.isCenterVolume ? Volume.getWrapPanelWidths({volume: data.volume})[inverseSideKey] : data.volume.dimensions.depth;
  //           }));

  //           netMeldedDepth += otherVolumesWrapPanelWidth;

  //           var endPanelsAreMelded = netMeldedDepth <= 47;

  //           meldedVolumesData[sideKey] = {
  //             otherVolumes: _.map(meldedSiblingsData, 'volume'),
  //             isPrimaryVolume: oppositeVolume.id > c1.id,
  //             volume: oppositeVolume,
  //             netMeldedDepth,
  //             endPanelsAreMelded,
  //             otherVolumesWrapPanelWidth
  //           };
  //         }
  //       }
  //     });
  //   }

  //   return isCenterVolume ? {isCenterVolume: true} : meldedVolumesData;
  // },

  getOwnedCompatibleDetails({volume}) {
    return [];
  },

  getSnapToLines({volume, elevation, room, viewKey}) {
    const snapToWall = Volume.getSnapToWall({volume, viewKey});
    // const {snapToFloor} = Volume.getTypeDataFor({volume});
    const snapToLines = [];

    if (viewKey === 'front') {
      const walls = Elevation.get('walls', {elevation});
      const outline = Elevation.getOutline({elevation});

      if (walls?.length > 0 && outline) {
        var xRange = {from: _.minBy(outline, 'x').x, to: _.maxBy(outline, 'x').x};

        if (_.some(walls, wall => _.some(_.map(Wall.getFloorLines({wall, elevation}), 'y'), y => y !== 0))) {
          var wall = _.find(walls, wall => {
            return _.some(xRange, sideKey => {
              return Elevation.getWallX({wall, elevation}) <= sideKey && sideKey <= (Elevation.getWallX({elevation, wall}) + Elevation.getVisibleWallWidth({elevation, wall}));
            });
          });

          if (wall) {
            var overlappingFloorLines = _.filter(Wall.getFloorLines({wall, elevation}), line => {
              return lib.number.rangesOverlap({r1: _.mapValues(line, 'x'), r2: xRange, inclusive: false});
            });

            snapToLines.push(...overlappingFloorLines);
          }
        }
      }
    }
    else if (snapToWall && viewKey === 'top') {
      snapToLines.push(...lib.polygon.toLines(_.map(room.plan.points, point => lib.object.sum(point, room.plan.position))));
    }

    return snapToLines;
  },

  getFill({volume, activeFillMode}) {
    let fill = '#f0f0f0';

    if (activeFillMode === 'grayscale') fill = _.get(volume, 'customData.value', '#f4f4f4');

    return fill;
  },

  position3dTransformFor({volume, position2d, position3d, viewKey, room, elevation, forceSnapToWall = false}) {
    if (!room) room = Volume.get('room', {volume});
    var newTransformProps = {};

    if (viewKey === 'top') {
      position3d = {x: position2d.x, y: position3d.y, z: position2d.y};
    }
    else {
      //TODO correct Z position in front view
      //snap to nearest wall
      // if (Container.getSnapToWall({container, viewKey}) || forceSnapToWall) {
      //   var wallDataFor = ({x}) => {
      //     var wall = Elevation.getWallFor({elevation, x});
      //     var wallX = Elevation.getWallX({elevation, wall});

      //     return {wall, xRelativeToWall: x - wallX};
      //   };

      //   var {wall, xRelativeToWall} = wallDataFor({x: position2d.x});

      //   if (wall) {
      //     var wallPositionXZInWall = lib.trig.rotate({point: {x: xRelativeToWall, y: 0}, byDegrees: Elevation.getRotation({elevation})});
      //     var wallPositionXZ = lib.object.sum(Wall.getLine({wall}).inRoom.from, wallPositionXZInWall);

      //     position3d = {x: wallPositionXZ.x, y: -position2d.y, z: wallPositionXZ.y};
      //   }
      // }
      // else {
      //convert the existing position3d to a position on the wall, so it is effectively 2d
      var elevationXYOrigin = elevation.lineInRoom.from;
      var elevationXZOrigin = {x: elevationXYOrigin.x, z: elevationXYOrigin.y};

      var offsetXZPositionInElevation = lib.object.difference(position3d, elevationXZOrigin);
      var rotatedXZPositionInElevation = lib.math.trig.rotate({point: {x: offsetXZPositionInElevation.x, y: offsetXZPositionInElevation.z}, byDegrees: -Elevation.getRotation({elevation})});

      var newXZPosition = {x: position2d.x, y: rotatedXZPositionInElevation.y};

      //convert the position back to 3d
      var rotatedXZPositionInRoom = lib.math.trig.rotate({point: newXZPosition, byDegrees: Elevation.getRotation({elevation})});
      position3d = {x: rotatedXZPositionInRoom.x, y: -position2d.y, z: rotatedXZPositionInRoom.y};
      position3d = lib.object.sum(position3d, elevationXZOrigin);
      // }
    }

    newTransformProps.position3d = position3d;

    return newTransformProps;
  },

  getUpdatedPropsForTransformerProps({volume, elevation, viewOffset, room, viewKey, transformerProps, roundToMinPrecision = false}) {
    const sideKey = Volume.getSideKey({volume, elevation, viewKey});

    transformerProps.size = _.mapKeys(transformerProps.size, (size, sizeKey) => (K.sideSizeMap[sideKey][sizeKey]));
    var updatedDimensions = _.mapValues({...volume.dimensions, ...transformerProps.size}, value => lib.round(value, {toNearest: K.minPrecision}));

    var updatedPosition = transformerProps.position;
    var normalizeOffsets = viewOffset;

    //HINT renormalize
    if (viewKey === 'front') {
      var sideKeyOffset = {
        front: 0,
        left: 0,
        right: -updatedDimensions.depth,
        back: -updatedDimensions.width
      }[sideKey];

      normalizeOffsets = lib.object.sum(normalizeOffsets, {y: -updatedDimensions.height, x: sideKeyOffset});
    }
    else {
      normalizeOffsets = lib.object.sum(normalizeOffsets, room.plan.position);
    }

    updatedPosition = lib.object.difference(updatedPosition, normalizeOffsets);

    if (roundToMinPrecision) {
      updatedPosition = _.mapValues(updatedPosition, value => lib.round(value, {toNearest: K.minPrecision}));
    }

    // attemptSpacialUpdate() {
    var position3dProps = Volume.position3dTransformFor({
      volume, viewKey, elevation,
      position2d: updatedPosition,
      position3d: volume.position
    });

    var updatedProps = {
      position: position3dProps.position3d,
      dimensions: updatedDimensions
    };

    if (viewKey === 'top') updatedProps.rotation = lib.round(position3dProps.rotation || transformerProps.rotation, {toNearest: 1});

    return updatedProps;
  }
};

export default Volume;