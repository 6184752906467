import React, { useContext } from 'react';
import _ from 'lodash';
import K from 'k';
import lib from 'lib';
import CanvasLine from 'canvas/canvas-line';
import PositionHelper from 'helpers/position-helper';
import { CanvasDataContext, CanvasErrorFallback } from 'canvas';

import { withErrorBoundary } from 'react-error-boundary';

class CanvasFloor extends React.PureComponent {
  render() {
    var {floor} = this.props;
    var {canvasData} = this.props;

    return !!floor.stencil && _.map(floor.stencil.data.entities, (entity, index) => {
      if (!entity.type === 'polyline' && entity.points.length > 0) return null;

      var points = entity.points;

      points = _.map(points, point => PositionHelper.toCanvas(lib.object.sum(point, this.props.viewOffset), canvasData));

      if (entity.closed) points = [...points, points[0]];

      points = _.flatMap(points, point => [point.x, point.y]);

      return (
        <CanvasLine
          key={index}
          stroke={K.colors.stencil}
          points={points}
        />
      );
    });
  }
}

function CanvasFloorWithContext(props) {
  const canvasData = useContext(CanvasDataContext);

  return <CanvasFloor {...props} {...{canvasData}}/>;
}

export default withErrorBoundary(CanvasFloorWithContext, {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Floor DXF'})
});