import {Component} from 'react';
import _ from 'lodash';
import lib from 'lib';

import './box-3d.scss';

export default class Box3dView extends Component {
  constructor(props) {
    super(_.defaults(props, {
      dimensions: {width: 1, height: 1, depth: 1},
      maxElementSizeInPixels: {width: 300, height: 300}
    }));
  }

  render() {
    const {dimensions, maxDimensions, parentKey} = this.props;

    const texts = _.mapValues(dimensions, dimension => `${lib.dimensions.fractionStringFromDecimal(dimension)}"`);

    const maxSize = 200;
    const scale = 2.5 / Math.max(1, _.max(_.values(maxDimensions || dimensions)) / maxSize);

    const {width, height, depth} = lib.object.multiply(dimensions, scale);

    const sideSizeMap = {
      top: {width: depth, height: width},
      left: {width: width, height: height},
      front: {width: depth, height: height}
    };

    const topSize = sideSizeMap.top;

    return (
      <div className="box-3d-container">
        <div className="box-3d" style={{width: `${depth}px`, height: `${height}px`}}>
          {_.map(_.keys(sideSizeMap), sideKey => {
            const {width, height} = sideSizeMap[sideKey];
            const transform = {
              top: `rotateX(90deg) translateZ(${height / 2}px)`,
              left: `rotateY(-90deg) translateZ(${width / 2}px)`,
              front: `rotateY(0deg) translateZ(${topSize.height / 2}px)`
            }[sideKey];
            const style = {
              width: `${width}px`,
              height: `${height}px`,
              transform
            };

            if (sideKey === 'left') {
              return (
                <div key={`${parentKey}-${sideKey}`} className="box-face box-face-left" style={style}>
                  <div className="box-dimension-container">
                    <div className="box-dimension">
                      <div className="box-dimension-text box-dimension-width">{texts.width}</div>
                    </div>
                  </div>
                </div>
              );
            }
            else if (sideKey === 'front') {
              return (
                <div key={`${parentKey}-${sideKey}`} className="box-face box-face-front" style={style}>
                  <div className="box-dimension-container">
                    <div className="box-dimension box-dimension-depth">
                      <div className="box-dimension-text">{texts.depth}</div>
                    </div>
                    <div className="box-dimension box-dimension-height" style={{width: `${height}px`}}>
                      <div className="box-dimension-text">{texts.height}</div>
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div key={`${parentKey}-${sideKey}`} className={`box-face box-face-${sideKey}`} style={style} />
            );
          })}
          <div key={`${parentKey}-back`} className="box-face box-face-back"></div>
          <div key={`${parentKey}-right`} className="box-face box-face-right"></div>
          <div key={`${parentKey}-bottom`} className="box-face box-face-bottom"></div>
        </div>
      </div>
    );
  }
}
