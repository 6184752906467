import React, {useState} from 'react';

import Popup from '../../../components/popup';
import K from 'k';

const DeleteStandardPagePopup = ({onClose, handleDeleteStandardPage}) => {
  const [hoveredKey, setHoveredKey] = useState(null);

  const styles = {
    popup: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 3,
    },
    button: (key) => ({
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      ...K.fonts.label,
      height: 20,
      opacity: hoveredKey === key ? 0.7 : 0.9
    }),
    buttonContainer: {marginTop: K.spacing, display: 'flex', gap: K.spacing, justifyContent: 'flex-end'}
  };

  const handleMouseEnter = (key) => () => setHoveredKey(key);
  const handleMouseLeave = () => setHoveredKey(null);

  return (
    <Popup {...{onClose}} style={styles.popup}>
      <div style={{}}>
        <div style={{marginTop: K.spacing / 2, marginBottom: K.spacing / 2}}>Are you sure you want to delete this page?</div>
      </div>
      <div style={styles.buttonContainer}>
        <button style={styles.button('cancel')} onClick={onClose} onMouseEnter={handleMouseEnter('cancel')} onMouseLeave={handleMouseLeave}>Cancel</button>
        <button style={styles.button('ok')}
          onClick={handleDeleteStandardPage}
          onMouseEnter={handleMouseEnter('ok')} onMouseLeave={handleMouseLeave}>Ok</button>
      </div>
    </Popup>
  );
};

export default DeleteStandardPagePopup;