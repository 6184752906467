import _ from 'lodash';
import lib from 'lib';

export default function addRoomArchElementWidthDimensions({archElement, archElementAlpha, dimensionSets, footprintLines, getIsTrapped, outsideDimensionSets, room}) {
  var isTrapped = false;

  if (!isTrapped) var dimensionSet = _.find(outsideDimensionSets, ({alpha}) => lib.trig.anglesAreEqual({a1: alpha, a2: archElementAlpha + Math.PI}));

  if (dimensionSet) {
    dimensionSet.targets.push(
      {position: lib.object.sum(footprintLines.back.from, room.plan.position), id: `room-arch-element-${archElement.id}-width-from`},
      {position: lib.object.sum(footprintLines.back.to, room.plan.position), id: `room-arch-element-${archElement.id}-width-to`}
    );
  }
}
