import React, {useState, useRef} from 'react';
import _ from 'lodash';
import lib from 'lib';
import K from 'k';

import ScrollableNote from '../../../components/scrollable-note';
import Popup from '../../../components/popup';
import Dropdown from '../../../components/dropdown';
import NumberInput from '../../../components/number-input';
import xIcon from '../../../assets/x-icon-black.png';
import settingsIcon from '../../../assets/settings-icon-black.png';
import useDetectPrint from '../../../hooks/use-detect-print';

const Annotations = ({
  notes, handleUpdateNotes, placeholder
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [showingSettingsPopup, setShowSettingsPopup] = useState(false);
  const [activeNoteIndex, setActiveNoteIndex] = useState(-1);
  const [forceUpdateKey, setForceUpdateKey] = useState(lib.string.uuid());
  const notesRef = useRef(notes);

  var textStyles = {backgroundColor: 'transparent'}

  const updateNotes = ({updatedNotes}) => {
    notesRef.current = updatedNotes;
    handleUpdateNotes({notes: updatedNotes});
  }

  const handleAddNote = ({value}) => {
    var updatedNotes = _.cloneDeep(notesRef.current) || [];
    updatedNotes = [...updatedNotes, {text: value}];

    if (_.isNull(value) || _.isEmpty(value)) return;
    else {
      updateNotes({updatedNotes});
    }

    setForceUpdateKey(lib.string.uuid())
  }

  const handleRemoveNote = ({index}) => {
    var updatedNotes = _.cloneDeep(notesRef.current);
    _.pullAt(updatedNotes, [index]);

    updateNotes({updatedNotes});
  }

  const handleUpdateNote = ({value, index, key}) => {
    var updatedNotes = _.cloneDeep(notesRef.current);
    _.set(updatedNotes[index], key, value);

    updateNotes({updatedNotes});
  }

  const toggleNotesSettingPopup = ({index}) => {
    setActiveNoteIndex(index);
    setShowSettingsPopup(!showingSettingsPopup);
  }

  return (<>
    <div style={{zIndex: 2}}>
      <div>
        {_.map(notes, (note, index) => {
          return (
            <div
              key={index}
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              {hoveredIndex === index && (
                <div className={'hide-print'} style={{...K.defaultIconSize, marginTop: 0}} onClick={() => handleRemoveNote({index})}>
                  <img src={xIcon} style={{width: '1.75rem', height: '1.75rem'}}/>
                </div>
              )}
              {hoveredIndex === index && (
                <div className={'hide-print'} style={{...K.defaultIconSize, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 0, marginRight: K.margin}} onClick={() => toggleNotesSettingPopup({index})}>
                  <img src={settingsIcon} style={{width: '1.25rem', height: '1.25rem'}}/>
                </div>
              )}
              <ScrollableNote
                rows={1}
                key={note.text}
                {...{textStyles: {
                  ...textStyles, color: note.fontColor || 'black', fontSize: `${note.fontSize || 1.25}rem`, fontWeight: note.fontWeight || 'bold'
                }}}
                onChange={({value}) => handleUpdateNote({value, index, key: 'text'})}
                propNotes={note.text}
                maxWidth={600}
              />
            </div>
          )
        })}
      </div>
      <div className={'hide-print'}>
        <ScrollableNote
          {...{placeholder, textStyles}}
          onChange={handleAddNote}
          maxWidth={600}
          key={forceUpdateKey}
          rows={1}
        />
      </div>
    </div>
    {showingSettingsPopup && activeNoteIndex !== -1 && (
      <Popup
        onClose={() => toggleNotesSettingPopup({index: -1})}
      >
        <div>
          <div>
          <div style={{display: 'inline-block', width: '60px'}}>Font Size:</div>
            <NumberInput
              placeholder={1.25}
              value={notes[activeNoteIndex].fontSize}
              onChange={({value}) => {
                if (value === 0) value = '';

                handleUpdateNote({value, key: 'fontSize', index: activeNoteIndex});
              }}
            />
          </div>
          <div style={{marginTop: K.margin}}>
            {_.map([{
              label: 'Font Color',
              key: 'fontColor',
              value: notes[activeNoteIndex].fontColor || 'black',
              options: [{value: 'black', title: 'Black'}, {value: 'red', title: 'Red'}]
            },
            {
              label: 'Font Weight',
              key: 'fontWeight',
              value: notes[activeNoteIndex].fontWeight || 'bold',
              options: [{value: 'bold', title: 'Bold'}, {value: 'normal', title: 'Normal'}]
            }], ({label, key, value, options}) => {
              return (
                <div {...{key}} style={{marginTop: K.margin}}>
                  <div style={{marginRight: K.spacing, display: 'inline-block', width: '60px'}}>{label}:</div>
                  {_.map(options, option => {
                  return (<div key={`${option.value}`}
                    className="mode-toggle-option"
                    onClick={() => handleUpdateNote({value: option.value, key, index: activeNoteIndex})}
                    style={{
                      display: 'inline-block',
                      marginBottom: 0,
                      marginRight: K.spacing,
                      minWidth: '30px',
                      fontWeight: value === option.value ? 'bold' : 'normal',
                      cursor: 'pointer'
                    }}
                    >{option.title}</div>);
                })}
                </div>
              );
            })}
          </div>
        </div>
      </Popup>
    )}
  </>
  );
};

export default Annotations;