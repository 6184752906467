import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Volume from 'project-helpers/volume';
import Elevation from 'project-helpers/elevation';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addElevationVolumeDimensions({elevation, dimensionSets, outsideDimensionSets, isSection, volumes, getIsTrapped}) {
  if (!isSection) {
    var parallelVolumes = _.filter(volumes, volume => {
      return _.includes(['front', 'back'], Volume.getSideKey({volume, elevation, viewKey: 'front'}));
    });

    //TODO treat more like containers
    //pull dims up when volume is being used like a soffit
    if (parallelVolumes.length > 0) {
      var pointsByVolume = _.mapValues(_.keyBy(parallelVolumes, 'id'), volume => {
        var position = Volume.getPositionInElevation({volume, elevation});
        var outlinePoints = Volume.getPoints({volume, elevation, sideKey: Volume.getSideKey({volume, elevation, viewKey: 'front'})});

        return {volume, outlinePoints: _.map(outlinePoints, point => lib.object.sum(point, position))};
      });

      var volumePoints = _.flatten(_.values(_.map(pointsByVolume, 'outlinePoints')));
      var minX = _.min(_.map(volumePoints, 'x'));
      var maxX = _.max(_.map(volumePoints, 'x'));

      _.forEach(pointsByVolume, ({outlinePoints, volume}, volumeId) => {
        var treatLikeSoffit = volume.position.y > 30;

        _.forEach(outlinePoints, (point, p) => {
          // var isVerticalEdgeDim = point.x === _.get(lib.array.next(outlinePoints, p), 'x') && _.some([point, lib.array.next(outlinePoints, p)], p1 => _.includes([minX, maxX], p1.x));

          // var isTopDimToHide = _.some([point, lib.array.next(outlinePoints, p)], p1 => p1.y <= -30) && !isVerticalEdgeDim;//top wall point that isn't the vertical dim on an edge

          // if (!isTopDimToHide) {
            var prevPoint = lib.array.prev(outlinePoints, p);
            var nextPoint = lib.array.next(outlinePoints, p);
            var alpha = lib.trig.alpha({p1: point, p2: nextPoint});
            var prevAlpha = lib.trig.alpha({p1: prevPoint, p2: point});

            _.forEach([alpha, prevAlpha], (alpha, alphaIndex) => {
              var isTrapped = getIsTrapped({line: alphaIndex === 0 ? {from: nextPoint, to: point} : {from: point, to: prevPoint}, entityType: 'volume', entityId: volumeId});

              if (!isTrapped) var dimensionSet = _.find(outsideDimensionSets, ({alpha: dimensionSetAlpha}) => lib.trig.anglesAreEqual({a1: dimensionSetAlpha, a2: alpha + Math.PI}));

              if (dimensionSet) {
                dimensionSet.targets.push({summarize: true, position: point, id: `volume-${volumeId}-${elevation.id}-point-${p}-${alphaIndex === 0 ? 'alpha' : 'prevAlpha'}`});
              }
            });
          // }
        });
      });
    }
  }
}


