import lib from 'lib';

export default class Incrementer {
  constructor(props) {
    this.props = _.defaults(props, {
      initialValue: 0,
      step: 1,
      precision: 0
    });

    this.reset();
  }

  set(props) {
    this.props = {...this.props, ...props};

    if (props.initialValue !== undefined || props.step !== undefined) {
      this.reset();
    }
  }

  increment() {
    this.counter++;

    var step = this.props.step, initialStep = this.props.step;

    if (this.props.precision) {
      //correct unrounded spacing without adding up to rounding errors
      step = lib.number.round(step, {toNearest: this.props.precision});

      this.stepRemainder += (step - initialStep);

      if (Math.abs(this.stepRemainder) >= this.props.precision) {
        var scalar = this.stepRemainder > 0 ? 1 : -1;

        step -= this.props.precision * scalar;
        this.stepRemainder -= this.props.precision * scalar;
      }
    }

    this.value += step;

    return this.value;
  }

  getValue({thenIncrement = false, last = false} = {}) {
    var value = this.value;

    if (last) {
      value = lib.number.round(value - this.stepRemainder, {toNearest: 0.0000001});
    }
    else if (thenIncrement) {
      this.increment();
    }

    return value;
  }

  jump({by, to}) {
    if (by !== undefined) this.value += by;
    if (to !== undefined) this.value = to;

    this.stepRemainder = 0;
  }

  reset() {
    this.counter = 0;
    this.value = this.props.initialValue;
    this.stepRemainder = 0;
  }
}
