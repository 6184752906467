import React from 'react';

import K from 'k';
import _ from 'lodash';
import Popup from 'components/popup';
import Checkbox from 'components/checkbox';

const TOLERANCE_OPTIONS = [{value: 0, title: 'Standard Tolerance'}, {value: -1, title: 'Binding'}, {value: 'tbd', title: 'TBD, Please Confirm'}, {value: 0.125, title: '1/8"'}, {value: 0.25, title: '1/4"'}, {value: 0.5, title: '1/2"'}, {value: 2, title: '2"'}];

export default class ToleranceSelectPopup extends React.PureComponent {
  state = {tolerance: this.props.tolerance, shouldHoldTo: this.props.shouldHoldTo};

  handleToleranceChange = (event) => {
    if (event.target.value === 'tbd') {
      this.setState({tolerance: 'tbd'});
    }
    else {
      this.setState({tolerance: _.toNumber(event.target.value)});
    }
  };

  handleShouldHoldToChange = (shouldHoldTo) => {
    this.setState({shouldHoldTo});
  };

  render() {
    const {tolerance, shouldHoldTo} = this.state;

    return (
      <Popup onClose={() => this.props.close({tolerance, shouldHoldTo})} type={'fitted'}>
        <div style={{display: 'flex', flexDirection: 'column', marginBottom: K.spacing, gap: K.spacing}}>
          <select label='Tolerance' value={tolerance} onChange={this.handleToleranceChange}>
            {_.map(TOLERANCE_OPTIONS, ({title, value}) => {
              return <option key={value} value={value}>{title}</option>;
            })}
          </select>
          <div style={{display: 'flex'}}>
            <Checkbox checked={shouldHoldTo} onChange={this.handleShouldHoldToChange}/>
            <label style={{marginLeft: K.margin}}>Hold to</label>
          </div>
        </div>
      </Popup>
    );
  }
}
