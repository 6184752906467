import React, {useState} from 'react';

import ReactTooltip from 'react-tooltip';

import K from 'k';
import _ from 'lodash';

const ExpandedPropertyFieldOption = ({onClick, noThumbnail, isEditable, thumbnail, id, value, title, thumbnailStyles, invalid, hasOptionGroups, radioActiveOption, highlightRadioActiveOption, optionGroupTitle}) => {
  const [isHovering, setIsHovering] = useState(false);

  const isActive = !highlightRadioActiveOption
    ? false
    : ((_.get(radioActiveOption, 'id') || _.get(radioActiveOption, 'value')) === (id || value));

  var label = optionGroupTitle ? _.replace(title, optionGroupTitle, '') : title;

  return (noThumbnail || !thumbnail) ? (
    <div
      style={{
        ...isActive ? {fontWeight: 'bold'} : {},
        ...hasOptionGroups ? {display: 'flex', alignItems: 'center', marginBottom: K.margin / 2, cursor: 'pointer', opacity: invalid ? 0.3 : 1} : {},
        ...isHovering ? {backgroundColor: 'rgb(245, 245, 245)'} : {}
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div style={hasOptionGroups ? {left: K.spacing, width: 160, textOverflow: 'ellipsis'} : {}}>{label}</div>
    </div>
  ) : (<div style={{display: 'flex'}}>
    <ReactTooltip id={`${title}-tooltip`} effect='solid'>
      {label}
    </ReactTooltip>
    <div
      data-tip='tooltip'
      data-for={`${title}-tooltip`}
      onClick={onClick}
      style={{...thumbnailStyles, marginLeft: K.margin, marginBottom: K.margin}}>
      <img
        src={thumbnail}
        style={{...thumbnailStyles, ...(invalid ? {border: '1px solid red'} : isActive ? {border: '1px solid black'} : {})}}
      />
      {(invalid && !hasOptionGroups) ? (
          <div style={{position: 'absolute', color: 'red'}}>X</div>
        ) : null}
    </div>
  </div>
  );
}

export default ExpandedPropertyFieldOption
