import React, { useState, useEffect } from 'react';
import K from 'k';
import _ from 'lodash';
import linkIcon from 'assets/link-icon-black.png';

const Error = ({error}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [copyingText, setCopyingText] = useState('copy error text');

  var handleCopyClick = () => {
    setCopyingText('copying...');

    navigator.clipboard.writeText(error.info.componentStack)

    setTimeout(() => {
      setCopyingText('copied');

      setTimeout(() => {
        setCopyingText('copy error text');
      }, 1000)
    }, 500);
  }

  return (
    <div style={{backgroundColor: '#E37E7E', color: 'white', position: 'relative', borderRadius: K.borderRadius, maxHeight: 150, padding: K.spacing, paddingRight: K.spacing * 2, marginBottom: K.spacing}}>
      <div style={{fontWeight: 'bold', position: 'absolute', right: K.spacing, top: K.spacing, cursor: 'pointer'}} onClick={() => global.dismissError(error.id)}>X</div>
      <div style={{marginRight: K.spacing / 2}}>
        <span style={{fontWeight: 'bold'}}>Error - </span> {_.get(error, 'message', 'unknown resource')}.
        <br/><br/>
        Please create a ticket with a description of the last few actions you took, the text of this error, and a screenshot, then refresh the page.
      </div>
      <div style={{marginTop: K.spacing, display: 'flex', cursor: 'pointer', alignItems: 'center'}} onClick={handleCopyClick}>
        <img style={{...K.defaultIconSize, marginRight: K.spacing / 2, width: '17px', height: '17px', margin: 1, filter: 'invert(100%)'}} src={linkIcon} alt='Link'></img>
        {copyingText}
      </div>
    </div>
  );
}

export default Error;