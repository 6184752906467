import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Floor from 'project-helpers/floor';

function getFloorFieldGroups({floor}) {
  var {project, dependencies} = Floor.get(['project', 'dependencies'], {floor});
  var {companyKey} = project;

  var fieldSetGroups = [
    {title: floor.title || 'Floor', properties: []}
  ];

  fieldSetGroups[0].properties.push({
    path: 'infinitePrecision',
    type: 'checkbox',
    defaultTrue: project.infinitePrecision,
    title: 'Infinite Precision',
    views: ['top', 'front']
  });

  fieldSetGroups[0].properties.push({
    path: 'customData.threeDBackgroundColor',
    type: 'text',
    title: '3d background color',
    views: ['top', 'front']
  });

  return fieldSetGroups;
}

function handleFloorPropertyChange({activeEntityId, activeEntity, path, resourceActions, value}) {
  if (path === 'infinitePrecision') {
    var {project} = Floor.get(['project'], {floor: activeEntity});

    lib.api.update('projectVersion', {where: {id: project.versionId}, props: {infinitePrecision: value}});

    resourceActions.updateProject({id: project.id, props: {infinitePrecision: value}, hitApi: false});
  }
  else {
    if (_.split(path, '.').length > 1) {
      var topLevelKey = _.split(path, '.')[0];

      _.set(activeEntity, path, value);

      var props = {[topLevelKey]: activeEntity[topLevelKey]};

      // if (_.includes(path, 'details')) {
      //   props.details = _.mapValues(props.details, detail => {
      //     return _.pick(detail, ['id', 'settings']);
      //   });

      //   var updatesMap = {
      //     rooms: {creations: [], deletedIds: [], updates: [{where: {id: activeEntityId}, props}]},
      //     containers: {creations: [], deletedIds: [], updates: []},
      //     products: {creations: [], deletedIds: [], updates: []},
      //     productOptions: {creations: [], deletedIds: [], updates: []}
      //   };

      //   var children = DetailsHelper.getDetailsChildrenFor({room: activeEntity});

      //   if (children) _.forEach(children, child => {
      //     UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleContainerPropertyChange({activeEntityId: child.id, activeEntity: child, path, resourceActions, value, isBatched: true}));
      //   });

      //   UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, resourceActions});

      //   Room.updateManagedResources({room: activeEntity, resourceActions});
      // }
      // else {
        resourceActions.updateFloor({id: activeEntityId, props});
      // }
    }
    else {
      resourceActions.updateFloor({id: activeEntityId, props: {[path]: value}});
    }
  }
}

export {getFloorFieldGroups, handleFloorPropertyChange};
