import K from 'k';
import lib from 'lib';
import _ from 'lodash';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';

var getProductsByCategoryFor = ({container, product}) => {
  let project, companyKey, dependencies, typeData;
  let isBacksplashContainer = false;
  var isFloatingShelvesContainer = false;

  if (container) {
    const containerDeps = Container.get(['project', 'companyKey', 'dependencies'], {container});
    project = containerDeps.project;
    companyKey = containerDeps.companyKey;
    dependencies = containerDeps.dependencies;

    typeData = Container.getTypeDataFor({container});

    isBacksplashContainer = container.type === 'backsplash';
    isFloatingShelvesContainer = container.type === 'floatingShelves';
  }
  else if (product) {
    const productDeps = Product.get(['project', 'companyKey', 'dependencies'], {product});
    project = productDeps.project;
    companyKey = productDeps.companyKey;
    dependencies = productDeps.dependencies;

    typeData = Product.getProductData({product});
  } else {
    return [];
  }

  const categoryIds = typeData.productCategoryIds({project}) || [];
  const excludedProductIds = typeData.excludedProductIds || [];
  const productIds = typeData.productIds;

  var {productTypes, productCategories, models} = _.mapValues(dependencies, 'byId');

  var projectPricingDate = lib.date.moment(project.effectivePricingDate || project.created).unix();

  productTypes = lib.waterfall(productTypes, [
    [_.filter, ({categoryId, id}) => {
      if (container && container.type === 'custom') {
        return true;
      }
      else if (productIds) {
        return _.includes(productIds, id);
      }
      else {
        return (_.includes(categoryIds, categoryId) && !_.includes(excludedProductIds, id)) || id === 516;
      }
    }],
    [_.filter, {isDiscontinued: 0}],
    [_.filter, ({id}) => {
      var shouldInclude = true;

      //HINT filter to correct trash units based on legrabox transition
      if (_.includes([32, 35, 80, 84, 1630, 1633, 1634, 1635], id)) {
        var isOldTrashUnit = _.includes([32, 35, 80, 84], id);
        var isValidTrashUnitForProject = project.useTandemBox ? isOldTrashUnit : !isOldTrashUnit;

        shouldInclude = isValidTrashUnitForProject;
      }

      if (_.includes([990, 1191], id) && container.type !== 'cornerCounterTransition') {
        shouldInclude = false;
      }

      if (_.includes([1673, 1674], id) && container.type === 'cornerCounterTransition') {
        shouldInclude = false;
      }

      return shouldInclude;
    }],
    [_.reject, ({categoryId, title}) => {
      return companyKey !== 'hb' ? (categoryId === 54 && !_.includes(title, 'st ')) : _.includes(title, 'st ');
    }],
    [_.reject, {categoryId: 57}],
    [_.reject, ({categoryId}) => {// get the correct product for backsplash based on companyKey
      var shouldReject = false;

      if (isBacksplashContainer) {
        shouldReject = companyKey !== 'hb' ? categoryId === 24 : categoryId === 53;
      }

      return shouldReject;
    }],
    [_.sortBy, 'title'],
    [_.forEach, p => p.model = _.find(models, {productId: p.id})],
    [_.filter, p => p.model],
    // [_.forEach, p => p.imageUrl = `${App.uploadedFilesUrlPrefix}/public/models/thumbnails/${this.props.theme === 'light' ? 'dark' : 'light'}/${p.model.id}/${p.model.version_id}.png`]
  ]);

  //HINT disable products that won't fit
  if (container && !_.includes(['opencase', 'backsplash', 'horizontalBarblock', 'rearFacingBarblock'], container.type)) {
    var wrapSizes = Container.getWrapSizes({container});
    var containerPadding = {width: wrapSizes.x, height: wrapSizes.y, depth: 0};

    _.forEach(productTypes, productType => {
      var productConstraints = productType.constraints;

      var dimensionConstrainer = new lib.DimensionConstrainer({constraints: productConstraints});

      var minProductDimensions = dimensionConstrainer.getDimensionsFor('min');

      productType.isDisabled = !_.every(['width', 'height', 'depth'], sizeKey => {
        return minProductDimensions[sizeKey] <= (container.dimensions[sizeKey] - containerPadding[sizeKey]);
      });

      //pivot doors and oriented panels
      if (container.customData.hasNonStandardDimensions || _.includes([...K[companyKey].ids.verticalHiddenPanels, ...K[companyKey].ids.horizontalHiddenPanels, 383, 384, 385], productType.id)) productType.isDisabled = false;
    });
  }
  else if (container && container.type === 'opencase') {
    // _.forEach(productTypes, productType => {
    //   productType.isDisabled = false;
    // });
  }

  productCategories = lib.waterfall(productCategories, [
    // [_.filter, ({id}) => id !== 22],
    [_.filter, ({id}) => {
      var containerIsCustom = (container && container.type === 'custom');
      //HINT id 64 is vertical barblock, which should be filtered out for ST
      var isRelevantCategory = _.includes(categoryIds, id) && !(companyKey !== 'hb' && id === 64);
      var isPartsCategory = companyKey === 'hb' && id === 62;

      return containerIsCustom || isRelevantCategory || isPartsCategory;
    }],
    [_.forEach, pc => {
      pc.productTypes = productTypes;

      if (pc.id === 52 && isFloatingShelvesContainer) {
        pc.productTypes = _.filter(productTypes, product => {
          return _.includes([1102, 517, 525], product.id);
        });
      }
      else if (pc.id === 53 && !isBacksplashContainer) {//opencase
        pc.productTypes = [
          {..._.find(productTypes, {id: companyKey !== 'hb' ? 1058 : 435}), title: 'opencase panel'},
          ..._.filter(productTypes, ({id}) => companyKey !== 'hb' && _.includes([1135, 1131], id)), //aluminum and solid surface opencase
          ..._.filter(productTypes, ({id}) => companyKey !== 'hb' && _.includes([1188, 1090, 1664, 1665], id))
        ];
      }
      else if (pc.id === 53 && isBacksplashContainer && companyKey !== 'hb') {
        pc.productTypes = [_.find(productTypes, {id: 1161})];
      }
      else if (pc.id === 64) {//vertical barblock
        pc.productTypes = _.filter(productTypes, product => {
          return _.includes(container ? [1142, 1450, 496, 497, 498, 499, 500, 501, 502, 503, 504, 505, 506, 507] : [1145, 1509, 1146, 1407, 1419, 1524, 1525], product.id);
        });
      }
      else if (pc.id === 25) {//horizontal barblock
        pc.productTypes = _.filter(productTypes, productType => {
          var isRearFacingContainer = _.has(container, 'type') && container.type === 'rearFacingBarblock';
          var isRearFacingBarblockProduct = _.get(product, 'productId') === 1610;

          return _.includes(container
            ? isRearFacingContainer ? [1610] : [1148]
            : isRearFacingBarblockProduct ? [1144, 1147, 1382, 1383, 1522, 1538, 1670] : [1144, 1147, 1382, 1383, 1384, 1522, 1538, 1670], productType.id);
        });
      }
      else if (pc.id === 20) {//shelfbank
        pc.productTypes = _.filter(productTypes, product => {
          return _.includes(container ? [1150, 1563, 680, 1563, 678, 162, 163, 164, 165, 166, 165, 167 /*978, 162*/] : [1159, 1160, 1179, 1322, /*978, 162,*/ 1564], product.id);
        });
      }
      else if (pc.id === 194) {
        pc.productTypes = _.filter(productTypes, p => p.id !== 1387); //filter out managed frame product
      }
      else if (pc.id === 62) {
        var partProductIds = [516]; //usually only include prototype

        //HINT productIds that can have pegs placed
        if (product && Product.getCanHavePegs({product})) {
          partProductIds.push(524, 531, 1626);
        }

        pc.productTypes = _.filter(productTypes, p => _.includes(partProductIds, p.id));
      }
      else {
        pc.productTypes = _.filter(productTypes, p => p.categoryId === pc.id);
      }

      pc.productTypes = _.filter(pc.productTypes, p => p.isProducible);
    }],
    [_.filter, category => category.productTypes.length > 0]
  ]);

  return productCategories;
};

export default getProductsByCategoryFor;
