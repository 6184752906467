import _ from 'lodash';
import lib from 'lib';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addRoomContainerWidthDimensions({volume, allPoints, dimensionSets, footprintLines, volumeAlpha, getIsTrapped, outsideDimensionSets, room}) {
  var candidateLines = _.filter([footprintLines.left, footprintLines.right], line => !getIsTrapped({line, entityType: 'volume', entityId: volume.id}));

  candidateLines = _.map(candidateLines, candidateLine => ({
    from: lib.object.sum(candidateLine.from, room.plan.position),
    to: lib.object.sum(candidateLine.to, room.plan.position)
  }));

  if (candidateLines.length) {
    addLineToNearestSide({allPoints, outsideDimensionSets, candidateLines, idPrefix: `room-volume-${volume.id}-outside-depth`});
  }
}
