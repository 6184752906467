import _ from 'lodash';
import K from 'k';
import Product from 'project-helpers/product';
import lib from 'lib';

var Shelfbank = {
  getNearestPointOnShelf({product}) {
    const parentProduct = Product.get('parentProduct', {product});
    const children = Product.get('childProducts', {product: parentProduct});
    const shelves = _.filter(children, product => _.includes(['woodShelf', 'steelShelf'], product.type));
    const middlePoint = Shelfbank.getMiddlePoint({product});

    const nearestShelf = _.minBy(shelves, shelf => {
      const shelfLines = Shelfbank.getShelfLines({product: shelf});

      return lib.trig.distance({fromPoint: middlePoint, toLine: shelfLines.middleLine, extendLine: true});
    });

    const {middleLine, lowerLine, upperLine} = nearestShelf.shelfLines;

    const nearestShelfLine = _.minBy([middleLine, lowerLine, upperLine], line => {
      return lib.trig.distance({fromPoint: middlePoint, toLine: line, extendLine: true});
    });

    return lib.math.trig.nearestPoint({point: middlePoint, onLine: nearestShelfLine});
  },
  // HINT to maintaine the distance between shelves
  //TODO relates to shelfbank invalidation
  isFarEnoughFrom({product, siblingProduct}) {
    const {minimumShelvesDistance} = K.shelfbankComponentConstants;
    const wallprint = Shelfbank.getWallprint({product: siblingProduct});
    const shelfLines = Shelfbank.getShelfLines({product});

    const bottomDistance = lib.trig.distance({
      fromPoint: wallprint[0],
      toLine: shelfLines.upperLine,
      extendLine: true
    });

    const upDistance = lib.trig.distance({
      fromPoint: wallprint[1],
      toLine: shelfLines.lowerLine,
      extendLine: true
    });

    return _.every([bottomDistance, upDistance], distance => distance >= minimumShelvesDistance);
  },
  sharesWallprintWith({product, siblingProduct}) {
    return lib.polygon.polygonsOverlap(Shelfbank.getWallprint({product}), Shelfbank.getWallprint({product: siblingProduct}), {inclusive: false});
  },
  getShelfLines({product}) {
    const wallprint = Shelfbank.getWallprint({product});

    return {
      upperLine: {from: wallprint[1], to: wallprint[2]},
      middleLine: {
        from: {x: wallprint[0].x, y: (wallprint[0].y + wallprint[1].y) / 2},
        to: {x: wallprint[2].x, y: (wallprint[2].y + wallprint[3].y) / 2}
      },
      lowerLine: {from: wallprint[0], to: wallprint[3]}
    };
  },
  getWallprint({product}) {
    const {width, height} = product.dimensions;
    const {x, y} = product.position;

    return [
      {x: x, y: y},
      {x: x, y: y - height},
      {x: x + width, y: y - height},
      {x: x + width, y: y}
    ];
  },
  //TODO find out where this.middlePoint comes from in shelfbank-component-product.js
  getMiddlePoint({product}) {
    return {x: 0, y: 0};
  },
};

export default Shelfbank;