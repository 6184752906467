import React from 'react';

class Icon extends React.Component {
  render() {
    return (
      <img {...this.props} src={this.props.src} alt={this.props.alt}/>
    );
  }
}

export default Icon;
