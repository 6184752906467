import _ from 'lodash';
import lib from 'lib';

export default function considerUpdatingSummarizedDimensionEdits({projectData, considerSummarizationChange, dimensionSets, cachedDimensionSets, resourceActions}) {
  if (projectData && considerSummarizationChange && cachedDimensionSets) {
    var previouslySummarizedDimSets = _.filter(cachedDimensionSets, {type: 'summary'});
    var previouslyNonSummarizedDimSets = _.reject(cachedDimensionSets, {type: 'summary'});
    var updates = [];

    _.forEach(dimensionSets, dimensionSet => {
      var cachedSummarySet = _.find(previouslySummarizedDimSets, {id: dimensionSet.type === 'summary' ? dimensionSet.id : `${dimensionSet.id}-summary`});

      //if set is summary and cached set did not have a summary set
      if (dimensionSet.type === 'summary' && !cachedSummarySet) {
        var cachedNonSummarySet = _.find(previouslyNonSummarizedDimSets, {id: _.replace(dimensionSet.id, '-summary', '')});

        //transfer edits from non-summary targets in set to summary targets
        if (cachedNonSummarySet) {
          _.forEach(dimensionSet.targets, target => {
            var cachedNonSummaryTarget = _.find(cachedNonSummarySet.targets, {id: _.replace(target.id, '-summary', '')});

            if (cachedNonSummaryTarget) {
              updates.push({
                from: _.replace(target.id, '-summary', ''),
                to: target.id
              });
            }
          })
        }
      }

      //HINT if set isn't summarized, but was previously
      if (dimensionSet.type !== 'summary' && !_.find(dimensionSets, {id: `${dimensionSet.id}-summary`}) && cachedSummarySet) {
        //transfer edits from summary targets in set to non-summary targets
        _.forEach(dimensionSet.targets, target => {
          var cachedSummaryTarget = _.find(cachedSummarySet.targets, {id: `${target.id}-summary`});

          if (cachedSummaryTarget) {
            updates.push({
              from: `${target.id}-summary`,
              to: target.id
            });
          }
        })
      }
    });

    if (updates.length) {
      var dimensionsData = projectData.dimensionsData;

      var originalDimensionsDataString = JSON.stringify(dimensionsData);
      var dimensionsDataString = originalDimensionsDataString;

      //HINT quicker to use string replace on JSON string than to iterate through object
      _.forEach(updates, ({from, to}) => {
        dimensionsDataString = dimensionsDataString.replaceAll(`"${from}"`, `"${to}"`);
      });

      var shouldUpdate = dimensionsDataString !== originalDimensionsDataString;

      if (shouldUpdate) {
        dimensionsData = JSON.parse(dimensionsDataString);

        lib.api.update('projectVersion', {where: {id: projectData.versionId}, props: {dimensionsData}});

        setTimeout(() => {
          resourceActions.updateProject({id: projectData.id, props: {dimensionsData}, hitApi: false});
        });
      }
    }

    return dimensionSets;
  }
}