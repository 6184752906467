import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Wall from './wall';
import Room from './room';
import ArchElementTypes from 'project-helpers/arch-element-helpers/arch-element-types';
import getDependencies from 'helpers/get-dependencies';
import Elevation from './elevation';

// import { store } from 'redux/index.js';

var initializeTypes = ({types, classification}) => {
  _.forEach(types, (typeData, typeKey) => {
    typeData.type = typeKey;
    typeData.classification = classification;
    typeData.title = typeData.title || lib.string.title({forKey: typeKey});
    typeData.canvasObjectType = typeData.canvasObjectType || 'script';
    typeData.snapToFloor = typeData.snapToFloor || false;

    if (_.includes(['script', 'model'], typeData.canvasObjectType)) {
      // typeData.getSize = typeData.getSize || (({props}) => ({width: props.customData.width, height: props.customData.height}));
      typeData.getSize = typeData.getSize || (({props}) => {
        var size = {};

        _.forEach(['width', 'height'], dimensionKey => {
          size[dimensionKey] = typeData.canvasObjectType === 'script' ? props.customData[dimensionKey] : props.customData.dimensions[dimensionKey];
        });

        if (typeData.canvasObjectType === 'model') {
          size.depth = props.customData.dimensions.depth;
        }

        return size;
      });
    }
  });
};

var InitializedArchElementTypes = _.mapValues(ArchElementTypes, (types, classification) => {
  initializeTypes({types, classification});

  return types;
});

var ArchElement = {
  get(dependencyKeys, {archElement}) {
    return getDependencies({dependencyKeys}, ({state, useDependency}) => {
      var room = _.find(state.resources.rooms.byId, {id: archElement.roomId});

      if (useDependency('wallSets')) {
        var wallSets = Room.getWallSets({room});
      }

      if (useDependency('wall')) {
        var wall = _.find(state.resources.walls.byId, {id: archElement.wallId});
      }

      return {
        wallSets: () => wallSets,
        wall: () => wall,
        room: () => room,
        typeData: () => ArchElement.getTypeData({archElement})
      };
    });
  },

  getTypeData({type, archElement}) {
    if (!type && archElement) {
      type = archElement.type;
    }

    var types = {...InitializedArchElementTypes.hybrid, ...InitializedArchElementTypes.wall, ...InitializedArchElementTypes.floor, ...InitializedArchElementTypes.generic};

    return types[type];
  },

  getWall({walls, archElement}) {
    return _.find(walls, {id: archElement.wallId});
  },

  getRoom({rooms, archElement}) {
    return _.find(rooms, {id: archElement.roomId});
  },

  getTypesFor({parentType}) {
    var exclusiveTypeKey = parentType === 'room' ? 'floor' : 'wall';
    var types = {...InitializedArchElementTypes.hybrid, ...InitializedArchElementTypes[exclusiveTypeKey], ...InitializedArchElementTypes.generic};

    //HINT there are separate Generic Rectangle arch elements for each view, confusing to show both
    let disabledTypes = _.filter(InitializedArchElementTypes[exclusiveTypeKey === 'floor' ? 'wall' : 'floor'], type => type.title !== 'Generic Rectangle');

    types = _.sortBy(_.map({
      ..._.mapValues(types, type => ({...type, isDisabled: false})),
      ..._.mapValues(disabledTypes, type => ({...type, isDisabled: true}))
    }), 'isDisabled');

    return lib.object.clone(types);
  },

  getPositionInRoom({wall, wallSet, wallSets, archElement}) {
    if (!wall || !wallSets) {
      var {wall, wallSets} = ArchElement.get(['wall', 'wallSets'], {archElement});
    }

    const size = ArchElement.getSize({archElement, viewKey: 'front'});
    const {typeData} = ArchElement.get(['typeData'], {archElement});
    const {classification} = typeData;
    let position;

    //HINT for floor archElements height is depth;
    //rotation is always 0 so we don't need to worry about that
    if (classification === 'floor') {
      position = lib.object.difference(archElement.position, {y: size.height});
    }
    else {
      let offsets = {x: size.width, y: 0};

      position = Wall.getPlanDataFor({wall, wallSet, wallSets, position: lib.object.sum(archElement.position, offsets)}).position;
    }

    return position;
  },

  getRotationInRoom({wall, wallSet, wallSets, archElement}) {
    var {classification} = ArchElement.get('typeData', {archElement});
    var rotation = 0;

    if (classification !== 'floor') {
      if (!wall) {
        var wall = ArchElement.get('wall', {archElement});
      }

      var {rotation} = Wall.getPlanDataFor({wall, position: archElement.position});
    }

    return rotation;
  },

  getFootprintInRoom({wall, wallSets, archElement, room}) {
    if (!wallSets) wallSets = Room.get('wallSets', {room});
    if (!wall) wall = ArchElement.get('wall', {archElement});

    var positionInRoom = ArchElement.getPositionInRoom({archElement, wall, wallSets});
    var rotation = ArchElement.getRotationInRoom({archElement});
    var size = ArchElement.getSize({archElement, viewKey: 'top'});

    var footprintInRoom = [
      positionInRoom,
      lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: size.width, y: 0}, byDegrees: rotation})),
      lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: size.width, y: size.height}, byDegrees: rotation})),
      lib.object.sum(positionInRoom, lib.math.trig.rotate({position: {x: 0, y: size.height}, byDegrees: rotation})),
    ];

    return {
      left: {from: footprintInRoom[0], to: footprintInRoom[1]},
      front: {from: footprintInRoom[1], to: footprintInRoom[2]},
      right: {from: footprintInRoom[2], to: footprintInRoom[3]},
      back: {from: footprintInRoom[3], to: footprintInRoom[0]}
    };
  },

  isShowingFrontFor({archElement, elevation}) {
    return lib.round(lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), ArchElement.getRotationInRoom({archElement}) - 90]}), {toNearest: 5}) === 0;
  },

  getSize({archElement, viewKey}) {
    //TODO
    // var sideKey = viewKey === 'top' ? 'top' : ArchElement.getSideKey
    return ArchElement.getTypeData({type: archElement.type}).getSize({props: archElement, sideKey: viewKey});
  },

  getWallprintInElevation({archElement, elevation}) {
    var {width, height} = ArchElement.getSize({archElement, viewKey: 'front'});
    var wall = ArchElement.get('wall', {archElement});
    var wallPosition = {x: Wall.getXOffsetInElevation({wall, elevation})};
    var position = lib.object.sum(wallPosition, archElement.position);
    var {x, y} = position;

    return [
      {x: x, y: y},
      {x: x, y: y + height},
      {x: x + width, y: y + height},
      {x: x + width, y: y}
    ];
  },

  getWallprintLines({archElement, elevation}) {
    var wallprint = ArchElement.getWallprintInElevation({archElement, elevation});

    return {
      left: {from: wallprint[0], to: wallprint[1]},
      top: {from: wallprint[1], to: wallprint[2]},
      right: {from: wallprint[2], to: wallprint[3]},
      bottom: {from: wallprint[3], to: wallprint[0]}
    };
  },

  getZIndex({archElement, elevation, viewKey}) {
    if (viewKey === 'front') {
      var {wall} = ArchElement.get(['wall'], {archElement});

      var archElementModifier = 0.001;

      if (archElement.type === 'soffit') {
        archElementModifier = _.get(archElement, 'customData.dimensions.depth', 0.001);
      }
      else if (archElement.type === 'outlet') {
        archElementModifier = 25.125 + 0.001 + 0.001;
      }

      return Wall.getZIndexFor({wall, elevation}) + archElementModifier;
    }
    else {
      return 0;
    }
  },

  getScript({archElement, sideKey = 'top'}) {
    var typeData = ArchElement.getTypeData({archElement});

    return _.get(typeData.scripts, sideKey, '');
  },

  create({props, resourceActions}) {

  },

  update({id, archElement = {}, cachedArchElement = {}, props, resourceActions, pushToUndoQueue}) {
    if (!id) id = archElement.id || cachedArchElement.id;

    resourceActions.updateArchElement({id, props});

    if (pushToUndoQueue && cachedArchElement) pushToUndoQueue({type: 'archElement', eventKey: 'transformEnd', instance: cachedArchElement});
  },

  destroy({archElement, resourceActions, pushToUndoQueue}) {
    if (pushToUndoQueue) {
      pushToUndoQueue({type: 'archElement', eventKey: 'destroy', instance: archElement, data: {}});
    }

    resourceActions.destroyArchElement({id: archElement.id});
  },

  getNearestWallData ({archElement, position2d, mousePosition}) {
    let nearestWallData = {};
    const room = ArchElement.get('room', {archElement});

    var wallsData = _.sortBy(_.map(_.values(Room.get('walls', {room})), wallCandidate => {
      var isCurrentWall = wallCandidate.id === archElement.wallId;
      // var widthOffsetPoint = lib.object.sum(position2d, lib.trig.rotate({point: {y: ArchElement.getSize({archElement, viewKey: 'top'}).height, x: 0}, byDegrees: ArchElement.getRotationInRoom({archElement})}));

      var distanceCandidates = [
        lib.trig.distance({fromPoint: position2d, toLine: Wall.getLine({wall: wallCandidate}).inRoom}),
        // lib.trig.distance({fromPoint: widthOffsetPoint, toLine: Wall.getLine({wall: wallCandidate}).inRoom}),
      ];

      return {wall: wallCandidate, distance: _[isCurrentWall ? 'max' : 'min'](distanceCandidates)};
    }), ['distance', wallData => {
      var distanceIsZero = wallData.distance < Number.EPSILON;
      var isCurrentWall = wallData.wall.id === archElement.wallId;

      //HINT want to maintain current wall when the distance is 0, but move to new wall when you go over
      return distanceIsZero ? (isCurrentWall ? 0 : 1) : (isCurrentWall ? 1 : 0);
    }]);

    var nearestWall = wallsData[0].wall;

    var nearestWallLine = Wall.getLine({wall: nearestWall});

    nearestWallData.nearestWallId = nearestWall.id;

    var positionOnWall = lib.trig.nearestPoint({point: position2d, onLine: nearestWallLine.inRoom});
    // var widthOffsetPointOnWall = lib.object.sum(positionOnWall, lib.trig.rotate({point: {y: ArchElement.getSize({archElement, viewKey: 'top'}).height, x: 0}, byDegrees: ArchElement.getRotationInRoom({archElement, wall: nearestWall, position: position2d})}));

    // if (lib.number.round(lib.trig.distance({fromPoint: widthOffsetPointOnWall, toLine: nearestWallLine.inRoom}), {toNearest: 0.00001}) > Number.EPSILON) {
    //   positionOnWall = widthOffsetPointOnWall;
    // }

    nearestWallData.xOnWall = K.round(lib.trig.distance({fromPoint: nearestWallLine.inRoom.from, toPoint: positionOnWall}));

    if (archElement.type === 'door') {
      var opens = archElement.customData.opens;
      if (mousePosition) {
        var perpendicularData = lib.waterfall([{key: 'in', theta: Math.PI / 2}, {key: 'out', theta: -Math.PI / 2}], [
          [_.map, data => ({...data, alpha: lib.trig.normalize({radians: Wall.getAlpha({wall: nearestWall}) + data.theta})})],
          [_.minBy, data => {
            return lib.trig.distance({
              fromPoint: mousePosition,
              toPoint: lib.object.sum(positionOnWall, lib.trig.rotate({point: {x: 1, y: 0}, byRadians: data.alpha}))
            });
          }]
        ]);

        var opens = perpendicularData.key;
      }

      nearestWallData.opens = opens;
    }

    return nearestWallData;
  },

  getFieldGroups({archElement, viewKey, elevation, viewMode}) {
    const typeData = ArchElement.getTypeData({archElement});

    var fieldSetGroups = [
      {title: typeData.title, properties: []},
    ];

    fieldSetGroups[0].properties.push(...[
      ...typeData.editablePropFieldSets,
      {key: 'customData.isLocked', title: 'Locked', type: 'checkbox', views: ['front', 'top', 'bottom', 'left', 'right'], options: [
        {value: false, title: 'Unlocked'}, {value: true, title: 'Locked'}
      ]},
    ]);

    return fieldSetGroups;
  },

  getSnapToLines({archElement, elevation, room, viewKey}) {
    const typeData = ArchElement.getTypeData({archElement});
    const snapToFloor = typeData.snapToFloor;
    const snapToWall = typeData.classification !== 'floor';
    const snapToLines = [];

    if (snapToFloor && viewKey === 'front') {
      const walls = Elevation.get('walls', {elevation});
      const outline = Elevation.getOutline({elevation});

      if (walls?.length > 0 && outline) {
        var xRange = {from: _.minBy(outline, 'x').x, to: _.maxBy(outline, 'x').x};

        if (_.some(walls, wall => _.some(_.map(Wall.getFloorLines({wall, elevation}), 'y'), y => y !== 0))) {
          var wall = _.find(walls, wall => {
            return _.some(xRange, sideKey => {
              return Elevation.getWallX({wall, elevation}) <= sideKey && sideKey <= (Elevation.getWallX({elevation, wall}) + Elevation.getVisibleWallWidth({elevation, wall}));
            });
          });

          if (wall) {
            var overlappingFloorLines = _.filter(Wall.getFloorLines({wall, elevation}), line => {
              return lib.number.rangesOverlap({r1: _.mapValues(line, 'x'), r2: xRange, inclusive: false});
            });

            snapToLines.push(...overlappingFloorLines);
          }
        }
      }
    }
    else if (snapToWall && viewKey === 'top') {
      snapToLines.push(...lib.polygon.toLines(_.map(room.plan.points, point => lib.object.sum(point, room.plan.position))));
    }

    return snapToLines;
  },

  //TODO doesn't exist in current DE
  // getSideKey({viewKey, container, elevation}) {
  //   var sideKey = 'top';

  //   if (viewKey === 'front') {
  //     var theta = lib.round(lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), container.rotation]}), {toNearest: 5});

  //     sideKey = 'back';
  //     var sideCandidates = [
  //       {theta: 0, sideKey: 'front'},
  //       {theta: 270, sideKey: 'right'},
  //       {theta: 90, sideKey: 'left'}
  //     ];
  //     sideCandidates.forEach(candidate => {
  //       if (candidate.theta === theta) {
  //         sideKey = candidate.sideKey;
  //       }
  //     });
  //   }

  //   return sideKey;
  // }
};

export default ArchElement;
