import React, { useContext, useEffect, useState } from 'react';

import { Circle, Group, Label, Line, Tag } from 'react-konva';
import { CanvasText, NumericInputContext, CanvasDataContext } from 'canvas';

import PositionHelper from 'helpers/position-helper';
import Product from 'project-helpers/product';

import lib from 'lib';
import _ from 'lodash';
import K from 'k';

const CanvasSettingsGroup = (props) => {
  const {canvasData, position, size, resourceKey, resourceId, resource, ...rest} = props;

  //get settings for resource key and id
  //dont recalculate while transforming


  var settings = [];
  
  if (resourceKey === 'product') {
    settings = Product.getCanvasSettings({product: resource});
  }

  var handleSmallLargeCircleClick = (value) => () => {
    // handleSmallLargeSettingChange(value);

    props.onSettingsChange({key: 'size', value});
  }

  return (
    <Group position={PositionHelper.toCanvas(lib.object.sum(position, {x: size.width / 2, y: size.height / 2}), canvasData)}>
      {_.map(settings, ({type, value}, index) => {  
        if (type === 'smallLarge') {
          return (
            <Group key={index} position={{x: -2 * canvasData.scale, y: -2 * canvasData.scale}}>
              <Circle strokeWidth={0.5} stroke={'black'} fill={value === 'small' ? 'black' : 'rgb(204, 204, 204)'} radius={1 * canvasData.scale} onClick={handleSmallLargeCircleClick('small')}/>
              <Circle strokeWidth={0.5} stroke={'black'} fill={value === 'large' ? 'black' : 'rgb(204, 204, 204)'} position={{x: 4 * canvasData.scale, y: 0}} radius={1.5 * canvasData.scale} onClick={handleSmallLargeCircleClick('large')}/>
            </Group>
          );
        }
      })}
    </Group>
  );
};

export default function CanvasSettingsGroupWithContext(props) {
  let canvasData = useContext(CanvasDataContext);
  let numericInputData = useContext(NumericInputContext);

  return <CanvasSettingsGroup {...props} {...{canvasData, numericInputData}} />;
}
