import _ from 'lodash';

if (!window.lib) require('henrybuilt-js-library');

var lib = window.lib;


lib.string.numberToLetters = number => {
  for (var ret = '', a = 1, b = 26; (number -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((number % b) / a) + 65) + ret;
  }

  return ret;
};

lib.trig.extendedLineFrom = ({point, alpha}) => ({
  from: lib.object.sum(point, lib.trig.rotate({
    point: {x: -100000, y: 0}, byRadians: alpha
  })),
  to: lib.object.sum(point, lib.trig.rotate({
    point: {x: 100000, y: 0}, byRadians: alpha
  }))
});

lib.number.rangesOverlap = ({r1, r2, inclusive=true} = {}) => {
  var x1 = r1.from, x2 = r1.to, y1 = r2.from, y2 = r2.to;

  if (x1 > x2) {
    var temp = x1;
    x1 = x2;
    x2 = temp;
  }

  if (y1 > y2) {
    var temp = y1;
    y1 = y2;
    y2 = temp;
  }

  var outerDistance = Math.max(x2, y2) - Math.min(x1, y1);
  var netWidth = (x2 - x1) + (y2 - y1);

  return inclusive ? outerDistance <= netWidth : outerDistance < netWidth;
};

lib.trig.anglesAreParallel = ({a1, a2, mode='radians'}) => {
  a1 = lib.trig.normalize({[mode]: a1});
  a2 = lib.trig.normalize({[mode]: a2});

  if (a1 >= Math.PI) a1 -= Math.PI;
  if (a2 >= Math.PI) a2 -= Math.PI;

  return a1 === a2;
};

lib.trig.anglesAreEqual = ({a1, a2, mode='radians'}) => {
  a1 = lib.trig.normalize({[mode]: a1});
  a2 = lib.trig.normalize({[mode]: a2});

  return Math.abs(a1 - a2) <= 0.00000001;
};

lib.number.evenlyDistributedDistancesFor = ({distance, maxDistance, precision}) => {
  var count = _.ceil(distance / maxDistance);
  var subDistance = distance / count;

  if (precision) subDistance = lib.number.round(subDistance, {toNearest: precision});

  //WARNING total needs to add up to distance, despite rounding, so last width is relative
  var distances = _.times(count, index => {
    var isMiddle = index === Math.floor(count / 2);

    return isMiddle ? (distance - subDistance * (count - 1)) : subDistance;
  });

  return distances;
};

lib.filterEvery = (collection, predicates) => {
  return _.filter(collection, (...args) => {
    return _.every(predicates, predicate => predicate(...args));
  });
};

lib.dimensions = {
  fractionStringFromDecimal: decimal => lib.number.toFraction(decimal)
};

lib.DimensionConstrainer = window.DimensionConstrainer;

lib.trig.pointsAreEqual = ({p1, p2}) => {
  return p1 && p2 && p1.x === p2.x && p1.y === p2.y;
};

lib.trig.linesAreEqual = ({l1, l2}) => {
  return (lib.trig.pointsAreEqual({p1: l1.from, p2: l2.from}) && lib.trig.pointsAreEqual({p1: l1.to, p2: l2.to}))
      || (lib.trig.pointsAreEqual({p1: l1.from, p2: l2.to}) && lib.trig.pointsAreEqual({p1: l1.to, p2: l2.from}));
};

lib.math.pointIsOnLine = ({line, point}) => {
  var lpa = line.from, lpb = line.to, p = point;

  var d = (a, b) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

  return Math.abs(d(lpa, lpb) - d(lpa, p) - d(lpb, p)) < 0.00000001;
};

lib.event = {};

lib.event.keyPressed = (event, key) => {
  var keyCode = event.keyCode;
  var pressed = false;

  if (key === 'left') pressed = keyCode === 37;
  if (key === 'up') pressed = keyCode === 38;
  if (key === 'right') pressed = keyCode === 39;
  if (key === 'down') pressed = keyCode === 40;

  if (key === 's') pressed = keyCode === 83;
  if (key === 'y') pressed = keyCode === 89;
  if (key === 'z') pressed = keyCode === 90;
  if (key === '+') pressed = keyCode === 187;
  if (key === '-') pressed = keyCode === 189;
  if (key === 'd') pressed = keyCode === 68;
  if (key === 'q') pressed = keyCode === 81;
  if (key === 't') pressed = keyCode === 84;
  if (key === 'v') pressed = keyCode === 86;
  if (key === 'm') pressed = keyCode === 77;
  if (key === 'e') pressed = keyCode === 69;
  if (key === 'c') pressed = keyCode === 67;
  if (key === 'x') pressed = keyCode === 88;
  if (key === 'a') pressed = keyCode === 65;
  if (key === 'o') pressed = keyCode === 79;

  if (key === 'enter') pressed = keyCode === 13;
  if (key === 'ctrlcmd') pressed = (event.ctrlKey || event.metaKey || event.which === 22 || event.which === 224);
  if (key === 'alt') pressed = (event.altKey);
  if (key === 'esc') pressed = keyCode === 27;
  if (key === 'tab') pressed = keyCode === 9;
  if (key === 'shift') pressed = event.shiftKey;
  if (key === 'backspace') pressed = keyCode === 8;
  if (key === 'space') pressed = keyCode === 32;
  if (key === 'delete') pressed = keyCode === 8 || keyCode === 46;

  return pressed;
};

lib.event.arrowKeyCode = (event) => {
  var arrowKeyCode = false;
  var keyCode = event.keyCode;

  if (keyCode === 37 ) arrowKeyCode = 'left';
  if (keyCode === 38) arrowKeyCode = 'up';
  if (keyCode === 39) arrowKeyCode = 'right';
  if (keyCode === 40) arrowKeyCode = 'down';

  return arrowKeyCode;
}

lib.event.numberKeyPressed = (event) => {
  var numberKeyCodes = [49, 50, 51, 52, 53, 54, 55, 56, 57, 48];
  var keyCode = event.keyCode;

  return _.includes(numberKeyCodes, keyCode);
};

lib.math.linesIntersectInclusive = ({l1, l2, inclusive = true}) => {
  var linesIntersect = false;
  var {from: {x: a, y: b}, to: {x: c, y: d}} = l1;
  var {from: {x: p, y: q}, to: {x: r, y: s}} = l2;

  var det, gamma, lambda;
  det = (c - a) * (s - q) - (r - p) * (d - b);

  if (det !== 0) {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;

    linesIntersect = (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
  }

  if (inclusive && det !== 0 && !linesIntersect) {
    linesIntersect = _.some(l1, point => lib.math.pointIsOnLine({point, line: l2})) || _.some(l2, point => lib.math.pointIsOnLine({point, line: l1}));
  }

  return linesIntersect;
};

//https://stackoverflow.com/a/6853926
lib.math.minPointDistanceFromLine = ({point, line}) => {
  var {x, y} = point;
  var {x: x1, y: y1} = line.from;
  var {x: x2, y: y2} = line.to;

  var A = x - x1;
  var B = y - y1;
  var C = x2 - x1;
  var D = y2 - y1;

  var dot = A * C + B * D;
  var len_sq = C * C + D * D;
  var param = -1;
  if (len_sq != 0) //in case of 0 length line
      param = dot / len_sq;

  var xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  }
  else if (param > 1) {
    xx = x2;
    yy = y2;
  }
  else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  var dx = x - xx;
  var dy = y - yy;
  return Math.sqrt(dx * dx + dy * dy);
}

lib.polygon.makeClockwise = ({points}) => {
  const isClockwise = lib.math.polygon.clockwise({points});

  if (!isClockwise) {
    // Get the center (mean value) using reduce
    const center = lib.polygon.center({points});

    // Add an angle property to each point using tan(angle) = y/x
    const angles = points.map(({ x, y }, index) => {
      const next = lib.array.next(points, index);
      return { x, y, angle: lib.trig.alpha({p1: {x, y}, p2: next})};
    });

    // Sort your points by angle
    points = angles.sort((a, b) => a.angle - b.angle);
  }

  return points;
};

//WARNING do not move to henrybuilt-js-library for now - not a client library yet

lib.cookie = {};

lib.cookie.set = ({scope, key, value, days, domain}) => {
  App.setCookie(scope, key, JSON.stringify(value), days, domain);
};

lib.cookie.get = ({scope, key, defaultValue, parse=true}) => {
  var value = App.getCookie(scope, key);

  if (value === null) {
    value = undefined;
  }
  else {
    if (parse) value = JSON.parse(value);
  }

  if (defaultValue !== undefined && value === undefined) {
    value = defaultValue;
  }

  return value;
};

//HINT this function exists in lib.number, but is a performance liability because it
//HINT requires lib.script every time the function is called (we call it every time we render a dimension line)
lib.toNormalScriptFraction = (number, {normalscript=false}={}) => {
  var string = '';
  var remainder = Math.round((number - Math.floor(number)) * 100000) / 100000;

  //Strip integer off the front
  if (number >= 1 || remainder === 0) {
    string += Math.floor(number);
  }

  if (remainder > 0) {
    //Identify the fraction parts
    var gcd = function(a, b) {
      if (!b) return a;
      a = parseInt(a);
      b = parseInt(b);
      return gcd(b, a % b);
    };

    var len = remainder.toString().length - 2;

    var denominator = Math.pow(10, len);
    var numerator = remainder * denominator;
    var divisor = gcd(numerator, denominator);
    numerator /= divisor;
    denominator /= divisor;

    //Add the fraction to the string
    if (number >= 1) string += ' ';

    numerator = normalscript ? numerator.toFixed() : lib.string.toSuperscript(numerator.toFixed());
    var slash = normalscript ? '/' : String.fromCharCode(8260);
    denominator = normalscript ? denominator.toFixed() : lib.string.toSubscript(denominator.toFixed());

    string += [numerator, slash, denominator].join('');
  }

  return string;
};

lib.materials.getIsVeneer = ({material}) => {
  return _.includes([
    191, 194, 1, 158, 166, 176, 103, 104, 105, 239, 1, 3, 4, 5, 6, 8,
    135, 151, 152, 153, 154, 155, 170, 171, 172, 173, 174,
    175, 176, 177, 178, 180, 279, 303, 8, 9, 136, 143, 144, 145, 146, 147, 148, 149, 150, 158,
    160, 161, 162, 163, 164, 165, 166, 167, 168, 179, 191, 192, 193, 194, 240, 249, 250, 251,
    262, 304, 316, 317, 319, 320, 321, 325, 329, 330, 332, 337, 346, 349, 339, 350, 351, 352, 353, 358, 368, 369, 370, 405, 410, 412, 413, 431
  ], material.id) || _.includes([1, 3, 12], material.materialTypeId);
},

lib.price.obfuscateShipping = ({project}) => {
  var obfuscateShipping = true;

  if (project) {
    var isShippingTaxExemptState = _.includes([
      'AK', 'AL', 'AZ', 'CA', 'CO', 'IA', 'ID', 'LA',
      'MA', 'MD', 'ME', 'MO', 'UT', 'VA', 'WY'
    ], project.deliveryState);

    //only CA HI AK were broken out/not obfuscated
    var isPreAll50StatesRevalation = project.effectivePricingDate < Date.parse('01-04-2023');

    var revelationDate = Date.parse('12-02-2018');
    var newDiscountsUsed = project.discount > 0 || (project.discountPercentage && project.discountPercentage > 0);

    var preShippingRevelation = project.effectivePricingDate < revelationDate && !newDiscountsUsed;

    obfuscateShipping = !(
      (isPreAll50StatesRevalation ? project.deliveryState === 'CA' : isShippingTaxExemptState) ||
      project.deliveryState == 'HI' ||
      project.deliveryState == 'AK' || //intentionally leaving this here because AK is here for both shipping tax exempt and needs to be explicitly broken out for other pricing reasons
      project.international ||
      project.companyKey === 'vp' ||
      project.orderType === 'change-order' ||
      preShippingRevelation ||
      (project.customShippingPrice > 0)
    );
  }

  return obfuscateShipping;
}

var App = {};

/**
 * Creates a new cookie
 * @param  {string} scope pt, hb, cs, oc...
 * @param  {string} name key
 * @param  {string} value value
 * @param  {integer} days expiration length
 * @param  {string} domain url
 */
 App.setCookie = function(scope, name, value, days, domain) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
  }
  else {
    var expires = "";
  }

  if (domain) {
    var domain = domain.replace(/(^\w+:|^)\/\//, '');

    if (domain.includes("localhost")) domain="localhost";

    domain = "; domain="+domain;
  }
  else {
    var domain = "";
  }

  document.cookie = scope+'_'+name+"="+value.replace(/"/g, '%22').replace(/,/g, '%2C')+expires+domain+"; path=/";
};

/**
 * Read a key given a scope
 * @param  {string} scope pt, hb, cs, oc...
 * @param  {string} name key
 * @param  {string} domain url
 */
App.getCookie = function(scope, name) {
  if (scope) name = `${scope}_${name}`;

  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length).replace(/%22/g, '"').replace(/%2C/g, ',');
  }
  return null;
};

export default lib;
