import React, { Component } from 'react';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import Popup from 'components/popup';
import TextInput from 'components/text-input';

export default class DxfPopup extends React.PureComponent {
  state = {roomTitle: ''};

  render() {
    return (
      <Popup onClose={() => this.props.close()}>
        <div>
          <label style={{marginRight: K.margin}}>New room title:</label>
          <TextInput value={this.state.roomTitle} onInput={(value) => {this.setState({roomTitle: value})}}
            onSubmit={() => {
              this.props.handleAddRoom({title: this.state.roomTitle});
              this.props.close();
            }}
            autoFocus submitOnEnter
          />
        </div>
        <div style={{marginTop: K.spacing, justifyContent: 'flex-end', display: 'flex'}}>
          <button onClick={() => this.props.close()} style={{marginRight: 5}}>Cancel</button>
          <button onClick={() => {
            this.props.handleAddRoom({title: this.state.roomTitle});
            this.props.close();
          }}>Create</button>
        </div>
      </Popup>
    );
  }
}