import React from 'react';
import _ from 'lodash';
import lib from 'lib';
import { connect } from 'redux/index.js';
import { resourceActions } from 'redux/index.js';
import xIcon from '../../../assets/x-icon-black.png';
import K from 'k';
import ProductInfoHudElement from '../hud-elements/product-info-hud-element';

import AccessoriesItem from './accessories-item';
import './accessories-view.scss';
import AccessoriesGuidePopup from '../popups/accessories-guide-popup';

class AccessoriesView extends React.Component {
  state = {
    infoPopupShowing: false,
    infoPopupSubject: undefined,
    accessoriesGuidePopupShowing: false
  };

  componentDidMount() {
    if (this.props.project.companyKey === 'vp' && !lib.cookie.get({scope: 'cfg', key: 'has-seen-accessories-guide'})) {
      this.setState({accessoriesGuidePopupShowing: true});
    }
  }

  handleDelete = (id) => {
    this.props.destroyProduct({id});
  };

  handleChangeQuantity = ({id, quantity}) => {
    this.props.updateProduct({id, props: {quantity}});
  };

  closeAccessoriesGuidePopup = () => {
    lib.cookie.set({scope: 'cfg', key: 'has-seen-accessories-guide', value: true});
    this.setState({accessoriesGuidePopupShowing: false});
  };

  render() {
    var {pricingRules, project, productTypes, materialClasses, productOptions, isEmployee} = this.props;

    const {companyKey} = project;

    var accessoriesData = _.map(this.props.products, product => {
      var productType = _.find(this.props.productTypes, {id: product.productId});
      return {
        id: product.id,
        thumbnail: this.props.media[product.id] || '', //'https://assets.kogan.com/images/livingtoday/LVT-7905340653801/2-9d836f74f3-cheeseboardwithknifeset_2.png?auto=webp&canvas=753%2C502&fit=bounds&height=502&quality=90&width=753',
        title: productType.title,
        info: '',
        quantity: product.quantity,
        price: lib.price.priceDataFor('productInstance', product, {
          pricingRules, project, products: productTypes, materialClasses, productOptions}).total.toFormat('$0,0')
      };
    });

    return (
      <>
        <div className='accessories-view' {...(this.props.isAdding ? {style: {marginLeft: '270px'}} : {})}>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: K.spacing * 2}}>
            <h2>Accessories</h2>
            <div onClick={this.props.toggleAccessoriesView}>
              <img style={{...K.defaultIconSize, cursor: 'pointer'}}src={xIcon} alt={'x icon'}/>
            </div>
          </div>
          <div className='accessories-list'>
            {accessoriesData.length > 0
              ? (accessoriesData.map(accessories => (
                <AccessoriesItem
                  showInfoPopup={({accessoriesId}) => this.setState({infoPopupShowing: true, infoPopupSubject: _.find(this.props.products, {id: accessoriesId})})}
                  key={accessories.id}
                  accessories={accessories}
                  handleDelete={this.handleDelete}
                  handleChangeQuantity={this.handleChangeQuantity}
                />
              )))
              : <React.Fragment>
                <p>No accessories have been selected.</p>
                <p>You can select accessories from the black + button in the bottom left.</p>
              </React.Fragment>
            }
          </div>
        </div>
        {this.state.infoPopupShowing && (
          <ProductInfoHudElement
            onClose={() => this.setState({infoPopupShowing: false})}
            product={this.state.infoPopupSubject}
            {...{isEmployee, project}}
            resourceKey={'product'}
          />
        )}
        {this.state.accessoriesGuidePopupShowing && (
          <AccessoriesGuidePopup
            companyKey={companyKey}
            close={this.closeAccessoriesGuidePopup}
          />
        )}
      </>
    );
  }
}

export default connect({
  mapState: state => {
    var productTypes = _
      .chain(state.resources.productTypes.byId)
      .filter({isStandalone: 1, isSellable: 1, isDiscontinued: 0})
      .sortBy([({categoryId}) => !_.includes([81], categoryId), 'categoryId'])
      .value();

    return {
      products: _.filter(state.resources.products.byId, product => _.includes(_.map(productTypes, 'id'), product.productId)),
      productTypes,
      project: _.values(state.resources.projects.byId)[0],
      materialClasses: state.resources.materialClasses.byId,
      pricingRules: state.resources.pricingRules.byId,
      productOptions: state.resources.productOptions.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.products, ['trackProducts', 'updateProduct', 'destroyProduct']),
    ..._.pick(resourceActions.materialClasses, ['trackMaterialClasses']),
    ..._.pick(resourceActions.pricingRules, ['trackPricingRules']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions']),
  }
})(AccessoriesView);
