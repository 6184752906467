import K from 'k';

const getTextSize = ({
  text,
  fontSize = 12.6,
  fontFamily = K.fontFamily,
  fontWeight = 'normal',
  canvasData,
}) => {
  // var oldFont = context.oldFont;
  if (!canvasData.layer) return {width: 0, height: 0};

  var context = canvasData.layer?.canvas.context._context;

  context.font = `${fontWeight} ${Math.round(fontSize * 100) / 100}px ${fontFamily} `;
  context.letterSpacing = 0;

  const measurement = context.measureText(text);

  return {width: measurement.width, height: (measurement.actualBoundingBoxAscent + measurement.actualBoundingBoxDescent) * 1.2};
};

export default getTextSize;
