import React, { useContext, useEffect, useState, useRef } from 'react';
import { CanvasDataContext, CanvasPath, CanvasText, CanvasCircle } from 'canvas';
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber';
import { EffectComposer, Outline } from '@react-three/postprocessing';
import { BlendFunction, Resizer, KernelSize } from 'postprocessing';
import { Edges } from '@react-three/drei';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import DetailsHelper from 'helpers/details-helper';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import roboto from '../assets/fonts/roboto.json';

import { connect } from 'redux/index.js';
import { withErrorBoundary } from 'react-error-boundary';

import _ from 'lodash';
import K from 'k';
import lib from 'lib';
import Room from 'project-helpers/room';
import Floor from 'project-helpers/floor';
import PositionHelper from 'helpers/position-helper';
import Container from 'project-helpers/container';
import Volume from 'project-helpers/volume';
import CanvasScript3D from 'components/three/canvas-script-3d';
import Cube3D from '../components/three/cube-3d';
import Mesh3D from '../components/three/mesh-3d';
import Scene3D from '../components/three/scene-3d';
import ErrorFallback from 'components/error-fallback';

extend({TextGeometry});
extend({FontLoader});

//TODO tint

//TODO https://github.com/mapbox/earcut real polygons, not just rects

// var [posA, posB, posC] = points;

// var planeNormal = dot(_.times(3, i => posB[i] - posA[i]), _.times(3, i => posC[i] - posA[i]));

// var tintAngle = Math.atan2(planeNormal[2], planeNormal[0]);
// var tintAngle2 = Math.atan2(planeNormal[1], planeNormal[0]);

// fill = new Color(fill);

// if (!isShadow) fill = fill.darken(0.2 * Math.max(0, Math.cos(tintAngle)) + 0.1 * Math.cos(tintAngle2)).hex();

//scribes - extend size by scribe size?
//architectural elements
//wall shapes
//opencase
//island seating
//annotations/drawings
//3D export

const CanvasRoom3D = ({roomData, viewOffset, isContextElement, columnWidth, rowHeight, showWallsAndArchElements, activeDetailLevel, activeFillMode, meshRefs, hideArchitecture, includeRoomPosition, onClick, showLabel, materialClasses, isArchetype}) => {
  const [hasError, setHasError] = useState(false);
  var [isPanning, setIsPanning] = useState(false);
  var [mouseDown, setMouseDown] = useState(false);

  if (hasError) {
    return <ErrorFallback />;
  }
  else {
    try {
      var threeDObjects = [];

      var ceilingHeight = _.get(roomData, 'ceilingHeight', 96);

      if (showWallsAndArchElements) {
        _.forEach(roomData.plan.points, (point, p) => {
          if (!point.isPseudoPoint) {
            var wall = _.find(roomData.walls, {pointId: point.id})
            var wallLine = lib.trig.extend({line: {from: point, to: lib.array.next(roomData.plan.points, p)}, by: 0});
            var alpha = -lib.trig.alpha({p1: wallLine.from, p2: wallLine.to});

            wallLine = _.mapValues(wallLine, point => lib.object.sum(lib.trig.translate({point, by: 1, alpha: -alpha + -Math.PI / 2}), includeRoomPosition ? roomData.plan.position : undefined));

            var nodes = [];
            nodes.push({
              nodeProps: {
                position: {x: 0, y: 0, z: 0},
                dimensions: {width: lib.trig.distance({fromPoint: wallLine.from, toPoint: wallLine.to}), depth: 1, height: _.get(wall, 'outline.height') || ceilingHeight},
                rotation: 0,
                fill: '#ddd',
                opacity: 1
              }
            })

            threeDObjects.push({
              objectProps: {
                key: point.id,
                type: 'wall',
                position: {x: wallLine.from.x, y: 0, z: wallLine.from.y},
                dimensions: {width: lib.trig.distance({fromPoint: wallLine.from, toPoint: wallLine.to}), depth: 1, height: _.get(wall, 'outline.height') || ceilingHeight},
                rotation: alpha //alpha lib.trig.degreesToRadians(container.rotation)
              },
              nodes
            })
          }

          // planes.push({key: `wall-${p}`, fill: `rgba(0, 0, ${p * 0}, 0.3)`, points: _.map([
          //   {x: wallLine.from.x, y: 0, z: wallLine.from.y},
          //   {x: wallLine.to.x, y: 0, z: wallLine.to.y},
          //   {x: wallLine.to.x, y: 96, z: wallLine.to.y},
          //   {x: wallLine.from.x, y: 96, z: wallLine.from.y},
          // ], point => lib.object.sum({x: roomData.plan.position.x, z: roomData.plan.position.y}, point))});
        });
      }

      // planes.push({key: 'fl', fill: 'rgba(0, 0, 0, 0.3)', points: _.map(roomData.plan.points, point => ({x: point.x + roomData.plan.position.x, y: 0, z: point.y + roomData.plan.position.y}))});

      var {containerInstances: containers, volumes, plan, gridPosition, gridTextOffset, gridOffset, title} = roomData;
      var hiddenContainers = _.filter(containers, (container) => container.customData.hideIn3d || Container.getTypeDataFor({container}).isOrnament || (!container.position || _.isEqual(container.position, {})));

      containers = _.filter(containers, container => !_.includes(hiddenContainers, container));

      _.forEach(containers, (container) => {
        var shouldPushContainer = true;

        if (container.customData.hideIn3d) shouldPushContainer = false;

        if (container.type === 'countertop') {
          if (_.find(hiddenContainers, hiddenContainer => {
            return !Container.getTypeDataFor({container: hiddenContainer}).isOrnament && Container.sharesFootprintWith({sourceContainer: container, container: hiddenContainer});
          })) {
            shouldPushContainer = false;
          }
        }


        if (shouldPushContainer) {
          var nodes = Container.get3dNodesFor({container, activeFillMode, isArchetype, isContextElement});

          threeDObjects.push({
            objectProps: {
              key: container.id,
              type: 'container',
              position: lib.object.sum({
                x: includeRoomPosition ? plan.position.x : 0,
                z: includeRoomPosition ? plan.position.y : 0
              }, container.position),
              dimensions: container.dimensions,
              rotation: -lib.trig.degreesToRadians(container.rotation),
            },
            nodes
          });

        }
      });

      if (!hideArchitecture) { //TODO: test to see if it's working, can't tell yet.
        _.forEach(_.values(volumes), (volume) => {
          if (!volume.customData.hideIn3d) {
            //HINT match architecture
            let fill = '#ddd';//Volume.getFill({volume, activeFillMode: activeFillMode === 'grayscale' ? 'grayscale' : 'unitType'});

            var nodes = [];
            nodes.push({
              nodeProps: {
                position: {x: 0, y: 0, z: 0},
                dimensions: volume.dimensions,
                rotation: 0,
                fill,
                opacity: 1
              }
            })

            threeDObjects.push({
              objectProps: {
                key: volume.id,
                type: 'volume',
                position: lib.object.sum({x: includeRoomPosition ? plan.position.x : 0, z: includeRoomPosition ? plan.position.y : 0}, volume.position),
                dimensions: volume.dimensions,
                rotation: -lib.trig.degreesToRadians(volume.rotation)
              },
              nodes
            })
          }
        });
      }

      var offsetPosition = [viewOffset.x, 0, viewOffset.y];
      var gridPosition = gridPosition ? [(gridPosition.x * columnWidth) + (gridOffset?.x || 0), 0 + (gridOffset?.y || 0), (gridPosition.y * rowHeight) + (gridOffset?.z || 0)] : [0, 0, 0];
      var gridTextOffset = gridTextOffset ? [gridTextOffset.x, gridTextOffset.y, gridTextOffset.z] : [0, 0, 0];
      var groupPosition = [offsetPosition[0] + gridPosition[0], offsetPosition[1] + gridPosition[1], offsetPosition[2] + gridPosition[2]];
      var font = new FontLoader().parse(roboto);

      return (
        <group
          position={groupPosition}
          onPointerDown={(event) => {
            setMouseDown(true);
          }}
          onPointerMove={(event) => {
            if (mouseDown) setIsPanning(true);
          }}
          onPointerUp={(event) => {
            setMouseDown(false);
          }}
          onClick={(event) => {
            if (onClick && !isPanning) onClick({event, roomData});

            setMouseDown(false);
            setIsPanning(false);
          }}
          // {...(onClick ? {onClick: (event) => onClick({event, roomData})} : {})}
        >
          {/* HINT: -50 is to adjust camera position to be 50" off ground */}
          {/* <pointLight position={[0, 300, 0]} intensity={0.4} color={'#fff'} castShadow shadow-mapSize-height={1024} shadow-mapSize-width={1024}/> */}
          {_.map(threeDObjects, ({objectProps, nodes}, i) => (<CanvasScript3D key={i} {...{objectProps, nodes}} />))}
          {showLabel && (
            <mesh
              position={gridTextOffset}
              rotation-x={lib.trig.degreesToRadians(-90)}
            >
              <textGeometry args={[title, {font, height: 0, size: 10}]} />
              <meshPhysicalMaterial attach="material" color={'black'} />
            </mesh>
          )}
        </group>
      );
    }
    catch (error) {
      global.handleError({error, info: {componentStack: error.stack}, message: 'CanvasRoom3D'});

      setHasError(true);

      return null;
    }
  }
};

function CanvasRooms3D({className, viewOffset, isContextElement, showWallsAndArchElements, activeDetailLevel, activeFillMode, cameraZoom, cameraPosition, roomsData, floor, hideArchitecture, cameraMouseControls, onRoomClick, showLabel, isArchetype, includeRoomPosition=false, materialClasses}) {
  const [hasError, setHasError] = useState(false);
  const meshRefs = useRef();

  if (hasError) {
    return <ErrorFallback />;
  }
  else {
    try {
      // cubes = _.map(cubes, cube => {
      //   var origin = {x: 'near', y: 'near', z: 'near'};

      //   var type = 'cube';
      //   var originMultipliers = _.mapValues(origin, value => type === 'cube' ? _.get({near: 0.5, center: 0, far: -0.5}, value, value || 0) : value);

      //   var dimensions = [cube.dimensions.width || 0, cube.dimensions.height || 0, cube.dimensions.depth || 0];

      //   var position = [
      //     ((cube.position.x || 0) + originMultipliers.x * dimensions[0]),
      //     ((cube.position.y || 0) + originMultipliers.y * dimensions[1]),
      //     ((cube.position.z || 0) + originMultipliers.z * dimensions[2])
      //   ];

      //   return {...cube, originalPosition: position, position, dimensions};
      // });

      //TODO offset rooms
      //TODO walls
      //TODO floor?
      //TODO outline
      //TODO orthographic https://onion2k.github.io/r3f-by-example/examples/cameras/orthographic-camera/
      //TODO multiple rooms

      //TODO effect composer?
      // <EffectComposer ref={effectComposerRef}>
      //       <Outline
      //         selection={[..._.values(meshRefs.current)]}
      //         edgeStrength={1}
      //         visibleEdgeColor={0x000000}
      //         hiddenEdgeColor={0x000000}
      //         width={Resizer.AUTO_SIZE}
      //         height={Resizer.AUTO_SIZE}
      //         kernelSize={KernelSize.LARGE}
      //         xray={false}
      //         blur={true}
      //       />
      //     </EffectComposer>

      // var offsetPosition = [viewOffset.x, 0, viewOffset.y];

      var gridLines = [];

      var columnWidth = 500;
      var rowHeight = 350;

      _.times(5, column => {
        gridLines.push({
          key: `grid-column-${column}`,
          fill: '#ddd',
          opacity: 1,
          position: {x: (column - 2) * columnWidth, y: 0, z: -1 * rowHeight},
          dimensions: {width: columnWidth * 5, depth: 1, height: 5},
          rotation: Math.PI / 2
        });
      });

      _.times(8, row => {
        gridLines.push({
          key: `grid-row-${row}`,
          fill: '#ddd',
          opacity: 1,
          position: {x: -2 * columnWidth, y: 0, z: (row - 1) * rowHeight},
          dimensions: {width: columnWidth * 5, depth: 1, height: 5},
          rotation: 0
        });
      });

      var lightPosition = [0, _.get(cameraPosition, '[1]', 300), 0];

      return <div style={{height: '100%', width: '100%'}} className={className}>
        <Scene3D {...{innerRef: meshRefs, cameraZoom, cameraPosition, cameraMouseControls, ...(!isContextElement ? {backgroundColor: _.get(floor, 'customData.threeDBackgroundColor')} : {})}}>
          <pointLight position={lightPosition} intensity={0.1} color={'#fff'} castShadow shadow-mapSize-height={1024} shadow-mapSize-width={1024}/>
          {_.map(roomsData, (roomData, index) => (
            <CanvasRoom3D
              key={index}
              onClick={onRoomClick}
              innerRef={meshRefs}
              {...{isArchetype, isContextElement, materialClasses, viewOffset, columnWidth, rowHeight, includeRoomPosition, showWallsAndArchElements, activeDetailLevel, activeFillMode, roomData, hideArchitecture, showLabel}}
            />
          ))}
          {/* HINT code to generate grid below*/}
          {/* {true && <group position={[viewOffset.x, 0, viewOffset.y]}>
            {_.map(gridLines, ({key, position, dimensions, fill, rotation, ...cube}) => {
              var origin = {x: 'near', y: 'near', z: 'near'};

              var type = 'cube';
              var originMultipliers = _.mapValues(origin, value => type === 'cube' ? _.get({near: 0.5, center: 0, far: -0.5}, value, value || 0) : value);

              var dimensions = [dimensions.width || 0, dimensions.height || 0, dimensions.depth || 0];

              var position = [
                ((position.x || 0) + originMultipliers.x * dimensions[0]),
                ((position.y || 0) + originMultipliers.y * dimensions[1]),
                ((position.z || 0) + originMultipliers.z * dimensions[2])
              ];

              var originalPosition = position;
              var offset = {x: dimensions[0] / 2, y: dimensions[2] / 2}; //HINT using dims[2] for z
              offset = lib.trig.rotate({point: offset, byRadians: rotation});
              offset = lib.object.difference(offset, {x: dimensions[0] / 2, y: dimensions[2] / 2});

              return (
                <group key={key} {...{position}}>
                  <mesh
                    receiveShadow
                    position={[offset.x, 0, offset.y]}
                    rotation-y={rotation}
                  >
                    <boxBufferGeometry attach="geometry" args={dimensions} />
                    <meshStandardMaterial attach="material" color={fill} />
                  </mesh>
                </group>
              );
            })}
          </group>} */}
        </Scene3D>
      </div>;
    }
    catch (error) {
      global.handleError({error, info: {componentStack: error.stack}, message: 'CanvasRooms3D'});

      setHasError(true);

      return null;
    }
  }
}

export default connect({
  mapState: (state, {floor, room, roomsData}) => {
    var stateAdditions = {
      materialClasses: state.resources.materialClasses
    };

    if (!roomsData) {
      var rooms;

      if (_.size(room)) {
        rooms = [room];
      }
      else {
        rooms = Floor.get('rooms', {floor, state});
      }

      stateAdditions.roomsData = _.map(rooms, room => {
        var {containers, volumes, walls} = Room.get(['containers', 'volumes', 'walls'], {room});

        return {
          ...room,
          containerInstances: _.values(containers),
          volumes,
          walls
        }
      });
    }

    return stateAdditions;
  }
})(CanvasRooms3D);
