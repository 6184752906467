import React from 'react';
import { resourceActions, connect } from 'redux/index.js';
import K from 'k';
import _ from 'lodash';
import xIconBlack from '../../../../assets/x-icon-black.png';
import createIcon from '../../../../assets/create-icon.png';
import { pluralize } from 'inflection';
import getMaterialOptionsForArchetypeParameter from 'helpers/get-material-options-for-archetype-parameter';
import PropertyField from 'components/property-field';

class AppliedOption extends React.Component {
  render() {
    var {hoveredAppliedDetailId, appliedDetail, hoveredAppliedDetailType, type, unappliedDetails} = this.props;
    var appliedDetailId = appliedDetail.value || appliedDetail.id;
    var dbPullId = -1;
    var pullMedia;

    if (type === 'pull') {
      var dbPullId = appliedDetailId;
      if (appliedDetail.companyKey === 'vp') {
        //HINT
        //ST pulls are hardcoded in the DE and were later added to the DB
        //So the ids are not matching
        dbPullId = _.find([
          {hardcoded: 1, db: 27}, //   {id: 1, title: 'Radius Staple'},
          {hardcoded: 2, db: 28}, //   {id: 2, title: 'Radius Flat Tab'},
          {hardcoded: 3, db: 30}, //   {id: 3, title: 'Knurled Knob'},
          {hardcoded: 4, db: 31}, //   {id: 4, title: 'Curved Knob'},
          {hardcoded: 5, db: 32}, //   {id: 5, title: 'Notched Knob'},
          {hardcoded: 6, db: 34}, //   {id: 6, title: 'No Pulls'},
          {hardcoded: 7, db: 33}, //   {id: 7, title: 'Touch Latch'},
          {hardcoded: 8, db: 29}, //   {id: 8, title: 'Wire Radius Staple'},
          {hardcoded: 9, db: 35}, //   {id: 9, title: 'Pulls by Others'},
          {hardcoded: 10, db: 36}, //   //{id: 10, title: 'Scooped Bottom'}
        ], {hardcoded: id}).db;
      }

      var dbPull = this.props.pulls[dbPullId] || {};

      //find media associated with pull and pull material, if no pull material or no media with both, get a baseline graphic
      var pullMedia;

      var hasIcon = _.some(dbPull.media, {subjectType: 'icon'});

      //TODO check if pull material is set and get image of relevant pull material
      if (false) {
        var dbPullMaterial = _.find(this.props.materials, {id: pullMaterial.id});
        var dbPullMaterialMedia = dbPullMaterial ? dbPullMaterial.media : [];

        pullMedia = _.find(dbPull.media, media => {
          return _.find(dbPullMaterialMedia, {id: media.id});
        });

        if (!pullMedia) {
          //HINT if no photo of pull in specified material, use generic pull photo
          pullMedia = _.find(dbPull.media, media => {
            return (hasIcon ? media.subjectType === 'icon' : true) && !_.find(dbPullMaterialMedia, {id: media.id});
          });
        }
      }
      else {
        pullMedia = _.find(dbPull.media, media => {
          return (hasIcon ? media.subjectType === 'icon' : true);
        });
      }
    }

    return (
      <div
        style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'center', margin: K.spacing, width: 100, marginLeft: 0, marginRight: K.spacing
        }}
      >
        <div
          onMouseEnter={() => this.props.handleUpdateHoveredAppliedDetail({appliedDetailId})}
          onMouseLeave={() => this.props.handleUpdateHoveredAppliedDetail({appliedDetailId: -1})}
          style={{position: 'relative', height: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}
        >
          <div
            style={{
              borderRadius: '50%', width: 50, height: 50, marginTop: -8,
              ...(!_.includes(['pull', 'material'], type) ? {border: '1px solid black'} : {}),
              ...((_.includes(['material', 'pullMaterial'], type)) ? {backgroundColor: appliedDetail.color} : {}),
              ...(appliedDetailId === hoveredAppliedDetailId ? {opacity: 0.4} : {})
            }}
          >
            {type === 'pull' && (<img src={_.get(pullMedia, 'presignedUrl')} style={{width: 50, height: 50, borderRadius: '50%', border: '1px solid black'}} />)}
            {type === 'material' && (<img src={_.get(appliedDetail, 'thumbnail')} style={{width: 50, height: 50, borderRadius: '50%', border: '1px solid black'}} />)}
          </div>
          {(appliedDetailId === hoveredAppliedDetailId && type === hoveredAppliedDetailType) && (
            <div
              style={{position: 'absolute', marginTop: -8, cursor: 'pointer', justifySelf: 'center', alignSelf: 'center', width: 50, height: 50}}
              onClick={() => this.props.onChange({value: appliedDetailId, action: 'remove'})}
            >
              <img src={xIconBlack} style={{width: 50, height: 50}} />
            </div>
          )}
        </div>
        <div style={{textTransform: 'capitalize', opacity: 0.7, textAlign: 'centers'}}>
          {appliedDetail.title}
        </div>
      </div>
    )
  }
}

class AppliedOptions extends React.Component {
  constructor(props) {
    super(props);
    this.instanceTitleRef = React.createRef();

    this.state = {
      hoveredAppliedDetailType: '',
      hoveredAppliedDetailId: -1,
    };
  }

  handleUpdateHoveredAppliedDetail({appliedDetailId}) {
    this.setState({hoveredAppliedDetailId: appliedDetailId, hoveredAppliedDetailType: this.props.type})
  }

  render() {
    var {type, parameter, expandedPropertyFieldType, compatiblePullIds, compatibleMaterialIds, defaultOptions, room, title, appliedDefaultDetails} = this.props;
    var compatibleDetails;

    if (type === 'pull') {
      compatibleDetails = getMaterialOptionsForArchetypeParameter({parameter, room, materialKey: 'pullType', ignoreCompatibleOptions: true})
    }

    if (type === 'pullMaterial') {
      var compatiblePullIds = _.get(parameter, 'compatiblePullIds');

      if (!compatiblePullIds || compatiblePullIds.length === 0) {
        compatiblePullIds = _.map(getMaterialOptionsForArchetypeParameter({parameter, room, materialKey: 'pullType', ignoreCompatibleOptions: true}), pull => (pull.value));
      }

      var materialClassIds = _.compact(_.map(compatiblePullIds, pullId => {

      if (this.props.project.companyKey === 'hb') {
        var {materialClassId} = _.find(this.props.pulls, {id: pullId});
      }
      else {
        if (!_.includes([6, 7, 9, 10], pullId)) { //HINT No Pulls and Pulls by others ID) {
          materialClassId = 67;

          //knurled knob pull from aluminum and brass
          if (currentPullId === 3) materialClassId = 143;
          //wire radius pull from brass only
          if (currentPullId === 8) materialClassId = 142;
        }
        else {
          materialClassId = -1;
        }
      }

        return materialClassId;
      }));

      compatibleDetails = _.filter(this.props.materials, material => {
        return _.some(materialClassIds, materialClassId => _.includes(material.materialClassIds, `.${materialClassId}.`));
      })
    }

    if (type === 'material') {
      compatibleDetails = getMaterialOptionsForArchetypeParameter({parameter, room, ignoreCompatibleOptions: true})
    }

    var appliedCompatibleDetailIds = type === 'pull' ? compatiblePullIds : compatibleMaterialIds;
    var unappliedDetails = _.filter(compatibleDetails, detail => (!_.includes(appliedCompatibleDetailIds, detail.id)));

    return (
      <div
        style={{
          display: 'flex', flexDirection: 'row', display: 'flex', marginTop: 0, marginBottom: K.spacing * 3, marginRight: K.spacing, flexWrap: 'wrap', overflow: 'visible'
        }}
        onMouseEnter={() => this.setState({hoveredAppliedDetailType: type})}
        onMouseLeave={() => this.setState({hoveredAppliedDetailType: ''})}
      >
        <div style={{width: 100, marginRight: K.spacing}}>
          <div
            style={{marginLeft: 23, marginTop: 7, marginBottom: -10, backgroundColor: K.colors.gray}}
            onClick={() => this.props.handleSetExpandedPropertyFieldType({value: type})}
          >
            <PropertyField
              key={`applied-option-${type}}`}
              type={'select'}
              context={'configurator'}
              hasDefaultTrue={false}
              hasOptionGroups={false}
              views={['front', 'top']}
              placeHolder={'Search...'}
              index={0}
              options={
                defaultOptions
                  ? defaultOptions
                  : _.filter(compatibleDetails, detail => (!_.includes(appliedCompatibleDetailIds, detail.id)))
              }
              defaultTrue={false}
              widerOptionsDropdown={true}
              isExpanded={expandedPropertyFieldType === type}
              onPropertyFieldChange={({value}) => this.props.onChange({id: value, action: 'add'})}
              onClick={() => {return}}
              showRadioActiveOption={false}
              defaultThumbnailIcon={createIcon}
              showDefaultThumbnailIcon={true}
            />
            <div style={{...this.props.styles.secondaryHeader, alignSelf: 'center', marginTop: -17, width: 100, textAlign: 'center', marginLeft: -24}}>
              {title}
            </div>
          </div>
        </div>
        {_.map(appliedDefaultDetails ? appliedDefaultDetails : appliedCompatibleDetailIds, (id) => {
          var appliedDetail = _.find(compatibleDetails, (detail) => detail.value === id || detail.id === id);

          if (appliedDetail) {
            return (
              <AppliedOption
              {...{parameter, appliedDetailId: id, unappliedDetails, type, compatibleDetails, appliedDetail}}
              key={id}
              pulls={this.props.pulls}
              hoveredAppliedDetailId={this.state.hoveredAppliedDetailId}
              hoveredAppliedDetailType={this.state.hoveredAppliedDetailType}
              handleUpdateHoveredAppliedDetail={({appliedDetailId}) => this.handleUpdateHoveredAppliedDetail({appliedDetailId})}
              onChange={({value, action}) => this.props.onChange({id: value, action})}
            />
            )
          }
        })}
      </div>
    )
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData, archetype} = ownProps;

    var activeEntities = _.map(activeEntitiesData, (activeEntity) => {
      return _.get(state.resources, `[${pluralize(activeEntity.resourceKey)}].byId[${activeEntity.id}]`);
    });

    return {
      project: _.values(state.resources.projects.byId)[0],
      containerTypes: state.resources.containerTypes.byId,
      containers: state.resources.containers.byId,
      productTypes: state.resources.productTypes.byId,
      products: state.resources.products.byId,
      pulls: state.resources.pulls.byId,
      activeEntities,
      activeEntitiesData,
      archetype: _.find(state.resources.archetypes.byId, {id: archetype.id}),
      materialClasses: state.resources.materialClasses.byId,
      materials: state.resources.materials.byId,
      room: _.find(state.resources.rooms.byId, {id: archetype.roomId})
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'destroyRoom', 'destroyRooms']),
    ..._.pick(resourceActions.archetypes, ['updateArchetype', 'destroyArchetype']),
    ..._.pick(resourceActions.products, ['updateProducts', 'modifyProducts']),
    ..._.pick(resourceActions.containers, ['updateContainers', 'modifyContainers']),
  }
})(AppliedOptions);