import React, { Component } from 'react';
import { DxfParser } from 'dxf-parser';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import axios from 'axios';
import Popup from 'components/popup';

export default class DxfPopup extends React.PureComponent {
  state = {};

  uploadFile = async (e) => {
    var file = _.get(e, 'target.files[0]');

    if (file) {
      this.setState({isUploading: true});

      try {
        await lib.api.update('project', {
          where: {id: this.props.projectId},
          props: {hasFile: true}
        }, {files: [file]});

        var {floorPlanFileUrl} = await lib.api.get('project', {
          where: {id: this.props.projectId},
          fields: ['floorPlanFileUrl']
        });

        var dxfFileContent = (await axios.get(floorPlanFileUrl)).data;

        await this.props.updateFloor({id: this.props.floorId, props: {stencil: {
          data: new CanvasDxfParser(dxfFileContent).serialize(),
          format: 'dxf'
        }}});

        this.setState({isUploading: false});

        this.props.close();

        alert('Success!');
      }
      catch (error) {
        console.error(error);

        alert('File upload failed');
      }

      this.setState({isUploading: false});
    }
  };

  render() {
    return (
      <Popup onClose={() => this.props.close()}>
        {this.state.isUploading ? (
          <div>Uploading...</div>
        ) : (
          <input name='file-upload' id='file-upload-button' type='file' accept='.dxf' onInput={this.uploadFile}/>
        )}
      </Popup>
    );
  }
}

class CanvasDxfParser {
  constructor(dxfFileContent) {
    this.parseDxfFileContent(dxfFileContent);
    this.calcMinMaxPoints();
    this.calcCenterOfMass();
    this.calcOffset();
    this.validateEntities();
  }

  parseDxfFileContent(dxfFileContent) {
    try {
      var dxfParser = new DxfParser();

      this.dxfObject = dxfParser.parseSync(dxfFileContent);
    }
    catch (error) {
      this.invalidate('We were unable to parse this file for an unknown reason.');
    }
  }

  normalizePoint(point) {
    return {
      x: this.offsetInCanvas.x + point.x,
      y: -1 * (this.offsetInCanvas.y + point.y)
    };
  }

  calcMinMaxPoints() {
    this.minPoint = {x: 10000000, y: 10000000};
    this.maxPoint = {x: -10000000, y: -10000000};

    var checkVertex = vertex => {
      if (vertex.x < this.minPoint.x) this.minPoint.x = vertex.x;
      if (vertex.y < this.minPoint.y) this.minPoint.y = vertex.y;
      if (vertex.x > this.maxPoint.x) this.maxPoint.x = vertex.x;
      if (vertex.y > this.maxPoint.y) this.maxPoint.y = vertex.y;
    };

    this.dxfObject.entities.forEach(entity => {
      if (entity.type === 'LINE' || entity.type === 'LWPOLYLINE' || entity.type === 'POLYLINE') {
        entity.vertices.forEach(checkVertex);
      }
    });
  }

  calcOffset() {
    this.offsetInCanvas = {
      x: -1 * this.centerOfMass.x, //(this.maxPoint.x + this.minPoint.x) / -2,
      y: -1 * this.centerOfMass.y//(this.maxPoint.y + this.minPoint.y) / -2
    };
  }

  calcCenterOfMass() {
    var xs = [], ys = [];

    this.dxfObject.entities.forEach(entity => {
      if (entity.type === 'LINE' || entity.type === 'LWPOLYLINE' || entity.type === 'POLYLINE') {
        entity.vertices.forEach(vertex => {
          xs.push(vertex.x);
          ys.push(vertex.y);
        });
      }
    });

    xs = lib.number.sort(xs);
    ys = lib.number.sort(ys);

    this.centerOfMass = {
      x: xs[_.floor(xs.length / 2)] || 0,
      y: ys[_.floor(ys.length / 2)] || 0
    };
  }

  serialize() {
    //Generate final dxf object
    var counts = {};
    var entities = [];

    this.dxfObject.entities.forEach(entity => {
      counts[entity.type] = (counts[entity.type] || 0) + 1;

      if (entity.type === 'LINE' || entity.type === 'LWPOLYLINE' || entity.type === 'POLYLINE') {
        entities.push({
          type: 'polyline',
          points: entity.vertices.map(v => this.normalizePoint(v)),
          closed: entity.shape === true && entity.type === 'LWPOLYLINE' || entity.type === 'POLYLINE'
        });
      }
    });

    return {
      minPoint: this.minPoint,
      maxPoint: this.maxPoint,
      offset: this.offsetInCanvas,
      centerOfMass: this.centerOfMass,
      entities
    };
  }

  validateEntities() {
    if (this.dxfObject.entities.length > 10000) {
      this.invalidate('There are too many entities in this file. Try to clean it up by removing decorative or otherwise unnecessary elements.');
    }
  }

  invalidate(message) {
    throw new Error(message);
  }
}
