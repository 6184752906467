import _ from 'lodash';
import K from 'k';
import Product from 'project-helpers/product';
import Elevation from 'project-helpers/elevation';
import Container from 'project-helpers/container';
import PositionHelper from 'helpers/position-helper';
import lib from 'lib';

//TODO check positioning in top and front
var Barblock = {
  getWrapInset({product, parentProduct}) {
    if (!parentProduct) parentProduct = Product.get('parentProduct', {product});
    const {wrapThickness} = K.barblockConstants;
    const {dimensions} = parentProduct;
    const parentType = Product.getType({product: parentProduct});

    return {x: wrapThickness, y: parentType === 'verticalBarblock' ? -wrapThickness : -((_.get(dimensions, 'height') || 6.125) - 4.625 - 0.75)};
  },

  getCustomMove({product}) {
    const parentProduct = Product.get('parentProduct', {product});
    const {shelfHeight, topOfFixedShelfYPosition, bottomOfFixedShelfYPosition} = K.barblockConstants.vertical;
    const {height: parentHeight} = Product.getDropzoneSize({product, viewKey: 'front'});
    const isBaseVbb = parentProduct?.productId === 1450;

    return (position) => {
      const componentY = position.y;

      let y;
      //base vbb is justified top, tall vbb is justified from the fixed divider up and down relative to it
      if (isBaseVbb) {
        y = -parentHeight + Math.round((componentY + parentHeight) / shelfHeight) * shelfHeight;
      }
      else {
        const isBelowFixedShelf = componentY > topOfFixedShelfYPosition;

        if (isBelowFixedShelf) {
          y = Math.max(bottomOfFixedShelfYPosition + shelfHeight, bottomOfFixedShelfYPosition + Math.round((componentY - bottomOfFixedShelfYPosition) / shelfHeight) * shelfHeight); //don't let y overlap with fixed shelf
        }
        else {
          y = topOfFixedShelfYPosition + Math.round((componentY - topOfFixedShelfYPosition) / shelfHeight) * shelfHeight;
        }
      }

      if (y > -4) y = 0; //if it's near the bottom, snap it to the bottom

      position.y = y;

      return position;
    };
  },

  getCustomDragBoundFunc({product, viewOffset, elevation, nonSpacialContainerPosition, overrideSideKey}) {
    const container = Product.get('container', {product});
    const containerPosition = nonSpacialContainerPosition ? nonSpacialContainerPosition : Elevation.getPosition2d({elevation, position3d: container.position});
    const containerDropzoneInset = Container.getDropzoneInset({container, viewKey: 'front'});
    const parentProduct = Product.get('parentProduct', {product});
    const parentPosition = parentProduct.position;
    const offset = lib.object.sum(containerPosition, parentPosition, viewOffset, containerDropzoneInset);
    const {shelfHeight, topOfFixedShelfYPosition, bottomOfFixedShelfYPosition} = K.barblockConstants.vertical;
    const dropzoneSize = Product.getDropzoneSize({product, viewKey: 'front'});
    const {height: parentHeight} = dropzoneSize;
    const dropzoneInset = Product.getDropzoneInset({product, viewKey: 'front', elevation});
    const isBaseVbb = parentProduct?.productId === 1450;

    return (positionInCanvas, canvasData) => {
      const positionInReal = PositionHelper.toReal(positionInCanvas, canvasData);

      let relativePosition = lib.object.difference(positionInReal, offset);
      let y;

      const componentY = relativePosition.y;
      //base vbb is justified top, tall vbb is justified from the fixed divider up and down relative to it
      if (isBaseVbb) {
        y = -K.barblockConstants.wrapThickness + -parentHeight + Math.round((componentY + parentHeight) / shelfHeight) * shelfHeight;
      }
      else {
        const isBelowFixedShelf = componentY > topOfFixedShelfYPosition;

        if (isBelowFixedShelf) {
          y = -K.barblockConstants.wrapThickness + Math.max(bottomOfFixedShelfYPosition + shelfHeight, bottomOfFixedShelfYPosition + Math.round((componentY - bottomOfFixedShelfYPosition) / shelfHeight) * shelfHeight); //don't let y overlap with fixed shelf
        }
        else {
          y = topOfFixedShelfYPosition + Math.round((componentY - topOfFixedShelfYPosition) / shelfHeight) * shelfHeight;

          if (y === -35.875) y -= K.barblockConstants.wrapThickness;
        }
      }

      if (y > -4) y = 0; //if it's near the bottom, snap it to the bottom

      relativePosition.y = y;

      //HINT constrain to dropzone
      const origin = lib.object.difference(dropzoneInset, containerPosition, parentPosition, containerDropzoneInset);
      const boundingRect = {...origin, ...dropzoneSize};

      const maxX = boundingRect.width + boundingRect.x - product.dimensions.width;
      const minX = boundingRect.x;
      const maxY = boundingRect.height + boundingRect.y - product.dimensions.height;
      const minY = boundingRect.y;

      const constrainedX = lib.number.constrain(relativePosition.x, {min: minX, max: maxX});
      const constrainedY = lib.number.constrain(relativePosition.y, {min: minY, max: maxY});

      relativePosition = {...relativePosition, x: constrainedX, y: constrainedY};

      const newPositionInReal = _.pick(lib.object.sum(relativePosition, offset), ['x', 'y']);

      return PositionHelper.toCanvas(newPositionInReal, canvasData);
    };
  },
};

export default Barblock;