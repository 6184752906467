import React, { Fragment } from 'react';
import _ from 'lodash';

import lib from 'lib';
import Project from 'project-helpers/project';
import Product from 'project-helpers/product';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Drawings from 'project-helpers/drawings';
import BindingDimensionsTolerances from '../../../assets/bindingdimensionstolerances.png';

import Box3dView from 'ui/components/box-3d/box-3d';

import '../drawings-page.scss';

export default class CriticalSchedulesPage extends React.Component {
  getSiteAccessDimensions() {
    const volumes = Drawings.getSiteAccessDimensions({project: this.props.project});

    const maxDimensions = lib.object.max(..._.map(volumes, 'dimensions'));

    return _.map(volumes, volume => ({...volume, maxDimensions}));
  }

  render() {
    const siteAccessDimensions = this.getSiteAccessDimensions();
    const companyName = Project.getCompanyName({project: this.props.project});

    const glossaryTerms = [
      {title: 'Scope', definition: `${companyName}'s area of work`},
      {title: 'Container', definition: 'A group or run of individual units'},
      {title: 'B/O', definition: `A panel provided by a company that isn't ${companyName}`}
    ];

    return (
      <div className="critical-schedules-container drawings-side-panel-container clearfix">
        <div className="site-access-content-container drawings-page-main-content clearfix">
          <div className="site-access-dimensions">
            {_.map(siteAccessDimensions, ({title, ...volume}, index) => (
              <div className="site-access-volume" key={`site-access-volume-${index}`}>
                <div className="volume-graphic"><Box3dView {...volume} parentKey={`site-access-volume-${index}`}/></div>
                <div className="volume-title">{title}<div className="in-packaging">(in packaging)</div></div>
              </div>
            ))}
          </div>
          <div className="site-access-disclaimer">
            <div className="disclaimer-terms-red">
              <span className="red-text-attention">ATTENTION</span>
              {`If ${companyName} products cannot be safely delivered into the space without risk of damage or injury - determined by the delivery crew - charges for delay or alternative delivery means will be billed to the client or client's representative.`}
            </div>
            <div className="disclaimer-terms-black">
              <ul>
                <li>Your signature confirms objects of these sizes can be moved safely from the street to the position where they will be installed.</li>
                <li><b><u>{companyName} delivery includes:</u></b> up to 1 elevator or 2 exterior flights of stairs + 3 interior flights of stairs.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="drawings-side-panel">
          <div className="drawings-side-panel-content">
            <div className="glossary side-panel-section">
              <div className="side-panel-section-header">Glossary</div>
              <div className="side-panel-section-content table">
                {_.map(glossaryTerms, ({title, definition}, index) => (
                  <div className="glossary-term table-row" key={`glossary-term-${index}`}>
                    <div className="glossary-title table-cell">{title}</div>
                    <div className="glossary-definition table-cell">{definition}</div>
                  </div>
                ))}
              </div>
            </div>
            {this.props.bindingDimensionsShowing && (
              <div className={`side-panel-section side-panel-bindingDimensions`}>
                <div className="side-panel-section-content">
                  <div className="detail-category">
                    <div className="detail-category-title">Attention</div>
                    <div>Dimensions in red are to be held by contractor during construction to within 1/4” tolerance.<br/><br/>
                  All dimensions noted here are finish to finish.<br/><br/>
                  Any architectural or field conditions not noted here including, but not limited to soffits, beams, windows of relevance should be called to the attention of your {companyName} designer.<br/><br/>
                  Please review accordingly.</div>
                  </div>
                  <div className="detail-category">
                    <div className="detail-category-title">Key</div>
                    <img className="binding-dimensions-key-image" src={BindingDimensionsTolerances}/>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="signature-container">
            <div className="signature-content">
              <div className="signature-label">CLIENT SIGNATURE</div>
              <div className="date-label">DATE</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
