import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import K from 'k';
import lib from 'lib';

import NumberInput from './number-input';
import ColorInput from './color-input';
import TextInput from './text-input';
import ExpandedPropertyFieldOption from './expanded-property-field-option';
import ScrollableNote from './scrollable-note';

import { resourceActions } from 'redux/index.js';
import { connect } from 'redux/index.js';

import checkIcon from '../assets/check-icon.png';
import upArrowIcon from 'assets/up-arrow-black.png';

import { handleFloorPropertyChange } from 'properties-view-data/floor-properties-view-helpers';
import { handleRoomPropertyChange } from 'properties-view-data/room-properties-view-helpers';
import { handleContainerPropertyChange } from 'properties-view-data/container-properties-view-helpers';
import { handleProductPropertyChange } from 'properties-view-data/product-properties-view-helpers';
import { handleProjectGraphicPropertyChange } from 'properties-view-data/project-graphic-properties-view-helpers';
import { handleVolumePropertyChange } from 'properties-view-data/volume-properties-view-helpers';

import Elevation from 'project-helpers/elevation';

//import getContainerFieldGroups from 'properties-view-data/container-properties-view-helpers'

class PropertyField extends React.PureComponent {
  state = {
    searchTerm: '',
    expandedOptionGroupIndexes: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isExpanded && this.props.isExpanded === false) {
      this.setState({searchTerm: ''});
    }
  }

  handlePropertiesChange = async ({invalid, activeEntityId, activeEntity, activeEntityResourceKey, path, value}) => {
    if (this.props.onPropertyFieldChange) {
      this.props.onPropertyFieldChange({value});

      this.props.onClick();
    }
    else {
      var shouldUpdate = true;

      if (invalid) {
        shouldUpdate = await confirm(`This option is discontinued and requires engineering approval. If you haven't gotten explicit approval DO NOT proceed.`);
      }

      if (shouldUpdate) {
        activeEntity = _.cloneDeep(activeEntity);

        if (activeEntityResourceKey === 'room') {
          handleRoomPropertyChange({activeEntityId, activeEntity, path, value, resourceActions: this.props});
        }
        else if (activeEntityResourceKey === 'floor') {
          handleFloorPropertyChange({activeEntityId, activeEntity, path, value, resourceActions: this.props});
        }
        else if (activeEntityResourceKey === 'container') {
          handleContainerPropertyChange({activeEntityId, activeEntity, path, value, resourceActions: this.props});
        }
        else if (activeEntityResourceKey === 'product') {
          handleProductPropertyChange({activeEntityId, activeEntity, path, value, resourceActions: this.props});
        }
        else if (activeEntityResourceKey === 'volume') {
          handleVolumePropertyChange({activeEntityId, activeEntity, path, value, resourceActions: this.props});
        }

        else if (activeEntityResourceKey === 'projectGraphic') {
          handleProjectGraphicPropertyChange({activeEntityId, activeEntity, path, value, resourceActions: this.props});
        }
        //HINT general handler for elevations, project graphics, archelements
        else {
          if ((path === 'ceilingHeight' || path === 'datums' || path === 'customData.pullAlignment') && activeEntityResourceKey === 'elevation') {
            let room = Elevation.get('room', {elevation: activeEntity});

            handleRoomPropertyChange({activeEntityId: room.id, activeEntity: room, path, value, resourceActions: this.props});
          }
          else if (_.split(path, '.').length > 1) {
            var topLevelKey = _.split(path, '.')[0];

            _.set(activeEntity, path, value);

            let updates = {
              [topLevelKey]: activeEntity[topLevelKey]
            };

            this.props[`update${_.upperFirst(activeEntityResourceKey)}`]({id: activeEntity.id, props: {...updates}});
          }
          else {
            this.props[`update${_.upperFirst(activeEntityResourceKey)}`]({id: activeEntity.id, props: {[path]: value}});
          }
        }
      }

      this.props.onClick();
    }
  };

  handleCheckboxPress = () => {
    var {path, activeEntity, activeEntityId, activeEntityResourceKey, defaultTrue} = this.props;

    var value = _.get(activeEntity, path, defaultTrue ? 1 : 0) === 1 ? 0 : 1;

    this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
  };

  handleUpdateExpandedGroupIndexes = (index) => {
    let {expandedOptionGroupIndexes} = this.state;
    const currentlyExpanded = _.includes(expandedOptionGroupIndexes, index);

    expandedOptionGroupIndexes = currentlyExpanded ? _.reject(expandedOptionGroupIndexes, num => num === index) : [...expandedOptionGroupIndexes, index];

    this.setState({expandedOptionGroupIndexes});
  };

  handleSearchInputChange = (value) => {
    this.setState({searchTerm: value});
  };

  render () {
    var {details, path, title, type, options, optionGroups = [], noThumbnail = true, hasOptionGroups, defaultTrue, defaultColor, inverseValue, placeholder, activeEntity, activeEntityId, activeEntityResourceKey, maxValue, minValue, step, allowEmptyString, isExpanded, isMultiline, widerOptionsDropdown, context = 'editor', value, showRadioActiveOption=true, defaultThumbnailIcon, showDefaultThumbnailIcon, isEditable = true} = this.props;

    var thumbnailStyles = {height: 32, width: 32, minWidth: 32, backgroundColor: 'white', borderRadius: 50, border: K.grayBorder, display: 'flex', justifyContent: 'center', alignItems: 'center'};

    if (context === 'configurator') {
      thumbnailStyles.height = 50;
      thumbnailStyles.width = 50;
      thumbnailStyles.minWidth = 50;
    }

    options = _.cloneDeep(options);
    optionGroups = _.cloneDeep(optionGroups);

    var radioActiveOption, activeThumbnail;

    let checkboxValue = _.get(activeEntity, path, defaultTrue ? 1 : 0) ? 1 : 0;
    var isSubdued = _.includes(['checkbox'], type) && checkboxValue === (inverseValue ? 1 : 0);

    var valuePath = path;

    // if (_.includes(path, 'details')) valuePath = `${path}.id`;

    var value = _.get(activeEntity, valuePath) || value;

    if (_.includes(path, 'details')) value = _.get({details}, valuePath);

    if (_.includes(path, 'details') && _.get({details}, `${_.replace(path, '.id', '')}.isMixed`)) {
      value = 0;
      var mixedOption = {title: 'Mixed', id: -1, thumbnail: 'tbd'};

      if (options && options.length && options[0].title !== 'Mixed') options.unshift(mixedOption);
      if (optionGroups && optionGroups.length && _.get(optionGroups, '[0].options[0].title') !== 'Mixed') optionGroups[0].options.unshift(mixedOption);
    }

    if (activeEntityResourceKey === 'elevation' && (valuePath === 'ceilingHeight' || valuePath === 'datums' || valuePath === 'customData.pullAlignment')) {
      value = _.get(Elevation.get('room', {elevation: activeEntity}), valuePath);
    }

    if (_.includes(['radio', 'select'], type) && !hasOptionGroups) radioActiveOption = _.find(options, {id: value}) || _.find(options, {value}) || options[0];
    else if (_.includes(['radio', 'select'], type) && hasOptionGroups) {
      //HINT order of options matters for materials
      let flattenedOptions = options || _.flatMap(_.map(optionGroups, 'options'));
      radioActiveOption = _.find(flattenedOptions, {id: value}) || _.find(flattenedOptions, {value}) || flattenedOptions[0];
    }

    if (radioActiveOption) activeThumbnail = radioActiveOption.thumbnail;
    if (defaultThumbnailIcon && showDefaultThumbnailIcon) activeThumbnail = defaultThumbnailIcon;

    if (this.state.searchTerm && options) {
      options = _.filter(options, option => {
        return lib.string.isSearchMatch({title: option.title, input: this.state.searchTerm, distance: 0, matchPlurals: true});
      });
    }

    if (this.state.searchTerm && optionGroups) {
      optionGroups = _.map(optionGroups, optionGroup => {
        //HINT pulling optionGroup.options out causes a reference issue that mutates state
        return {...optionGroup, options: _.filter(optionGroup.options, option => {
          return lib.string.isSearchMatch({title: option.title, input: this.state.searchTerm, distance: 0, matchPlurals: true});
        })};
      });
    }

    if (options) {
      options = _.filter(options, option => {
        const isActive = (_.get(radioActiveOption, 'id') || _.get(radioActiveOption, 'value')) === (option.id || option.value);

        return !(option.shouldHide && !isActive);
      });
    }

    if (optionGroups) {
      optionGroups = _.map(optionGroups, optionGroup => {
        return {...optionGroup, options: _.filter(optionGroup.options, option => {
          const isActive = (_.get(radioActiveOption, 'id') || _.get(radioActiveOption, 'value')) === (option.id || option.value);

          return !(option.shouldHide && !isActive);
        })};
      });

      optionGroups = _.filter(optionGroups, optionGroup => {
        return optionGroup.options.length > 0;
      });
    }

    var popupWidth = K.paneWidth - 60 - K.spacing;
    var widthIncreaseBy = 120;

    if (widerOptionsDropdown) popupWidth += widthIncreaseBy;

    var renderInput = () => {
      var inputStyle = {};

      if (context === 'configurator') {
        inputStyle = {fontWeight: 500};
      }
      else if (context === 'editor') {
        inputStyle = {textAlign: 'right', opacity: 0.7};
      }

      var searchVisible = ((options && options.length > 5) || hasOptionGroups || this.state.searchTerm);

      return (
        <div>
          {_.includes(['number', 'size'], type) && (
            <NumberInput
              {...{placeholder, value, maxValue, minValue, step, allowEmptyString}}
              style={{...inputStyle, backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)', width: 120, paddingRight: K.margin, position: 'relative', ...(context === 'editor' && {right: -5}), height: 16}}
              id={`${title}-${type}-input`}
              value={value}
              onChange={({value}) => {
                this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
              }}
            />
          )}
          {_.includes(['color'], type) && (
            <ColorInput
              {...{defaultColor, value}}
              style={{...inputStyle, backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)', width: 120, paddingRight: K.margin, position: 'relative', ...(context === 'editor' && {right: -5}), height: 16}}
              id={`${title}-${type}-input`}
              value={value}
              onChange={({value}) => {
                this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
              }}
            />
          )}
          {_.includes(['text'], type) && (<>
            {isMultiline ? (
              <ScrollableNote
                key={`${activeEntityId}-${value}`}
                onChange={({value}) => {
                  this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
                }}
                propNotes={value}
                placeholder={placeholder}
              />
            ) : (
              <TextInput
                {...{placeholder, value}}
                style={{...inputStyle, backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)', paddingRight: K.margin, position: 'relative', ...(context === 'editor' && {right: -5}), height: 16, width: 120}}
                id={`${title}-${type}-input`}
                onChange={({value}) => {
                  this.handlePropertiesChange({value, path, activeEntityId, activeEntity, activeEntityResourceKey});
                }}
              />
            )}
          </>)}
          {_.includes(['radio', 'select'], type) && (<div style={{position: 'relative'}}>
            {showRadioActiveOption && (<div style={{...inputStyle}}>{_.get(radioActiveOption, 'title', '')}</div>)}
            {isExpanded && (
              <div
                style={{...K.shadow, position: 'absolute', backgroundColor: 'white', flexDirection: 'column', ...(context === 'editor' ? {top: 'calc(100% + 5px)', maxHeight: 300, zIndex: 1, right: 0, width: 295} : {top: '-175px', height: 150, zIndex: 2, left: 0})}}
              >
                <div style={{display: 'flex', flexDirection: (!hasOptionGroups && noThumbnail) ? 'column' : 'row', flexWrap: 'wrap', padding: K.spacing, ...(context === 'editor' ? {maxHeight: 250, overflow: 'auto'} : {height: searchVisible ? 106 : 150})}}>
                  {hasOptionGroups ? (
                    _.map(optionGroups, ({title: optionGroupTitle, options, id}, index) => (
                      <div key={index}>
                        <div style={{display: 'flex', width: popupWidth, ...(noThumbnail ? {alignItems: 'center'} : {}), marginBottom: K.margin, cursor: isEditable ? 'pointer' : undefined}}
                          onClick={() => this.handleUpdateExpandedGroupIndexes(index)}
                        >
                          <img src={upArrowIcon} alt='arrow' style={{width: 16, height: 16, cursor: isEditable ? 'pointer' : undefined, marginLeft: K.margin, backgroundColor: 'transparent', transform: (_.includes(this.state.expandedOptionGroupIndexes, index) || this.state.searchTerm) ? 'rotate(180deg)' : 'rotate(90deg)'}}/>
                          <div style={{marginLeft: K.margin, fontWeight: 'bold'}}>{optionGroupTitle}</div>
                        </div>

                        {(this.state.searchTerm || _.includes(this.state.expandedOptionGroupIndexes, index)) && (
                          <div style={{paddingBottom: K.spacing, paddingTop: K.spacing / 2, flexWrap: 'wrap', display: 'flex', width: popupWidth - K.spacing * 3, flexDirection: noThumbnail ? 'column' : 'row'}}>
                            {_.map(options, ({thumbnail, title, id, value, invalid}, optionIndex) => {
                              return (
                                <ExpandedPropertyFieldOption
                                  key={optionIndex}
                                  {...{noThumbnail, thumbnail, id, value, title, thumbnailStyles, invalid, hasOptionGroups, radioActiveOption, highlightRadioActiveOption: showRadioActiveOption, optionGroupTitle}}
                                  onClick={() => this.handlePropertiesChange({invalid, activeEntityId, activeEntity, activeEntityResourceKey, path, value: id || value})}
                                  />
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ))) : (
                    <div>
                      {
                        noThumbnail ? _.map(options, ({thumbnail, title, id, value, invalid}, optionIndex) => {
                          const isActive = (_.get(radioActiveOption, 'id') || _.get(radioActiveOption, 'value')) === (id || value);

                          return (
                            (noThumbnail ?
                            <ExpandedPropertyFieldOption
                              key={optionIndex}
                              {...{id, value, isActive, title, thumbnail, noThumbnail, thumbnailStyles, invalid, radioActiveOption, highlightRadioActiveOption: showRadioActiveOption}}
                              onClick={() => this.handlePropertiesChange({invalid, activeEntityId, activeEntity, activeEntityResourceKey, path, value: id || value})}
                            />
                            : <div style={{paddingBottom: K.spacing, paddingTop: K.spacing / 2, flexWrap: 'wrap', display: 'flex', flexDirection: 'row'}}>
                              <ExpandedPropertyFieldOption
                              key={optionIndex}
                              {...{id, value, isActive, title, thumbnail, noThumbnail, thumbnailStyles, invalid, radioActiveOption, highlightRadioActiveOption: showRadioActiveOption}}
                              onClick={() => this.handlePropertiesChange({invalid, activeEntityId, activeEntity, activeEntityResourceKey, path, value: id || value})}
                            />
                            </div>
                          ));
                        }) : (
                          <div style={{paddingBottom: K.spacing, paddingTop: K.spacing / 2, flexWrap: 'wrap', display: 'flex', flexDirection: 'row'}}>
                            {_.map(options, ({thumbnail, title, id, value, invalid}, optionIndex) => {
                              const isActive = (_.get(radioActiveOption, 'id') || _.get(radioActiveOption, 'value')) === (id || value);

                              return (
                                <ExpandedPropertyFieldOption
                                  key={optionIndex}
                                  {...{id, value, isActive, title, thumbnail, noThumbnail, thumbnailStyles, invalid, radioActiveOption, highlightRadioActiveOption: showRadioActiveOption}}
                                  onClick={() => this.handlePropertiesChange({invalid, activeEntityId, activeEntity, activeEntityResourceKey, path, value: id || value})}
                                />
                              );
                            })}
                          </div>
                        )
                      }
                    </div>
                  )}
                </div>
                <>
                  {((options && options.length > 5) || hasOptionGroups || this.state.searchTerm) && (
                    <TextInput
                      style={{margin: K.spacing, width: popupWidth - K.spacing * 2, backgroundColor: '#f5f5f5', border: '1px solid rgba(0, 0, 0, 0.07)', ...(context === 'editor' ? {} : {top: 0})}}
                      onInput={this.handleSearchInputChange}
                      placeholder='SEARCH...'
                      autoFocus={true}
                    />
                  )}
                </>
              </div>
            )}
          </div>)}
        </div>
      )
    };

    var renderTextElements = () => {
      var textProps = context === 'configurator' ? {opacity: 0.5, marginBottom: 5, textTransform: 'upperCase', fontSize: '0.85em', letterSpacing: '0.075em'} : {textAlign: 'right', fontWeight: 'bold'};

      return (
        <div className='should-close' style={{display: 'flex', flexDirection: 'column', position: 'relative', top: _.includes(['number', 'size', 'text'], type) ? -3 : 0, ...(context === 'editor' && {marginRight: K.spacing, alignItems: 'flex-end'})}}>
          <div
            className='should-close'
            style={{opacity: isSubdued ? 0.5 : 1, marginBottom: _.includes(['number', 'size', 'text'], type) ? 3 : 0, ...textProps}}
            onClick={!isEditable ? () => {} : this.props.onClick}
          >
            {title}
          </div>
          {renderInput()}
        </div>
      );
    }

    var renderThumbnail = () => {
      var hideBrokenImage = ({event}) => {
        //HINT hide images that didn't load
        event.target.style.display = 'none';
      }

      return (
        <div onClick={!isEditable ? () => {} : this.props.onClick} style={{...thumbnailStyles, opacity: isSubdued ? 0.1 : 1, ...(isExpanded ? {border: '1px solid black'} : {}), ...(context === 'configurator' && {marginRight: K.spacing * 2})}}>
          {type === 'checkbox' && (
            <img src={checkIcon} alt='check' style={{width: 20, height: 20, backgroundColor: 'transparent'}}/>
          )}
          {(type !== 'checkbox' && activeThumbnail) && (
            <img src={activeThumbnail} onError={event => hideBrokenImage({event})} style={{...thumbnailStyles}}/>
          )}
        </div>
      )
    };

    return (
      <div style={{opacity: isEditable ? 1 : 0.5}}>
        <div onClick={(event) => {
          if (!isEditable) return;

          if (_.includes(['checkbox'], type)) {
            this.handleCheckboxPress();
          }
          else if (this.props.onClick && (!isExpanded || event.target.className === 'should-close')) {
            this.props.onClick();
          }}
        } className='should-close' style={{...(!_.includes(['number', 'size', 'text'], type) ? {cursor: isEditable ? 'pointer' : undefined} : {})}}>
          {context === 'configurator' ? (
            <div style={{display: 'flex', marginBottom: K.spacing * 2, alignItems: 'center', width: '14rem'}}>
              {renderThumbnail()}
              {renderTextElements()}
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: K.spacing, justifyContent: 'flex-end', alignItems: 'center'}}>
              {renderTextElements()}
              {renderThumbnail()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['updateRoom', 'modifyRooms']),
    ..._.pick(resourceActions.archetypes, ['createArchetype', 'destroyArchetype']),
    ..._.pick(resourceActions.containers, ['updateContainer', 'modifyContainers']),
    ..._.pick(resourceActions.archElements, ['updateArchElement']),
    ..._.pick(resourceActions.products, ['updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.projectGraphics, ['updateProjectGraphic', 'trackProjectGraphics']),
    ..._.pick(resourceActions.elevations, ['updateElevation', 'modifyElevations']),
    ..._.pick(resourceActions.walls, ['updateWalls']),
    ..._.pick(resourceActions.volumes, ['updateVolume']),
    ..._.pick(resourceActions.parts, ['updateParts']),
    ..._.pick(resourceActions.projects, ['updateProject']),
    ..._.pick(resourceActions.floors, ['updateFloor']),
  }
})(PropertyField);
