import React from 'react';
import ConfiguratorDetailsPopup from '../popups/configurator-details-popup';
import DocumentationSubjectView from '../popups/documentation-subject-view';

class ProductInfoHudElement extends React.Component {
  render() {
    const {isEmployee, product, project, resourceKey, onClose} = this.props;

    return (
      <>
        {isEmployee && resourceKey !== 'container'
          ? <DocumentationSubjectView
            {...{product, isEmployee, resourceKey, onClose, companyKey: project.companyKey}}
          />
          : <ConfiguratorDetailsPopup
            {...{product, isEmployee, resourceKey, onClose, companyKey: project.companyKey}}
          />
        }
      </>
    );
  }
}

export default ProductInfoHudElement;