import React from 'react';
import Scene3D from 'components/three/scene-3d';
import Cube3D from 'components/three/cube-3d';
import Mesh3D from 'components/three/mesh-3d';

function TestPage() {
  //TESTING ENVIRONMENT: please don't commit changes.
  return (
    <div>
      <Test3D />
    </div>
  );
}

function Test3D() {
  return (
    <div style={{width: 500, height: 500}}>
      <Scene3D>
        <Mesh3D
          vertices={[
            {x: 100, y: 0, z: 100},
            {x: 100, y: 0, z: 0},
            {x: 0, y: 0, z: 0}
          ]}
          fill={'black'}
          position={{x: 0, y: 0, z: 0}}
        />
      </Scene3D>
    </div>
  );
}

export default TestPage;
