import K from 'k';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import xIconBlack from '../../../assets/x-icon-black.png';
import xIconWhite from '../../../assets/x-icon-white.png';

const DrawingsGraphicActions = ({id, onDelete, onReplaceClick}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hoveredAction, setHoveredAction] = useState(null);

  const styles = {
    actions: {
      userSelect: 'none',
      position: 'absolute',
      zIndex: 2,
      top: 0, right: 0, left: 0, bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    actionsWrapper: {
      backgroundColor: 'black',
      overflow: 'hidden',
      borderRadius: K.borderRadius,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      ...isHovering
        ? {opacity: 1, visibility: 'visible'}
        : {opacity: 0, visibility: 'hidden'},
      transition: 'opacity 0.2s, visibility 0.3s'
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: (key) => ({
      width: 30,
      height: 30,
      cursor: 'pointer',
      padding: K.spacing / 2,
      backgroundColor: key === hoveredAction ? K.colors.active : 'transparent',
    }),
  };

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const handleMouseEnterAction = (key) => () => setHoveredAction(key);
  const handleMouseLeaveAction = () => setHoveredAction(null);

  const handleReplaceClick = () => onReplaceClick?.(id);

  return (
    <div style={styles.actions} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div style={styles.actionsWrapper}>
        <div style={styles.iconWrapper}>
          <ReactTooltip id={`${id}-replace-tooltip`} effect='solid'>Replace</ReactTooltip>
          <img
            className={'prevent-drag'}
            onClick={handleReplaceClick}
            onMouseEnter={handleMouseEnterAction('replace')}
            onMouseLeave={handleMouseLeaveAction}
            src={`https://henrybuilt-cdn.s3.us-west-2.amazonaws.com/design-engine/icons/swap-icon-${hoveredAction === 'replace' ? 'black' : 'white'}.png`}
            style={styles.icon('replace')}
            data-tip='tooltip'
            data-for={`${id}-replace-tooltip`}
          />
        </div>
        <div style={styles.iconWrapper}>
          <ReactTooltip id={`${id}-delete-tooltip`} effect='solid'>Delete</ReactTooltip>
          <img
            className={'prevent-drag'}
            onClick={onDelete}
            onMouseEnter={handleMouseEnterAction('delete')}
            onMouseLeave={handleMouseLeaveAction}
            src={hoveredAction === 'delete' ? xIconBlack : xIconWhite}
            style={styles.icon('delete')}
            data-tip='tooltip'
            data-for={`${id}-delete-tooltip`}
          />
        </div>
      </div>
    </div>
  );
};

export default DrawingsGraphicActions;