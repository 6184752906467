import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Elevation from 'project-helpers/elevation';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addElevationContainerLightingDimensions({elevation, dimensionSets, containersData, containers, minWallX, showProjections}) {
  if (showProjections) {
    _.forEach(containersData, container => {
      if (Container.getHasLighting({container}) && container.customData.lightingType === 'puck') {
        var sideKey = Container.getSideKey({container, elevation, viewKey: 'front'});

        if (sideKey === 'front') {
          var unmanagedProductInstances = Container.get('unmanagedProductInstances', {container});

          var lightPositions = Container.getLightPositions({container});
          var y = -(Elevation.getMaxHeight({elevation}) + 5 + 35);
          let xOrigin = container.wallprint[0].x;
          var sortedProducts = _.sortBy(unmanagedProductInstances, 'position.x');

          _.forEach(sortedProducts, productInstance => {
            var xRange = Product.getXRange({product: productInstance});

            var to = {y, x: xOrigin + productInstance.position.x};

            var lightXsForProduct = _.filter(lightPositions, x => x >= xRange.from && x <= xRange.to);

            _.forEach(lightXsForProduct, x => {
              var idPrefix = `lighting-${container.id}-${productInstance.id}-${x}`;
              dimensionSets.push({
                type: 'standalone',
                id: idPrefix,
                alpha: 0,
                offset: 6.5,
                showLabelBorder: true,
                targets: [
                  {position: {x: xOrigin + x, y}, id: `${idPrefix}-from`},
                  {position: to, id: `${idPrefix}-to`}
                ]
              });

              to = {x: xOrigin + x, y};
            });
          });
        }
      }
    });
  }
}
