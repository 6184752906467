import React, {useState} from 'react';
import _ from 'lodash';

import K from '../k';
// import Icon from './icon';

import checkIcon from '../assets/check-icon-black.png';

const Checkbox = ({label, name, disabled, checked, onChange, indeterminate, style, containerStyle, checkedStyle, checkTheme = 'white', alwaysRenderCheck = false}) => {
  //HINT determines whether or not component should be managed locally.
  //When isControlled is true, behavior is only driven by props.
  const isControlled = checked !== undefined;
  const [isChecked, setIsChecked] = isControlled ? [checked, null] : useState(false);

  const checkboxSize = {
    width: 16.5,
    height: 16.5,
  };

  const styles = {
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      ...K.preventSelection,
      ...containerStyle
    },
    nativeCheckbox: {
      zIndex: 1,
      opacity: 0,
      ...checkboxSize,
    },
    checkbox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid',
      borderRadius: K.borderRadius,
      borderColor: isChecked ? 'transparent' : K.colors.doubleGray,
      backgroundColor: isChecked ? K.colors.active : 'transparent',
      ...checkboxSize,
      ...disabled ? {...K.disabled, borderColor: K.colors.gray} : {},
      ...style,
    },
    checkboxLabel: {marginLeft: K.spacing, ...K.fonts.standard},
    checked: {
      margin: 'auto',
      display: 'block',
      width: '75%',
      height: '75%',
      objectFit: 'contain',
      ...checkedStyle
    },
    indeterminate: {
      backgroundColor: K.colors.active,
      width: '50%',
      height: '50%',
      borderRadius: K.borderRadius,
      border: 0
    },
  };

  const handleChange = (e) => {
    e.preventDefault();

    if (disabled) return null;

    setIsChecked?.(!isChecked);

    onChange?.(!isChecked);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleChange(e);
    }
  };

  return (
    <div style={styles.checkboxContainer} onClick={(e) => handleChange(e)}>
      <input
        {...{disabled, name, label}}
        type={'checkbox'}
        checked={isChecked}
        onChange={handleChange}
        hidden
      />
      <span style={styles.checkbox} onKeyDown={handleKeyDown}>
        {alwaysRenderCheck ? (<img src={checkIcon} style={styles.checked} />) : isChecked && (<img src={checkIcon} style={styles.checked} />)}
        {!isChecked && indeterminate && <div style={styles.indeterminate}/>}
      </span>
      {label && <span style={styles.checkboxLabel}>{label}</span>}
    </div>
  );
};

const CheckboxGroup = ({defaultValue = [], disabled = false, options = [], value, onChange, checkboxStyle, style}) => {
  const isControlled = value !== undefined;
  const [checkedValues, setCheckedValues] = isControlled ? [value, null] : useState(defaultValue);

  const styles = {
    checkboxGroup: {
      display: 'flex',
      flexDirection: 'column',
      ...style,
    },
    checkbox: {
      marginBottom: K.spacing / 5,
      ...checkboxStyle,
    },
  };

  const handleCheckboxChange = (value) => (checked) => {
    let updatedCheckedValues = checkedValues.concat();

    const updatedValueIndex = updatedCheckedValues.indexOf(value);

    if (checked && updatedValueIndex === -1) {
      updatedCheckedValues.push(value);
    }
    else if (!checked && updatedValueIndex !== -1) {
      updatedCheckedValues.splice(updatedValueIndex, 1);
    }

    updatedCheckedValues = _.uniq(updatedCheckedValues);

    if (!isControlled) setCheckedValues(updatedCheckedValues);

    if (onChange) onChange(updatedCheckedValues);
  };

  return (<div style={styles.checkboxGroup}>
    {_.map(options, (option) => (
      <Checkbox
        {...{...option}}
        key={option.value}
        style={styles.checkbox}
        disabled={disabled ? disabled : option.disabled}
        checked={_.includes(checkedValues, option.value)}
        onChange={handleCheckboxChange(option.value)}
      />
    ))}
  </div>);
};

export default Checkbox;

export {Checkbox, CheckboxGroup};
