import React from 'react';
import { Circle } from 'react-konva';

export default function CanvasCircle({...props}) {
  if (props.radius) {
    props = _.omit(props, ['width', 'height']);
  }

  return <Circle {...props}/>;
}
