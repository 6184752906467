import React, { useState, useEffect } from 'react';
import K from 'k';
import _ from 'lodash';

const ScrollableNote = ({propNotes, onChange, placeholder, textStyles, maxWidth, rows}) => {
  const [notes, setNotes] = useState(propNotes);
  const textareaRef = React.useRef(null);

  const styles = {
    note: {
      marginTop: 2,
      width: maxWidth ? maxWidth : 170,
      border: 'none',
      outline: 'none',
      fontFamily: K.fontFamily,
      ...textStyles
    },
  }

  const updateNotes = () => {
    onChange?.({value: notes});
  };

  const handleNotesInput = (event) => {
    const updatedNotes = event.target.value;

    setNotes(updatedNotes);
  };

  React.useLayoutEffect(() => {
    textareaRef.current.style.height = "inherit";
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      K.spacing * 2
    )}px`;
  }, [notes]);

  return (
    <textarea
      style={{
        maxHeight: 100,
        resize: "none",
        ...styles.note,
      }}
      rows={rows}
      ref={textareaRef}
      onBlur={updateNotes}
      onInput={handleNotesInput}
      placeholder={placeholder}
      data-no-dnd={true}
      value={notes}
    />
  );
}

export default ScrollableNote;