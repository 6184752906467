import React, {createRef} from 'react';
import _ from 'lodash';
import K from 'k';

import loadProjectData from 'helpers/load-project-data';
import PriceElement from './header/price-element';
import RoomSelectorElement from './header/room-selector-element';
import ConfiguratorGraphic from './components/configurator-graphic';
import ConfiguratorPropertyField from './components/configurator-property-field';
import Konva from 'konva';

import applyArchetypeParameterSelection from 'helpers/apply-archetype-parameter-selection';
import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';
import upArrowIcon from 'assets/up-arrow-black.png';

import { connect } from 'redux/index.js';
import { resourceActions } from 'redux/index.js';
import withUseParams from 'hooks/with-use-params';
import Popup from 'components/popup';

class ConfiguratorPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      twoDGraphicContainerSize: {width: 0, height: 0},
      isShowingBuyPopup: false,
      isConfirming: false,
      hasConfirmed: false
    };
  }

  styles = {
    button: {
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      borderRadius: 20,
      ...K.fonts.label,
      padding: K.spacing,
      opacity: 0.9,
    }
  }

  async componentDidMount() {
    var {match} = this.props; //TODO convert match to params
    Konva.pixelRatio = 2;

    if (new URLSearchParams(window.location.search).get('shareToken')) {
      global.apiToken = new URLSearchParams(window.location.search).get('shareToken');
    }

    await loadProjectData({match, resourceActionDispatchers: this.props});

    setTimeout(async () => {
      var activeRoomId = _.sortBy(_.get(this.roomsByFloor[0], 'rooms'), 'rank')[0].id
      this.setState({activeRoomId, activeElevationId: _.get(Room.get('sortedElevations', {roomId: activeRoomId}), '[0].id')});

      setTimeout(() => {
        var {project} = this.props;
        var projectTitle = `${project.title || `${project.companyKey === 'vp' ? 'ST' : 'HB'} for ${project.clientName}`}`

        document.title = `${projectTitle} - ${project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt'} Configurator`;

        if (project.companyKey === 'vp') {
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.head.appendChild(link);
          }
          link.href = 'https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/st-favicon.ico';
        }

        this.setState({isLoaded: true});

        setTimeout(() => {
          var containerBoundingRect = this.twoDGraphicRef.getBoundingClientRect();

          var twoDGraphicContainerSize = {
            width: containerBoundingRect.right - containerBoundingRect.left,
            height: containerBoundingRect.bottom - containerBoundingRect.top
          };

          this.setState({twoDGraphicContainerSize});
        })
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.twoDGraphicRef) {
      var containerBoundingRect = this.twoDGraphicRef.getBoundingClientRect();

      var twoDGraphicContainerSize = {
        width: containerBoundingRect.right - containerBoundingRect.left,
        height: containerBoundingRect.bottom - containerBoundingRect.top
      };

      if (!_.isEqual(prevState.twoDGraphicContainerSize, twoDGraphicContainerSize)) {
        this.setState({twoDGraphicContainerSize});
      }
    }
  }

  async handlePropertyFieldChange({id, value, path}) {
    var room = _.find(this.props.rooms, {id: this.state.activeRoomId});

    await applyArchetypeParameterSelection({
      room,
      archetypeId: _.get(room, 'archetypeId'),
      parameter: _.find(this.roomArchetypeParameters, {id}),
      value, path,
      resourceActions: this.props
    });
  }

  handleBuyPopupOnClose = () => this.setState({isShowingBuyPopup: false});
  handleBuyButtonClick= () => this.setState({isShowingBuyPopup: true});

  handleConfirmClick = async () => {
    var {project: {id: projectId, versionId}} = this.props;

    try {
      this.setState({isConfirming: true});
      var apiResponse = await lib.api.request({uri: 'de-project/handle-configurator-draft-submission', body: {projectId, versionId}});

      this.handleBuyPopupOnClose();

      this.setState({isConfirming: false, hasConfirmed: true});

      if (_.get(apiResponse, 'data.locked')) {
        await this.props.updateProject({id: projectId, props: {locked}, hitApi: false});
      }
    }
    catch(err) {
      this.handleBuyPopupOnClose();

      this.setState({isConfirming: false});
    }
  }

  get roomsByFloor() {
    var {floors, rooms} = this.props;

    return _.map(floors, (floor) => {
      return {
        ...floor,
        rooms: _.filter(rooms, {floorId: floor.id})
      };
    });
  }

  get roomArchetypeParameters() {
    var room = _.find(this.props.rooms, {id: this.state.activeRoomId});
    var roomParameters = _.flatMap(_.get(room, `parameters.${room.archetypeId}`, []));

    return roomParameters;
  }

  get archetypeParameterGroups() {
    var archetypeParameterGroups = [];

    _.forEach(this.roomArchetypeParameters, (parameter) => {
      var parameterGroup = _.find(archetypeParameterGroups, {key: parameter.type});

      var TypeToTitleMap = {
        material: 'Materials',
        pull: 'Pulls',
        conditionalResources: 'Configuration',
      };

      if (!parameterGroup) {
        archetypeParameterGroups.push({
          key: parameter.type,
          title: TypeToTitleMap[parameter.type],
          parameters: [parameter]
        });
      }
      else {
        parameterGroup.parameters.push(parameter);
      }
    });

    //HINT manually sort parameter groups by type
    archetypeParameterGroups = _.sortBy(archetypeParameterGroups, ({key}) => _.indexOf([
      'material', 'pull', 'conditionalResources'
    ], key));

    return archetypeParameterGroups;
  }

  getTwoDGraphicRef = (ref) => this.twoDGraphicRef = ref;


  render() {
    var {project, rooms, floors, elevations} = this.props;
    var {isLoaded, twoDGraphicContainerSize} = this.state;

    if (isLoaded) {
      var room = _.find(rooms, {id: this.state.activeRoomId});
    }
    var style = {
      header: {
        column: {flex: 1, display: 'flex', flexDirection: 'column'}
      },
      fonts: {
        label: {fontWeight: 500, fontSize: '1em', letterSpacing: '0.1em'}
      },
    };

    return (
      <div style={{height: '100%', width: '100%'}} className='cfg-configurator-page'>
        {!isLoaded ? (
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{...style.fonts.label, textTransform: 'uppercase', marginBottom: '0.5rem'}}>
              Loading
            </div>
            <div style={{width: '2rem', height: '2rem'}} className="show-loader loader-dark"></div>
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
            <div className='header' style={{display: 'flex', width: '100%', padding: '1rem 1.5rem'}}>
              <div style={{...style.fonts.label, ...style.header.column}}>
                <div style={{letterSpacing: '0.15em', textTransform: 'uppercase'}}>
                  {project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt'}
                </div>
                <div style={{opacity: 0.5, marginTop: '0.5rem', fontSize: '0.9em', letterSpacing: '0.06em'}}>
                  {project.title || `${project.companyKey === 'vp' ? 'ST' : 'HB'} for ${project.clientName}`}
                </div>
              </div>
              <div style={{...style.fonts.label, ...style.header.column, alignItems: 'center'}}>
                <RoomSelectorElement {...{
                  rooms, floors, elevations,
                  roomsByFloor: this.roomsByFloor,
                  activeRoomId: this.state.activeRoomId,
                  activeElevationId: this.state.activeElevationId,
                  setActiveRoomId: ({roomId}) => {
                    var sortedElevations = Room.get('sortedElevations', {roomId});

                    this.setState({activeRoomId: roomId, activeElevationId: _.get(sortedElevations, '[0].id')});
                  },
                  setActiveElevationId: ({elevationId}) => this.setState({activeElevationId: elevationId})
                }}/>
              </div>
              <div style={{...style.fonts.label, ...style.header.column, alignItems: 'flex-end'}}>
                {!_.get(room, `customData.hidePricingByArchetypeId.${room.archetypeId}`) && <PriceElement {...{project}}/>}
              </div>
              {!!project.locked && project.isEmployee && <div style={{position: 'fixed', right: '1.5rem', ...style.fonts.label}} >Finalized By Client</div>}
              {!project.locked && !this.state.isConfirming && !this.state.hasConfirmed && <div style={{position: 'fixed', right: '1.5rem', cursor: 'pointer', ...style.fonts.label}} onClick={this.handleBuyButtonClick}>Finalize</div>}
              {!project.locked && this.state.isShowingBuyPopup && (
                <Popup onClose={this.handleBuyPopupOnClose}>
                  <div style={{backgroundColor: 'white'}}>
                    <p>Are you sure? Clicking "confirm" notifies our team and locks your selections, you won't be able to make edits in the future.</p>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <button onClick={this.handleBuyPopupOnClose} style={{...this.styles.button}}>Cancel</button>
                      <button disabled={this.state.isConfirming} onClick={this.handleConfirmClick} style={{...this.styles.button, marginLeft: 15, opacity: this.state.isConfirming ? 0.5 : 0.9}}>Confirm</button>
                    </div>
                  </div>
                </Popup>
              )}
            </div>
            <div className='content' style={{display: 'flex', flex: 1, padding: '2.5rem 1.5rem', flexDirection: 'column'}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60%', zIndex: 1}} ref={this.getTwoDGraphicRef}>
                {!(twoDGraphicContainerSize.width === 0 && twoDGraphicContainerSize.height === 0) && this.state.activeElevationId && (
                  <ConfiguratorGraphic
                    type={'elevation'}
                    {...{containerSize: twoDGraphicContainerSize, model: _.find(this.props.elevations, {id: this.state.activeElevationId}), project, visibilityLayers: {}, activeDimensionsLayer: 'client', activeProjectGraphicsLayer: 'renderings', detailLevel: 'rendering', fillMode: 'materialColors'}}
                  />
                )}
              </div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40%'}}>
                <div style={{display: 'flex', gap: '5rem', alignItems: 'baseline', justifyContent: 'center'}}>
                  {_.map(this.archetypeParameterGroups, ({key, title, parameters}) => {
                    return (
                      <div key={key} style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{...style.fonts.label, letterSpacing: '0.09em', opacity: 0.5, textTransform: 'uppercase', marginBottom: '2rem', marginLeft: '70px'}}>{title}</div>
                        <div>{_.map(parameters, (parameter, index) =>{
                          var {type} = parameter;

                          return (
                            <ConfiguratorPropertyField
                              isEditable={((!project.locked || project.isEmployee) && !this.state.isConfirming && !this.state.hasConfirmed)}
                              key={index}
                              handlePropertyFieldChange={({id, value, path}) => this.handlePropertyFieldChange({id, value, path})}
                              handleClick={({activePropertyKey}) => this.state.activePropertyKey === activePropertyKey ? this.setState({activePropertyKey: null}) : this.setState({activePropertyKey})}
                              {...{parameter, room, index, activePropertyKey: this.state.activePropertyKey}}
                            />
                          );
                        })}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect({
  mapState: state => {
    return {
      projects: state.resources.projects.byId,
      floors: state.resources.floors.byId,
      rooms: state.resources.rooms.byId,
      elevations: state.resources.elevations.byId,
      scopes: state.resources.scopes.byId,
      containerTypes: state.resources.containerTypes.byId,
      products: state.resources.products.byId,
      productTypes: state.resources.productTypes.byId,
      project: _.values(state.resources.projects.byId)[0],
      materialTypes: state.resources.materialTypes.byId,
      materials: state.resources.materials.byId,
      materialClasses: state.resources.materialClasses.byId,
      pulls: state.resources.pulls.byId,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['trackProjects', 'updateProject']),
    ..._.pick(resourceActions.floors, ['trackFloors']),
    ..._.pick(resourceActions.rooms, ['trackRooms', 'updateRoom', 'modifyRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'updateScope', 'updateScopes']),
    ..._.pick(resourceActions.walls, ['trackWalls', 'updateWall', 'modifyWalls']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'createContainer', 'createContainers', 'updateContainer', 'updateContainers', 'destroyContainer', 'destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.elevations, ['trackElevations', 'updateElevations', 'updateElevation', 'createElevations', 'destroyElevations', 'modifyElevations']),
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'updateArchElement', 'destroyArchElement', 'destroyArchElements']),
    ..._.pick(resourceActions.products, ['trackProducts', 'createProduct', 'createProducts', 'updateProduct', 'updateProducts', 'destroyProduct', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productTypes, ['trackProductTypes']),
    ..._.pick(resourceActions.models, ['trackModels']),
    ..._.pick(resourceActions.appliances, ['trackAppliances']),
    ..._.pick(resourceActions.pulls, ['trackPulls']),
    ..._.pick(resourceActions.materialClasses, ['trackMaterialClasses']),
    ..._.pick(resourceActions.materialTypes, ['trackMaterialTypes']),
    ..._.pick(resourceActions.materials, ['trackMaterials']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.pricingRules, ['trackPricingRules']),
    ..._.pick(resourceActions.productOptionTypes, ['trackProductOptionTypes']),
    ..._.pick(resourceActions.projectGraphics, ['trackProjectGraphics', 'updateProjectGraphic', 'updateProjectGraphics', 'destroyProjectGraphic', 'destroyProjectGraphics']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories']),
    ..._.pick(resourceActions.archetypes, ['trackArchetypes']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'updateVolume', 'updateVolumes', 'destroyVolume', 'destroyVolumes', 'modifyVolumes']),
    ..._.pick(resourceActions.parts, ['trackParts', 'updateParts'])
  }
})(withUseParams(ConfiguratorPage));