import _ from 'lodash';
import React from 'react';
import { Line } from 'react-konva';
import {CanvasPath} from 'canvas';

export default function CanvasLine({from, to, x1, x2, y1, y2, closed, showToArrow, arrowSize, ...props}) {
  if (from || to) props.points = [from.x, from.y, to.x, to.y];
  if ((x1 || x1 === 0) && (x2 || x2 === 0) && (y1 || y1 === 0) && (y2 || y2 === 0)) props.points = [x1, y1, x2, y2];
  if (!props.onClick && !props.onMouseDown) props.listening = false;

  var defaultProps = {
    strokeWidth: 1,
    stroke: 'black'
  };

  props = _.defaults(props, defaultProps);

  let toArrowComponent;

  if (showToArrow) {
    toArrowComponent = (
      <CanvasPath {...{
        x: props.points[2],
        y: props.points[3],
        width: arrowSize || 3,
        height: arrowSize || 3,
        data: 'M 0 0 L 4 -3 L 4 3 Z',
        fill: props.stroke || 'black',
        stroke: props.stroke || 'black',
        strokeWidth: props.strokeWidth,
        opacity: 1,
        listening: false,
        rotation: lib.trig.radiansToDegrees(lib.trig.alpha({p1: {x: props.points[2], y: props.points[3]}, p2: {x: props.points[0], y: props.points[1]}})),
      }}/>
    );
  }

  return <>
    {toArrowComponent}
    <Line shadowForStrokeEnabled={false} perfectDrawingEnabled={false} {...props}/>
  </>;
}
