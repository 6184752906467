import { Text, Label, Tag } from 'react-konva';

import React, { useContext } from 'react';
import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import CanvasDataContext from 'contexts/canvas-data-context';
import getTextSize from 'helpers/get-text-size';

function CanvasText({...props}) {
  if (_.get(global.visibilityLayers, 'isExportingDxf')) return null;
  var {x, y, textSize} = props;

  if (props.align || props.alignVertical || props.origin || props.backgroundColor) {
    textSize = textSize || getTextSize({...props, fontWeight: props.fontStyle || 'normal'});
  }

  if (props.origin && !props.align) props.align = props.origin.x;
  if (props.origin && !props.alignVertical) props.alignVertical = props.origin.y;

  var xOffset = 0;
  var yOffset = 0;

  if (props.align) {
    xOffset = -{
      left: 0,
      right: props.width || textSize.width,
      center: (props.width || textSize.width) / 2
    }[props.align || 'left'];
  }
  else if (props.left) {
    var offsetX = getTextSize({...props, fontWeight: props.fontStyle || 'normal'}).width / 2; //HINT left is relative to the center of the text

    x = props.left * props.canvasData.scale;
  }

  if (props.alignVertical) {
    yOffset = -{
      top: 0,
      bottom: textSize.height,
      center: textSize.height / 2 //WARNING Konva might use middle, not center, should test more
    }[props.alignVertical || 'top'];
  }
  else if (props.top) {
    var offsetY = getTextSize({...props, fontWeight: props.fontStyle || 'normal'}).height / 2; //HINT top is relative to the center of the text

    y = props.top * props.canvasData.scale;
  }

  if (props.rotation && (props.align || props.alignVertical)) {
    ({x: xOffset, y: yOffset} = lib.trig.rotate({point: {x: xOffset, y: yOffset}, byDegrees: props.rotation}))
  }

  x += xOffset;
  y += yOffset;

  var padding = 0;

  if (props.padding) padding = ((props.padding * (props.preventPaddingScale ? (1 / props.canvasData.scale) : props.canvasData.scale)));

  if (props.backgroundColor) {
    return (
      <Label {...{x, y, offsetX, offsetY}}>
        <Tag
          fill={props.backgroundColor}
          {...{rotation: props.rotation, ...(props.backgroundStroke ? {stroke: props.backgroundStroke, strokeWidth: 0.5} : {})}}
        />
        <Text fontFamily={K.fontFamily}  fontStyle={props.fontWeight} letterSpacing={0.001}
          padding={padding} {..._.omit(props, ['backgroundColor', 'backgroundStroke', 'x', 'y'])}
        />
      </Label>
    );
  }
  else {
    return (
      //WARNING for some reason this letter spacing prop helps - some default value scales in a way we don't like
      <Text fontFamily={K.fontFamily} letterSpacing={0.001} fontStyle={props.fontWeight} {...props} {...{x, y, offsetX, offsetY}}/>
    );
  }
}

export default function CanvasTextWithContext(props) {
  let canvasData = useContext(CanvasDataContext);

  return <CanvasText {...props} {...{canvasData}}/>;
}
