import React from 'react';

import Popup from 'components/popup';
import linkIcon from 'assets/link-icon-black.png';
import K from 'k';

export default class ShareableLinkPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHoveringLink: false,
      copyingLink: false,
      linkCopied: false,
      shareableLink: ''
    };
  }

  async componentDidMount() {
    //generate link
    var {id: projectId, versionId} = this.props.project;

    var response = await lib.api.request({uri: 'de-project/generate-token', body: {projectId, versionId, access: ['configurator']}, useActualErrorMessages: true});

    this.setState({shareableLink: response.data.shareableLink});
  }

  handleLinkCopyClick() {
    this.setState({copyingLink: true});

    navigator.clipboard.writeText(this.state.shareableLink);

    setTimeout(() => {
      this.setState({copyingLink: false, linkCopied: true});
    }, 1000);
  }

  render() {
    var {shareableLink, copyingLink, linkCopied} = this.state;

    return (
      <Popup
        onClose={() => this.props.toggleShareableLinkPopup()} type={'fitted'}
        style={{width: K.paneWidth * 2}}
      >
        {shareableLink ? (
          <div style={{fontWeight: 'bold', marginBottom: K.spacing * 2, flexDirection: 'row', display: 'flex'}}>
            <div style={{display: 'flex', alignItems: 'center', marginRight: K.spacing}}>{copyingLink ? 'Copying Link...' : (linkCopied ? 'Link Copied' : 'Copy Link')}</div>
            <img style={{...K.defaultIconSize, margin: 1, display: 'flex', cursor: 'pointer'}} src={linkIcon} onClick={() => this.handleLinkCopyClick()} alt='Link'></img>
          </div>
        ) : (
          <div style={{fontWeight: 'bold', marginBottom: K.spacing * 2}}>Generating Shareable Link...</div>
        )}
        <div>This link allows people outside of the company access and edit this project. Please share wisely.</div>
      </Popup>
    );
  }
}