import React from 'react';
import _ from 'lodash';
import lib from 'lib';
import K from 'k';

class TextInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = () => {
    this.setState({inputValue: undefined});

    if (this.props.onChange) this.props.onChange({value: this.state.inputValue !== undefined ? this.state.inputValue : this.props.value});

    if (this.props.onBlur) this.props.onBlur();
  };

  handleChange = event => {
    this.setState({inputValue: event.target.value});
  };

  handleKeyUp = event => {
    if (lib.event.keyPressed(event, 'enter')) {
      if (this.props.submitOnEnter) {
        this.setState({inputValue: undefined});

        if (this.props.onChange) this.props.onChange({value: this.state.inputValue !== undefined ? this.state.inputValue : this.props.value});

        if (this.props.onSubmit) this.props.onSubmit();
      }
      else if (this.props.autoFocus && this.props.continuouslyFocus) {
        this.handleSubmit();
      }
      else {
        this.inputRef.blur();
      }
    }
  };

  handleKeyDown = event => {
    if (event.key === 'Escape') {
      event.stopPropagation();

      this.inputRef.blur();
    }
  };

  render() {
    return (
      <input
        {..._.omit(this.props, ['maxValue', 'minValue', 'allowEmptyString', 'inputRef', 'noFill', 'continuouslyFocus', 'submitOnEnter'])}
        type={this.props.type || 'text'}
        ref={ref => {
          if (this.props.inputRef) {
            if (typeof this.props.inputRef === 'function') {
              this.props.inputRef(ref);
            }
            else {
              this.props.inputRef.current = ref;
            }
          }

          this.inputRef = ref;
        }}
        autoFocus={this.props.autoFocus || false}
        style={{border: 'none', outline: 'none', height: 24, alignSelf: 'flex-end', ...(this.props.noFill ? {backgroundColor: 'transparent'} : {paddingLeft: K.spacing, borderRadius: 5, backgroundColor: '#f5f5f5'}), ...this.props.style}}
        value={this.state.inputValue !== undefined ? this.state.inputValue : ((this.props.value || this.props.value === 0) ? this.props.value : '')}
        onChange={this.handleChange}
        onInput={(event) => {
          if (this.props.onInput) this.props.onInput(event.target.value);
        }}
        onKeyUp={this.handleKeyUp}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleSubmit}
      />
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <TextInput inputRef={ref} {...props} />;
});
