import React from 'react';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';

class DateInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = () => {
    this.setState({inputValue: undefined});

    const dateValue = lib.date.moment(this.state.inputValue).utc().format('YYYY-MM-DD HH:mm:ss');

    if (this.props.onChange) this.props.onChange({value: this.state.inputValue !== undefined ? dateValue : this.props.value});

    if (this.props.onBlur) this.props.onBlur();
  };

  handleChange = event => {
    this.setState({inputValue: event.target.value});
  };

  handleKeyUp = event => {
    if (lib.event.keyPressed(event, 'enter')) {
      this.handleSubmit();
    }
  };

  render() {
    const style = this.props.style || {
      border: 'none',
      outline: 'none',
      height: 24,
      alignSelf: 'flex-end',
      ...(this.props.noFill ? {backgroundColor: 'transparent'} : {paddingLeft: K.spacing, borderRadius: 5, backgroundColor: '#f5f5f5'})
    };

    return (
      <input
        {..._.omit(this.props, ['maxValue', 'minValue', 'inputRef'])}
        type='date'
        ref={this.props.inputRef}
        placeholder={this.props.placeholder || 'mm/dd/yyyy'}
        autoFocus={this.props.autoFocus || false}
        className={this.props.className}
        style={style}
        value={this.state.inputValue ? this.state.inputValue : (this.props.value || '')}
        onChange={this.handleChange}
        onInput={(event) => {
          if (this.props.onInput) this.props.onInput(event.target.value);
        }}
        onKeyUp={this.handleKeyUp}
        onBlur={this.handleSubmit}
      />
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <DateInput inputRef={ref} {...props} />;
});
