import React from 'react';
import TextInput from 'components/text-input';
import Popup from 'components/popup';
import lib from 'lib';
import K from 'k';
import _ from 'lodash';
import Room from 'project-helpers/room';

export default class AddDatumPopup extends React.PureComponent {
  state = {datumDirection: 'horizontal'};

  handleAddDatum = () => {
    this.addDatumLine({room: this.props.room, value: _.toNumber(this.state.datumOffset), direction: this.state.datumDirection});

    this.props.toggleDatumPopup(true);
  };

  addDatumLine = async ({room, value, direction = 'vertical'}) => {
    let width = Room.getWidth({room}), height = Room.getHeight({room});

    if (isNaN(value)) value = direction === 'vertical' ? width / 2 : height / 2;

    var datum;
    var extension = 2; //HINT datums should extend 2 inches outside the room
    var {points} = room.plan;
    var minVertex = {x: _.minBy(points, 'x').x, y: _.minBy(points, 'y').y};

    if (direction === 'horizontal') {
      datum = {from: {x: 0 - extension, y: value}, to: {x: width + extension, y: value}};
    }
    else {
      datum = {from: {x: value, y: 0 - extension}, to: {x: value, y: height + extension}};
    }

    datum.from = lib.object.sum(datum.from, minVertex);
    datum.to = lib.object.sum(datum.to, minVertex);

    if (!Room.xzDatumLineAlreadyExists({room, datum})) {
      this.handleDatumChange({datum});
    }
  };

  handleDatumChange = ({datum, updatedProps = {}}) => {
    var clone = _.cloneDeep(this.props.room.xzDatums || []);
    var indexOf = datum ? _.findIndex(clone, ({from, to}) => datum.from.x === from.x && datum.from.y === from.y && datum.to.x === to.x && datum.to.y === to.y) : -1;

    if (datum && indexOf > -1) {
      clone[indexOf] = {...datum, ...updatedProps};
    }
    else {
      clone.push({...datum, ...updatedProps});
    }

    this.props.updateRoom({id: this.props.room.id, props: {xzDatums: clone}});
  };

  render() {
    return (
      <Popup onClose={this.props.toggleDatumPopup}>
        <div>
          <div>
            <label>Enter a value relative to the top-left of the plan</label>
            <TextInput
              style={{width: '100%', marginBottom: K.spacing * 2, marginTop: K.spacing * 2, backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)'}}
              onChange={({value}) => {
                this.setState({datumOffset: value});
              }}
              value={this.state.datumOffset}
              autoFocus={true}
            />
          </div>
          <div style={{marginBottom: 20}}>
            <label>Direction</label>
            <div>
              <input
                style={{verticalAlign: 'middle'}}
                type='radio'
                checked={this.state.datumDirection === 'horizontal'}
                onChange={() => this.setState({
                  ...this.state,
                  datumDirection: 'horizontal'
                })}
              />
              <label style={{verticalAlign: 'middle', top: 1, position: 'relative', marginLeft: 5}}>Horizontal</label>
              <input
                style={{verticalAlign: 'middle'}}
                type='radio'
                checked={this.state.datumDirection === 'vertical'}
                onChange={() => this.setState({
                  ...this.state,
                  datumDirection: 'vertical'
                })}
              />
              <label style={{verticalAlign: 'middle', top: 1, position: 'relative', marginLeft: 5}}>Vertical</label>
            </div>
          </div>
          <div style={{justifyContent: 'flex-end', display: 'flex'}}>
            <button onClick={this.props.toggleDatumPopup} style={{marginRight: 5}}>Cancel</button>
            <button onClick={this.handleAddDatum}>Create</button>
          </div>
        </div>
      </Popup>
    );
  }
}
