import React, { useContext, useEffect } from 'react';

import { Image, Circle } from 'react-konva';
import { CanvasDataContext, CanvasSelectionContext } from 'canvas';

import useImage from 'use-image';
import _ from 'lodash';
import lib from 'lib';

import PositionHelper from 'helpers/position-helper';

import CanvasMask from './canvas-mask';
import CanvasTransformer from './canvas-transformer';

function CanvasTexture({src, height, width, opacity, canvasData, projectGraphic, maskingPolygons, isSelected, isCircle, circleProps, onTransform, onTransformEnd, onSelect, onDelete, ...props}) {
  const [image] = useImage(src);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);

  });

  const handleKeyDown = (event) => {
    if (onDelete && isSelected && lib.event.keyPressed(event, 'delete') && document.activeElement.tagName === 'BODY') {
      onDelete();
    }
  }

  const originalHeight = height;
  const originalWidth = width;

  height = height * canvasData.scale;
  width = width * canvasData.scale;

  const {positionInCanvas, position, rotation, selectionData, isScalable = false, isRotatable = false} = props;
  var {x, y} = positionInCanvas;

  const handleTransform = (props) => onTransform(props);
  const handleTransformEnd = (props) => onTransformEnd(props);
  const handleSelect = () => {
    selectionData.setActiveProjectGraphicData({projectGraphicId: projectGraphic.id});
    onSelect();
  };

  const imageComponent = (
    <Image
      {...{image, height, width, opacity, x, y, rotation}}
      onClick={handleSelect}
    />
  );

  const p1 = isCircle ? null : _.map([
    {x: x, y: y},
    {x: x + width, y: y},
    {x: x + width, y: y + height},
    {x: x, y: y + height},
    {x: x, y: y}
  ], point => {
    return rotation ? lib.trig.rotate({point, byDegrees: rotation, aroundOrigin: lib.object.sum({x, y})}) : point;
  });

  const shouldClip = isCircle || maskingPolygons.length > 0 && _.some(maskingPolygons, p2 => lib.polygon.polygonsOverlap(p1, p2, {inclusive: false}));

  // const objectProps = _.pick(props, ['fill', 'size', 'position', 'rotation', 'hatchFill', 'hatchFills', 'stroke']);
  const objectProps = {size: {width: originalWidth, height: originalHeight}, position, rotation};


  return (
    <>
      {shouldClip ? (
        <CanvasMask
          {...{isCircle, circleProps, maskingPolygons}}
        >
          {imageComponent}
        </CanvasMask>
      ) : imageComponent}
      {isSelected && (
        <Image {...{image, width, height, x, y, rotation}}
          opacity={0.2}
        />
        )}
      {isSelected && <CanvasTransformer {...{
        isMask: true,
        shapeProps: objectProps,
        isSelected,
        isScalable: true,
        isDraggable: true,
        isRotatable: true,
        onTransform: handleTransform,
        onTransformEnd: handleTransformEnd,
      }}/>}
    </>
  );
}

export default function CanvasTextureWithContext(props) {
  const canvasData = useContext(CanvasDataContext);
  const selectionData = useContext(CanvasSelectionContext);

  return <CanvasTexture {...props} {...{canvasData, selectionData}} />;
}