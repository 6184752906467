import _ from 'lodash';
import lib from 'lib';

export default function addRoomContainerWidthDimensions({volume, dimensionSets, footprintLines, volumeAlpha, getIsTrapped, outsideDimensionSets, room}) {
  var isTrapped = getIsTrapped({line: footprintLines.back, entityType: 'volume', entityId: volume.id});

  if (!isTrapped) var dimensionSet = _.find(outsideDimensionSets, ({alpha}) => lib.trig.anglesAreEqual({a1: alpha, a2: volumeAlpha + Math.PI}));

  if (dimensionSet) {
    dimensionSet.targets.push(
      //if scribe, intersection point
      {position: lib.object.sum(footprintLines.back.from, room.plan.position), id: `room-volume-${volume.id}-width-from`},
      {position: lib.object.sum(footprintLines.back.to, room.plan.position), id: `room-volume-${volume.id}-width-to`}
    );
  }
  else {
    dimensionSets.push({
      type: 'extrudeLocally',
      id: `room-volume-${volume.id}-width`,
      alpha: volumeAlpha,
      targets: [
        {position: lib.object.sum(footprintLines.front.from, room.plan.position), id: `room-volume-${volume.id}-width-from`},
        {position: lib.object.sum(footprintLines.front.to, room.plan.position), id: `room-volume-${volume.id}-width-to`}
      ]
    });
  }
}
