import React, {useRef, useState, useEffect} from 'react';
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';

import _ from 'lodash';

/* eslint-disable react/no-unknown-property */

extend({OrbitControls});

function CameraControls({cameraMouseControls}) {
  var {camera, gl: { domElement }} = useThree();
  var controls = useRef();
  var mouseButtons;

  if (cameraMouseControls) {
    mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.ROTATE,
      RIGHT: THREE.MOUSE.DOLLY
    };
  }
  else {
    mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.PAN
    }
  }

  useFrame(() => controls.current.update());

  return <orbitControls ref={controls} args={[camera, domElement]} {...{mouseButtons}} />;
}

function SetCamera({canvasRef, effectComposerRef, shouldSetCamera, zoom, position}) {
  useThree((three) => {
    // setTimeout(() => {
    //   if (!set) {
    //     three.gl = new THREE.WebGLRenderer({powerPreference: "high-performance", canvas: canvasRef.current, antialias: true, logarithmicDepthBuffer: true});
    //     three.gl.setPixelRatio(window.devicePixelRatio);
    //     // three.gl.setClearColor(0xffffff, 1.0);
    //     effectComposerRef.current.setRenderer(three.gl);
    //     set = true;
    //   }
    // });
    if (shouldSetCamera) {
      var {camera} = three;
      camera.fov *= 1;
      camera.zoom = zoom || 0.4;
      camera.far = 1000 * camera.zoom * 10;
      // camera.position.set(10, 30, 20);
      // camera.position.set(100, 300, 200);

      camera.position.set(...(position || [100, 300, 200]));
      // camera.position.set(200, 600, 400);
      camera.lookAt([0, 0, 0]);
      camera.updateProjectionMatrix();
    }
  });

  return null;
}

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state

  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

function Scene3D({cameraZoom, cameraPosition, cameraMouseControls, innerRef, children, backgroundColor}) {
  const canvasRef = useRef();
  const effectComposerRef = useRef();
  const meshRefs = useRef(innerRef);
  const forceUpdate = useForceUpdate();
  const [shouldSetCamera, setShouldSetCamera] = useState(true);

  if (!meshRefs.current) meshRefs.current = {};

  useEffect(() => {
    setTimeout(() => forceUpdate(), 100);
  }, []);

  useEffect(() => {
    setTimeout(() => setShouldSetCamera(false), 1000);
  }, []);

  return (
    <Canvas
      ref={canvasRef}
      orthographic
      linear
      flat
      logarithmicDepthBuffer
      dpr={Math.max(window.devicePixelRatio, 2)}
      style={{...(backgroundColor ? {background: backgroundColor} : {})}}
    >
      <CameraControls {...{cameraMouseControls}} />
      <SetCamera {...{canvasRef, effectComposerRef, shouldSetCamera}} zoom={cameraZoom} position={cameraPosition}/>
      <ambientLight intensity={1}/>
      {children}
    </Canvas>
  );
}

export default Scene3D;
