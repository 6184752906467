import React, { Component, useContext } from 'react';
import { CanvasText, EditableCanvasLine } from 'canvas';
import CFG from 'k';
import lib from 'lib';
import { CanvasDataContext, CanvasSelectionContext, CanvasErrorFallback } from 'canvas';
import PositionHelper from 'helpers/position-helper';

import {withErrorBoundary} from 'react-error-boundary';

import { connect } from 'redux/index.js';
import Elevation from 'project-helpers/elevation';
import K from 'k';

class CanvasDatum extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  handleTransformEnd = (props) => {
    let from = props.from;
    let to = props.to;
    const { datum, viewOffset, room } = this.props;

    from = lib.object.difference(from, viewOffset, room.plan.position);
    to = lib.object.difference(to, viewOffset, room.plan.position);

    this.props.handleDatumChange({datum, updatedProps: { from, to }});
  };

  render() {
    const {type, datum, room, elevation, viewOffset, viewKey = '', canvasData} = this.props;
    let from, to;

    if (type !== 'y') {
      from = datum.from;
      to = datum.to;
    }

    if (viewKey === 'front') {
      var margin = 35, x = 0, width = 0, height = 0;

      if (Elevation.getIsSection({elevation})) margin = 13;
      if (type === 'xz') margin = 50;

      x = -margin;
      width = Elevation.getWidth({elevation}) + margin * 2;
      height = Elevation.getHeight({elevation}) + margin * 2;

      if (type === 'xz') {
        var intersection = lib.math.intersectionPoint({l1: datum, l2: elevation.lineInRoom});

        var x = lib.trig.distance({fromPoint: elevation.lineInRoom.from, toPoint: intersection});

        from = lib.object.sum(viewOffset, {x, y: margin});
        to = lib.object.sum(viewOffset, {x, y: -height + margin});
      }
      else {
        from = lib.object.sum(viewOffset, {x, y: -datum});
        to = lib.object.sum(viewOffset, {x: x + width, y: -datum});
      }
    }
    else {
      from = lib.object.sum(from, viewOffset, viewKey !== 'front' ? room.plan.position : '');
      to = lib.object.sum(to, viewOffset, viewKey !== 'front' ? room.plan.position : '');
    }

    return (
      <>
        <EditableCanvasLine
          from={from}
          to={to}
          stroke={'#ed7b7b'}
          strokeWidth={0.5}
          useViewDepth={false}
          isDisabled={canvasData.isStatic}
          isSelected={this.props.isSelected}
          onDelete={this.props.onDelete}
          onClick={this.props.onClick}
          onTransformEnd={this.handleTransformEnd}
        />
        {viewKey === 'front' && <CanvasText
          listening={false}
          text={type === 'y' ? datum : x}
          origin={type === 'y' ? {x: 'right', y: 'center'} : {x: 'center', y: 'top'}}
          x={PositionHelper.toCanvas(lib.object.sum(from, type === 'y' ? {x: -3} : {y: 3}), this.props.canvasData).x}
          y={PositionHelper.toCanvas(lib.object.sum(from, type === 'y' ? {x: -3} : {y: 3}), this.props.canvasData).y}
        />}
      </>
    );
  }
}

function CanvasDatumWithContext(props) {
  const canvasData = useContext(CanvasDataContext);
  const selectionData = useContext(CanvasSelectionContext);

  return <CanvasDatum {...props} {...{canvasData, selectionData}}/>;
}

export default withErrorBoundary(connect({
  // // mapState: state => {
  // //   return {
  // //     rooms: state.resources.rooms.byId[props.datum.room.id],
  // //   }
  // // },
  // mapDispatch: {
  //   // ..._.pick(resourceActions.rooms, ['updateRoom', 'trackRooms']),
  // }
})(CanvasDatumWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasDatum'})
});
