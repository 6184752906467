import React, { Fragment, useState, memo } from 'react';
import { resourceActions, connect } from 'redux/index.js';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import HudElement from './hud-element';
import CanvasRooms3D from 'project-components/canvas-rooms-3d';
import CanvasView from 'canvas/canvas-view';
import CanvasScriptObject from 'canvas/canvas-script-object';
import CanvasContainer from 'project-components/canvas-container';

import Room from 'project-helpers/room';
import Wall from 'project-helpers/wall';
import Elevation from 'project-helpers/elevation';
import Project from 'project-helpers/project';
import Container from 'project-helpers/container';
import Volume from 'project-helpers/volume';
import ArchElement from 'project-helpers/arch-element';
import Product from 'project-helpers/product';
import Scope from 'project-helpers/scope';

import architectureActiveIcon from '../../../assets/architecture-active-icon.png';
import architectureIcon from '../../../assets/architecture-icon-black.png';
import cabinetActiveIcon from '../../../assets/cabinet-active-icon.png';
import cabinetIcon from '../../../assets/cabinet-icon-black.png';
import containerActiveIcon from '../../../assets/container-active-icon.png';
import containerIcon from '../../../assets/container-icon-black.png';
import propsActiveIcon from '../../../assets/props-active-icon.png';
import propsIcon from '../../../assets/props-icon-black.png';
import upArrowIcon from '../../../assets/up-arrow-black.png';
import createIcon from 'assets/create-icon-white.png';
import InfoIcon from '../../../assets/info-icon.png';

import getProductsByCategoryFor from 'helpers/product-categories-helper';
import { pluralize } from 'inflection';

import TextInput from 'components/text-input';
import updateProductionIds from 'helpers/update-production-ids-helper';

var thumbnailDiameter = 90;

function Thumbnail({title, thumbnail, style, showAccessoriesView, item, optionIndex, isOrnament, isSpeccedContainer, isDisabled, thumbnailScript, companyKey}) {
  //TODO: if (addingGroup === 'container') show canvasScriptObject like isOrnament is doing. ISSUE: finding an elevation for the canvasScriptObject
  var canvasScale = _.get(item, 'thumbnailData.canvasScale', 1);
  var offset = _.get(item, 'thumbnailData.offset', {x: 0, y: 0});

  thumbnailDiameter = isSpeccedContainer ? 180 : 90;

  var scriptContainerSize = {
    width: Math.max(thumbnailDiameter, _.get(item, 'resourceProps.dimensions.width') || _.get(item, 'props.dimensions.width')),
    height: Math.max(thumbnailDiameter, _.get(item, 'resourceProps.dimensions.height') || _.get(item, 'props.dimensions.height'))
  };

  if (isSpeccedContainer) {
    canvasScale = (thumbnailDiameter - 45) / _.max([_.get(item, 'resourceProps.dimensions.width'), _.get(item, 'resourceProps.dimensions.height')]);
  }

  return (
    <div className={`add-menu-thumbnail${isDisabled ? '-static' : ''}`} style={{marginLeft: K.spacing / 2, width: thumbnailDiameter, height: thumbnailDiameter, borderRadius: 10, flexShrink: 0, border: '1px solid rgba(0, 0, 0, 0.2)', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', ...style}}>
      {(isSpeccedContainer) ? (
        <CanvasView
          viewMode={'front'}
          className={`add-button-ornament-view-${item.id}`}
          style={{width: '100%', height: '100%', backgroundColor: 'transparent'}}
          scale={canvasScale}
          containerSize={scriptContainerSize}
          projectData = {{
            companyKey
            // ..._.pick(project, ['dimensionsData', 'companyKey', 'versionId', 'id', 'isEmployee']),
            // activeDimensionsLayer,
            // isEditingDimensions: false,
            // activeProjectGraphicsLayer,
            // countertopsAreSelectable: false,
            // pushToUndoQueue: null,
            // toggleTolerancePopupShowing: false,
            // showingAlignmentIndicators: false,
          }}
          isStatic
        >
          <CanvasContainer
            //HINT bug where thumbnails didn't update when changing viewmodes
            key={`${optionIndex}-${_.get(item, 'id', lib.string.uuid())}`}
            isNonSpacial
            nonSpacialSideKey={'front'}
            overridePosition={lib.object.sum({x: -item.resourceProps.dimensions.width / 2, y: item.resourceProps.dimensions.height / 2, z: 0}, offset)}
            {...{viewKey: 'front', activeDetailLevel: 'fullDetail'}}
            container={item.resourceProps}
          />
        </CanvasView>
      ) : ((isOrnament) ? (
          <CanvasView
            viewMode={'front'}
            className={`add-button-ornament-view-${item.id}`}
            style={{width: '100%', height: '100%', backgroundColor: 'transparent'}}
            scale={canvasScale}
            containerSize={scriptContainerSize}
            isStatic
          >
            <CanvasScriptObject
              //HINT bug where thumbnails didn't update when changing viewmodes
              key={`${optionIndex}-${_.get(item, 'id', lib.string.uuid())}`}
              metaProps={{props: {...item.resourceProps}}}
              script={thumbnailScript}
              rotation={0}
              fill={''}
              strokeWidth={0.25}
              isDisabled={isDisabled}
              {...{stroke: 'black', position: lib.object.sum({x: -item.resourceProps.dimensions.width / 2, y: 0, z: 0}, offset), size: item.resourceProps.dimensions, isSelected: false, multipleEntitiesSelected: false, viewKey: 'front', disabledAnchors: true, snapToLines: false, locked: true, isRotatable: false, isScalable: true, hatchFill: 'white', hatchFills: {}, renderForDrawings: false}}
            />
          </CanvasView>
        ) : (
          <img
            src={thumbnail}
            alt={title}
            style={showAccessoriesView
              ? {width: '100%', height: '100%', objectFit: 'cover'}
              : {width: '65%', height: '65%', objectFit: 'contain'}
            }
          />
        )
      )}
    </div>
  );
}

const ProductThumbnailEpic = memo(function ProductThumbnailEpic({option, optionIndex, addingGroup, toggleInfoPopupShowing, createDragItem, setDragData, viewMode, bothIsShowingElevations, handleAddMenuMouseUp, companyKey}) {
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringInfoIcon, setIsHoveringInfoIcon] = useState(false);
  const styles = {
    infoButton: {
      width: 16, height: 16
    }
  };

  var thumbnailScript;
  var isOrnament = _.get(option, 'isOrnament', false);
  var isSpeccedContainer = _.get(option, 'isSpeccedContainer', false);
  var isDisabled = _.get(option, 'isDisabled', false);
  var usesTopScriptForPreviewOrnaments = ['scaleFigure7', 'scaleFigure8', 'scaleFigure9'];
  var addableFromTopOrnaments = [...usesTopScriptForPreviewOrnaments, 'propCouch1', 'propCouch2'];

  if (isOrnament) {
    thumbnailScript = option.scripts.front;

    if (_.includes(usesTopScriptForPreviewOrnaments, option.type)) {
      thumbnailScript = option.scripts.top;
    }

    if (_.includes(['top', 'both'], viewMode) && !bothIsShowingElevations && !_.includes(addableFromTopOrnaments, option.type)) {
      isDisabled = true;
    }
  }

  return (<>
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{overflow: 'visible', flexDirection: 'row', ...(addingGroup === 'nonSpacialContainers' ? {display: 'inline-block'} : {})}}
      key={optionIndex}
    >
      <div
        className={'add-menu-item'}
        style={{display: 'flex', alignItems: 'center', paddingBottom: K.spacing / 2, paddingTop: K.spacing / 2, opacity: isDisabled ? 0.3 : 1}}

        {...(viewMode === 'lite' ? {
          onClick: (event) => {
            event.preventDefault();



            if (!isDisabled && !event.target.closest('.info-icon')) {
              var dragItem = createDragItem(option);

              handleAddMenuMouseUp(dragItem);
            }
          }
        } : {
          onMouseDown: (event) => {
            event.preventDefault();

            if (!isDisabled) {
              setDragData({isDragging: true, dragItem: createDragItem(option), lastMouseEvent: event});
            }
          }
        })}
      >
        <Thumbnail {...{item: option, addingGroup, thumbnail: option.thumbnail, viewMode, optionIndex, bothIsShowingElevations, isOrnament, isSpeccedContainer, isDisabled, thumbnailScript, companyKey}}/>
        <div style={{marginLeft: K.spacing * 2, textOverflow: 'ellipsis', fontSize: '0.8rem', width: 120, display: 'table-cell', verticalAlign: 'middle'}}>{option.title}</div>
        <div style={{width: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignSelf: 'flex-start', marginRight: K.spacing / 2}}>
          {_.includes(['product', 'container'], addingGroup) && isHovering && (
            <div
              className={'info-icon'}
              onMouseEnter={() => setIsHoveringInfoIcon(true)}
              onMouseLeave={() => setIsHoveringInfoIcon(false)}
              style={{cursor: 'pointer'}}
              onClick={() => toggleInfoPopupShowing({entity: addingGroup === 'container' ? Container.get('dbContainerType', {container: option}) : option, entityResourceKey: addingGroup})}
              // data-tip='tooltip' data-for={`${activeEntityId}-tooltip`}
            >
              <div style={{opacity: isHoveringInfoIcon ? 0.9 : 0.5}}>
                <img src={InfoIcon} style={styles.infoButton} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </>);
});

class AddButtonHudElement extends React.Component {
  shouldComponentUpdate(prevProps) {
    return (_.get(prevProps.activeEntities, '0.eventType') !== 'transform' || _.get(this.props.activeEntities, '0.eventType') !== 'transform' || prevProps.viewMode !== this.props.viewMode);
  }

  state = {
    searchTerm: '',
    expandedAddGroupOptionIndexes: [],
    addList: [],
    addGroups: [],
    addingGroup: '',
    configurationLibraryCategory: 'archetype',
  };

  componentDidMount() {
    var defaultGroup = 'container';

    if ((_.size(this.props.activeEntities) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'container'})) && this.props.viewMode !== 'top') {
      defaultGroup = 'product';
    }
    else if (this.props.showAccessoriesView) {
      defaultGroup = 'accessories';
    }

    this.handleAddingGroupChange(defaultGroup);

    document.addEventListener('mouseup', () => this.props.handleAddMenuMouseUp());
  }

  componentDidUpdate(prevProps) {
    if (this.props.showAccessoriesView && this.state.addingGroup !== 'accessories') {
      this.handleAddingGroupChange('accessories');
    }
    else if (this.state.addingGroup === 'nonSpacialContainer') {
      var roomHasNonSpacialContainers = this.props.room && Room.get('nonSpacialContainers', {room: this.props.room}).length > 0;
      var addingGroupValid = _.includes(['front', 'both', 'top'], this.props.viewMode) && roomHasNonSpacialContainers;

      if (!addingGroupValid) {
        this.handleAddingGroupChange(this.state.cachedAddingGroup === 'nonSpacialContainer' ? 'container' : this.state.cachedAddingGroup);
      }
      else if (_.get(prevProps, 'room.id') !== _.get(this, 'props.room.id')) {
        this.handleAddingGroupChange('nonSpacialContainer');
      }
    }
    //HINT if viewMode changes and the current addingGroup needs to re-evaluate call handleAddingGroupChange based on viewKey
    else if ((prevProps.viewMode !== this.props.viewMode || prevProps.bothIsShowingElevations !== this.props.bothIsShowingElevations) && _.includes(['container', 'ornament', 'archElement', 'archetype'], this.state.addingGroup)) {
      this.handleAddingGroupChange(this.state.addingGroup);
    }
    else if (!_.includes(['top', 'both'], this.props.viewMode) && this.state.addingGroup === 'archetype') {
      this.handleAddingGroupChange(this.state.cachedAddingGroup === 'archetype' ? 'container' : this.state.cachedAddingGroup);
    }
    else if (this.state.addingGroup === 'archElement' && !this.props.showWallsAndArchElements) {
      this.handleAddingGroupChange(this.state.cachedAddingGroup === 'archElement' ? 'container' : this.state.cachedAddingGroup);
    }
    else if (!(this.props.viewMode === 'front' || this.props.viewMode === 'lite' || (this.props.viewMode === 'both' && this.props.bothIsShowingElevations)) && this.state.addingGroup === 'product') {
      this.handleAddingGroupChange(this.state.cachedAddingGroup || 'container');
    }
    //HINT the following logic manages automatically changing the selected adding group and list based on the activeEntity
    else if (!_.isEqual(this.props.activeEntitiesData, prevProps.activeEntitiesData)) {
      const {addingGroup} = this.state;
      const {activeEntities} = this.props;

      if (
        !_.includes(['schematic'], this.props.activeDetailLevel) && ((_.size(this.props.activeEntities) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'container'}) && !Container.getTypeDataFor({container: _.get(activeEntities, '0')}).isOrnament && this.props.viewMode !== 'top' && _.get(activeEntities, '0.type') !== 'countertop' && !(this.props.viewMode === 'both' && !this.props.bothIsShowingElevations)) || //HINT activeEntity is set to container
        (_.size(this.props.activeEntities) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'product'}) && Product.getHasComponents({product: _.get(activeEntities, '0')})) //HINT activeEntity is set to component-based product
        )) {
        this.handleAddingGroupChange('product');
      }
      else if (
        (addingGroup === 'product' && !(_.size(activeEntities) === 1 && ((this.props.getIncludesActiveEntity({resourceKey: 'container'}) && !Container.getTypeDataFor({container: _.get(activeEntities, '0')}).isOrnament) || (this.props.getIncludesActiveEntity({resourceKey: 'product'}) && Product.getHasComponents({product: _.get(activeEntities, '0')})))))//HINT previous addingGroup was 'product' and activeEntity is set to non-component-based product
      ) {
        this.handleAddingGroupChange(this.state.cachedAddingGroup || 'container');
      }
      else if (addingGroup === 'archetype') {
        if (this.props.viewMode === 'top' && _.size(activeEntities) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'room'})) {
          this.handleAddingGroupChange('archetype');
        }
        else if (this.props.viewMode === 'top' && _.size(prevProps.activeEntitiesData) === 1 && prevProps.activeEntitiesData[0].resourceKey === 'room') {
          this.handleAddingGroupChange('archetype');
        }
      }
    }
    else if ((_.size(prevProps.activeEntities) === 1 && _.size(this.props.activeEntities) === 1) && _.get(prevProps, 'activeEntities.0.id') === _.get(this.props, 'activeEntities.0.id')) {
      const {activeEntities} = this.props;
      const areActiveEntityResourceKeysValid = _.size(activeEntities) === 1 && (
        (this.props.getIncludesActiveEntity({resourceKey: 'container'}) && this.props.viewMode !== 'top' && _.get(activeEntities, '0.type') !== 'countertop') || //HINT activeEntity is set to container
        (this.props.getIncludesActiveEntity({resourceKey: 'product'}) && Product.getHasComponents({product: _.first(activeEntities)}))
      ); //HINT activeEntity is set to component-based product

      if (areActiveEntityResourceKeysValid) {
        const {activeEntities: prevEntities} = prevProps;
        const dimensionsChanged = !_.isEqual(_.get(prevEntities, '0.dimensions'), _.get(activeEntities, '0.dimensions'));

        if (dimensionsChanged) this.handleAddingGroupChange('product');
      }
    }
  }

  componentWillUnmount() {
    document.addEventListener('mouseup', () => this.props.handleAddMenuMouseUp());
  }

  handleSearchInputChange = (value) => {
    this.setState({searchTerm: value});
  };

  addAccessory = async (addItem) => {
    var product = await lib.api.create('productInstance', {
      props: {
        productId: addItem.id,
        projectId: this.props.project.id,
        versionId: this.props.project.versionId,
        quantity: 1,
        rank: 0,
        primaryAssociationKey: 'project'
      }
    });

    this.props.trackProducts({products: [product]});
  };

  createDragItem = (addItem) => {
    var {project, activeEntitiesData, activeEntities, activeViewEntity, viewMode} = this.props;

    var defaultProps = {
      container: {
        resourceKey: 'container',
        props: {projectId: project.id, versionId: project.versionId, rotation: 0,
          customData: {wrap: {overhang: 1 / 8, boldStyle: {left: 'bold', right: 'bold'}},
            panelStyle: 'panel', lightingType: 'puck', lightingTemp: '2700'},
          dimensions: {width: 10, height: 10, depth: 10},
          type: addItem.type,
        }
      },
      product: {
        resourceKey: 'product',
        props: {
          projectId: project.id,
          ...(_.size(activeEntitiesData) === 1 && this.props.getIncludesActiveEntity({resourceKey: _.get(activeEntitiesData, '0.resourceKey'), id: _.get(activeEntitiesData, '0.id')}) ? {[`${_.get(activeEntitiesData, '0.resourceKey')}InstanceId`]: _.get(activeEntitiesData, '0.id')} : {}),
          versionId: project.versionId,
          productId: addItem.id,
          dimensions: {width: 12, height: 10, depth: 10},
          details: {},
          quantity: 1,
          customData: {},
          applianceData: [],
          rotation: 0,
          position: {x: 0, y: 0, z: 0},
          type: addItem.title,
        }
      },
      archElement: {
        props: {
          type: addItem.type,
          projectId: project.id,
          versionId: project.versionId
        },
      },
      archetype: {resourceKey: 'archetype', props: {
        projectId: project.id, versionId: project.versionId,
        floorId: this.props.activeViewEntityId,
        plan: {
          closed: true,
          points: [
            {
              'x': 200,
              'y': 0,
              'id': lib.string.uuid(),
              'isPseudoPoint': false
            },
            {
              'x': 200,
              'y': 200,
              'id': lib.string.uuid(),
              'isPseudoPoint': false
            },
            {
              'x': 0,
              'y': 200,
              'id': lib.string.uuid(),
              'isPseudoPoint': false
            },
            {
              'x': 0,
              'y': 0,
              'id': lib.string.uuid(),
              'isPseudoPoint': false
            }
          ]
        }
      }},
      volume: {
        resourceKey: 'volume',
        props: {
          projectId: project.id,
          versionId: project.versionId,
          position: {x: 0, y: 0, z: 0},
          dimensions: {depth: 30, width: 50, height: 50},
          rotation: 0,
          outlinesBySideKey: {},
          details: {},
          customData: {}
        }
      }
    };

    if (_.includes(['container', 'ornament'], this.state.addingGroup)) {
      var typeData = Container.getTypeDataFor({container: {type: addItem.type, projectId: project.id, versionId: project.versionId}});

      const dimensions = {...defaultProps.container.props.dimensions, ...typeData.resourceProps.dimensions};
      const customData = {...defaultProps.container.props.customData, ...typeData.resourceProps.customData};

      var props = _.defaultsDeep({props: {...typeData.resourceProps, dimensions, customData}}, {resourceKey: 'container', ...defaultProps.container});

      return props;
    }
    if (this.state.addingGroup === 'nonSpacialContainer') {
      return {props: addItem, resourceKey: 'container', isNonSpacial: true};
    }
    if (this.state.addingGroup === 'product' && _.size(activeEntitiesData) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'container'})) {
      const product = {productId: addItem.id, projectId: project.id, versionId: project.versionId};
      const typeData = Product.getProductData({product});
      const defaultCustomData = {...typeData.customData, ...Product.getDefaultCustomData({product})};

      const dimensions = {...defaultProps.product.props.dimensions, ...Product.getDefaultDimensions({product: {...defaultProps.product.props}, container: activeEntities[0]})};
      const customData = {...defaultProps.product.props.customData, ...defaultCustomData};

      const props = _.defaultsDeep({props: {...typeData.resourceProps, customData, dimensions}}, {resourceKey: 'product', ...defaultProps.product});

      return props;
    }
    if (this.state.addingGroup === 'product' && _.size(activeEntitiesData) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'product'})) {
      const product = {productId: addItem.id, projectId: project.id, versionId: project.versionId};

      if (Product.getIsComponentProduct({product}) || Product.getIsApplianceStackComponent({product})) {
        const typeData = Product.getProductData({product});
        const defaultCustomData = {...typeData.customData, ...Product.getDefaultCustomData({product})};

        const dimensions = {...defaultProps.product.props.dimensions, ...Product.getDefaultDimensions({product: {...defaultProps.product.props}, container: Product.get('container', {product: activeEntities[0]})})};
        const customData = {...defaultProps.product.props.customData, ...defaultCustomData};

        const props = _.defaultsDeep({props: {...typeData.resourceProps, customData, dimensions}}, {resourceKey: 'product', ...defaultProps.product});

        return props;
      }
    }
    if (this.state.addingGroup === 'archElement') {
      typeData = ArchElement.getTypeData({archElement: addItem});

      const customData = {...defaultProps.archElement.props.customData, ...typeData.resourceProps.customData};
      const dimensions = {...defaultProps.archElement.props.dimensions, ...typeData.resourceProps.dimensions};

      var props = _.defaultsDeep({props: {...typeData.resourceProps, customData}}, {resourceKey: 'archElement', ...defaultProps.archElement});

      return props;
    }
    else if (this.state.addingGroup === 'archetype') {
      var dragItem = _.defaultsDeep(addItem, defaultProps.archetype);

      dragItem.props.plan = _.cloneDeep(addItem.roomData.plan);

      var isAddingArchetypeToRoom = viewMode === 'both' || (viewMode === 'top' && _.size(this.props.activeEntities) === 1 && this.props.getIncludesActiveEntity({resourceKey: 'room'}));

      // if (isAddingArchetypeToRoom) {
      //   var activeRoom = viewMode === 'both' ? activeViewEntity : activeEntities[0];

      //   if (activeRoom && !activeRoom.plan.closed) {
      //     isAddingArchetypeToRoom = false;
      //     dragItem.props.floorId = activeRoom.floorId;
      //   }
      // }

      dragItem.props.isAddingArchetypeToRoom = isAddingArchetypeToRoom;

      if (isAddingArchetypeToRoom) {
        var {volumes, containerInstances} = addItem.roomData;

        var volumeFootprints = _.map(volumes, volume => Volume.getFootprintInRoom({volume}));
        var containerFootprints = _.map(containerInstances, container => Container.getFootprintInRoom({container}));

        var entityFootprints = _.concat(volumeFootprints, containerFootprints);
        var flattenedFootprints = _.flatten(entityFootprints);

        var maxX = _.max(_.map(flattenedFootprints, 'x'));
        var minX = _.min(_.map(flattenedFootprints, 'x'));
        var maxY = _.max(_.map(flattenedFootprints, 'y'));
        var minY = _.min(_.map(flattenedFootprints, 'y'));

        dragItem.props.plan.position = {x: minX, y: minY};

        dragItem.props.plan.points = [
          {
            'x': minX,
            'y': minY,
            'id': lib.string.uuid(),
            'isPseudoPoint': false
          },
          {
            'x': maxX,
            'y': minY,
            'id': lib.string.uuid(),
            'isPseudoPoint': false
          },
          {
            'x': maxX,
            'y': maxY,
            'id': lib.string.uuid(),
            'isPseudoPoint': false
          },
          {
            'x': minX,
            'y': minY,
            'id': lib.string.uuid(),
            'isPseudoPoint': false
          }
        ];
      }

      return dragItem;
    }
    else if (this.state.addingGroup === 'volume') {
      return defaultProps.volume;
    }
  };

  handleUpdateExpandedGroupIndexes = (index) => {
    let {expandedAddGroupOptionIndexes} = this.state;
    const currentlyExpanded = _.includes(expandedAddGroupOptionIndexes, index);

    expandedAddGroupOptionIndexes = currentlyExpanded ? _.reject(expandedAddGroupOptionIndexes, num => num === index) : [...expandedAddGroupOptionIndexes, index];

    this.setState({expandedAddGroupOptionIndexes});
  };

  handleAddingGroupChange = (key) => {
    const {viewMode, companyKey, isEmployee, activeEntitiesData, activeEntities, bothIsShowingElevations} = this.props;

    let {addList, addGroups} = this.state;

    if (key === 'container') {
      const containerTypes = Container.getTypesFor({viewKey: (viewMode === 'both' && !bothIsShowingElevations) ? 'top' : viewMode, companyKey, isEmployee});
      var filteredContainerTypes = _.filter(containerTypes, ({isOrnament}) => !isOrnament);

      var groupedContainerTypes = ['base', 'floatingBase', 'baseFreestandingAppliance', 'sculptedPanel', 'kick', 'wall', 'floatingShelves', 'opencase',
        'wallFreestandingAppliance', 'wallPanel', 'wallUnitLiner', 'backsplash', 'cornerCounterTransition', 'tall', 'tallFreestandingAppliance',
        'baseWithChase', 'islandSeating', 'islandExtension', 'hbIslandExtension', 'islandBackPanel', 'horizontalBarblock', 'rearFacingBarblock', 'endPanel', 'daylightIsland',
        'vanity', 'pivotDoor', 'countertop', 'countertopSupport', 'valet', 'assembly', 'capPanel'
      ];

      addGroups = [
        {title: 'Base', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['base', 'floatingBase', 'baseFreestandingAppliance', 'sculptedPanel', 'kick'], type)))},
        {title: 'Wall', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['wall', 'floatingShelves', 'opencase', 'wallFreestandingAppliance', 'wallPanel', 'wallUnitLiner', 'backsplash', 'cornerCounterTransition'], type)))},
        {title: 'Tall', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['tall', 'tallFreestandingAppliance', 'sculptedPanel'], type)))},
        {title: 'Island', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['base', 'baseWithChase', 'islandSeating', 'islandExtension', 'hbIslandExtension', 'islandBackPanel', 'horizontalBarblock', 'rearFacingBarblock', 'sculptedPanel', 'endPanel', 'daylightIsland', 'kick'], type)))},
        {title: 'Opencase', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['opencase'], type)))},
        {title: 'Vanities', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['vanity', 'kick', 'capPanel', 'endPanel'], type)))},
        {title: 'Panels', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['endPanel', 'capPanel', 'wallPanel', 'sculptedPanel', 'opencase'], type)))},
        {title: 'Wardrobe', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['base', 'tall', 'floatingBase', 'valet', 'assembly', 'endPanel', 'capPanel', 'kick'], type)))},
        {title: 'Pivot Door', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['pivotDoor'], type)))},
        {title: 'Countertop', options: _.filter(filteredContainerTypes, ({type}) => (_.includes(['countertop', 'countertopSupport'], type)))},
        {title: 'Other', options: _.filter(filteredContainerTypes, ({type}) => (!_.includes(groupedContainerTypes, type)))}
      ];

      addGroups = _.filter(addGroups, addGroup => {
        return addGroup.options && addGroup.options.length > 0;
      });

      addList = [];
    }
    else if (key === 'ornament') {
      const containerTypes = Container.getTypesFor({viewKey: (viewMode === 'both' && !bothIsShowingElevations) ? 'top' : viewMode, companyKey, isEmployee});

      addGroups = [];
      addList = _.filter(containerTypes, (type) => {
        if (type.isOrnament) return type;
      });
    }
    else if (key === 'product') {
      if (_.size(activeEntities) === 1 && _.size(activeEntitiesData) === 1 && (this.props.getIncludesActiveEntity({resourceKey: 'product'}) || this.props.getIncludesActiveEntity({resourceKey: 'container'}))) {
        addGroups = _.map(getProductsByCategoryFor({[_.get(activeEntitiesData, '0.resourceKey')]: _.get(activeEntities, '0')}), productCategory => {
          return {title: productCategory.title, options: _.map(productCategory.productTypes, productType => {
            return {
              ...productType,
              thumbnail: `${K.uploadedFilesUrlPrefix}/public/models/thumbnails/dark/${productType.model.id}/${productType.model.versionId}.png`
            };
          })};
        });
      }

      addList = [];
    }
    else if (key === 'archElement') {
      addGroups = [];
      addList = ArchElement.getTypesFor({parentType: viewMode !== 'front' ? 'room' : ''});

      addList = _.reject(addList, addItem => _.includes(['pillar', 'polygon', 'rectangularFloorArchitecturalElement', 'rectangularWallArchitecturalElement'], addItem.type));
    }
    else if (key === 'archetype') {
      addGroups = [];
      var filteredArchetypes = _.sortBy(_.values(this.props.archetypes), archetype => archetype.type === 'buildingBlock' ? 0 : 1);
      var roomIsSelected = _.size(activeEntities) === 1 && activeEntitiesData[0].resourceKey === 'room';
      var includeBuildingBlocks = viewMode === 'both' || (viewMode === 'top' && roomIsSelected);

      if (!(includeBuildingBlocks)) {
        filteredArchetypes = _.filter(filteredArchetypes, archetype => _.includes(['archetype', 'commonConfiguration', 'atlanticFields'], archetype.type));
      }

      addGroups = _.map(_.groupBy(filteredArchetypes, 'type'), (archetypeGroup, type) => {
        return {key: type, title: pluralize(_.startCase(type)), options: _.map(archetypeGroup, archetype => {
          return {...archetype, thumbnail: require('assets/container-icons/custom-icon.png')};
        })};
      });
      // addList = _.map(filteredArchetypes, archetype => ({...archetype, thumbnail: require('assets/container-icons/custom-icon.png')}));
    }
    else if (key === 'volume') {
      addGroups = [];
      addList = [{
        title: 'Volume',
        type: 'volume',
        thumbnail: require('assets/container-icons/floating-base-icon.png'),
        isDisabled: false
      }];
    }
    else if (key === 'nonSpacialContainer') {
      var {room} = this.props;
      addGroups = [];
      addList = [];

      if (room) {
        var nonSpacialContainers = Room.get('nonSpacialContainers', {room});

        addList = _.map(nonSpacialContainers, nonSpacialContainer => {
          return {key: nonSpacialContainer.id, isSpeccedContainer: true, title: `${_.startCase(nonSpacialContainer.type)} - ${nonSpacialContainer.id}`, resourceProps: nonSpacialContainer, ...nonSpacialContainer};
        });
      }
    }
    else {
      addGroups = [];
      addList = [];
    }

    var searchTerm = key === this.state.addingGroup ? this.state.searchTerm : '';

    this.setState({addingGroup: key, addList, addGroups, searchTerm, cachedAddingGroup: _.includes(['product', 'archetype'], key) ? this.state.cachedAddingGroup : key});
  };

  getAddButtonOptions = () => {
    const {showAccessoriesView, isEmployee, activeDetailLevel, activeEntitiesData, activeEntities, viewMode, bothIsShowingElevations, getIncludesActiveEntity, room} = this.props;

    let addButtonOptions = [
      {title: 'CONTAINERS', key: 'container', icon: containerIcon, activeIcon: containerActiveIcon},
    ];

    if (viewMode !== 'lite') addButtonOptions.push({title: 'VOLUMES', key: 'volume', icon: containerIcon, activeIcon: containerActiveIcon});

    if (this.props.showWallsAndArchElements && viewMode !== 'lite') addButtonOptions.unshift({title: 'DOORS & WINDOWS', key: 'archElement', icon: architectureIcon, activeIcon: architectureActiveIcon});

    if ((viewMode === 'top' || viewMode === 'both') && _.values(this.props.archetypes).length) addButtonOptions.unshift({title: 'CONFIG LIBRARY', key: 'archetype', icon: containerIcon, activeIcon: containerActiveIcon});
    if (isEmployee && viewMode !== 'lite') addButtonOptions.push({title: 'PROPS', key: 'ornament', icon: propsIcon, activeIcon: propsActiveIcon});

    if (room && Room.get('nonSpacialContainers', {room}).length && !_.includes(['lite', 'threeD'], viewMode)) {
      addButtonOptions.unshift({title: 'Unplaced Containers', key: 'nonSpacialContainer', icon: containerIcon, activeIcon: containerActiveIcon});
    }

    if (_.size(activeEntities) === 1 && _.size(activeEntitiesData) === 1 ) {
      if (viewMode === 'front' || (viewMode === 'both' && bothIsShowingElevations) || viewMode === 'lite') {
        if (!_.includes(['schematic'], activeDetailLevel) && getIncludesActiveEntity({resourceKey: 'container'}) && _.get(activeEntities, '0.type') !== 'countertop' && !Container.getTypeDataFor({container: _.get(activeEntities, '0')}).isOrnament) {
          addButtonOptions.push({title: 'PRODUCTS', key: 'product', icon: cabinetIcon, activeIcon: cabinetActiveIcon});
        } else if (!_.includes(['schematic'], activeDetailLevel) && getIncludesActiveEntity({resourceKey: 'product'}) && Product.getHasComponents({product: _.get(activeEntities, '0')})) {
          addButtonOptions.push({title: 'PRODUCTS', key: 'product', icon: cabinetIcon, activeIcon: cabinetActiveIcon});
        }
      }
    }

    if (showAccessoriesView) {
      addButtonOptions = [
        {title: 'ACCESSORIES', key: 'accessories', icon: '', activeIcon: ''},
      ];
    }

    return addButtonOptions;
  };

  render() {
    var {isDragging, dragItem, lastMouseEvent, isDraggingOnCanvas, showAccessoriesView, isVisible, isEmployee, projectTreeIsShowing, show, viewMode, activeEntities, activeEntitiesData, toggleInfoPopupShowing, companyKey} = this.props;
    let {addingGroup = 'container', addList, addGroups, configurationLibraryCategory} = this.state;
    var roomsData = _.map(_.find(addGroups, {key: configurationLibraryCategory})?.options, option => ({...option.roomData, ..._.pick(option, ['gridPosition', 'gridOffset', 'title', 'gridTextOffset']), option}));
    var roomIsSelected = _.size(activeEntities) === 1 && activeEntitiesData[0].resourceKey === 'room';

    if (!isVisible) {
      return (
        <div
          onClick={show}
          style={{cursor: 'pointer', position: 'absolute', bottom: K.spacing * 2, left: projectTreeIsShowing ? 250 + K.spacing * 2 : K.spacing * 2, borderRadius: 75, width: 50, height: 50, minHeight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', color: 'white'}}
        >
          <img src={createIcon} style={{width: 20, height: 20}}/>
        </div>
      );
    }

    var addButtonOptions = this.getAddButtonOptions();

    if (showAccessoriesView) {
      addList = _
        .chain(this.props.productTypes)
        .filter({isStandalone: 1, isSellable: 1, isDiscontinued: 0})
        .filter(productType => {
          let shouldShow = true;

          if (!isEmployee) {
            shouldShow = !_.includes([819, 820], productType.id);
          }

          return shouldShow;
        })
        .sortBy([({categoryId}) => !_.includes([81], categoryId), 'categoryId'])
        .value();
    }

    if (this.state.searchTerm && addList) {
      addList = _.filter(addList, addItem => {
        return lib.string.isSearchMatch({title: addItem.title, input: this.state.searchTerm, distance: 0, matchPlurals: true});
      });
    }

    if (this.state.searchTerm && this.state.searchTerm !== '' && this.state.addingGroup === 'container') {
      addList = [
        ..._.uniq(_.filter(_.flatMap(addGroups, (addGroup) => (addGroup.options)), containerType => {
          return lib.string.isSearchMatch({title: containerType.title, input: this.state.searchTerm, distance: 0, matchPlurals: true});
        }))
      ];

      addGroups = [];
    }

    if (this.state.searchTerm && addGroups) {
      addGroups = _.map(addGroups, addGroup => {
        //HINT pulling addGroup.options out causes a reference issue that mutates state
        return {...addGroup, options: _.filter(addGroup.options, addItem => {
          return lib.string.isSearchMatch({title: addItem.title, input: this.state.searchTerm, distance: 0, matchPlurals: true});
        })};
      });

      addGroups = _.filter(addGroups, addGroup => {
        return addGroup.options.length > 0;
      });
    }

    var scale = 2.5;
    //TODO autofocus container
    //TODO autofocus on change

    var paneWidthMultiplier = 1;
    if (addingGroup === 'archetype') paneWidthMultiplier = 2.5;
    if (addingGroup === 'nonSpacialContainer') paneWidthMultiplier = 1.75;

    return <>
      {isDragging && !isDraggingOnCanvas && (
        <div style={{position: 'fixed', zIndex: 100, top: lastMouseEvent.pageY - thumbnailDiameter / 2, left: lastMouseEvent.pageX - thumbnailDiameter / 2, pointerEvents: 'none'}}>
          <Thumbnail item={dragItem}/>
        </div>
      )}
      <HudElement x={'left'} y={'bottom'} style={{width: K.paneWidth * paneWidthMultiplier, borderRight: K.grayBorder, zIndex: 1000, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingRight: 0, paddingTop: 0, backgroundColor: '#f5f5f5'}}>
        <div
          onClick={this.props.hide}
          style={{cursor: 'pointer', position: 'absolute', top: K.spacing * 2, left: `calc(100% + ${K.spacing * 2}px)`, width: '50px', ...K.fonts.label}}
        >{'< HIDE'}</div>
        <>
          <div style={{flex: '0 1 auto', overflowY: 'overlay', paddingRight: K.spacing, paddingTop: K.spacing * 2}} className={'add-menu-list'}>
            {(addingGroup === 'archetype' && configurationLibraryCategory !== 'atlanticFields') ? (<div style={{position: 'absolute', top: K.spacing * 2, width: '94%', height: '60%'}}>
              <CanvasRooms3D
                isArchetype={true}
                roomsData={roomsData}
                viewOffset={{x: 0, y: 0}}
                activeDetailLevel={this.props.activeDetailLevel}
                activeFillMode={'materialColors'}
                cameraZoom={0.1 * scale}
                cameraPosition={[100 * scale, 300 * scale, 200 * scale]}
                cameraMouseControls={true}
                hideArchitecture={addingGroup === 'buildingBlock'}
                onRoomClick={({event, roomData}) => {
                  this.props.setDragData({
                    isDragging: true,
                    dragItem: this.createDragItem(roomData.option),
                    lastMouseEvent: event,
                    activeRoomId: _.map(this.props.activeEntitiesData, activeEntity => {
                      if (activeEntity.resourceKey === 'room') return activeEntity.id;
                    })});
                }}
                showLabel={true}
              />
            </div>) :
              (<>
                {addGroups.length > 0 ? (
                  _.map(addGroups, ({title, options}, index) => {
                    return (
                      <div key={index}>
                        <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: K.spacing}}
                          onClick={() => this.handleUpdateExpandedGroupIndexes(index)}
                        >
                          <img src={upArrowIcon} alt='arrow' style={{width: 16, height: 16, marginLeft: 11, backgroundColor: 'transparent', transform: _.includes(this.state.expandedAddGroupOptionIndexes, index) ? {} : 'rotate(180deg)'}}/>
                          <div style={{marginLeft: 16, fontWeight: 'bold'}}>{title}</div>
                        </div>
                        {(this.state.searchTerm || _.includes(this.state.expandedAddGroupOptionIndexes, index)) && (
                          <div style={{paddingTop: K.spacing, paddingBottom: K.spacing + K.margin}}>
                            {_.map(_.sortBy(options, ['isDisabled', 'id']), (option, optionIndex) => {
                              return (
                                <ProductThumbnailEpic
                                  key={`product-option-thumbnail-epic-index-${optionIndex}`}
                                  {...{option, optionIndex, toggleInfoPopupShowing, addingGroup, viewMode, companyKey}}
                                  setDragData={this.props.setDragData}
                                  handleAddMenuMouseUp={this.props.handleAddMenuMouseUp}
                                  createDragItem={this.createDragItem}
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  _.map(addList, (addItem, index) => {
                    if (showAccessoriesView && this.props.media[addItem.id]) addItem.thumbnail = this.props.media[addItem.id].url;

                    return (
                      <Fragment key={index}>
                        {showAccessoriesView ? (
                          <div style={{display: 'flex', alignItems: 'center', marginBottom: K.spacing}}
                            onClick={() => this.addAccessory(addItem)}
                          >
                            <Thumbnail {..._.pick(addItem, ['title', 'thumbnail'])} {...{showAccessoriesView, option: addItem}}/>
                            <div style={{marginLeft: K.spacing, fontSize: '0.8rem', width: 120, display: 'table-cell', verticalAlign: 'middle'}}>{addItem.title}</div>
                          </div>
                        ) : (
                          <ProductThumbnailEpic
                            {...{toggleInfoPopupShowing, addingGroup, viewMode, companyKey}}
                            setDragData={this.props.setDragData}
                            handleAddMenuMouseUp={this.props.handleAddMenuMouseUp}
                            createDragItem={this.createDragItem}
                            option={addItem}
                            optionIndex={`add-item-${index}`}
                            addingGroup={this.state.addingGroup}
                            bothIsShowingElevations={this.props.bothIsShowingElevations}
                          />
                        )}
                      </Fragment>
                    );
                  })
                )}
              </>)
            }
          </div>
          <div style={{marginRight: K.spacing * 2}}>
            <TextInput
              style={{width: '100%', marginBottom: K.spacing * 2, marginTop: K.spacing * 2, backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.07)'}}
              onInput={this.handleSearchInputChange}
              placeholder='SEARCH...'
              autoFocus={true}
              value={this.state.searchTerm}
            />
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', marginBottom: -K.spacing, gap: K.spacing * 3}}>
              <div style={{flexDirection: 'column'}}>
                {_.map(addButtonOptions, (addButtonOption) => (
                  <div
                    key={addButtonOption.key}
                    style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: K.margin}}
                    onClick={() => this.handleAddingGroupChange(addButtonOption.key)}
                  >
                    {!showAccessoriesView && (
                      <img src={addButtonOption.key === addingGroup ? addButtonOption.activeIcon : addButtonOption.icon} alt={`${addButtonOption.title}-icon`} style={addButtonOption.key === addingGroup ? {width: 32, height: 32, margin: 0} : {width: 20, height: 20, margin: 6} }/>
                    )}
                    <div
                      style={{...K.fonts.label, marginLeft: K.spacing, fontWeight: addButtonOption.key === addingGroup ? 'bold' : 'normal', opacity: addButtonOption.key === addingGroup ? 1 : 0.5}}
                    >{addButtonOption.title}</div>
                  </div>
                ))}
              </div>
              {addingGroup === 'archetype' && (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flexGrow: 1, marginTop: K.spacing}}>
                  {_.map([
                    {title: 'Archetypes', value: 'archetype'},
                    {title: 'Common Configurations', value: 'commonConfiguration'},
                    {title: 'Atlantic Fields', value: 'atlanticFields'},
                    ...(viewMode === 'both' || (viewMode === 'top' && roomIsSelected) ? [{title: 'Building Blocks', value: 'buildingBlock'}] : [])
                  ], configurationLibraryCategoryOption => (
                    <input
                      key={configurationLibraryCategoryOption.value}
                      value={configurationLibraryCategoryOption.title}
                      type={'button'}
                      style={{background: 'none', display: 'block', border: 'none', outline: 'inherit', textTransform: 'uppercase', fontSize: 12, letterSpacing: '0.1em', cursor: 'pointer', marginBottom: 18, ...(configurationLibraryCategoryOption.value === configurationLibraryCategory ? {fontWeight: 'bold'} : {opacity: 0.5})}}
                      onClick={() => this.setState({configurationLibraryCategory: configurationLibraryCategoryOption.value})}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      </HudElement>
    </>;
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData} = ownProps;

    var activeEntities = _.map(activeEntitiesData, (activeEntity) => {
      return _.get(state.resources, `[${pluralize(activeEntity.resourceKey)}].byId[${activeEntity.id}]`);
    });

    return {
      scopesByRoomId: state.resources.scopes.byFieldKeyIndex.roomId,
      rooms: state.resources.rooms.byId,
      project: _.values(state.resources.projects.byId)[0],
      containerTypes: state.resources.containerTypes.byId,
      productTypes: state.resources.productTypes.byId,
      activeEntities,
      activeEntitiesData,
      archetypes: state.resources.archetypes.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.elevations, ['trackElevations', 'createElevation', 'updateElevations', 'destroyElevations', 'modifyElevations']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'updateContainer', 'updateContainers', 'createContainers', 'destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'destroyRoom', 'destroyRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'createScopes', 'updateScope', 'destroyScopes']),
    ..._.pick(resourceActions.products, ['trackProducts', 'updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'destroyArchElements']),
    ..._.pick(resourceActions.walls, ['trackWalls', 'destroyWall', 'destroyWalls', 'updateWall', 'updateWalls', 'createWall', 'createWalls', 'modifyWalls']),
    ..._.pick(resourceActions.projectGraphics, ['trackProjectGraphics', 'destroyProjectGraphics']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'destroyVolumes']),
    ..._.pick(resourceActions.parts, ['updateParts']),
  }
})(AddButtonHudElement);