import React from 'react';

import MeasuringTapeCanvasObject from 'canvas/canvas-measuring-tape';

const DimensionCreatorCanvasObject = (props) => {
  return (
    <MeasuringTapeCanvasObject hideMeasuringTape={props.handleCreateDimension} />
  );
};

export default DimensionCreatorCanvasObject;
