import React from 'react';
import _ from 'lodash';

import PropertyField from 'components/property-field';
import getMaterialOptionsForArchetypeParameter from 'helpers/get-material-options-for-archetype-parameter';

import { connect } from 'redux/index.js';

class ConfiguratorPropertyField extends React.PureComponent {
  renderPropertyField = ({id, title, path, type, options, inputType, noThumbnail, hasOptionGroups, index}) => {
    var {materialTypes, room, isEditable} = this.props;

    if (hasOptionGroups) {
      if (options.length < 7) {
        hasOptionGroups = false;
      }
      else {
        var optionGroups = _.map(_.groupBy(options, 'materialTypeId'), (options, materialTypeId) => {
          return {title: _.get(materialTypes[materialTypeId], 'title', materialTypeId), options};
        });
      }
    }

    return (
      <PropertyField
        key={`${id}-${path ? `${path}` : ''}`}
        {...{
          title: _.startCase(title),
          type: inputType,
          widerOptionsDropdown: true,
          options, optionGroups, hasOptionGroups, noThumbnail, isEditable
        }}
        value={_.get(room, `selectedParameters.${room.archetypeId}.${id}${path ? `.${path}` : ''}`)}
        onPropertyFieldChange={({value}) => {this.props.handlePropertyFieldChange({id, value, path})}}
        onClick={() => this.props.handleClick({activePropertyKey: `${type}-${index}-${id}`})}
        isExpanded={_.includes(['radio', 'select'], inputType) && this.props.activePropertyKey === `${type}-${index}-${id}`}
        context={'configurator'}
      />
    );
  };

  renderPullPropertyFields = ({inputType, noThumbnail, hasOptionGroups}) => {
    var {materialClasses, pulls, room, parameter} = this.props;
    var {id, title, type, options = []} = parameter;

    var pullTypeOptions = getMaterialOptionsForArchetypeParameter({parameter, room, materialKey: 'pullType'});

    //HINT render propertyFields for 'pullType' and 'pullMaterial' paths
    return _.map(['pullType', 'pullMaterial'], (path, index) => {
      if (path === 'pullType') {
        //TODO default pullType?
        return this.renderPropertyField({
          id, path: 'pullType',
          title: `${title !== 'Pulls' ? `${title} ` : ''} Type`,
          type, options: pullTypeOptions,
          inputType, noThumbnail, hasOptionGroups, index
        });
      }
      else if (path === 'pullMaterial') {
        //HINT get compatible materials based on selected pull type
        var selectedPullTypeId = _.get(room, `selectedParameters.${room.archetypeId}.${id}.pullType`) || _.get(pullTypeOptions, '[0].value');

        if (!selectedPullTypeId) return; //HINT prevents error when instances are empty

        var selectedPull = _.find(pulls, {id: selectedPullTypeId});
        var compatibleMaterials = _.get(materialClasses, `${selectedPull.materialClassId}.materials`);

        if (parameter.compatibleMaterialIds) { //HINT filter compatible materials to those set manually on the parameter
          compatibleMaterials = _.filter(compatibleMaterials, ({id}) => {
            return _.includes(parameter.compatibleMaterialIds, id);
          });
        }

        //HINT only show property field if there are compatible materials
        if (compatibleMaterials.length > 1) {
          options = _.map(compatibleMaterials, ({id, title, materialTypeId}) => {
            return {
              value: id, title,
              thumbnail: `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/material_swatches/${id}.jpg`,
              materialTypeId
            };
          });

          return this.renderPropertyField({
            id, path: 'pullMaterial',
            title: `${parameter.title !== 'Pulls' ? `${parameter.title} ` : ''} Material`,
            type, options, inputType, hasOptionGroups, index, noThumbnail: false
          });
        }
      }
    });
  }

  render() {
    var {room, parameter, index} = this.props;
    var {id, title, type, options = []} = parameter;

    var inputType = 'select'; //TODO handle other input types
    var noThumbnail = true;
    var hasOptionGroups = false;

    if (type === 'pull') {
      return this.renderPullPropertyFields({inputType, noThumbnail, hasOptionGroups});
    }
    else {

      if (type === 'material') {
        hasOptionGroups = true;
        noThumbnail = false;

        options = getMaterialOptionsForArchetypeParameter({parameter, room});
      }
      else if (type === 'conditionalResources') {

      }

      return this.renderPropertyField({id, title, type, options, inputType, noThumbnail, hasOptionGroups, index});
    }
  }
}

export default connect({
  mapState: state => {
    return {
      materialClasses: state.resources.materialClasses.byId,
      pulls: state.resources.pulls.byId,
      materialTypes: state.resources.materialTypes.byId
    };
  },
  mapDispatch: {}
})(ConfiguratorPropertyField);
