import React, {useState, useRef, useEffect} from 'react';

import Drawings from 'project-helpers/drawings';

import _ from 'lodash';

const Draggable = ({children, containerDimensions, isEditing, project, mode, visibilityLayers, activeDimensionsLayer, activeProjectGraphicsLayer, detailLevel, fillMode, moveGraphic, id, size, title, isContext, isEmpty, position, scale = 1, type, model, indicators, onReplaceClick, onDelete, imageMode}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [translateGraphicFrom, setTranslateGraphicFrom] = useState();
  const [translateGraphicTo, setTranslateGraphicTo] = useState();

  const canvasSize = isEmpty ? lib.object.multiply(containerDimensions, 1 / 6) : Drawings.getSizeForPrintArea({printArea: {contentSize: size}, scale, project});
  const positionInPage = Drawings.getPositionInPage({position, size: canvasSize, containerDimensions});

  const translateGraphicToRef = useRef();


  useEffect(() => {
    window.addEventListener('mousemove', (handleMouseMove));
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
  }, [isDragging]);

  const handleMouseDown = (event) => {
    if (isEditing && !_.includes(event.target.className, 'prevent-drag')) {
      var { clientX, clientY } = event;
      setIsDragging(true);
      translateGraphicToRef.current = {x: clientX, y: clientY};
      setTranslateGraphicFrom({x: clientX, y: clientY});
    }
  };

  const handleMouseMove = ({clientX, clientY}) => {
    if (isDragging) {
      translateGraphicToRef.current = {x: clientX, y: clientY};
      setTranslateGraphicTo({x: clientX, y: clientY});
    }
  }

  const handleMouseUp = async () => {
    if (isDragging && translateGraphicToRef.current && translateGraphicFrom) {
      var delta = {
        x: translateGraphicToRef.current.x - translateGraphicFrom.x,
        y: translateGraphicToRef.current.y - translateGraphicFrom.y
      }

      if (delta.x !== 0 || delta.y !== 0) {
        var left = Math.round(positionInPage.left + delta.x);
        var top = Math.round(positionInPage.top + delta.y);

        moveGraphic(id, left, top);
      }

      setIsDragging(false);
    }
  }

  return (<div onMouseDown={handleMouseDown} style={{
    transform: `translate(${isDragging && translateGraphicTo ? translateGraphicTo.x - translateGraphicFrom.x : 0}px, ${isDragging && translateGraphicTo ? translateGraphicTo.y - translateGraphicFrom.y : 0}px)`
  }}>{children}</div>);
}

export default Draggable
