import _ from 'lodash';

function getVolumeFieldGroups({volume, activeFillMode}) {
  var fieldSetGroups = [
    {title: 'Volume', properties: [
      {
        title: 'Width',
        key: 'dimensions.width',
        type: 'number',
        views: ['top', 'front'],
      },
      {
        title: 'Height',
        key: 'dimensions.height',
        type: 'number',
        views: ['top', 'front'],
      },
      {
        title: 'Depth',
        key: 'dimensions.depth',
        type: 'number',
        views: ['top', 'front'],
      },
      {
        title: 'Y',
        key: 'position.y',
        type: 'number',
        views: ['top', 'front'],
      },
      {key: 'customData.editAsPolyline', title: 'Edit as Polyline', type: 'checkbox', views: ['front']},
      {
        key: 'customData.isLocked', title: 'Locked', type: 'checkbox', views: ['front', 'top', 'bottom', 'left', 'right']
      },
      {
        key: 'customData.hideTopFill', title: 'Hide Top Fill', type: 'checkbox', views: ['top', 'both']
      },
      {
        key: 'customData.resetPoints', title: 'Reset Front Outline', type: 'checkbox', views: ['front', 'top', 'bottom', 'left', 'right']
      },
      ...(activeFillMode === 'grayscale' ? [{
        key: 'customData.value', title: 'Value', type: 'radio', views: ['front', 'top', 'bottom', 'left', 'right'],
        options: [
          {value: '#f4f4f4', title: 'White'},
          {value: '#d7d7d7', title: 'Light Gray'},
          {value: '#ababab', title: 'Dark Gray'},
          {value: '#777777', title: 'Black'}
        ]
      }
      ] : [])
    ]
    },
    {title: 'Other', properties: [
      // checkbox property object like isLocked above saying title: "Hide in 3d"
      {
        key: 'customData.hideIn3d',
        title: 'Hide in 3d',
        type: 'checkbox',
        views: ['front', 'top', 'bottom', 'left', 'right']
      }
    ]}
  ];

  return fieldSetGroups;
}

function handleVolumePropertyChange({activeEntityId, activeEntity, path, resourceActions, value}) {
  if (_.split(path, '.').length > 1) {
    var topLevelKey = _.split(path, '.')[0];
    var shouldUpdate = true;

    if (path === 'customData.resetPoints') {
      path = 'outlinesBySideKey';
      value = {};

      topLevelKey = 'outlinesBySideKey';

      shouldUpdate = confirm('Are you sure you want to reset the outline of this volume? this cannot be undone');
    }

    if (shouldUpdate) {
      _.set(activeEntity, path, value);

      resourceActions.updateVolume({id: activeEntityId, props: {[topLevelKey]: activeEntity[topLevelKey]}});
    }
  }
  else {
    resourceActions.updateVolume({id: activeEntityId, props: {[path]: value}});
  }
}

export { handleVolumePropertyChange, getVolumeFieldGroups };