import React, { useEffect, useState } from 'react';
import { connect } from 'redux/index.js';
import { resourceActions } from 'redux/index.js';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import Floor from 'project-helpers/floor';
import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';
import Scope from 'project-helpers/scope';
import HudElement from './hud-element';
import TextInput from 'components/text-input';
import DxfPopup from '../popups/dxf-popup';
import DragDropList from 'components/drag-drop-list';
import AddRoomPopup from '../popups/add-room-popup';
import AddFloorPopup from '../popups/add-floor-popup';
import AddScopePopup from '../popups/add-scope-popup';

import dxfIcon from 'assets/dxf-icon.png';
import upArrow from '../../../assets/up-arrow-black.png';
import deleteIcon from '../../../assets/trash-icon-black.png';
import projectTreeIcon from 'assets/list-icon-white.png';
import Container from 'project-helpers/container';
import { pluralize, singularize } from 'inflection';

const TreeNode = (props) => {
  const {entity, children, expanded, isEditable, isExpandable, isSelected, isNavigable, isDxfUploadable, isDeletable, title, prefix, style, onClick, onChange, onNavigateTo, onDelete, updateFloor, onExpandChange} = props;

  const [isExpanded, setExpanded] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isUploadingDxf, setUploadingDxf] = useState(false);

  useEffect(() => {
    let nodeIsExpanded = false;
    const nodeIsManuallyExpanded = _.includes(expanded?.get(props.resourceKey), props.id);

    let nodeIsProgramaticallyExpanded = false;

    if (props.activeViewEntityResourceKey === 'floor') {
      nodeIsProgramaticallyExpanded = props.activeViewEntity.id === props.id && props.resourceKey === 'floor';
    }
    else if (props.activeViewEntityResourceKey === 'room') {
      nodeIsProgramaticallyExpanded = props.activeViewEntity.floorId === props.id && props.resourceKey === 'floor';
      nodeIsProgramaticallyExpanded = nodeIsProgramaticallyExpanded || props.activeViewEntity.id === props.id && props.resourceKey === 'room';
    }
    else if (props.activeViewEntityResourceKey === 'elevation') {
      const room = Elevation.get('room', {elevation: props.activeViewEntity});
      const floor = Room.get('floor', {room});

      if (props.resourceKey === 'floor' && floor) nodeIsProgramaticallyExpanded = floor.id === props.id;
      else if (props.resourceKey === 'room' && room) nodeIsProgramaticallyExpanded = room.id === props.id;
    }
    else if (props.activeViewEntityResourceKey === 'scope') {
      const room = Scope.get('room', {scope: props.activeViewEntity});
      const floor = Room.get('floor', {room});

      if (props.resourceKey === 'floor' && floor) nodeIsProgramaticallyExpanded = floor.id === props.id;
      else if (props.resourceKey === 'room' && room) nodeIsProgramaticallyExpanded = room.id === props.id;
    }

    nodeIsExpanded = nodeIsManuallyExpanded || nodeIsProgramaticallyExpanded;

    if (isExpanded !== nodeIsExpanded) setExpanded(nodeIsExpanded);
  }, [expanded, props.activeViewEntityResourceKey, props.activeViewEntityId]);

  const toggleIsExpanded = () => {
    if (onExpandChange) onExpandChange(!isExpanded);

    setExpanded(!isExpanded);
  };

  const handleTitleChange = ({value}) => {
    if (onChange) onChange({value});
  };

  const handleTitleOnBlur = () => {
    setEditing(false);
  };

  const handleDoubleClick = () => {
    if (isEditable) {
      setEditing(true);
    }
  };

  const expandArrowSize = {height: 14, width: 14};
  const titleDivProps = !isExpandable ? {
    style: {fontWeight: isSelected ? 'bold' : 'normal', textDecoration: isSelected ? 'bold' : 'normal', marginLeft: K.spacing + expandArrowSize.width, ...style},
    onClick,
    onDoubleClick: handleDoubleClick,
  } : {
    style: {fontWeight: isSelected ? 'bold' : 'normal', textDecoration: isSelected ? 'bold' : 'normal', ...style},
    onClick,
    onDoubleClick: handleDoubleClick,
  };

  return (<>
    <div style={{height: isEditing ? 24 : 'auto', opacity: 1, paddingBottom: K.margin, display: 'flex', cursor: 'pointer'}} data-conditional-opacity-parent={1}>
      {isExpandable && (
        <img {...(!isExpanded ? {'data-conditional-opacity': 1} : {})} src={upArrow}
          style={{...expandArrowSize, marginRight: K.spacing, objectFit: 'contain', cursor: 'pointer', ...(isExpanded ? {transform: 'rotate(180deg)'} : {transform: 'rotate(90deg)'})}}
          onClick={toggleIsExpanded}
        />
      )}
      <div className='title' {...titleDivProps}>
        {isEditing ? (<>
          <TextInput
            style={{padding: 0, width: 120}}
            autoFocus={true}
            noFill
            value={title}
            onChange={handleTitleChange}
            onBlur={handleTitleOnBlur}
          />
        </>) : (title)}
      </div>
      {isNavigable && (
        <div data-conditional-opacity={1} style={{marginLeft: K.spacing, cursor: 'pointer'}} onClick={onNavigateTo}>→</div>
      )}
      {isDxfUploadable && (
        <img data-conditional-opacity={1} src={dxfIcon} style={{marginLeft: K.spacing, cursor: 'pointer', width: 16, objectFit: 'contain'}} onClick={() => setUploadingDxf(true)}/>
      )}
      {isDeletable && (
        <img data-conditional-opacity={1} src={deleteIcon} style={{marginLeft: K.spacing, cursor: 'pointer', width: 16, objectFit: 'contain'}} onClick={onDelete}/>
      )}
    </div>
    {isExpanded && isExpandable && !!children && (
      <div style={{marginLeft: K.spacing, paddingBottom: K.spacing * 1.5}}>
        {children}
      </div>
    )}
    {isUploadingDxf && (
      <DxfPopup
        {...{updateFloor}}
        floorId={entity.id}
        projectId={entity.projectId}
        close={() => setUploadingDxf(false)}
      />
    )}
  </>);
};

class ProjectTreeHudElement extends React.Component {
  shouldComponentUpdate(prevProps) {
    return (_.get(prevProps.activeEntities, '0.eventType') !== 'transform' || _.get(this.props.activeEntities, '0.eventType') !== 'transform');
  }

  constructor(props) {
    super(props);

    this.state = {
      isShowingAddFloorPopup: false,
      isShowingAddRoomPopup: false,
      isShowingAddScopePopup: false,
      expanded: new Map(),
    };
  }

  componentDidMount() {
    this.updateExpandedMap();
  }

  componentDidUpdate(prevProps) {
    this.updateExpandedMap(prevProps);
  }

  handleAddFloor = async ({projectId, title = '', roomTitle = ''}) => {
    var floor = await lib.api.create('floor', {props: {versionId: this.props.project.versionId, projectId: projectId, title}});

    this.props.trackFloors({floors: [floor]});

    await this.handleAddRoom({floorId: floor.id, title: roomTitle});

    setTimeout(() => this.props.setActiveViewEntityId('floor', floor.id));
  };

  handleAddRoom = async ({floorId, title = ''}) => {
    var {viewMode} = this.props;
    var {versionId} = this.props.project;

    var customData = {}; //{volumesOnly: false};

    var roomProps = {title, floorId: floorId, versionId: versionId, projectId: this.props.project.id, plan: {points: []}, customData};

    let siblingRooms = _.find(this.props.floors, {id: floorId}) ? Floor.get('rooms', {floor: _.find(this.props.floors, {id: floorId})}) : [];

    var roomsUsingRank = _.some(siblingRooms, room => room.rank);

    if (roomsUsingRank) {
      roomProps.rank = _.max(_.map(siblingRooms, 'rank')) + 1;
    }

    var room = await lib.api.create('room', {props: roomProps});

    var scope = await lib.api.create('scope', {props: {title, projectId: this.props.project.id, versionId: versionId, roomId: room.id}});

    this.props.trackScopes({scopes: [scope]});
    this.props.trackRooms({rooms: [room]});

    setTimeout(() => {
      if (this.props.viewMode === 'lite') {
        this.props.setActiveViewEntityId('scope', scope.id);
      }
      else {
        this.props.setActiveEntities({entities: [{resourceKey: 'room', id: room.id}]});

        if (this.props.activeViewEntityResourceKey !== 'floor') this.props.setActiveViewEntityId('room', room.id);
      }
    });
  };

  handleAddScopeButtonPress = async ({roomId, floorId}) => {
    var {viewMode} = this.props;

    if (viewMode === 'lite') {
      this.setState({isShowingAddScopePopup: true, activeFloorId: floorId, activeRoomId: roomId});
    }
    else {
      this.props.onAddScope({roomId, floorId});
    }
  }

  handleAddScope = async ({title, roomId}) => {
    var {versionId} = this.props.project;
    var projectId = this.props.project.id

    var scope = await lib.api.create('scope', {props: {versionId, projectId, roomId, title}});

    this.props.trackScopes({scopes: [scope]});

    setTimeout(() => {
      if (this.props.viewMode === 'lite') {
        this.props.setActiveViewEntityId('scope', scope.id)
      }
    });
  }

  handleTreeNodeDelete = ({entity, resourceKey}) => () => {
    var shouldDelete = false;

    if (resourceKey === 'scope') {
      shouldDelete = confirm(`Are you sure you want to delete this scope?`);
    }
    else if (resourceKey === 'elevation') {
      shouldDelete = confirm(`Are you sure you want to delete this elevation?`);
    }
    else {
      shouldDelete = prompt(`Please enter "${entity.title || `Untitled ${_.upperFirst(resourceKey)}`}" to confirm deletion. This can't be undone.`) === (entity.title || `Untitled ${_.upperFirst(resourceKey)}`);
    }

    if (shouldDelete) {
      if (resourceKey === 'scope') {
        this.props.onDeselect();

        Scope.updateComponents({room: Scope.get('room', {scope: entity}), resourceActions: this.props, excludedScopeIds: [entity.id]});

        this.props.destroyScope({id: entity.id});
      }
      else if (resourceKey === 'elevation') {
        this.props.pushToUndoQueue({type: 'elevation', eventKey: 'destroy', instance: entity});

        this.props.onDeselect();

        this.props.destroyElevation({id: entity.id});
      }
      else {
        const Resource = {
          floor: Floor,
          room: Room
        }[resourceKey];

        this.props.onDeselect();

        Resource.destroy({[resourceKey]: entity, resourceActions: this.props});
      }
    }
  };

  handleTreeDragEnd = (updates) => {
    this.props.updateFloors({updates: _.map(updates, ({id, index}) => ({where: {id}, props: {rank: index}}))});
  };

  handleRoomsDragEnd = (updates) => {
    this.props.updateRooms({updates: _.map(updates, ({id, index}) => ({where: {id}, props: {rank: index}}))});
  };

  handleElevationDragEnd = (updates) => {
    this.props.updateElevations({updates: _.map(updates, ({id, index}) => ({where: {id}, props: {rank: index}}))});
  };

  handleScopeDragEnd = (updates) => {
    this.props.updateScopes({updates: _.map(updates, ({id, index}) => ({where: {id}, props: {rank: index}}))});
  };

  handleEntityClick = (resourceKey, id) => () => {
    this.props.setActiveEntities({entities: [{resourceKey, id}]});
  };

  handleNavigateToEntity = (activeViewEntityResourceKey, activeViewEntityId) => () => {
    this.props.setActiveViewEntityId(activeViewEntityResourceKey, activeViewEntityId);
  };

  updateExpandedMap = (prevProps) => {
    const {activeEntityId, activeEntityResourceKey, activeEntity, activeViewEntity, activeViewEntityId, activeViewEntityResourceKey} = this.props;

    const updates = [];
    //HINT automatically update this.state.expanded based on activeEntity changes
    // if (activeEntityId !== undefined && activeEntityId !== prevProps?.activeEntityId || activeEntityResourceKey !== prevProps?.activeEntityResourceKey) {
    //   updates.push({resourceKey: activeEntityResourceKey, id: activeEntityId});

    //   if (activeEntityResourceKey === 'room') {
    //     const floor = Room.get('floor', {room: activeEntity});

    //     if (floor) updates.push({resourceKey: 'floor', id: floor.id});
    //   }
    //   else if (activeEntityResourceKey === 'elevation') {
    //     const room = Elevation.get('room', {elevation: activeEntity});
    //     const floor = Room.get('floor', {room});

    //     if (floor) updates.push({resourceKey: 'floor', id: floor.id});
    //     if (room) updates.push({resourceKey: 'room', id: room.id});
    //   }
    //   else if (activeEntityResourceKey === 'container') {
    //     const room = Container.get('room', {container: activeEntity});
    //     const {floor, elevations: roomElevations} = Room.get(['floor', 'elevations'], {room});

    //     const elevations = _.filter(roomElevations, elevation => {
    //       return _.findIndex(Elevation.get('containers', {elevation}), ({id}) => id === activeEntityId) !== -1;
    //     });

    //     if (floor) updates.push({resourceKey: 'floor', id: floor.id});
    //     if (room) updates.push({resourceKey: 'room', id: room.id});
    //     if (elevations.length > 0) updates.push(...elevations.map(elevation => ({resourceKey: 'elevation', id: elevation.id})));
    //   }
    // }

    //HINT automatically update this.state.expanded based on activeViewEntity changes
    // if (activeViewEntityId !== undefined && activeViewEntityId !== prevProps?.activeViewEntityId || activeViewEntityResourceKey !== prevProps?.activeViewEntityResourceKey) {
    //   if (activeViewEntityResourceKey === 'room') {
    //     const floor = Room.get('floor', {room: activeViewEntity});

    //     if (floor) updates.push({resourceKey: 'floor', id: floor.id});

    //     // updates.push({resourceKey: activeViewEntityResourceKey, id: activeViewEntityId});
    //   }
    //   else if (activeViewEntityResourceKey === 'elevation') {
    //     const room = Elevation.get('room', {elevation: activeViewEntity});
    //     const floor = Room.get('floor', {room});

    //     if (floor) updates.push({resourceKey: 'floor', id: floor.id});
    //     if (room) updates.push({resourceKey: 'room', id: room.id});
    //   }
    // }

    if (updates.length > 0) this.handleExpandChange(updates)(true);
  };

  handleExpandChange = (updates) => (isExpanded) => {
    if (!Array.isArray(updates)) {
      updates = [updates];
    }

    const {expanded} = this.state;
    const updatedExpandedMap = new Map(expanded);
    const groupedUpdates = _.groupBy(updates, update => update.resourceKey);

    let isChanged = false;

    _.forEach(_.keys(groupedUpdates), resourceKey => {
      const updatedResources = updatedExpandedMap.get(resourceKey) || []; //HINT returns an array of ids that are expanded
      const resourceIdsToCheck = _.map(groupedUpdates[resourceKey], 'id');

      _.forEach(resourceIdsToCheck, id => {
        const indexOfId = updatedResources.findIndex(existingId => existingId === id);

        if (isExpanded && indexOfId === -1) {
          //HINT add id to expanded
          updatedResources.push(id);
          isChanged = true;
        }
        else if (!isExpanded && indexOfId !== -1) {
          //HINT remove id from expanded
          updatedResources.splice(indexOfId, 1);
          isChanged = true;
        }
      });

      updatedExpandedMap.set(resourceKey, updatedResources);
    });

    if (isChanged) {
      this.setState({
        expanded: updatedExpandedMap
      });
    }
  };
  //TODO fix bug with project tree not collapsing when isAdding is true
  //TODO fix bug with TreeNodes with no children not aligning properly

  render () {
    const {activeEntities, activeEntitiesData, getIsActiveEntity, activeViewEntityId, activeViewEntityResourceKey, activeViewEntity, isVisible, isAdding, show, room: activeRoom, tentativeScope, viewMode} = this.props;
    const {expanded} = this.state;

    if (!isVisible) {
      return (
        <div
          onClick={show}
          style={{cursor: 'pointer', position: 'absolute', zIndex: 2, bottom: 26, left: isAdding ? 250 + K.spacing * 2 : 85, width: 36, height: 36, backgroundColor: 'black', color: 'white', borderRadius: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          <img src={projectTreeIcon} style={{width: 16, height: 16}}/>
        </div>
      );
    }

    var floorsUsingRank = _.some(this.props.floors, floor => floor.rank);

    var orderedFloors = _.orderBy(this.props.floors, [floorsUsingRank ? 'rank' : 'id'], ['asc']);

    return (
      <>
        <div
          onClick={this.props.hide}
          style={{position: 'absolute', zIndex: 1, top: K.spacing * 2, left: `calc(${K.paneWidth + K.spacing * 2}px)`, width: '50px', ...K.fonts.label}}
        >{'< HIDE'}</div>
        <HudElement x='left' y='top' style={{height: '100%', backgroundColor: '#f5f5f5', borderRight: K.grayBorder, width: K.paneWidth, borderRadius: 0, overflow: 'scroll'}}>
          <div style={{position: 'relative'}}>
            <DragDropList
              onDragEnd={this.handleTreeDragEnd}
              items={_.map(orderedFloors, floor => {
                let rooms = Floor.get('rooms', {floor});

                var roomsUsingRank = _.some(rooms, room => room.rank);

                rooms = _.orderBy(rooms, [roomsUsingRank ? 'rank' : 'id'], ['asc']);

                return ({
                  isDragDisabled: orderedFloors.length <= 1,
                  draggableId: floor.id,
                  node: <TreeNode
                    {...{expanded, activeViewEntityId, activeViewEntityResourceKey, activeViewEntity}}
                    id={floor.id}
                    key={floor.id}
                    title={floor.title || 'Untitled Floor'}
                    resourceKey={'floor'}
                    isEditable
                    isExpandable
                    isDxfUploadable
                    isNavigable
                    isSelected={getIsActiveEntity({id: floor.id, resourceKey: 'floor'})}
                    isDeletable={_.values(this.props.floors).length !== 1}
                    onDelete={this.handleTreeNodeDelete({entity: floor, resourceKey: 'floor'})}
                    onNavigateTo={this.handleNavigateToEntity('floor', floor.id)}
                    onClick={this.handleEntityClick('floor', floor.id)}
                    onChange={({value}) => this.props.updateFloor({id: floor.id, props: {title: value}})}
                    onExpandChange={this.handleExpandChange({resourceKey: 'floor', id: floor.id})}
                    entity={floor}
                    updateFloor={this.props.updateFloor}
                  >
                    <DragDropList
                      onDragEnd={this.handleRoomsDragEnd}
                      items={_.map(rooms, room => {
                        let {sortedElevations: elevations, scopes} = Room.get(['sortedElevations', 'scopes'], {room});

                        return ({
                          isDragDisabled: rooms.length <= 1,
                          draggableId: room.id,
                          node: <TreeNode
                            {...{expanded, activeViewEntityId, activeViewEntityResourceKey, activeViewEntity}}
                            id={room.id}
                            key={room.id}
                            title={room.title || 'Untitled Room'}
                            resourceKey={'room'}
                            isEditable
                            isExpandable={elevations?.length > 0 || _.values(scopes).length > 0}
                            isNavigable
                            isDeletable={rooms.length !== 1}
                            isSelected={getIsActiveEntity({id: room.id, resourceKey: 'room'})}
                            onNavigateTo={this.handleNavigateToEntity('room', room.id)}
                            onDelete={this.handleTreeNodeDelete({entity: room, resourceKey: 'room'})}
                            onClick={this.handleEntityClick('room', room.id)}
                            onChange={({value}) => {
                              this.props.updateRoom({id: room.id, props: {title: value}});

                              //HINT if there's only 1 scope, update its title to match the room
                              if (_.values(Room.get('scopes', {room})).length === 1) {
                                this.props.updateScopes({ids: _.map(_.values(Room.get('scopes', {room})), 'id'), props: {title: value}});
                              }
                            }}
                            onExpandChange={this.handleExpandChange({resourceKey: 'room', id: room.id})}
                          >
                            <DragDropList
                              onDragEnd={this.handleElevationDragEnd}
                              items={_.map(elevations, (elevation, index) => ({
                                draggableId: elevation.id,
                                node: (
                                  <TreeNode
                                    {...{expanded, activeViewEntityId, activeViewEntityResourceKey, activeViewEntity}}
                                    id={elevation.id}
                                    key={elevation.id}
                                    title={Elevation.getTitle({elevation})}
                                    prefix={`Elevation ${index + 1} -`}
                                    resourceKey={'elevation'}
                                    isEditable
                                    isExpandable={Elevation.get('containers', {elevation})?.length > 0}
                                    isNavigable
                                    isDeletable={elevations.length !== 1}
                                    isSelected={getIsActiveEntity({id: elevation.id, resourceKey: 'elevation'})}
                                    onDelete={this.handleTreeNodeDelete({entity: elevation, resourceKey: 'elevation'})}
                                    onNavigateTo={this.handleNavigateToEntity('elevation', elevation.id)}
                                    onClick={this.handleEntityClick('elevation', elevation.id)}
                                    onChange={({value}) => this.props.updateElevation({id: elevation.id, props: {title: value}})}
                                    onExpandChange={this.handleExpandChange({resourceKey: 'elevation', id: elevation.id})}
                                  >
                                    {_.map(Elevation.get('containers', {elevation}), container => (
                                      <TreeNode
                                        {...{expanded}}
                                        id={container.id}
                                        key={container.id}
                                        title={`Container: ${singularize(Container.getTypeDataFor({container}).title)} #${container.id}`}
                                        style={{marginLeft: K.spacing * 2}}
                                        resourceKey={'container'}
                                        isSelected={getIsActiveEntity({id: container.id, resourceKey: 'container'})}
                                        onClick={this.handleEntityClick('container', container.id)}
                                      />
                                    ))}
                                  </TreeNode>
                                ),
                              }))}
                            />
                            <DragDropList
                              onDragEnd={this.handleScopeDragEnd}
                              items={_.map(_.orderBy(scopes, ['rank', 'id']), scope => (
                                {
                                  draggableId: scope.id,
                                  node: (
                                    <TreeNode
                                      {...{expanded}}
                                      id={scope.id}
                                      key={scope.id}
                                      title={scope.title || 'Untitled Scope'}
                                      style={{marginLeft: K.spacing * 2}}
                                      isEditable
                                      isNavigable
                                      isDeletable={scopes.length !== 1}
                                      onNavigateTo={this.handleNavigateToEntity('scope', scope.id)}
                                      onDelete={this.handleTreeNodeDelete({entity: scope, resourceKey: 'scope'})}
                                      resourceKey={'scope'}
                                      isSelected={getIsActiveEntity({id: scope.id, resourceKey: 'scope'})}
                                      onClick={this.handleEntityClick('scope', scope.id)}
                                      onChange={({value}) => this.props.updateScope({id: scope.id, props: {title: value}})}
                                    />
                                  )
                                }
                              ))}
                            />

                            {!!room && _.includes(['top', 'both', 'lite'], viewMode) && <div data-conditional-opacity-parent={1} onClick={() => this.handleAddScopeButtonPress({floorId: floor.id, roomId: room.id})} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <div style={{opacity: 0.5, marginLeft: 24}}>Add Scope</div>
                            </div>}
                          </TreeNode>
                        });})}
                    />
                    <div data-conditional-opacity-parent={1} onClick={()=> this.setState({activeFloorId: floor.id, isShowingAddRoomPopup: true})} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <div style={{opacity: 0.5, marginLeft: 24}}>Add Room</div>
                    </div>
                  </TreeNode>
                })
              })}
            ></DragDropList>
            {/* {_.map(orderedFloors, floor => {
              let rooms = Floor.get('rooms', {floor});

              var roomsUsingRank = _.some(rooms, room => room.rank);

              rooms = _.orderBy(rooms, [roomsUsingRank ? 'rank' : 'id'], ['asc']);

              return (
                <TreeNode
                  {...{expanded, activeViewEntityId, activeViewEntityResourceKey, activeViewEntity}}
                  id={floor.id}
                  key={floor.id}
                  title={floor.title || 'Untitled Floor'}
                  resourceKey={'floor'}
                  isEditable
                  isExpandable
                  isDxfUploadable
                  isNavigable
                  isSelected={getIsActiveEntity({id: floor.id, resourceKey: 'floor'})}
                  isDeletable={_.values(this.props.floors).length !== 1}
                  onDelete={this.handleTreeNodeDelete({entity: floor, resourceKey: 'floor'})}
                  onNavigateTo={this.handleNavigateToEntity('floor', floor.id)}
                  onClick={this.handleEntityClick('floor', floor.id)}
                  onChange={({value}) => this.props.updateFloor({id: floor.id, props: {title: value}})}
                  onExpandChange={this.handleExpandChange({resourceKey: 'floor', id: floor.id})}
                  entity={floor}
                  updateFloor={this.props.updateFloor}
                >
                  {_.map(rooms, room => {
                    let {sortedElevations: elevations, scopes} = Room.get(['sortedElevations', 'scopes'], {room});

                    return (
                      <TreeNode
                        {...{expanded, activeViewEntityId, activeViewEntityResourceKey, activeViewEntity}}
                        id={room.id}
                        key={room.id}
                        title={room.title || 'Untitled Room'}
                        resourceKey={'room'}
                        isEditable
                        isExpandable={elevations?.length > 0 || _.values(scopes).length > 0}
                        isNavigable
                        isDeletable={rooms.length !== 1}
                        isSelected={getIsActiveEntity({id: room.id, resourceKey: 'room'})}
                        onNavigateTo={this.handleNavigateToEntity('room', room.id)}
                        onDelete={this.handleTreeNodeDelete({entity: room, resourceKey: 'room'})}
                        onClick={this.handleEntityClick('room', room.id)}
                        onChange={({value}) => {
                          this.props.updateRoom({id: room.id, props: {title: value}});

                          //HINT if there's only 1 scope, update its title to match the room
                          if (_.values(Room.get('scopes', {room})).length === 1) {
                            this.props.updateScopes({ids: _.map(_.values(Room.get('scopes', {room})), 'id'), props: {title: value}});
                          }
                        }}
                        onExpandChange={this.handleExpandChange({resourceKey: 'room', id: room.id})}
                      >
                        <DragDropList
                          items={_.map(elevations, (elevation, index) => ({
                            draggableId: elevation.id,
                            node: (
                              <TreeNode
                                {...{expanded, activeViewEntityId, activeViewEntityResourceKey, activeViewEntity}}
                                id={elevation.id}
                                key={elevation.id}
                                title={Elevation.getTitle({elevation})}
                                prefix={`Elevation ${index + 1} -`}
                                resourceKey={'elevation'}
                                isEditable
                                isExpandable={Elevation.get('containers', {elevation})?.length > 0}
                                isNavigable
                                isDeletable={elevations.length !== 1}
                                isSelected={getIsActiveEntity({id: elevation.id, resourceKey: 'elevation'})}
                                onDelete={this.handleTreeNodeDelete({entity: elevation, resourceKey: 'elevation'})}
                                onNavigateTo={this.handleNavigateToEntity('elevation', elevation.id)}
                                onClick={this.handleEntityClick('elevation', elevation.id)}
                                onChange={({value}) => this.props.updateElevation({id: elevation.id, props: {title: value}})}
                                onExpandChange={this.handleExpandChange({resourceKey: 'elevation', id: elevation.id})}
                              >
                                {_.map(Elevation.get('containers', {elevation}), container => (
                                  <TreeNode
                                    {...{expanded}}
                                    id={container.id}
                                    key={container.id}
                                    title={`Container: ${singularize(Container.getTypeDataFor({container}).title)} #${container.id}`}
                                    style={{marginLeft: K.spacing * 2}}
                                    resourceKey={'container'}
                                    isSelected={getIsActiveEntity({id: container.id, resourceKey: 'container'})}
                                    onClick={this.handleEntityClick('container', container.id)}
                                  />
                                ))}
                              </TreeNode>
                            ),
                          }))}
                          onDragEnd={this.handleElevationDragEnd}
                        />
                        {_.map(scopes, scope => (
                          <TreeNode
                            {...{expanded}}
                            id={scope.id}
                            key={scope.id}
                            title={scope.title || 'Untitled Scope'}
                            style={{marginLeft: K.spacing * 2}}
                            isEditable
                            resourceKey={'scope'}
                            isSelected={getIsActiveEntity({id: scope.id, resourceKey: 'scope'})}
                            onClick={this.handleEntityClick('scope', scope.id)}
                            onChange={({value}) => this.props.updateScope({id: scope.id, props: {title: value}})}
                          />
                        ))}
                        {!!room && _.includes(['top', 'both'], viewMode) && <div data-conditional-opacity-parent={1} onClick={() => this.props.onAddScope({roomId: room.id, floorId: floor.id})} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <div style={{opacity: 0.5, marginLeft: 24}}>Add Scope</div>
                        </div>}
                      </TreeNode>
                    );})}
                  <div data-conditional-opacity-parent={1} onClick={()=> this.setState({activeFloorId: floor.id, isShowingAddRoomPopup: true})} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div style={{opacity: 0.5, marginLeft: 24}}>Add Room</div>
                  </div>
                </TreeNode>
              );}
            )} */}
          </div>
          <div data-conditional-opacity-parent={1} onClick={() => this.setState({isShowingAddFloorPopup: true})} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{opacity: 0.5, marginLeft: 24}}>Add Floor</div>
          </div>
        </HudElement>
        {this.state.isShowingAddScopePopup && (
          <AddScopePopup onClose={() => this.setState({isShowingAddScopePopup: false, activeFloorId: undefined, activeRoomId: undefined})} onCreate={({title}) => this.handleAddScope({floorId: this.state.activeFloorId, roomId: this.state.activeRoomId, title})}/>
        )}
        {this.state.isShowingAddRoomPopup && (
          <AddRoomPopup close={() => this.setState({isShowingAddRoomPopup: false, activeFloorId: undefined, activeRoomId: undefined})} handleAddRoom={({title}) => this.handleAddRoom({floorId: this.state.activeFloorId, title})}/>
        )}
        {this.state.isShowingAddFloorPopup && (
          <AddFloorPopup close={() => this.setState({isShowingAddFloorPopup: false, activeFloorId: undefined, activeRoomId: undefined})} handleAddFloor={({title, roomTitle}) => this.handleAddFloor({projectId: this.props.project.id, title, roomTitle})}/>
        )}
      </>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData} = ownProps;

    var activeEntities = _.map(activeEntitiesData, activeEntityData => {
      return _.get(state.resources, `[${pluralize(activeEntityData.resourceKey)}].byId[${activeEntityData.id}]`);
    });

    return {
      project: _.values(state.resources.projects.byId)[0],
      floors: state.resources.floors.byId,
      rooms: state.resources.rooms.byId,
      elevations: state.resources.elevations.byId,
      walls: state.resources.walls.byId,
      scopes: state.resources.scopes.byId,
      // containers: state.resources.containers.byId,
      // archElements: state.resources.archElements.byId,
      activeEntities,
      activeEntitiesData
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.walls, ['destroyWalls']),
    ..._.pick(resourceActions.containers, ['destroyContainers', 'updateContainers', 'modifyContainers']),
    ..._.pick(resourceActions.products, ['destroyProducts', 'updateProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['destroyProductOptions', 'updateProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.archElements, ['destroyArchElements']),
    ..._.pick(resourceActions.projectGraphics, ['destroyProjectGraphics']),
    ..._.pick(resourceActions.floors, ['createFloor', 'trackFloors', 'updateFloor', 'updateFloors', 'destroyFloor']),
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'updateRooms', 'destroyRoom', 'destroyRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'createScopes', 'updateScope', 'updateScopes', 'destroyScopes', 'destroyScope']),
    ..._.pick(resourceActions.elevations, ['trackElevations', 'updateElevation', 'updateElevations', 'destroyElevation', 'destroyElevations']),
    ..._.pick(resourceActions.volumes, ['destroyVolumes']),
    ..._.pick(resourceActions.parts, ['updateParts'])
  }
})(ProjectTreeHudElement);
