import React from 'react';

import Icon from './icon';

import PlusImg from '../../../assets/create-plus-icon-black.png';
import MinusImg from '../../../assets/minus-icon-black.png';

import K from 'k';

import './counter.scss';

class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: this.props.count || 0
    };
  }

  handleIncrement = () => {
    this.setState(prevState => {
      return {
        count: prevState.count + 1
      };
    }, () => {
      this.props.changeQuantity({id: this.props.id, quantity: this.state.count});
    });
  };

  handleDecrement = () => {
    this.setState(prevState => {
      return {
        count: prevState.count - 1
      };
    }, () => {
      this.props.changeQuantity({id: this.props.id, quantity: this.state.count});
    });
  };

  render() {
    var { count } = this.state;

    return (
      <div className="counter-container" style={{width: 80}}>
        <button
          onClick={this.handleDecrement}
          style={{ background: 'white', border: '0px', width: '20px', padding: 0}}
          disabled={this.state.count === 0}
        >
          <Icon width={20} height={20} src={MinusImg} alt="Minus Icon" />
        </button>
        <p style={{margin: K.spacing}}>{count}</p>
        <button
          onClick={this.handleIncrement}
          style={{background: 'white', border: '0px', fontSize: '20px', padding: 0}}
        >
          <Icon width={20} height={20} src={PlusImg} alt="Plus Icon" />
        </button>
      </div>
    );
  }
}

export default Counter;
