import { Portal } from 'react-konva-utils';
import { Group } from 'react-konva';

export default function CanvasPortal({shouldUsePortal=true, isExportingSvg, children, portalSelector}) {
  return (isExportingSvg || !shouldUsePortal) ? (
    <Group>
      {children}
    </Group>
  ) : (
    <Portal selector={portalSelector} enabled={true}>
       {children}
    </Portal>
  );
};