import React, { forwardRef, useState } from 'react';
import DateInput from 'components/date-input';
import TextInput from 'components/text-input';
import lib from 'lib';
import moment from 'moment';

import './drawing-page-footer.scss';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import Drawings from 'project-helpers/drawings';

const DrawingPageFooter = forwardRef(function DrawingPageFooter({page, project, pageNumber, pageCount, companyName, isSpaceTheory, isEditingEnabled, detailLevel, updateProject}, ref) {
  const [title, setTitle] = useState(page.title);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const handleTitleChange = ({title}) => {
    setTitle(title);
    setIsEditingTitle(false);

    const updatedPage = _.find(project.drawingsData.standardPages, {id: page.id});

    updatedPage.title = title;

    Drawings.handleUpdateDrawingsData({
      project,
      standardPages: project.drawingsData.standardPages.concat(),
      continuousDrawingsAutogenerationDisabled: 1,
      resourceActionDispatchers: {updateProject}
    });
  };

  const disclaimer = `All designs are property of ${companyName} and the Henrybuilt Corporation.
  Reproduction, modification, and use for construction by any party other than ${companyName} is prohibited.`;

  var projectNameText = `${project.title || project.clientName }${detailLevel === 'production' ? ` - #${project.id}` : ''}`;

  var pageTitle = (props) => {
    return (
      <div
        className="page-title-title"
        style={{...(title.length <= 50 ? {fontSize: '1.5rem'} : {fontSize: '0.9rem'})}}
        {...props}
      >
        {title}
      </div>
    )
  };

  return (
    <div {...{ref}} className="drawings-page-footer clearfix">
      <div className="left">
        <div className="company-name">{isSpaceTheory ? <>SPACE.<span className="accent-color">THEORY</span></> : 'HENRYBUILT'}</div>
        <div className="project-name" style={{...(projectNameText.length <= 50 ? {fontSize: '1.2rem'} : {fontSize: '0.9rem'})}}>
          {projectNameText}
        </div>
      </div>
      <div className="right">
        <div className="table">
          <div className="drawings-disclaimer drawings-footer-right-item">{disclaimer}</div>
            <div className="issue-date drawings-footer-right-item">
              {moment().format('M / D / YYYY')}
            </div>
          <div className="page-number drawings-footer-right-item">{pageNumber} / {pageCount}</div>
          {(page.type === 'standard' && isEditingEnabled) ? (
            <div className="page-title drawings-footer-right-item">
              {isEditingTitle ? (
                <TextInput
                  value={title}
                  onChange={({value}) => handleTitleChange({title: value})}
                  autoFocus={true}
                  style={{
                    fontSize: '1rem',
                    textAlign: 'center',
                    textTransform: 'lowercase'
                  }}
                />
              ) : (
                <>
                  <ReactTooltip id={'footer-title-tooltip'} effect='solid'>Double click to edit</ReactTooltip>
                  {pageTitle({
                    'data-tip': 'tooltip',
                    'data-for': 'footer-title-tooltip',
                    onDoubleClick: () => setIsEditingTitle(true)
                  })}
                </>
              )}
            </div>
          ) : (
            <div className="page-title drawings-footer-right-item">
              {pageTitle()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default DrawingPageFooter;
