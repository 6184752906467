import _ from 'lodash';
import lib from 'lib';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addRoomContainerOutsideDepthDimensions({allPoints, isIsland, container, footprintLines, getIsTrapped, dimensionSets, outsideDimensionSets, room}) {
  var candidateLines = _.filter([footprintLines.left, footprintLines.right], line => !getIsTrapped({line, entityType: 'container', entityId: container.id}));

  candidateLines = _.map(candidateLines, candidateLine => ({
    from: lib.object.sum(candidateLine.from, room.plan.position),
    to: lib.object.sum(candidateLine.to, room.plan.position)
  }));

  if (isIsland) {
    // var p1 = lib.object.sum(footprintLines.left.to, room.plan.position);
    // var p2 = lib.object.sum(footprintLines.left.from, room.plan.position);
    // var offset = container.dimensions.width > 16 ? 8 : container.dimensions.width / 2;

    // dimensionSets.push({
    //   alpha: lib.trig.alpha({p1, p2}),
    //   targets: [{position: p1, id: `room-container-${container.id}-outside-depth-from`}, {position: p2, id: `room-container-${container.id}-outside-depth-to`}],
    //   type: 'standalone',
    //   offset,
    //   showLabelBorder: true,
    //   id: `room-container-${container.id}-outside-depth`
    // });
  }
  else if (candidateLines.length) {
    addLineToNearestSide({allPoints, outsideDimensionSets, candidateLines, idPrefix: `room-container-${container.id}-outside-depth`});
  }
}
