import _ from 'lodash';
import lib from 'lib';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';

export default function addRoomContainerWidthDimensions({container, containerAlpha, dimensionSets, footprintLines, widthIsTrapped, outsideDimensionSets, room}) {
  if (!widthIsTrapped) var dimensionSet = _.find(outsideDimensionSets, ({alpha}) => lib.trig.anglesAreEqual({a1: alpha, a2: containerAlpha + Math.PI}));

  if (container.type === 'pivotDoor') {
    var products = _.filter(Container.get('products', {container}), product => (_.includes([384, 385], product.productId)));

    if (products.length) {
      _.forEach(products, (product) => {
        var pivotDoorWidth = product.dimensions.width;
        var position = Product.getPositionInRoom({product});

        dimensionSets.push({
          alpha: containerAlpha,
          id: `room-container-${container.id}-pivot-door-product-${product.id}-width`,
          offset: 4,
          showLabelBorder: true,
          targets: [
            {position:
              lib.object.sum(
                room.plan.position, position,
                lib.trig.rotate({point: {x: - 1, y: 1.75}, byRadians: containerAlpha})
              ), id: `room-container-${container.id}-pivot-door-product-${product.id}-width-from`
            },
            {position: lib.object.sum(
              room.plan.position, position,
              lib.trig.rotate({point: {x: pivotDoorWidth + 1, y: 1.75}, byRadians: containerAlpha})
              ), id: `room-container-${container.id}-pivot-door-product-${product.id}-width-to`
            }
          ]
        });
      })
    }
  }

  var localDimensionSet = _.find(dimensionSets, dimensionSet => {
    var isExtrudedLocally = dimensionSet.type === 'extrudeLocally';

    if (!isExtrudedLocally || dimensionSet.targets.length < 2) return false;

    var dimensionSetLine = {from: _.first(dimensionSet.targets).position, to: _.last(dimensionSet.targets).position};

    var intersection = lib.math.intersectionPoint({
      l1: {from: lib.object.sum(footprintLines.front.from, room.plan.position, lib.trig.rotate({
        point: {x: 0, y: 10000},
        byRadians: containerAlpha
      })), to: lib.object.sum(footprintLines.front.from, room.plan.position, lib.trig.rotate({
        point: {x: 0, y: 10000},
        byRadians: containerAlpha + Math.PI
      }))}, l2: dimensionSetLine
    });

    var normalizeLine = ({line}) => _.mapValues(line, point => lib.trig.rotate({point, byRadians: -containerAlpha}));

    var normalL1 = normalizeLine({line: {from: lib.object.sum(footprintLines.front.from, room.plan.position), to: lib.object.sum(footprintLines.front.to, room.plan.position)}});
    var normalL2 = normalizeLine({line: dimensionSetLine});

    var isInline = lib.trig.distance({fromPoint: {x: normalL1.from.x, y: 0}, toLine: normalL2}) < 48 || lib.trig.distance({fromPoint: {x: normalL1.to.x, y: 0}, toLine: normalL2}) < 48;

    return lib.trig.anglesAreEqual({a1: containerAlpha, a2: lib.trig.alpha({p1: dimensionSetLine.from, p2: dimensionSetLine.to})}) && isInline && lib.trig.distance({fromPoint: lib.object.sum(footprintLines.front.from, room.plan.position), toPoint: intersection}) < 48;
  });

  if (localDimensionSet) {
    localDimensionSet.targets.push(
      {position: lib.object.sum(footprintLines.front.from, room.plan.position), id: `room-container-${container.id}-width-from`},
      {position: lib.object.sum(footprintLines.front.to, room.plan.position), id: `room-container-${container.id}-width-to`}
    );
  }
  else if (dimensionSet) {
    dimensionSet.targets.push(
      //if scribe, intersection point
      {position: lib.object.sum(footprintLines.back.from, room.plan.position), id: `room-container-${container.id}-width-from`},
      {position: lib.object.sum(footprintLines.back.to, room.plan.position), id: `room-container-${container.id}-width-to`}
    );
  }
  else {
    dimensionSets.push({
      type: 'extrudeLocally',
      id: `room-container-${container.id}-width`,
      alpha: containerAlpha,
      targets: [
        {position: lib.object.sum(footprintLines.front.from, room.plan.position), id: `room-container-${container.id}-width-from`},
        {position: lib.object.sum(footprintLines.front.to, room.plan.position), id: `room-container-${container.id}-width-to`}
      ]
    });
  }
}
