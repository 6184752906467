import React from 'react';
import { useParams } from 'react-router-dom';

const withUseParams = (Component) => {
  return (props) => { //eslint-disable-line
    const params = useParams();

    return (<Component {...{...props, match: {params}}}/>);
  };
};

export default withUseParams;