import React from 'react';
import _ from 'lodash';
import lib from 'lib';

import Popup from 'components/popup';
import UIDimensionConstraints from 'components/dimension-constraints';
import HudElement from '../hud-elements/hud-element';
import Product from 'project-helpers/product';
import Container from 'project-helpers/container';
import 'styles/details.scss';

class ConfiguratorDetailsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      media: [],
      slides: [],
    };
  }

  async componentDidMount() {
    const {isEmployee, product, companyKey, resourceKey} = this.props;

    let media = await lib.api.get('media', {
      where: {[`${resourceKey}Id`]: product.id, public: isEmployee ? [0, 1] : 1}, limit: 20, order: ['rank']
    });

    media = _.filter(media, [`${companyKey}Compatible`, 1]);

    const slides = _.map(media, ({type, url}) => ({caption: '', type, src: url}));

    this.setState({
      ...this.state,
      media,
      slides,
    });
  }

  render() {
    const {product, resourceKey} = this.props;
    const resource = product;

    return (
      <Popup onClose={() => this.props.onClose && this.props.onClose()}>
        <HudElement x='right' y='bottom' pane
          style={{width: 'auto', padding: 'none', height: '100%', borderLeft: '1px solid rgba(0, 0, 0, 0.05)', overflow: 'overlay', backgroundColor: '#f5f5f5', zIndex: 3}}
        >
          {resource ? <div className="cfg-configurator-details-popup configurator-details-popup">
            <div className="resource-summary">
              <div className="resource-summary-content">
                <div className="resource-summary-text">
                  <div className="resource-title">{resource.title}</div>
                  <div className="resource-price"></div>
                  <div className="resource-description">{resource.description || ''}</div>
                  <div className="resource-design-details">
                    {resource.designDetails ? (
                      <div style={{paddingTop: '2rem'}}>
                        Design Considerations<br/>
                        <ul>
                          {_.map(_.split(resource.designDetails, /\r?\n/), detail => <li>${detail}</li>)}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="resource-dimensions">
                  {resource.constraints ? (
                    <UIDimensionConstraints
                      dimensions={resource.constraints}
                      theme='light'
                    />
                  ) : null}
                </div>
              </div>
              <div className="material-swatches-container">
                {this.props.materialSwatches ? _.map(this.props.materialSwatches, materialSwatch => {
                  return (
                    <div className="material-swatch-container">
                      <div className="material-swatch" style={{backgroundImage: `url(${materialSwatch.src})`}}></div>
                      <div className="material-swatch-label">{materialSwatch.title}</div>
                    </div>
                  );
                }) : null}
              </div>
            </div>
            <div className="resource-slideshow">
              {this.state.media.map(medium => {
                return (
                  <div className={`media-grid-cell ${medium.type}`} key={medium.id}>
                    {medium.type === 'image' || medium.type === 'vimage'
                      ? (<img src={medium.url} />)
                      : medium.type === 'video'
                        ? (<video loop autoPlay={false}>
                          <source src={medium.url} type="video/mp4"></source>
                        </video>)
                        : null
                    }
                  </div>
                );
              })}
            </div>
          </div> : null}
        </HudElement>
      </Popup>
    );
  }
}

export default ConfiguratorDetailsPopup;