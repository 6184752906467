import _ from 'lodash';
import lib from 'lib';

var projectGraphicTextures = {
  brick: 'https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/textures/brick.jpg',
  marble: 'https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/textures/marble.jpg'
};

async function handleProjectGraphicPropertyChange({activeEntityId, activeEntity, path, resourceActions, value}) {
  const updatedProps = _.cloneDeep(activeEntity);

  if (path === 'data.fillType' && value === 'linearGradient') {
    updatedProps.data.gradientStops = [{opacity: 0.2, color: 'black'}, {opacity: 0, color: 'black'}];

    if (activeEntity.type === 'polygon') {
      updatedProps.data.gradientPositions = {from: activeEntity.data.points[0], to: activeEntity.data.points[2]};
    }
    else {
      updatedProps.data.gradientPositions = {from: {x: 0, y: 0}, to: {x: activeEntity.data.size.width, y: activeEntity.data.size.height}};
    }
  }

  if (path === 'data.size.radius') {
    updatedProps.data.size.width = value * 2;
    updatedProps.data.size.height = value * 2;
  }

  if ((activeEntity.type === 'rectangle' || activeEntity.type === 'polygon') && activeEntity.data.fillType === 'texture' && _.includes(['data.fillTexture.mediumId'], path)) {
    updatedProps.data.fillTexture.mediumId = value;
    updatedProps.data.fillTexture.url = projectGraphicTextures[value];
  }

  _.set(updatedProps, path, value);

  resourceActions.updateProjectGraphic({id: activeEntity.id, props: updatedProps});
}

export { handleProjectGraphicPropertyChange };