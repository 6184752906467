import React, {useState, useEffect, useCallback} from 'react';
import _ from 'lodash';
import K from 'k';

import leftArrowIconWhite from '../assets/left-arrow-icon-white.png';
import xIconWhite from '../assets/x-icon-white.png';

const Slidshow = ({media, activeSlideshowMediumId, updateActiveSlideshowMediumId, onClose}) => {
  const [activeMediumIndex, setActiveMediumIndex] = useState(_.findIndex(media, (medium) => medium.id === activeSlideshowMediumId));
  const [isHoveringCloseButton, setIsHoveringCloseButton] = useState(false);
  const [isHoveringBackButton, setIsHoveringBackButton] = useState(false);
  const [isHoveringForwardButton, setIsHoveringForwardButton] = useState(false);
  let activeMedium = media[activeMediumIndex];

  const styles = {
    navigationBar : {
      position: 'fixed', bottom: 0, right: 0, width: '100%', height: 40, backgroundColor: K.colors.black, opacity: 0.3, zIndex: 11, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'
    },
      controlButton: {height: '100%', width: 60, display: 'flex', justifyContent: 'center', alignItems: 'center',
    },
    hoverOverControl: {
      backgroundColor: '#282828'
    },
    controlButtonIcon : {
      height: 27, width: 27,
    },
    indexIndicator: {
      color: K.colors.white, fontSize: 20, letterSpacing: 0.2, textAlign: 'center'
    }
  }

  const updateIndex = useCallback((index) => {
    setActiveMediumIndex(index);
  })

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight') updateIndex(activeMediumIndex === media.length - 1 ? 0 : activeMediumIndex + 1);

    if (e.key === 'ArrowLeft') updateIndex(activeMediumIndex === 0 ? media.length - 1 : activeMediumIndex - 1);

    if (e.key === 'Escape') onClose();
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return (() => window.removeEventListener('keydown', handleKeyPress));
  }, [handleKeyPress]);

  return (<>
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img
        src={activeMedium.url}
        style={{minHeight: '100%', backgroundColor: K.colors.gray, objectFit: 'contain'}}
      />
    </div>
    <div style={styles.navigationBar}>
      <div
        onMouseEnter={() => setIsHoveringCloseButton(true)}
        onMouseLeave={() => setIsHoveringCloseButton(false)}
        style={{...styles.controlButton, ...(isHoveringCloseButton ? {...styles.hoverOverControl} : {})}}
        onClick={onClose}
      >
        <img src={xIconWhite} style={styles.controlButtonIcon} />
      </div>
      {/* TODO possibly make index indicator a number input? */}
      <div style={{...styles.indexIndicator, width: 120}}>
        {`${activeMediumIndex + 1} / ${media.length}`}
      </div>
      <div
        onClick={() => updateIndex(activeMediumIndex === 0 ? media.length - 1 : activeMediumIndex - 1)}
        onMouseEnter={() => setIsHoveringBackButton(true)}
        onMouseLeave={() => setIsHoveringBackButton(false)}
        style={{...styles.controlButton, ...(isHoveringBackButton ? {...styles.hoverOverControl} : {})}}
      >
        <img src={leftArrowIconWhite} style={styles.controlButtonIcon} />
      </div>
      <div
        onClick={() => updateIndex(activeMediumIndex === media.length - 1 ? 0 : activeMediumIndex + 1)}
        onMouseEnter={() => setIsHoveringForwardButton(true)}
        onMouseLeave={() => setIsHoveringForwardButton(false)}
        style={{...styles.controlButton, ...(isHoveringForwardButton ? {...styles.hoverOverControl} : {})}}
      >
        <img
          src={leftArrowIconWhite}
          style={{...styles.controlButtonIcon, transform: 'rotate(180deg)'}}
        />
      </div>
    </div>
  </>);
}

export default Slidshow;