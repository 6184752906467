import K from 'k';
import React from 'react';
import Canvas from './canvas/canvas';

const Hatch = (props) => {
  const {hatchKey, width = 10, height = 10, ...rest} = props;
  const color = K.colors.hatches;
  const size = 10;

  let draw = (ctx) => {
    const offscreenCanvas = new OffscreenCanvas(size, size);
    const offscreenCtx = offscreenCanvas.getContext('2d');

    const drawLine = ({x1, x2, y1, y2, dashed, opacity}) => {
      if (dashed) offscreenCtx.setLineDash([size / 2, size / 2]);
      if (opacity) offscreenCtx.globalAlpha = opacity;
      offscreenCtx.strokeStyle = color;
      offscreenCtx.lineWidth = 1;
      offscreenCtx.beginPath();
      offscreenCtx.moveTo(x1, y1);
      offscreenCtx.lineTo(x2, y2);
      offscreenCtx.stroke();
    };

    const drawCircle = ({radius}) => {
      offscreenCtx.strokeStyle = color;
      offscreenCtx.fillStyle = color;
      offscreenCtx.lineWidth = 1;
      offscreenCtx.beginPath();
      offscreenCtx.arc(size / 2, size / 2, size, 0, Math.PI * 2, true);
      offscreenCtx.stroke();
      offscreenCtx.fill();
    };

    const diagonalLineRight = props => drawLine({
      x1: 0, y1: 0, x2: size, y2: size, ...props
    });

    const diagonalLineLeft = props => drawLine({
      x1: size, y1: 0, x2: 0, y2: size, ...props
    });

    const hatchGenerators = {
      blank: () => {},
      diagonalLinesRight: () => diagonalLineRight(),
      diagonalLinesLeft: () => diagonalLineLeft(),
      diagonalLinesBoth: () => {
        diagonalLineRight({opacity: 0.7});
        diagonalLineLeft({opacity: 0.7});
      },
      diagonalLinesRightDashed: () => diagonalLineRight({dashed: true}),
      diagonalLinesLeftDashed: () => diagonalLineLeft({dashed: true}),
      diagonalLinesBothDashed: () => {
        diagonalLineRight({dashed: true});
        diagonalLineLeft({dashed: true});
      },
      crosshair: () => {
        drawLine({
          x1: size / 2, y1: size / 4, x2: size / 2, y2: size * (3 / 4),
        });
        drawLine({
          x1: size / 4, y1: size / 2, x2: size * (3 / 4), y2: size / 2,
        });
      },
      waffle: () => {
        drawLine({
          x1: size / 4, y1: size / 4, x2: size / 4, y2: size * (3 / 4),
        });
        drawLine({
          x1: size / 4, y1: size / 4, x2: size * (3 / 4), y2: size / 4,
        });
      },
      dot: () => drawCircle({radius: 1}),
      dashX: () => drawLine({x1: 0, y1: size / 2, x2: size, y2: size / 2, dashed: true}),
      dashY: () => drawLine({x1: size / 2, y1: 0, x2: size / 2, y2: size, dashed: true}),
      invalid: () => {
        ctx.fillStyle = 'red';
        ctx.fillRect(0, 0, size, size);
      }
    };

    const generator = hatchGenerators[hatchKey] || hatchGenerators.invalid;

    generator();

    const pattern = ctx.createPattern(offscreenCtx.canvas, 'repeat');

    ctx.rect(0, 0, width, height);
    ctx.fillStyle = pattern;
    ctx.fillRect(0, 0, width, height);
  };

  return <Canvas {...{draw, width, height, ...rest}}/>;
};

export default Hatch;