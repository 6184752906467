import { Group } from 'react-konva';

import _ from 'lodash';

export default function CanvasMask({isCircle, maskingPolygons, children, circleProps, ...props}) {
  var maskingPolygon = _.flatten(maskingPolygons);

  return (
    <Group {...props} clipFunc={(isCircle || (_.get(maskingPolygon, 'length') > 0)) ? ctx => {
      if (isCircle) {
        ctx.arc(circleProps.positionInCanvas.x + circleProps.radius, circleProps.positionInCanvas.y + circleProps.radius, circleProps.radius, 0, 2 * Math.PI);
      }
      else {
        _.forEach(maskingPolygons, maskingPolygon => {
          _.forEach(maskingPolygon, (point, index) => {
            ctx[index === 0 ? 'moveTo' : 'lineTo'](point.x, point.y);
          });
        });
      }
    } : null}>
      {children}
    </Group>
  );
}