import _ from 'lodash';
import lib from 'lib';
import Elevation from 'project-helpers/elevation';

export default function addElevationWallDimensions({elevation, outsideDimensionSets, pointsByWall}) {
  if (!Elevation.getIsSection({elevation})) {
    var flattenedPoints = _.flatMap(pointsByWall);

    _.forEach(pointsByWall, (outlinePoints, wallId) => {
      _.forEach(outlinePoints, (point, index) => {
        var prevPoint = lib.array.prev(outlinePoints, index);
        var nextPoint = lib.array.next(outlinePoints, index);
        var alpha = lib.trig.alpha({p1: point, p2: nextPoint});
        var prevAlpha = lib.trig.alpha({p1: prevPoint, p2: point});

        _.forEach([alpha, prevAlpha], (alpha, alphaIndex) => {
          var isTrapped = false; //getIsTrapped({line: footprintLines.back, container}); TODO prev line
          var dimensionSet;

          if (!isTrapped) dimensionSet = _.find(outsideDimensionSets, ({alpha: dimensionSetAlpha}) => lib.trig.anglesAreEqual({a1: dimensionSetAlpha, a2: alpha + Math.PI}));

          if (dimensionSet) {
            dimensionSet.targets.push({summarize: true, position: lib.object.sum(point), id: `elevation-${elevation.id}-wall-${wallId}-point-${index}-${alphaIndex === 0 ? 'alpha' : 'prevAlpha'}`});
          }
          //HINT angle in ceiling line
          else if (alphaIndex === 0) {
            var isAngledOut = nextPoint.x < point.x;

            //hint draw orthagonal dims
            outsideDimensionSets.push({
              type: isAngledOut ? 'extrudeLocally' : 'extrudeOutside', offset: isAngledOut ? -10 : undefined, id: `wall-angled-ceiling-line-${elevation.id}-${wallId}-${index}-width`, alpha: Math.PI, targets: [
                {summarize: true, position: {x: point.x, y: _[isAngledOut ? 'max' : 'min']([point.y, nextPoint.y])}, id: `wall-angled-ceiling-line-width-${elevation.id}-${wallId}-${index}-from`},
                {summarize: true, position: {x: nextPoint.x, y: _[isAngledOut ? 'max' : 'min']([point.y, nextPoint.y])}, id: `wall-angled-ceiling-line-width-${elevation.id}-${wallId}-${index}-to`},
              ]
            });

            //should pull right unless all of the points are above everything to the left and angled up
            //if only line and angled down would ideally also pull right

            var isPulledRight = !_.some([point.x, nextPoint.x], x => {
              return x === _.min(_.map(flattenedPoints, 'x'));
            }) && !_.every([point.y, nextPoint.y], y => {
              var yCandidates = _.filter(flattenedPoints, candidatePoint => !_.isEqual(point, candidatePoint) && !_.isEqual(nextPoint, candidatePoint) && candidatePoint.x <= _.min([point.x, nextPoint.x]));

              return point.y > nextPoint.y && y <= _.min(_.map(yCandidates, 'y'));
            })

            var heightX = _[isPulledRight ? 'max' : 'min']([point.x, nextPoint.x]);

            var overlapCandidates = _.filter(flattenedPoints, candidatePoint => isPulledRight ? candidatePoint.x >= heightX : candidatePoint.x <= heightX);

            var isOverlappingWOutsideWall = _.some(overlapCandidates, ({y}) => {
              return  y < point.y || y < nextPoint.y
            });

            outsideDimensionSets.push({
              type: 'extrudeOutside', offset: isOverlappingWOutsideWall ? 14 : undefined, id: `wall-angled-ceiling-line-${elevation.id}-${wallId}-${index}-height`, alpha: isPulledRight ? (-Math.PI / 2) : (Math.PI / 2), targets: [
                {summarize: true, position: {x: heightX, y: point.y}, id: `wall-angled-ceiling-line-height-${elevation.id}-${wallId}-${index}-from`},
                {summarize: true, position: {x: heightX, y: nextPoint.y}, id: `wall-angled-ceiling-line-height-${elevation.id}-${wallId}-${index}-to`},
              ]
            });

            // outsideDimensionSets.push({
            //   type: 'extrudeOutside', id: `wall-angled-ceiling-line-${elevation.id}-${wallId}-${index}-alpha`, alpha: alpha + Math.PI, targets: [
            //     {summarize: true, position: point, id: `wall-angled-ceiling-line-${elevation.id}-${wallId}-${index}-from`},
            //     {summarize: true, position: nextPoint, id: `wall-angled-ceiling-line-${elevation.id}-${wallId}-${index}-to`},
            //   ]
            // });
          }
        });
      });
    });
  }
}
