import React, { useState } from 'react';
import _ from 'lodash';

import K from 'k';

import HudElement from './hud-element';
import DragDropList from 'components/drag-drop-list';
import ReactTooltip from 'react-tooltip';

import sendBackwardIcon from 'assets/controls-hud-element/send-backward-icon.png';

const PageListItem = ({id, title, isEditing, onCopyPage, onShowScalePopup, onShowGraphicPopup, onShowDeletePopup}) => {
  const [isHovering, setIsHovering] = useState(false);

  const styles = {
    icon: {
      width: 16,
      height: 16,
      opacity: isHovering ? 0.7 : 0,
      justifyContent: 'flex-end',
      flexShrink: 1
    },
    iconContainer: {display: 'flex', marginLeft: K.spacing / 2},
    iconsContainer: {display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}
  };

  return (<>
    <div style={{height: isEditing ? 24 : 'auto', opacity: 1, paddingBottom: K.margin, display: 'flex', cursor: 'pointer'}} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <div className='title'>{title}</div>
      <div style={styles.iconsContainer}>
        <div style={styles.iconContainer} onClick={onCopyPage(id)} data-tip='tooltip' data-for={`${id}-copy-page-tooltip`}>
          <ReactTooltip id={`${id}-copy-page-tooltip`} effect='solid'>Copy Page</ReactTooltip>
          <img style={styles.icon} src={sendBackwardIcon}/>
        </div>
        <div style={styles.iconContainer} onClick={onShowScalePopup(id)} data-tip='tooltip' data-for={`${id}-scale-item-tooltip`}>
          <ReactTooltip id={`${id}-scale-item-tooltip`} effect='solid'>Set Scale</ReactTooltip>
          <img style={styles.icon} src='https://henrybuilt-cdn.s3.us-west-2.amazonaws.com/design-engine/icons/set-scale-icon-black.png'/>
        </div>
        <div style={styles.iconContainer} onClick={onShowDeletePopup(id)} data-tip='tooltip' data-for={`${id}-delete-item-tooltip`}>
          <ReactTooltip id={`${id}-delete-item-tooltip`} effect='solid'>Delete Page</ReactTooltip>
          <img style={styles.icon} src='https://henrybuilt-cdn.s3.us-west-2.amazonaws.com/design-engine/icons/trash-icon-black.png'/>
        </div>
        <div style={styles.iconContainer} onClick={onShowGraphicPopup(id)} data-tip='tooltip' data-for={`${id}-add-graphic-item-tooltip`}>
          <ReactTooltip id={`${id}-add-graphic-item-tooltip`} effect='solid'>Add Graphic</ReactTooltip>
          <img style={styles.icon} src='https://henrybuilt-cdn.s3.us-west-2.amazonaws.com/design-engine/icons/add-elevation-icon-black.png' />
        </div>
      </div>
    </div>
  </>);
};

const DrawingsSidePanel = ({standardPages, onHide, onCopyPage, onAddStandardPage, onReorderStandardPages, onShowGraphicPopup, onShowScalePopup, onShowDeletePopup, leadingPagesCount=0}) => {
  const handlePageDragEnd = (updates) => {
    let updatedStandardPages = [];

    _.forEach(updates, ({id}) => updatedStandardPages.push(_.find(standardPages, {id})));

    updatedStandardPages = _.map(updatedStandardPages, (page, i) => ({...page, rank: i}));

    onReorderStandardPages(updatedStandardPages);
  };

  return (
    <HudElement style={{height: `100%`, paddingRight: 0, backgroundColor: '#f5f5f5', borderRight: K.grayBorder, width: K.paneWidth + 20, borderRadius: 0, position: 'fixed', top: 0, left: 0, color: 'black', zIndex: 3}}>
      <div style={{height: 'calc(100% - 125px)', position: 'relative', overflowY: 'scroll', overflowX: 'clip', scrollbarWidth: 'thin'}}>
        {/* <div
          onClick={onHide}
          style={{
            zIndex: 2,
            display: 'flex',
            flex: 1,
            justifyContent: 'end',
            ...K.fonts.label
          }}
        >{'< HIDE'}</div> */}
        <DragDropList
          onDragEnd={handlePageDragEnd}
          items={_.map(standardPages, (standardPage, i) => {
            return ({
              draggableId: standardPage.id,
              node: (<PageListItem
                {...{onCopyPage, onShowScalePopup, onShowGraphicPopup, onShowDeletePopup}}
                id={standardPage.id}
                key={standardPage.id}
                title={`${i + 1 + leadingPagesCount}/${standardPages.length + leadingPagesCount} - ${standardPage.title || 'Untitled Floor'}`}
              />)
            });
          })}
        />
      </div>
      <div onClick={onAddStandardPage} style={{marginTop: K.spacing, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div style={{opacity: 0.5}}>Add Page</div>
      </div>
    </HudElement>
  );
};

export default DrawingsSidePanel;
