import React, { useState, useRef } from 'react';
import K from 'k';
import _ from 'lodash';
import lib from 'lib';

import './links-menu.scss';

//HINT some code is kinda sloppy, but I was trying to
// write it so its easy to convert to a reusable component in the future
const LinksMenu = ({
  project,
  activeLinkKey = 'react-design-engine',
  hasProjects = true,
  showArrow = true,
  align = 'right'
}) => {
  const baseUrl = NODE_ENV === 'development' ? `http://localhost:${process.env.HB_PORT || 3000}` : 'https://apps.henrybuilt.com';
  let links = _.filter([
    {key: 'henrybuilt', title: 'Henrybuilt.com', href: `${baseUrl}/`},
    {key: 'private-site', title: 'Private Site', href: `${baseUrl}/page/private-site`},
    {key: 'client', title: 'Client Site', href: `${baseUrl}/client`},
    {key: 'rorschach', title: 'Rorschach', href: `https://client.henrybuilt.com/projects/${project.id}/rorschach`},
    {key: 'design-engine', title: 'Design Engine', href: `${baseUrl}/design-engine`},
    {key: 'react-design-engine', title: 'DE React', href: `${NODE_ENV === 'development' ? `http://localhost:${process.env.PORT || 3001}/` : 'https://design-engine.henrybuilt.com'}`},
    {key: 'old-dims-design-engine', title: 'DE Old Dims', href: `${NODE_ENV === 'development' ? `http://localhost:${process.env.PORT || 3001}/` : 'https://de-old-dims.henrybuilt.com'}`},
    {key: 'de-tracker', title: 'DE Tracker', href: 'https://de-tracker.scaffolding.site/tickets/-1'},
    {key: 'weflow', title: 'Weflow', href: 'https://www.weflowapp.com'},
    {key: 'pricing-tool', title: 'Pricing Tool', href: `${baseUrl}/pricing_tool`},
    {key: 'project-manager', title: 'Project Manager', href: `${baseUrl}/project-manager`},
    {key: 'modeler', title: 'Modeler', href: `${baseUrl}/modeler`},
    {key: 'documentation', title: 'Documentation', href: `${baseUrl}/documentation`},
    {key: 'admin', title: 'Admin', href: `${baseUrl}/admin`},
    {key: 'my-account', title: 'My Account', href: `${baseUrl}/users/${project.userId}`}
  ], link => {
    var include = false;

    if (_.includes(['henrybuilt', 'design-engine', 'de-tracker', 'react-design-engine', 'old-dims-design-engine', 'my-account'], link.key)) {
      include = true;
    }

    if (_.includes(['client', 'private-site'], link.key) && (hasProjects || project.isEmployee === true)) {
      include = true;
    }

    if (_.includes(['weflow', 'pricing-tool', 'modeler', 'documentation', 'admin', 'project-manager'], link.key) && project.isEmployee === true) {
      include = true;
    }

    if (_.includes(['rorschach'], link.key) && project.id && (hasProjects || project.isEmployee)) {
      include = true;
    }

    return include;
  });

  links = _.map(links, ({key, title, href}) => {
    var urlHasProject = _.includes(_.split(window.location.pathname, '/'), 'projects');

    if (urlHasProject && _.includes(['project-manager', 'design-engine', 'pricing-tool'], key)) {
      title += ` - #${project.id}`;

      if (key === 'pricing-tool') href += `/projects/${project.id}`;
      if (key === 'design-engine') href += `/projects/${project.id}`;
      if (key === 'project-manager') href += `/projects/${project.id}/drafts/${project.versionId}/lists/products-list-form`;
    }

    if (_.includes(['react-design-engine', 'old-dims-design-engine', 'rorschach'], key)) {
      var isDevelopment = NODE_ENV === 'development';
      if (isDevelopment) {
        href = `http://localhost:${process.env.PORT || 3001}`;
      }

      title += ` - #${project.id}`;

      if (_.includes(['old-dims-design-engine', 'react-design-engine'], key)) {
        href += `/projects/${project.id}/drafts/${project.versionId}/layout`;
      }
      else if (key === 'rorschach') {
        if (!isDevelopment) {
          href = 'https://client.henrybuilt.com';
        }
        href += `/projects/${project.id}/rorschach`;
      }
    }

    return {key, title, href};
  });

  return (
    <div className="links-dropdown-menu-element">
      <div className={`links-dropdown-menu-wrapper ${showArrow ? 'show-arrow' : ''} ${align === 'right' ? 'align-right' : ''} visible`}>
        <div className="rel">
          <div className="up-arrow"></div>
          <div className="links-dropdown-menu">
            {_.map(links, ({key, title, href}) => {

              return (
                <a className={`links-link ${key === activeLinkKey ? 'active' : ''}`} id={`links-dropdown-link-${key}`} key={key} href={href} target={'_blank'} rel={'noreferrer'}>
                  {title}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinksMenu;
