import React from 'react';
import K from 'k';

// Takes x & y and positions and returns <View> with position, can pass styles prop to override

class HudElement extends React.PureComponent {
  render() {
    var style = {position: 'absolute', zIndex: 1, backgroundColor: 'rgba(255, 255, 255, 0.97)', padding: K.spacing * 2, ...this.props.style};
    var {x, y, shadow} = this.props;

    if (shadow) style = {...style, ...K.shadow};

    if (x === 'left') style = {left: 0, ...style};
    if (x === 'right') style = {right: 0, ...style};
    if (x === 'center') style = {left: '50%', transform: 'translate(-50%, 0%)', ...style};

    if (y === 'top') style = {top: 0, ...style};
    if (y === 'bottom') style = {bottom: 0, ...style};
    if (y === 'center') style = {top: '50%', transform: 'translate(0, -50%)', ...style};
    return (
      <div {...{style}} >{this.props.children}</div>
    );
  }
}

export default HudElement;
