import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import DetailsHelper from 'helpers/details-helper';
import Room from 'project-helpers/room';
import Floor from 'project-helpers/floor';
import updateProductionIds from 'helpers/update-production-ids-helper';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import { handleContainerPropertyChange } from './container-properties-view-helpers';

function getRoomFieldGroups({room}) {
  var {project, floor, dependencies} = Room.get(['project', 'floor', 'dependencies'], {room});
  var {companyKey} = project;

  var fieldSetGroups = [
    {title: 'General', properties: []},
    {title: 'Materials', properties: []},
    {title: 'Pulls', properties: []},
    {title: 'Archetypes', properties: []}
  ];

  var generalGroupIndex = _.findIndex(fieldSetGroups, {title: 'General'});
  var materialGroupIndex = _.findIndex(fieldSetGroups, {title: 'Materials'});
  var pullGroupIndex = _.findIndex(fieldSetGroups, {title: 'Pulls'});
  var archetypeGroupIndex = _.findIndex(fieldSetGroups, {title: 'Archetypes'});

  var isArchetype = _.some(dependencies.archetypes.byId, archetype => archetype.roomId === room.id);

  fieldSetGroups[archetypeGroupIndex].properties.push({
    path: 'isArchetype',
    type: 'checkbox',
    defaultTrue: isArchetype,
    title: isArchetype ? 'Remove from Configuration Library' : 'Add to Configuration Library',
    views: ['top', 'front']
  });

  fieldSetGroups[generalGroupIndex].properties.push({
    path: 'ceilingHeight',
    type: 'number',
    title: 'Ceiling Height',
    views: ['top', 'front'],
    userLenses: ['design', 'sales', 'engineering']
  });

  fieldSetGroups[generalGroupIndex].properties.push({
    path: 'datums',
    type: 'text',
    title: 'Datum Lines',
    placeholder: 'i.e. 35, 60',
    views: ['front']
  });

  let sortedRooms = _.sortBy(Floor.get('rooms', {floor}), 'rank');

  if (companyKey === 'hb') {
    fieldSetGroups[generalGroupIndex].properties.push({
      path: 'customData.pullAlignment',
      type: 'size',
      title: 'Pull Alignment (distance from floor to center of pull, only for full height tall units)',
      views: ['top', 'front']
    });
  }

  fieldSetGroups[generalGroupIndex].properties.push({
    path: 'productionId',
    type: 'text',
    title: `Custom Unit Prefix (CANNOT INCLUDE NUMBERS or '.')`,
    placeholder: lib.string.numberToLetters(_.findIndex(sortedRooms, {id: room.id}) + 1),
    views: ['top']
  });

  fieldSetGroups[generalGroupIndex].properties.push({
    key: 'customData.preventAutoGeneration',
    title: 'Prevent Auto Generation',
    type: 'checkbox',
    views: ['front', 'top', 'bottom', 'left', 'right'],
    userLenses: ['design', 'sales', 'engineering']
  });

  // fieldSetGroups[generalGroupIndex].properties.push({
  //   key: 'customData.volumesOnly',
  //   title: 'No Walls',
  //   type: 'checkbox',
  //   views: ['front', 'top', 'bottom', 'left', 'right'],
  //   userLenses: ['design', 'sales', 'engineering']
  // });

  if (companyKey === 'vp') {
    fieldSetGroups[generalGroupIndex].properties.push({
      path: 'customData.grainFlowEnabled',
      title: 'Show Grainflow',
      type: 'checkbox',
      views: ['top', 'front']
    });
  }

  _.forEach(DetailsHelper.getCompatibleDetailsFor({room}), detail => {
    if (detail.type === 'material') {
      var relevantGroupIndex = _.includes(detail.key, 'pullMaterial') ? pullGroupIndex : materialGroupIndex;

      fieldSetGroups[relevantGroupIndex].properties[_.includes(['frontMaterial', 'boxMaterial', 'panelMaterial'], detail.key) ? 'unshift' : 'push']({
        path: `details.${detail.key}.id`,
        noThumbnail: false,
        type: 'radio',
        hasOptionGroups: true,
        optionGroups: detail.optionGroups,
        title: detail.title,
        views: ['top', 'front'],
        userLenses: ['design', 'sales', 'engineering']
      });
    }
    else {
      var relevantGroupIndex = (_.includes(detail.key, 'grain') || _.includes(detail.key, 'frontPanelType')) ? materialGroupIndex : pullGroupIndex;

      fieldSetGroups[relevantGroupIndex].properties[_.includes(detail.key, 'pullType') ? 'unshift' : 'push']({
        path: `details.${detail.key}.id`,
        noThumbnail: true,
        type: 'radio',
        options: detail.options,
        title: detail.title,
        views: ['top', 'front'],
        userLenses: ['design', 'sales', 'engineering']
      });
    }
  });

  return fieldSetGroups;
}

function handleRoomPropertyChange({activeEntityId, activeEntity, path, resourceActions, value}) {
  if (path === 'isArchetype') {
    var {dependencies, floor, project} = Room.get(['dependencies', 'floor', 'project'], {room: activeEntity});

    if (_.find(dependencies.archetypes.byId, {roomId: activeEntityId})) {
      //delete archetype
    }
    else {
      //create a new archetype with room, floor, project, project version , and company key
      //roomId, projectId, versionId, companyKey, deleted
      var {projectId, versionId} = activeEntity;
      var {companyKey} = project;

      resourceActions.createArchetype({props: {roomId: activeEntityId, projectId, versionId, companyKey, deleted: 0, parameters: []}});

      resourceActions.toggleParameterEditor(true);
    }
  }
  else {
    if (_.split(path, '.').length > 1) {
      var topLevelKey = _.split(path, '.')[0];

      _.set(activeEntity, path, value);

      var props = {[topLevelKey]: activeEntity[topLevelKey]};

      if (_.includes(path, 'details')) {
        props.details = _.mapValues(props.details, detail => {
          return _.pick(detail, ['id', 'settings']);
        });

        var updatesMap = {
          rooms: {creations: [], deletedIds: [], updates: [{where: {id: activeEntityId}, props}]},
          containers: {creations: [], deletedIds: [], updates: []},
          products: {creations: [], deletedIds: [], updates: []},
          productOptions: {creations: [], deletedIds: [], updates: []}
        };

        var children = DetailsHelper.getDetailsChildrenFor({room: activeEntity});

        if (children) _.forEach(children, child => {
          UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleContainerPropertyChange({activeEntityId: child.id, activeEntity: child, path, resourceActions, value, isBatched: true}));
        });

        UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, resourceActions});

        Room.updateManagedResources({room: activeEntity, resourceActions});
      }
      else {
        resourceActions.updateRoom({id: activeEntityId, props});
      }
    }
    else {
      resourceActions.updateRoom({id: activeEntityId, props: {[path]: value}});
    }

    if (_.includes(path, 'productionId')) {
      setTimeout(() => updateProductionIds({project: Room.get('project', {room: activeEntity}), resourceActions}));
    }

    if (_.includes(path, 'ceilingHeight')) {
      var ceilingHeight = value;
      var wallUpdates = [];

      _.forEach(Room.get('walls', {room: activeEntity}), wall => {
        var {points} = wall.outline;
        var wasUpdated = false;

        points = _.map(points, point => {
          if (point.y < -30) {
            point = {...point, y: -ceilingHeight};

            wasUpdated = true;
          }

          return point;
        });

        if (wasUpdated) {
          wallUpdates.push({where: {id: wall.id}, props: {
            outline: {
              ..._.get(wall, 'outline', {}),
              height: ceilingHeight,
              points
            }
          }});
        }
      });

      if (wallUpdates.length > 0) {
        resourceActions.updateWalls({updates: wallUpdates});
      }
    }
  }
}

export {getRoomFieldGroups, handleRoomPropertyChange};
