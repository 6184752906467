import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import memo from 'helpers/memo';
import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';
import getDependencies from 'helpers/get-dependencies';

import { store } from 'redux/index.js';

var Wall = {
  get(dependencyKeys, {wall}) {
    return getDependencies({dependencyKeys}, ({state, useDependency}) => {
      return {
        archElements: () => state.resources.archElements.byFieldKeyIndex.wallId[wall.id]
      };
    });
  },
  //HINT counterclockwise radians
  getAlpha({wall}) {
    var {to, from} = Wall.getLine({wall});

    return lib.math.trig.alpha({p1: from, p2: to});
  },

  getWidth({wall, line}) {
    if (!line) line = Wall.getLine({wall});

    var distance = lib.math.trig.distance({fromPoint: line.from, toPoint: line.to});

    return lib.number.round(distance, {toNearest: K.minPrecision});
  },

  getIndex({wall, wallSet}) {
    return _.indexOf(_.map(wallSet.walls, 'id'), wall.id);
  },

  getHeight: ({wall}) => {
    return _.max(_.map(wall.outline.points, point => point.y * -1));
  },

  //HINT the wall MUST be part of the rooms wallSets
  //TODO memo
  getLine: ({wall}) => {
    var state = store.getState();
    var room = state.resources.rooms.byId[wall.roomId];
    var wallSets = Room.getWallSets({room});
    var wallSet = _.find(wallSets, ({walls}) => _.includes(_.map(walls, 'id'), wall.id));

    var index = Wall.getIndex({wall, wallSet});
    var {points, position} = wallSet.source;
    var roomPosition = room.plan.position;

    var line = _.mapValues({from: points[index], to: lib.array.next(points, index)}, ({x, y}) => ({x, y}));

    if (wallSet.sourceType === 'plan') position = lib.object.difference(position, roomPosition);

    _.extend(line, {
      inRoom: _.mapValues(line, point => lib.object.sum(point, position)),
      absolute: _.mapValues(line, point => lib.object.sum(point, position, roomPosition))
    });

    return line;
  },

  getOutlinePoints: ({wall, elevation}) => {
    if (!elevation) {
      return _.map(wall.outline, point => ({...point, y: -point.y}));
    }
    else {
      var wallLineInRoom = Wall.getLine({wall}).inRoom;

      var getRoundedPoints = memo(({wall, elevation, wallLineInRoom}) => {
        var theta = Elevation.getWallTheta({elevation, wall});
        var thetaScalar = Math.cos(theta);
        var {outline} = wall;
        var wallLineInRoom = Wall.getLine({wall}).inRoom;

        var extendedElevationLineInRoom = lib.trig.extend({line: elevation.lineInRoom, by: 10000});
        var pointOnLine = lib.trig.nearestPoint({point: wallLineInRoom.from, onLine: extendedElevationLineInRoom});
        var distanceScalar = Math.abs(lib.trig.distance({fromPoint: pointOnLine, toLine: elevation.lineInRoom})) > K.precision ? -1 : 1;
        var xOffset = lib.trig.distance({fromPoint: pointOnLine, toPoint: elevation.lineInRoom.from}) * distanceScalar;

        var scaledOutlinePoints = _.map(outline.points, point => ({x: xOffset + point.x * thetaScalar, y: point.y}));

        var elevationWidth = Elevation.getWidth({elevation});
        var elevationPolygon = [{x: 0, y: -100000}, {x: 0, y: 100000}, {x: elevationWidth, y: 100000}, {x: elevationWidth, y: -100000}];

        var maskedPolygon = lib.polygon.intersection({p1: scaledOutlinePoints, p2: elevationPolygon});

        _.forEach(scaledOutlinePoints, (point, index) => {
          if (!_.find(maskedPolygon, point) && lib.polygon.pointInsidePolygon({point, polygon: maskedPolygon})) {
            //HINT trying to add in points that aren't part of the masked polygon
            //IE {x: 0, y: 0}, {x: 50, y: 0}, {x: 100, y: 0} will remove the x=50 point because its masked
            maskedPolygon.splice(index, 0, point);
          }
        });

        var roundedPoints = _.map(maskedPolygon, point => ({x: lib.number.round(point.x, {toNearest: 1 / 16}), y: lib.number.round(point.y, {toNearest: 1 / 16})}));

        roundedPoints = lib.polygon.makeClockwise({points: roundedPoints});

        return roundedPoints;
      });

      return getRoundedPoints({wall, elevation, wallLineInRoom});
    }
  },

  getXOffsetInElevation: ({wall, elevation}) => {
    var wallLineInRoom = Wall.getLine({wall}).inRoom;

    var getXOffsetInElevation = memo(({wall, elevation, wallLineInRoom}) => {
      var extendedElevationLineInRoom = lib.trig.extend({line: elevation.lineInRoom, by: 10000});
      var pointOnLine = lib.trig.nearestPoint({point: wallLineInRoom.from, onLine: extendedElevationLineInRoom});
      var distanceScalar = Math.abs(lib.trig.distance({fromPoint: pointOnLine, toLine: elevation.lineInRoom})) > K.precision ? -1 : 1;

      return lib.trig.distance({fromPoint: pointOnLine, toPoint: elevation.lineInRoom.from}) * distanceScalar;
    });

    return getXOffsetInElevation({wall, elevation, wallLineInRoom});
  },

  getAdjacentWalls({wall, wallSet}) {
    var index = Wall.getIndex({wall, wallSet});

    return {from: lib.array.prev(wallSet.walls, index), to: lib.array.next(wallSet.walls, index)};
  },

  getZIndexFor({wall, elevation}) {
    var normalizedElevationY = _.mapValues(elevation.lineInRoom, point => lib.trig.rotate({point, byRadians: -Elevation.getAlpha({elevation}) + Math.PI})).from.y;

    var theta = Elevation.getWallTheta({elevation, wall});
    var thetaScalar = Math.cos(theta);

    var zIndex = ((Wall.getNormalizedLineInRoom({wall}).from.y * thetaScalar) - normalizedElevationY);

    if (theta % 90 !== 0) {
      var wallLine = Wall.getLine({wall}).inRoom;

      zIndex = -_.max([lib.math.minPointDistanceFromLine({line: elevation.lineInRoom, point: wallLine.from}), lib.math.minPointDistanceFromLine({line: elevation.lineInRoom, point: wallLine.to})]);
    }

    return zIndex;
  },

  getNormalizedLineInRoom({wall, wallSet, wallSets}) {
    if (!wallSet) wallSet = _.find(wallSets, ({walls}) => _.includes(_.map(walls, 'id'), wall.id));

    var lineInRoom = Wall.getLine({wall}).inRoom;
    var alpha = Wall.getAlpha({wall});

    return _.mapValues(lineInRoom, point => lib.trig.rotate({point, byRadians: -alpha}));
  },

  getNormalizedXInRoom({wall, wallSet, wallSets}) {
    if (!wallSet) wallSet = _.find(wallSets, ({walls}) => _.includes(_.map(walls, 'id'), wall.id));

    var lineInRoom = Wall.getLine({wall}).inRoom;
    var alpha = Wall.getAlpha({wall});

    return lib.trig.rotate({point: lineInRoom.from, byRadians: -alpha}).x;
  },

  getPlanDataFor({wall, wallSet, wallSets, position}) {
    if (!wallSet) wallSet = _.find(wallSets, ({walls}) => _.includes(_.map(walls, 'id'), wall.id));

    var alpha = Wall.getAlpha({wall});
    var from = Wall.getLine({wall}).inRoom.from;
    var planPosition = lib.math.trig.translate({point: from, by: position.x, alpha});
    var perpendicularAlpha = alpha + (Math.PI / 2);

    return {position: planPosition, rotation: lib.math.trig.radiansToDegrees(perpendicularAlpha)};
  },
  getFloorLines({wall, elevation}) {
    var floorLines = [];
    var points = Wall.getOutlinePoints({wall, elevation});

    points.forEach((p1, p) => {
      var p2 = lib.array.next(points, p);

      //WARNING points are in clockwise order - floor points from.x > to.x so they need to be inverted
      //WARNING y values are negative because polyline is opposite y axis of design engine models
      if (p1.x > p2.x) {
        floorLines.push({from: {x: p2.x, y: p2.y * -1}, to: {x: p1.x, y: p1.y * -1}});
      }
    });

    return floorLines;
  },
  getNearestFloorData({xRange, elevation, wall}) {
    var overlappingFloorLines = _.filter(Wall.getFloorLines({wall, elevation}), line => {
      return lib.number.rangesOverlap({r1: _.mapValues(line, 'x'), r2: xRange, inclusive: false});
    });

    var y = _.max(_.map(overlappingFloorLines, 'from.y'));

    return {y: y || 0, isMixed: overlappingFloorLines.length > 1};
  }
};

export default Wall;
