import React, { Component } from 'react';

import TextInput from './text-input';

class NumberInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  handleChangeWithConstraints({value, minValue, maxValue, allowEmptyString, step}) {
    if (!(value === '' && allowEmptyString)) {
      var roundValue = value;

      if (step) {
        if (minValue) {
          roundValue = Math.round((roundValue - minValue) / step) * step + minValue;
        }
        else {
          roundValue = Math.round(roundValue / step) * step;
        }
      }

      if (maxValue && roundValue > maxValue) roundValue = maxValue;
      if (minValue && value < minValue) roundValue = minValue;

      value = roundValue;
    }

    if (this.props.onChange) this.props.onChange({value});
  }

  constrain(value) {
    var numericValue = 0;

    if (typeof value === 'number') {
      numericValue = value;
    }
    else if (typeof value === 'string') {
      if (this.props.allowEmptyString && value === '') {
        return '';
      }
      else {
        numericValue = _.toNumber(value);
        if (isNaN(numericValue)) {
          numericValue = value.replace(/[^\d.]+/g, '');
        }
      }
    }

    return _.toNumber(numericValue);
  }

  render() {
    var {minValue, maxValue, step, allowEmptyString} = this.props;

    var hasConstraints = (minValue || maxValue || step);

    return (
      <TextInput
        {...this.props}
        ref={this.props.inputRef}
        type='text'
        style={{border: 'none', outline: 'none', ...this.props.style}}
        onChange={({value}) => {
          try {
            var evaluatedValue = eval(value);

            //HINT support resetting input to empty string when allowed
            if (allowEmptyString && evaluatedValue === undefined) evaluatedValue = '';

            if (evaluatedValue || evaluatedValue === 0 || (evaluatedValue === '' && allowEmptyString)) {
              if (hasConstraints) {
                this.handleChangeWithConstraints({value: this.constrain(evaluatedValue), minValue, maxValue, step, allowEmptyString});
              }
              else {
                if (this.props.onChange) this.props.onChange({value: this.constrain(evaluatedValue)});
              }
            }
          }
          catch (error) {
            console.error(error);

            alert('Please enter a valid number or formula');
          }
        }}
      />
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <NumberInput inputRef={ref} {...props} />;
});
