import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Product from 'project-helpers/product';
import PositionHelper from 'helpers/position-helper';
import Elevation from 'project-helpers/elevation';
import Container from 'project-helpers/container';

var DaylightIslandComponent = {
  // getCustomOnMove({product}) {
  //   return (position) => position//Opencase.handleGridTransform({product, position});
  // },

  getCustomDragBoundFunc({product, viewOffset, elevation, nonSpacialContainerPosition, overrideSideKey}) {
    const {dimensions} = product;
    const size = {width: dimensions.width, height: dimensions.height};
    const container = Product.get('container', {product});
    const containerPosition = nonSpacialContainerPosition ? nonSpacialContainerPosition: Elevation.getPosition2d({elevation, position3d: container.position});
    const bayCount = {
      50.25: 2,
      75: 3,
      99.75: 4,
      124.5: 5
    }[container.dimensions.width || 50.25];
    const offset = lib.object.sum(containerPosition, viewOffset, Container.getDropzoneInset({container, viewKey: 'front'}));

    return (positionInCanvas, canvasData) => {
      const positionInReal = PositionHelper.toReal(positionInCanvas, canvasData);
      const relativePosition = lib.object.difference(positionInReal, offset);

      const position = {
        x: lib.array.closest([..._.times(bayCount, n => (n) * 24.75)], relativePosition.x),
        y: 0
      };

      const newPositionInReal = _.pick(lib.object.sum(position, offset, {y: -size.height}), ['x', 'y']);

      return PositionHelper.toCanvas(newPositionInReal, canvasData);
    };
  },
  sharesWallprintWith({product, siblingProduct}) {
    var f1 = DaylightIslandComponent.getWallprint({product});
    var isOverlapping;

    if (siblingProduct) {
      var f2 = [];

      // var {dividerThickness} = CFGDaylightIslandComponentProduct.K;
      const dividerThickness = 0.75;

      _.forEach(DaylightIslandComponent.getWallprint({product: siblingProduct}), (point, i) => {
        // manipulate the product's wallprint to handle the divider thickness between products
        if (_.includes([0, 1], i)) f2.push({x: point.x - dividerThickness, y: point.y});
        else if (_.includes([2, 3], i)) f2.push({x: point.x + dividerThickness, y: point.y});
      });

      isOverlapping = lib.polygon.polygonsOverlap(f1, f2, {inclusive: false});
    }

    return isOverlapping;
  },
  getWallprint({product}) {
    const {width, height} = product.dimensions;
    const {x, y} = product.position;

    return [
      {x: x, y: y},
      {x: x, y: y - height},
      {x: x + width, y: y - height},
      {x: x + width, y: y}
    ];
  },
};

export default DaylightIslandComponent;