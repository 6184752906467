import _ from 'lodash';
import lib from 'lib';

import Wall from './wall';
import memo from 'helpers/memo';

//A collection of walls (all the walls in a room, all the walls in a floating wall, etc)
var WallSet = {
  getComputedWalls: memo(({wallSet, room}) => {
    var computedWalls = [];

    _.forEach(wallSet.walls, (w1) => {
      var computedWall;

      computedWall = _.find(computedWalls, computedWall => {
        var w2 = _.last(computedWall.walls);
        var adjacentWalls1 = _.values(Wall.getAdjacentWalls({wall: w1, wallSet}));

        var sameAngle = lib.trig.normalize({radians: Wall.getAlpha({wall: w1, wallSet, room})}) === lib.trig.normalize({radians: Wall.getAlpha({wall: w2, wallSet, room})});

        var shortIntermediateWalls = _.filter(computedWall.walls, w2 => {
          var adjacentWalls2 = _.values(Wall.getAdjacentWalls({wall: w2, wallSet}));
          var intermediateWall = _.find(adjacentWalls1, aw1 => _.includes(adjacentWalls2, aw1));
          var jogThreshold = 40; //somewhat arbitrary value

          return intermediateWall && intermediateWall.width <= jogThreshold;
        });

        return sameAngle && shortIntermediateWalls.length > 0;
      });

      if (!computedWall) {
        computedWall = {wallSet, walls: [], isPerpendicularJog: false, alpha: Wall.getAlpha({wall: w1, wallSet, room})};

        computedWalls.push(computedWall);
      }

      computedWall.walls.push(w1);
    });

    _.forEach(computedWalls, computedWall => {
      var cw1 = computedWall;

      //sort walls by x in elevation view
      computedWall.walls = _.sortBy(computedWall.walls, wall => Wall.getNormalizedXInRoom({wall, wallSet, room}));

      //flag perpendicular jog walls (walls that are in between two parallel jog walls)
      if (cw1.walls.length === 1) {
        var adjacentWalls = Wall.getAdjacentWalls({wall: computedWall.walls[0], wallSet});

        var isBetweenTwoParallelJogWalls = _.some(computedWalls, cw2 => {
          return cw1 !== cw2 && _.includes(cw2.walls, adjacentWalls.from) && _.includes(cw2.walls, adjacentWalls.to);
        });

        if (isBetweenTwoParallelJogWalls) {
          computedWall.isPerpendicularJog = true;
        }
      }

      //determine innermost wall by rotating angle out of line,
      //use y value to determine inside distance to room
      computedWall.innermostWall = _.maxBy(computedWall.walls, wall => Wall.getNormalizedLineInRoom({wall, wallSet, room}).from.y);

      //calculate projected line off of innermost wall
      var normalizedY = Wall.getNormalizedLineInRoom({wall: computedWall.innermostWall, wallSet, room}).from.y;
      var normalizedXValues = _.flatMap(computedWall.walls, wall => _.map(Wall.getNormalizedLineInRoom({wall, wallSet, room}), 'x'));

      var normalizedLineInRoom = {
        from: {x: _.min(normalizedXValues), y: normalizedY},
        to: {x: _.max(normalizedXValues), y: normalizedY}
      };

      computedWall.lineInRoom = _.mapValues(normalizedLineInRoom, point => {
        return lib.trig.rotate({point, byRadians: computedWall.alpha});
      });

      computedWall.outline = []; //TODO

      computedWall.adjacentWalls = {};

      if (computedWall.walls.length > 1 || computedWall.isPerpendicularJog) {
        var firstWall = _.first(computedWall.walls);
        var lastWall = _.last(computedWall.walls);

        computedWall.adjacentWalls.from = Wall.getAdjacentWalls({wall: firstWall, wallSet}).from;
        computedWall.adjacentWalls.to = Wall.getAdjacentWalls({wall: lastWall, wallSet}).to;
      }
      else {
        computedWall.adjacentWalls = Wall.getAdjacentWalls({wall: computedWall.walls[0], wallSet});
      }
    });

    return computedWalls;
  })
};

export default WallSet;
