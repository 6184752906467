import React, {useState} from 'react';
import Container from 'project-helpers/container';
import Elevation from 'project-helpers/elevation';
import Room from 'project-helpers/room';
import Project from 'project-helpers/project';
import Product from 'project-helpers/product';
import Hatch from 'components/hatch';

import xIcon from '../../../assets/x-icon-black.png';

import K from 'k';
import VisibilityLayersHudElement from '../hud-elements/visibility-layers-hud-element';

var ColorKeyPopup = ({activeFillMode, activeDetailLevel, onClose, room, viewMode, visibleEntitiesDataByResourceKey, project, activeEntities}) => {
  var styles = {
    wrapper: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      width: 230,
      border: '1px solid red',
      bottom: K.spacing,
      right: 200 + (activeEntities.length === 1 ? 60 : 0),
      zIndex: 2,
      border: `3px solid ${K.colors.gray}`,
      borderRadius: K.borderRadius,
      backgroundColor: K.colors.base
    },
    closeButton: {
      background: 'none',
      border: 'none',
      opacity: 0.7,
      // marginTop: K.spacing / 2,
    }
  }
  // var newUnitTypeColors = [
  //   {title: 'Bar Block', color: '#b0bdb2'},
  //   {title: 'Base', color: '#dad3c3'},
  //   {title: 'Open Case', color: '#eaeaea'},
  //   {title: 'Floating Base', color: '#efecde'},
  //   {title: 'Backsplash', color: '#dcdcdc'},
  //   {title: 'Wall', color: '#d2d9dd'},
  //   {title: 'Floating Shelves', color: '#7c7c7c'},
  //   {title: 'Island Seating', color: '#bab0a6'},
  //   {title: 'Tall', color: '#b7bcb8'},
  //   {title: 'Assemblies', color: '#8b9aa4'},
  // ]


  var {rooms, elevations} = visibleEntitiesDataByResourceKey;
  var sectionContentJSX = [];

  if (activeFillMode === 'unitType') {
    const containers = [];

    if (elevations?.length > 0) {
      _.forEach(elevations, (({entity: elevation}) => {
        const elevationContainers = Elevation.get('containers', {elevation});

        containers.push(..._.uniq(elevationContainers));
      }));
    }

    if (rooms?.length > 0) {
      _.forEach(rooms, (room => {
        const roomContainers = Room.get('containers', {room});

        containers.push(..._.uniq(roomContainers));
      }));
    }

    let containerTypes = _.uniq(_.map(containers, 'type'));

    _.pullAll(containerTypes, ['capPanel', 'wallPanel', 'endPanel', 'baseFreestandingAppliance', 'wallFreestandingAppliance', 'valet']);


    sectionContentJSX = (<>{
      _.map(_.filter(containerTypes, type => type !== 'countertop'), type => {
        return (
          <div key={`color-key-${type}`} className={`drawings-color-key-${type}-row`} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12}}>
            <div className={`drawings-color-key-${type}-box`} style={{width: 20, height: 20, backgroundColor: `#${K.designPlanningCompleteColorMap[type] || K.designPlanningCompleteColorMap['base']}`}}></div>
            <div className={`drawings-color-key-${type}`} style={{marginLeft: 5}}>{_.lowerCase(type)}</div>
          </div>
        );
      })
    }</>);
  }
  else {
    const frontContainers = _.flatMap(elevations, ({entity: elevation}) => {
      const containers = Elevation.get('containers', {elevation});

      return _.filter(containers, container => {
        return _.includes([0, 360], lib.round(Container.getElevationTheta({container, elevation}), {toNearest: 1})) || (container.type === 'ocSolidCorner' && _.includes(['left', 'front'], Container.getSideKey({elevation, container, viewKey: 'front'})));
      });
    });

    const backContainers = _.flatMap(elevations, ({entity: elevation}) => {
      const containers = Elevation.get('containers', {elevation});

      return _.filter(containers, container => {
        const containerAlpha = Container.getAlpha({container});
        const elevationAlpha = Elevation.getAlpha({elevation});

        return lib.trig.anglesAreEqual({a1: containerAlpha, a2: elevationAlpha});
      });
    });

    const countertopContainers = _.flatMap(elevations, ({entity: elevation}) => {
      const containers = Elevation.get('containers', {elevation});

      return _.filter(containers, {type: 'countertop'});
    });

    const containers = _.uniq([...frontContainers, ...countertopContainers]);

    const frontProductInstances = _.flatMap(containers, container => {
      const products = Container.get('allProducts', {container});
      //TODO what to do with managed product instances
      return [..._.map(products, product => ({...product, sideKey: 'front'}))];
    });

    const backEndPanelProductInstances = _.flatMap(backContainers, container => {
      const products = Container.get('allProducts', {container});

      return _.map(_.filter(products, productInstance => {
        return container.type === 'endPanel' || _.includes(['leftWrapPanel', 'rightWrapPanel'], _.get(productInstance, 'managedData.managedKey'));
      }), product => ({...product, sideKey: 'back'}));
    });

    const productInstances = [...frontProductInstances, ...backEndPanelProductInstances];
    const applications = [];
    const materialFrequencyGroups = Project.getMaterialFrequencyGroups({project, activeFillMode});
    const {materialClasses} = Project.get('dependencies', {project});
    const materialHatchKeysJSX = [];
    const detailCategoriesJSX = [];

    _.forEach(productInstances, productInstance => {
      let ownedCompatibleDetails = Product.getOwnedCompatibleDetails({product: productInstance, renderForDrawings: true});
      var subproductData = Product.getSubproductData({product: productInstance});
      const {details} = productInstance;
      const compatibleDetailGroups = [];
      var hasAppliedBrass = (_.get(productInstance, 'customData.productOptions.80.enabled')) === 1 ||
        (_.get(productInstance, 'customData.productOptions.81.enabled')) === 1 ||
        (_.get(productInstance, 'customData.productOptions.82.enabled')) === 1 ||
        (_.get(productInstance, 'customData.productOptions.83.enabled')) === 1;

      ownedCompatibleDetails = _.reject(ownedCompatibleDetails, detail => _.includes(['pullLocation', 'yPullLocation'], detail.key));

      _.forEach(ownedCompatibleDetails, ownedCompatibleDetail => {
        if (hasAppliedBrass && ownedCompatibleDetail.key === 'frontMaterial') {
          compatibleDetailGroups.push([{key: 'brassFront', type: 'material', title: 'Brass Front', options: [{id: 'brass', title: 'Applied Brass'}]}, ownedCompatibleDetail]);
        }
        else if (!_.includes(ownedCompatibleDetail.key, 'pull')) {
          compatibleDetailGroups.push([ownedCompatibleDetail]);
        }
      });

      const pullTypeDetail = _.find(ownedCompatibleDetails, {key: 'pullType'});
      const pullMaterialDetail = _.find(ownedCompatibleDetails, {key: 'pullMaterial'});

      if (pullTypeDetail && pullMaterialDetail) {
        compatibleDetailGroups.push([pullTypeDetail, pullMaterialDetail]);
      }

      _.forEach(compatibleDetailGroups, (compatibleDetails) => {
        var appliedDetails = _.map(compatibleDetails, compatibleDetail => {
          var value = details[compatibleDetail.key];

          var isSubproductDetail = _.split(compatibleDetail.key, '-').length > 1;

          if (!value) {
            if (isSubproductDetail) {
              var subproductDetailKey = _.split(compatibleDetail.key, '-')[2];

              value = details[subproductDetailKey] || {id: _.get(compatibleDetail, 'options')[0].id};
            }
            else {
              value = {id: _.get(compatibleDetail, 'options')[0].id};
            }
          }

          var activeDetailOption = _.find(_.get(compatibleDetail, 'options'), {id: value.id});
          var title = _.get(activeDetailOption, 'title', '');

          return {key: compatibleDetail.key, value, compatibleDetail, title};
        });

        if (_.every(appliedDetails, ({value}) => value !== undefined)) {
          const appliedDetailsForComparison = _.map(appliedDetails, appliedDetail => ({key: appliedDetail.key, value: appliedDetail.value.id, title: appliedDetail.title}));

          let application = _.find(applications, application => {
            const applicationForComparison = _.map(application.appliedDetails, appliedDetail => ({key: appliedDetail.key, value: appliedDetail.value.id, title: appliedDetail.title}));

            return _.isEqual(applicationForComparison, appliedDetailsForComparison);
          });

          if (!application) {
            application = {appliedDetails, productInstances: []};

            applications.push(application);
          }

          application.productInstances.push(productInstance);
        }
      });
    });

    //HINT builds materialHatchKeysJSX
    _.forEach(materialFrequencyGroups.wHatches, ({materialId, hatchKey}) => {
      var appearsWithAppliedBrassInElevation =_.some(applications, application => {
        if (_.some(application.appliedDetails, {key: 'brassFront'})) {
          const {key, value} = application.appliedDetails[1];

          return value.id === materialId && _.some(application.productInstances, productInstance => {
            const productData = Product.getProductData({product: productInstance});
            return Project.getShouldShowHatch({detailKey: key, productData, activeFillMode});
          });
        }

        return false;
      });

      const hatchAppearsInElevation = _.some(applications, application => {
        if (application.appliedDetails.length === 1) {
          const {key, value} = application.appliedDetails[0];

          return value.id === materialId && _.some(application.productInstances, productInstance => {
            const productData = Product.getProductData({product: productInstance});
            return Project.getShouldShowHatch({detailKey: key, productData, activeFillMode});
          });
        }

        return false;
      });

      if (appearsWithAppliedBrassInElevation) {
        const material = _.find(_.flatMap(_.values(materialClasses.byId), materialClass => materialClass.materials), {id: materialId});

        if (material) {
          materialHatchKeysJSX.push(
            <div key={`hatch-brass-${hatchKey}-${materialId}`} className="detail-item-with-hatch" style={{display: 'flex', flexDirection: 'row', marginBottom: 12, alignItems: 'center', gap: K.spacing}}>
              {activeFillMode === 'materialColors' ? (
                <div className="detail-item-hatch"
                  style={{minWidth: 20, height: 20, border: material.color ? 'none' : '1px solid red', backgroundColor: '#967F72' || K.containerAppearancePropsByTheme().fill.light}}
                />
              ) : (
                <div className="detail-item-hatch" style={{width: 20, height: 20, backgroundColor: K.containerAppearancePropsByTheme().fill.light}}>
                  <Hatch {...{hatchKey}} width={20} height={20}/>
                </div>
              )}
              <div className="detail-item-title">Applied Brass: {material.title}</div>
            </div>
          );
        }
        else {
          console.error('missing material', materialId); //eslint-disable-line
        }
      }

      if (hatchAppearsInElevation) {
        const material = _.find(_.flatMap(_.values(materialClasses.byId), materialClass => materialClass.materials), {id: materialId});

        if (material) {
          materialHatchKeysJSX.push(
            <div key={`hatch-${hatchKey}-${material.color}`} className="detail-item-with-hatch" style={{display: 'flex', flexDirection: 'row', marginBottom: 12, alignItems: 'center', gap: K.spacing}}>
              {activeFillMode === 'materialColors' ? (
                <div className="detail-item-hatch"
                  style={{minWidth: 20, height: 20, border: material.color ? 'none' : '1px solid red', backgroundColor: material.color}}
                />
              ) : (
                <div className="detail-item-hatch" style={{width: 20, height: 20, backgroundColor: K.containerAppearancePropsByTheme().fill.light}}>
                  <Hatch {...{hatchKey}} width={20} height={20}/>
                </div>
              )}
              <div className="detail-item-title">{material.title}</div>
            </div>
          );
        }
        else {
          console.error('missing material', materialId); //eslint-disable-line
        }
      }
    });

    sectionContentJSX = (
      <>
        {materialHatchKeysJSX}
      </>
    );
  }

  return (
    <div style={styles.wrapper}>
      <div style={{position: 'absolute', right: 0}}>
        <button onClick={onClose} style={{...styles.closeButton, cursor: 'pointer'}}>
          <img style={{height: 16, width: 16, marginTop: K.spacing / 2}} src={xIcon} />
        </button>
      </div>
      <div className='drawings-side-panel-content'>
        <div className="drawings-color-keys">
          <div className="drawings-color-key" style={{padding: K.spacing}}>
            {sectionContentJSX}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorKeyPopup;






