import lib from 'lib';

var CanvasHelper = {
  pathDataFrom: ({points, closed, scale}) => {
    var pathCommands = [];

    points.forEach((point, p) => {
      var command = [];

      if (p > 0) {
        var lastPoint = points[p - 1];

        if (point.arc) {
          var delta = {x: point.x - lastPoint.x, y: point.y - lastPoint.y};
          var semicircleRadius = (Math.abs(delta.x) + Math.abs(delta.y)) / 2;
          var magnitude = scale / (point.magnitude || 1);
          var radii = {x: semicircleRadius * magnitude, y: semicircleRadius * magnitude};

          if (point.quarterCircle) {
            ['x', 'y'].forEach(axis => {
              radii[axis] = Math.abs(delta[axis]) * magnitude;
            });
          }

          var angle = lib.math.trig.alpha({p1: lastPoint, p2: point, perpendicular: 1});
          var isFlipped = point.flipped ? 1 : 0;

          command.push('A', radii.x, radii.y, angle, 0, isFlipped);
        }
        else {
          command.push('L');
        }
      }
      else {
        command.push('M');
      }

      command.push(point.x * scale, point.y * scale);
      pathCommands.push(command.join(' '));
    });

    if (closed) {
      pathCommands.push(['Z']);
    }

    return pathCommands.join(' ');
  }
};

export default CanvasHelper;
