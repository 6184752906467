import _ from 'lodash';
import lib from 'lib';

import Room from '../room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import getDependencies from 'helpers/get-dependencies';

var Scope = {
  get(dependencyKeys, {scope}) {
    return getDependencies({dependencyKeys}, ({state}) => {
      return {
        room: () => state.resources.rooms.byId[scope.roomId],
        containers: () => state.resources.containers.byFieldKeyIndex.scopeId[scope.id],
      };
    });
  },

  updateComponents({room, scope, resourceActions, excludedScopeIds}) {
    var containers = Room.get('containers', {room});
    var scopes = Room.get('scopes', {room});

    scopes = _.reject(scopes, scope => _.includes(excludedScopeIds, scope.id));

    //HINT updated or new scope
    if (scope) {
      var currentScopeIndex = _.findIndex(scopes, {id: scope.id});

      if (currentScopeIndex !== -1) scopes[currentScopeIndex] = scope;
      else scopes.push(scope);
    }

    var updatesMap = {
      containers: {creations: [], updates: [], deletedIds: []},
      products: {creations: [], updates: [], deletedIds: []},
      productOptions: {creations: [], updates: [], deletedIds: []},
    };

    _.forEach(containers, container => {
      var scopeId = Container.getScopeId({container, scopes});
      // if (Container.getScopeId({container, scopes}) === scope.id) {
      updatesMap.containers.updates.push({where: {id: container.id}, props: {scopeId}});

      var allProducts = Container.get('allProducts', {container});

      if (_.size(allProducts)) {
        _.forEach(allProducts, product => {
          updatesMap.products.updates.push({where: {id: product.id}, props: {scopeId}});

          var productOptionInstances = Product.get('productOptionInstances', {product});

          updatesMap.productOptions.updates.push(..._.map(productOptionInstances, productOptionInstance => {
            return {where: {id: productOptionInstance.id}, props: {scopeId}};
          }));
        });
      }
      // }
    });

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, resourceActions});
  }
};

export default Scope;