import _ from 'lodash';
import lib from 'lib';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addRoomContainerDepthDimensions({container, roomContainers, containerAlpha, dimensionSets, getIsTrapped, footprintLines, isIsland, room,  allPoints, outsideDimensionSets }) {
  var sideKey = containerAlpha > Math.PI ? 'left' : 'right';
  var offset = container.dimensions.width > 16 ? 8 : container.dimensions.width / 2;
  var yOffset = 0;
  var hasCleat = Container.getHasCleat({container});
  var isIslandSeating = _.includes(['islandSeating'], container.type);
  var isWall = _.includes(['wall'], container.type);

  //HINT We want base units to be dimensioned from the right and everything else dimensioned from the left (so they don't overlap in plan)
  if (container.position.y > 30) {
    sideKey = sideKey === 'left' ? 'right' : 'left';
  }

  if (Container.getHasWrap({container})) {
    var meldedContainersData = Container.getMeldedContainersData({container, roomContainers});
    var wrap = _.get(container, 'customData.wrap', {});
    var endPanelSideKeys = _.filter(['left', 'right'], sideKey => {
      return wrap[sideKey] && !(meldedContainersData[sideKey] && !meldedContainersData[sideKey].isPrimaryContainer);
    });

    if (endPanelSideKeys.length) {
      var wrapSizes = Container.getWrapSizes({container});
      var wrapHeights = Container.getWrapPanelWidths({container});
      var dimensionedWrapPanelDepths = [];

      var endPanelsData = lib.object.fromKeys(endPanelSideKeys, wrapSideKey => {
        const meldedContainerData = meldedContainersData[wrapSideKey];
        var x, y, wrapHeight, wrapWidth;
        var wrapWidth = wrapSizes[wrapSideKey];
        var customWrapPanelWidth = _.get(wrap, `depths.${wrapSideKey}`);
        var customPanelExtension = _.get(wrap, `backExtension.${wrapSideKey}`, 0);
        var customWrapWidthOffset = customWrapPanelWidth ? (customWrapPanelWidth - container.dimensions.depth - wrap.overhang + customPanelExtension) : customPanelExtension;
        var wrapHeight = meldedContainerData ? _.get(meldedContainerData, 'netMeldedDepth', 0) : wrapHeights[wrapSideKey];

        x = wrapSideKey === 'left' ? 0 : container.dimensions.width - wrapSizes[wrapSideKey];
        y = -_.get(meldedContainerData, 'otherContainersWrapPanelWidth', 0) - customWrapWidthOffset;

        var endPanelNodes = _.map([
          {x, y},
          {x, y: y + wrapHeight},
          {x: x + wrapWidth, y: y + wrapHeight},
          {x: x + wrapWidth, y},
        ], point => lib.object.sum(lib.trig.rotate({point, byDegrees: container.rotation}), Container.getPositionInRoom({container})));

        var p1 = wrapSideKey === 'right' ? endPanelNodes[2] : endPanelNodes[0];
        var p2 = wrapSideKey === 'right' ? endPanelNodes[3] : endPanelNodes[1];

        var candidateLines = _.filter(isIsland ? [{from: p1, to: p2}, {from: p2, to: p1}] : [{from: p1, to: p2}], candidateLine => {
          return !getIsTrapped({line: candidateLine, entityType: 'container', entityId: container.id});
        });

        return {
          meldedContainerData,
          candidateLines,
          isTrapped: isIsland ? (candidateLines.length === 0) : getIsTrapped({line: {from: p1, to: p2}, entityType: 'container', entityId: container.id}),
          //if end panel is against wall or other container, we offset the y so the don't overlap
          //if opposite end panel isn't trapped and equivalent, then we should dimensions that instead
          isTrappedLocally: getIsTrapped({line: {from: p1, to: p2}, entityType: 'container', entityId: container.id}, {filterEntities: meldedContainerData ? (entity) => !_.includes(_.map(meldedContainerData.otherContainers, 'id'), entity.container?.id) : undefined, locally: true}),
          endPanelNodes, p1, p2, wrapSideKey
        };
      });

      _.forEach(_.orderBy(endPanelsData, ['candidateLines.length'], ['desc']), (endPanelData) => {
        var {candidateLines, wrapSideKey, endPanelNodes, isTrappedLocally, meldedContainerData, p1, p2} = endPanelData;

        if (!_.some(dimensionedWrapPanelDepths, line => {
          var xsEqual = _.isEqual(_.sortBy([line.from.x, line.to.x]), _.sortBy([endPanelNodes[0].x, endPanelNodes[1].x])) && line.from.x !== line.to.x;
          var ysEqual = _.isEqual(_.sortBy([line.from.y, line.to.y]), _.sortBy([endPanelNodes[0].y, endPanelNodes[1].y])) && line.from.y !== line.to.y;

          return (xsEqual || ysEqual);
        })) {
          if (candidateLines.length && isIsland) {
            addLineToNearestSide({allPoints, outsideDimensionSets,
              candidateLines: _.map(candidateLines, cLine => _.mapValues(cLine, point => lib.object.sum(point, room.plan.position))),
              idPrefix: `island-endPanel-${container.id}-side-${wrapSideKey}`
            });
          }
          else {
            var distanceBetweenPoints = lib.trig.distance({fromPoint: p1, toPoint: p2});
            dimensionSets.push({
              type: 'standalone',
              id: `room-container-${container.id}-end-panel-${wrapSideKey}-depth`,
              alpha: lib.trig.alpha({p1, p2}),
              offset: container.type === 'endPanel' ? (isTrappedLocally ? 0 : 4) : (meldedContainerData ? 10 : 4) * (isTrappedLocally ? -1 : 1),
              showLabelBorder: true,
              targets: [
                {yOffset: (isTrappedLocally && distanceBetweenPoints > 10) ? (wrapSideKey === 'right' ? 5 : -5) : 0, position: lib.object.sum(p1, room.plan.position), id: `room-container-${container.id}-end-panel-${wrapSideKey}-width-from`},
                {yOffset: (isTrappedLocally && distanceBetweenPoints > 10) ? (wrapSideKey === 'right' ? 5 : -5) : 0, position: lib.object.sum(p2, room.plan.position), id: `room-container-${container.id}-end-panel-${wrapSideKey}-width-to`}
              ]
            });

            offset = (sideKey === 'right' ? wrapSideKey === 'right' : wrapSideKey === 'left') ? offset : container.dimensions.width - offset;

            if (isTrappedLocally && distanceBetweenPoints > 10) {
              yOffset = 5 * (sideKey === 'left' ? -1 : 1);
            }
          }

          dimensionedWrapPanelDepths.push({from: endPanelNodes[0], to: endPanelNodes[1]});
        }
      });
    }
  }

  if (isWall) {
    var children = Container.get('unmanagedProductInstances', {container});

    _.map(children, product => {
      var productSize = Product.getSize({product, viewKey: 'top'});

      if (productSize && productSize.height < container.dimensions.depth) {
        dimensionSets.push({
          type: 'standalone',
          id: `room-container-${container.id}-child-product-${product.id}-depth`,
          alpha: containerAlpha + (sideKey === 'left' ? -1 : 1) * Math.PI / 2,
          offset: productSize.width / 2,
          showLabelBorder: true,
          targets: [
            {
              position: lib.object.sum(
                room.plan.position,
                Product.getPositionInRoom({product})
              ),
              id: `room-container-${container.id}-child-product-${product.id}-depth-from`
            },
            {
              position:
                lib.object.sum(
                  room.plan.position,
                  Product.getPositionInRoom({product}),
                  lib.trig.rotate({point: {x: 0, y: productSize.height}, byRadians: containerAlpha})
                )
              ,
              id: `room-container-${container.id}-child-product-${product.id}-depth-to`
            }
          ]
        })
      }
    })
  }

  if (isIslandSeating) {
    var seatingDepth = container.customData.seatingDepth;
    sideKey = 'right';

    var p1 = lib.object.sum(footprintLines.left.to, room.plan.position);
    var p2 = lib.object.sum(footprintLines.left.from, room.plan.position);

    dimensionSets.push({
      alpha: lib.trig.alpha({p1, p2}),
      targets: [{position: p1, id: `room-container-${container.id}-outside-depth-from`}, {position: p2, id: `room-container-${container.id}-outside-depth-to`}],
      type: 'standalone',
      offset,
      showLabelBorder: true,
      id: `room-container-${container.id}-outside-depth`
    });
    dimensionSets.push({
      type: 'standalone',
      id: `room-container-${container.id}-seating-depth`,
      alpha: containerAlpha + (sideKey === 'left' ? -1 : 1) * Math.PI / 2,
      offset,
      showLabelBorder: true,
      targets: [
        {position: lib.object.sum(footprintLines[sideKey].from, room.plan.position), id: `room-container-${container.id}-seating-depth-from`},
        {position: lib.object.sum(footprintLines[sideKey].from, lib.object.sum(room.plan.position, lib.trig.rotate({point: {x: 0, y: - seatingDepth}, byRadians: containerAlpha}))), id: `room-container-${container.id}-seating-depth-to`}
      ]
    });
    dimensionSets.push({
      type: 'standalone',
      id: `room-container-${container.id}-chase-depth`,
      alpha: containerAlpha + (sideKey === 'left' ? -1 : 1) * Math.PI / 2,
      offset,
      showLabelBorder: true,
      targets: [
        {
          position: lib.object.sum(footprintLines[sideKey].from, lib.object.sum(room.plan.position, lib.trig.rotate({point: {x: 0, y: - seatingDepth - 0.75}, byRadians: containerAlpha}))),
          id: `room-container-${container.id}-chase-depth-from`
        },
        {
          position: lib.object.sum(footprintLines[sideKey].to, room.plan.position),
          id: `room-container-${container.id}-chase-depth-to`
        }
      ]
    });
  }
  else if (container.type === 'baseWithChase') {
    var unitDepth = container.customData.unitDepth;
    sideKey = 'right';

    dimensionSets.push({
      type: 'standalone',
      id: `room-container-${container.id}-unit-chase-depth`,
      alpha: containerAlpha + (sideKey === 'left' ? -1 : 1) * Math.PI / 2,
      offset,
      showLabelBorder: true,
      targets: [
        {position: lib.object.sum(footprintLines[sideKey].from, room.plan.position), id: `room-container-${container.id}-unit-depth-from`},
        {position: lib.object.sum(footprintLines[sideKey].from, lib.object.sum(room.plan.position, lib.trig.rotate({point: {x: 0, y: - unitDepth}, byRadians: containerAlpha}))), id: `room-container-${container.id}-unit-depth-to`},
        {
          position: lib.object.sum(footprintLines[sideKey].to, room.plan.position),
          id: `room-container-${container.id}-chase-depth`
        }
      ]
    });
  }
  else if (container.type !== 'endPanel') {
    dimensionSets.push({
      type: 'standalone',
      id: `room-container-${container.id}-depth`,
      alpha: containerAlpha + (sideKey === 'left' ? -1 : 1) * Math.PI / 2,
      offset,
      showLabelBorder: true,
      targets: [
        {yOffset, position: lib.object.sum(footprintLines[sideKey].from, room.plan.position), id: `room-container-${container.id}-depth-from`},
        ...(hasCleat ? (
          [{yOffset, position: lib.object.sum(footprintLines[sideKey][sideKey === 'left' ? 'from' : 'to'], lib.trig.rotate({point: {x: 0.5, y: 0}, byRadians: containerAlpha + Math.PI / 2}), room.plan.position), id: `room-container-${container.id}-depth-chase`}]
        ) : []),
        {yOffset, position: lib.object.sum(footprintLines[sideKey].to, room.plan.position), id: `room-container-${container.id}-depth-to`}
      ]
    });
  }
}