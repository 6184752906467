import React, { useState } from 'react';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import Popup from 'components/popup';
import TextInput from 'components/text-input';

function AddScopePopup({onClose, onCreate}) {
  const [title, setTitle] = useState('');

  return (
    <Popup {...{onClose}}>
      <div>
        <label style={{marginRight: K.margin}}>New scope title:</label>
        <TextInput value={title} onInput={(value) => setTitle(value)} onSubmit={() => {
          onCreate({title});
          onClose();
        }} autoFocus submitOnEnter/>
      </div>
      <div style={{marginTop: K.spacing, justifyContent: 'flex-end', display: 'flex'}}>
        <button onClick={() => onClose()} style={{marginRight: 5}}>Cancel</button>
        <button onClick={() => {
          onCreate({title});
          onClose();
        }}>Create</button>
      </div>
    </Popup>
  );
}

export default AddScopePopup;