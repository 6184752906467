import _ from 'lodash';
import lib from 'lib';

export default function addRoomWallDimensions({outsideDimensionSets, dimensionSets, room, getIsTrapped}) {
  var roomLines = [];

  _.forEach(room.plan.points, (point, index) => {
    var nextPoint = lib.array.next(room.plan.points, index);
    var alpha = lib.trig.alpha({p1: point, p2: nextPoint});

    roomLines.push({from: point, to: nextPoint, alpha});
  });

  _.forEach(room.plan.points, (point, index) => {
    var nextPoint = lib.array.next(room.plan.points, index);
    var alpha = lib.trig.alpha({p1: point, p2: nextPoint});

    if (!(point.isPseudoPoint)) {
      var alphaCandidates = [alpha, lib.trig.alpha({p1: nextPoint, p2: point})];
      var untrappedAlphas = _.filter(alphaCandidates, (alpha, index) => {
        return !getIsTrapped({line: {from: index === 0 ? point : nextPoint, to: index === 0 ? nextPoint : point}, entityType: 'wall', entityId: point.id});
      });

      if (untrappedAlphas.length) {
        var untrappedAlpha = untrappedAlphas[0];
        var line = untrappedAlpha === alpha ? {from: point, to: nextPoint} : {from: nextPoint, to: point};
        var dimensionSet = _.find(outsideDimensionSets, ({alpha: dimensionSetAlpha}) => lib.trig.anglesAreEqual({a1: dimensionSetAlpha, a2: untrappedAlphas[0] + Math.PI}))

        if (dimensionSet) {
          dimensionSet.targets.push({summarize: true, position: lib.object.sum(line.from, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-${_.round(untrappedAlphas[0], 3)}`});
          dimensionSet.targets.push({summarize: true, position: lib.object.sum(line.to, room.plan.position), id: `room-${room.id}-wall-point-${nextPoint.id}-${_.round(untrappedAlphas[0], 3)}`});
        }
        else {
          dimensionSets.push({
            type: 'extrudeLocally',
            id: `room-${room.id}-wall-point-${point.id}-locally`,
            alpha: untrappedAlphas[0] + Math.PI,
            targets: [
              {position: lib.object.sum(line.from, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-from`},
              {position: lib.object.sum(line.to, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-to`}
            ]
          });
        }
      }
      //HINT this is a wall that is trapped on both sides
      //HINT usually this means there is a peninsula where the wall is extending into the room
      else {
        var intrusionSiblings = _.filter(roomLines, line => {
          var normalizeLine = ({line}) => _.mapValues(line, point => lib.trig.rotate({point, byRadians: -alpha}));

          var normalL1 = normalizeLine({line: {from: point, to: nextPoint}});
          var normalL2 = normalizeLine({line});

          return !_.isEqual(line, {from: point, to: nextPoint, alpha}) && lib.trig.anglesAreParallel({a1: line.alpha, a2: alpha}) && lib.number.rangesOverlap({r1: _.mapValues(normalL1, 'x'), r2: _.mapValues(normalL2, 'x'), inclusive: false});
        });

        //TODO handle condition where there is a niche inside of a larger intrusion
        //shouldn't treat niche as sibling
        var intrusionSibling = _.minBy(_.map(intrusionSiblings, line => {
          //HINT find intersection with extended perpendicular line, the length of the line is the available distance
          var intersection = lib.math.intersectionPoint({
            l1: {from: lib.object.sum(point, lib.trig.rotate({
              point: {x: 0, y: 10000},
              byRadians: alpha
            })), to: lib.object.sum(point, lib.trig.rotate({
              point: {x: 0, y: 10000},
              byRadians: alpha + Math.PI
            }))}, l2: line
          });

          return {line, distance: lib.trig.distance({fromPoint: point, toPoint: intersection})};
        }), 'distance');

        var shouldDimension = true;
        var equivalentDistances = false;
        var isTrappedLocally = getIsTrapped({line: {from: point, to: nextPoint}, entityType: 'wall', entityId: point.id}, {filterEntities: intrusionSibling ? (entity) => entity.key !== `wall-${intrusionSibling.line.from.id}`: undefined, locally: true});

        if (intrusionSibling) {
          var xsEqual = _.isEqual(_.sortBy([intrusionSibling.line.from.x, intrusionSibling.line.to.x]), _.sortBy([point.x, nextPoint.x])) && point.x !== nextPoint.x;
          var ysEqual = _.isEqual(_.sortBy([intrusionSibling.line.from.y, intrusionSibling.line.to.y]), _.sortBy([point.y, nextPoint.y])) && point.y !== nextPoint.y;

          var equivalentDistances = xsEqual || ysEqual;

          var siblingIsTrappedLocally = getIsTrapped({line: intrusionSibling.line, entityType: 'wall', entityId: intrusionSibling.line.from.id}, {filterEntities: (entity) => {
            return entity.key !== `wall-${point.id}`
          }, locally: true});

          shouldDimension = !equivalentDistances || !(isTrappedLocally && !siblingIsTrappedLocally);
        }

        if (shouldDimension) {
          var isHorizontal = _.includes([0, 2, 4], Math.round(lib.trig.normalize({radians: alpha}) / Math.PI * 2));

          var smallIntrusionThreshold = isHorizontal ? (equivalentDistances ? 5.5 : 12) : 12;

          var localDimensionSet = _.find(dimensionSets, dimensionSet => {
            var isExtrudedLocally = dimensionSet.type === 'extrudeLocally';

            if (!isExtrudedLocally || dimensionSet.targets.length < 2) return false;

            var dimensionSetLine = {from: _.first(dimensionSet.targets).position, to: _.last(dimensionSet.targets).position};

            var intersection = lib.math.intersectionPoint({
              l1: {from: lib.object.sum(point, room.plan.position, lib.trig.rotate({
                point: {x: 0, y: 10000},
                byRadians: alpha
              })), to: lib.object.sum(point, room.plan.position, lib.trig.rotate({
                point: {x: 0, y: 10000},
                byRadians: alpha + Math.PI
              }))}, l2: dimensionSetLine
            });

            var normalizeLine = ({line}) => _.mapValues(line, point => lib.trig.rotate({point, byRadians: -alpha}));

            var normalL1 = normalizeLine({line: {from: lib.object.sum(point, room.plan.position), to: lib.object.sum(nextPoint, room.plan.position)}});
            var normalL2 = normalizeLine({line: dimensionSetLine});

            var isInline = lib.number.rangesOverlap({r1: _.mapValues(normalL1, 'x'), r2: _.mapValues(normalL2, 'x'), inclusive: false});

            return lib.trig.anglesAreEqual({a1: alpha, a2: lib.trig.alpha({p1: dimensionSetLine.from, p2: dimensionSetLine.to})}) && isInline && lib.trig.distance({fromPoint: lib.object.sum(point, room.plan.position), toPoint: intersection}) < 48;
          });

          if (localDimensionSet) {
            localDimensionSet.targets.push(
              {position: lib.object.sum(point, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-from`},
              {position: lib.object.sum(nextPoint, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-to`}
            );
          }

          //HINT in addition to local dim, when possible showing an overall dim pulled out or to the center of the intrusion
          if (intrusionSibling && intrusionSibling.distance < smallIntrusionThreshold) {
            //HINT only dimension as extrudeLocally pulled into room if there is no space in intrusion
            //and there isn't a local dim we already combined with
            if (!localDimensionSet) {
              dimensionSets.push({
                type: 'extrudeLocally',
                id: `room-${room.id}-wall-point-${point.id}-locally`,
                alpha,
                targets: [
                  {position: lib.object.sum(point, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-from`},
                  {position: lib.object.sum(nextPoint, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-to`}
                ]
              });
            }
          }
          else if (intrusionSibling && intrusionSibling.distance < (isHorizontal ? 15 : 30)) {
            //HINT narrow intrusions can still be dimensioned inside the intrusion, but are a bit fragile
            //HINT different for vertical and horizontal intrusions because dim lines overlap differently

            var lineIsHorizontal = _.includes([0, 2, 4], Math.round(lib.trig.normalize({radians: alpha}) / Math.PI * 2));

            dimensionSets.push({
              type: 'standalone',
              id: `room-${room.id}-wall-point-${point.id}-locally`,
              alpha: alpha + Math.PI,
              showLabelBorder: !equivalentDistances,
              offset: (intrusionSibling.distance / 2) + (equivalentDistances ? 0 : -0.5),
              targets: [
                //TODO offsets should consider differences in size and alpha
                //currently sometimes overlaps
                {yOffset: equivalentDistances ? 0 : (lineIsHorizontal ? 5 : 2), position: lib.object.sum(point, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-from`},
                {yOffset: equivalentDistances ? 0 : (lineIsHorizontal ? 5 : 2), position: lib.object.sum(nextPoint, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-to`}
              ]
            });
          }
          else {
            //HINT wide intrusion, safe to dim locally outside
            dimensionSets.push({
              type: 'extrudeLocally',
              id: `room-${room.id}-wall-point-${point.id}-locally`,
              alpha: alpha + Math.PI,
              targets: [
                {position: lib.object.sum(point, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-from`},
                {position: lib.object.sum(nextPoint, room.plan.position), id: `room-${room.id}-wall-point-${point.id}-locally-to`}
              ]
            });
          }
        }
      }
    }
  });
}
