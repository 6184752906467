import _ from 'lodash';
import lib from 'lib';

import Wall from 'project-helpers/wall';
// import Elevation from './elevation';
import Container from 'project-helpers/container/index';

var CountertopHelper = {
  getCountertopMaskingData({container, siblings, wallSets}) {
    var c1 = container;
    var isMasked = false;
    var relevantSiblings = _.filter(siblings, c2 => Container.verticallyOverlapsWith({sourceContainer: container, container: c2}));

    var otherPathsInRoomData = [
      ..._.map(relevantSiblings, c2 => ({
        isContainer: _.includes(['countertop', 'horizontalBarblock'], c2.type) && c1.type === 'countertop',
        siblingType: 'container',
        siblingLines: _.values(Container.getFootprintLines({container: c2})),
        pathInRoom: Container.getFootprintInRoom({container: c2})
      })),
      ..._.map(wallSets, wallSet => ({
        isContainer: false,
        siblingType: 'wall',
        pathInRoom: _.map(_.map(wallSet.walls, wall => Wall.getLine({wall}).inRoom), 'from')
      }))
    ];

    var footprintInRoom = Container.getFootprintInRoom({container: c1});
    var countertopLines = _.values(Container.getFootprintLines({container: c1}));
    var pathInRoom = footprintInRoom;
    var pathDifference;

    _.forEach(otherPathsInRoomData, data => {
      if (data.pathInRoom.length > 0 && lib.math.polygon.polygonsOverlap(pathInRoom, data.pathInRoom)) {
        if (data.isContainer) {
          var {siblingLines} = data;
          var intersectionPoints = [];

          _.forEach(countertopLines, line => {
            var intersectionFound = false;

            _.forEach(siblingLines, l => {
              if (!intersectionFound && (lib.math.linesIntersect({l1: line, l2: l}) || (false
                // lib.trig.isOnLine({point: lib.math.intersectionPoint({l1: l, l2: line}), line: line}) &&
                // lib.trig.isOnLine({point: lib.math.intersectionPoint({l1: l, l2: line}), line: l})
              ))) {
                intersectionPoints.push(lib.math.intersectionPoint({l1: l, l2: line}));

                intersectionFound = true;
              }
            });
          });

          if (intersectionPoints.length === 2 && !_.some(relevantSiblings, c => c.type === 'horizontalBarblock')) {
            isMasked = true;

            // var [from, to] = intersectionPoints;
            // var alpha = lib.trig.alpha({p1: from, p2: to});

            //TODO this is trying to draw an angled seam line, but isn't always accurate
            // var intersectionLine = {from, to};
            // var extendedIntersectionLine = lib.trig.extend({line: intersectionLine});
            // var normalLine = _.mapValues(extendedIntersectionLine, point => lib.trig.rotate({point, byRadians: -alpha}));
            // var transformedNormalLine = _.mapValues(normalLine, point => ({...point, y: point.y + 10000}));
            // var normalIntersectionPolygon = [normalLine.from, normalLine.to, transformedNormalLine.to, transformedNormalLine.from];
            // var intersectionPolygon = _.map(normalIntersectionPolygon, point => lib.trig.rotate({point, byRadians: alpha}));

            pathDifference = lib.polygon.difference({p1: footprintInRoom, p2: data.pathInRoom});//intersectionPolygon});
            // pathDifference = lib.polygon.difference({p1: footprintInRoom, p2: intersectionPolygon});

            if (pathDifference.length) pathInRoom = pathDifference;
          }
        }
        else {
          isMasked = true;

          if (data.siblingType === 'container') {
            pathDifference = lib.polygon.difference({p1: pathInRoom, p2: data.pathInRoom});

            if (pathDifference.length) pathInRoom = pathDifference;
          }
          else {
            pathDifference = lib.polygon.difference({p1: pathInRoom, p2: data.pathInRoom});

            if (pathDifference.length) pathInRoom = pathDifference;
          }
        }
      }
    });

    var {rotation, position} = container;

    var normalizePathFor = ({points}) => {
      return _.map(points, point => {
        var pointInContainer = lib.object.difference(point, {x: position.x, y: position.z});
        var normalizedPointInContainer = lib.trig.rotate({point: pointInContainer, byDegrees: -rotation});

        return normalizedPointInContainer;
      });
    };

    var pathInContainer = normalizePathFor({points: pathInRoom});

    return {isMasked, pathInRoom, pathInContainer, footprintInRoom: normalizePathFor({points: footprintInRoom})};
  },
};

export default CountertopHelper;
