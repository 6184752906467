import _ from 'lodash';
import CFG from 'k';
import lib from 'lib';
import seedrandom from 'seedrandom';
import DetailsHelper from 'helpers/details-helper';

import Product from 'project-helpers/product';
import ArchElement from 'project-helpers/arch-element';
import Elevation from 'project-helpers/elevation';
import Wall from 'project-helpers/wall';
import Container from 'project-helpers/container/index';
import Opencase from 'project-helpers/product-helpers/opencase';
import K from 'k';

var CanvasProductHelper = {
  getMetaProps({product, overrideSideKey, viewKey, elevation, room, showRevealSymbols, showUnitNumbers, renderForDrawings, showGrainFlow, showUnitLabels, isProjection, activeFillMode, activeDetailLevel, hatchFillData, isSelected}) {
    var {container, companyKey, models, productType, appliances, productOptionInstances, parentProduct, dependencies} = Product.get(['container', 'companyKey', 'models', 'productType', 'appliances', 'productOptionInstances', 'parentProduct', 'dependencies'], {product});
    const sideKey = overrideSideKey || Product.getSideKey({product, elevation, viewKey});
    var hasAppliedBrass =  (
      _.get(product, 'customData.productOptions.80.enabled') === 1 ||
      _.get(product, 'customData.productOptions.81.enabled') === 1 ||
      _.get(product, 'customData.productOptions.82.enabled') === 1 ||
      _.get(product, 'customData.productOptions.83.enabled') === 1
    );

    //HINT used for shouldShowCenterline
    if (elevation) {
      var {archElements, unmanagedProducts, containers} = Elevation.get(['archElements', 'unmanagedProducts', 'containers'], {elevation});
    }

    var metaProps = {
      companyKey,
      sideKey,
      dimensions: product.dimensions,
      productInstanceId: product.id,
      getRotation: () => container.rotation,
      getActiveFillMode: () => activeFillMode,
      getSinkData: () => {
        var countertopContainer = Product.getCountertopContainer({product});

        var sinkData = {};

        if (countertopContainer) {
          // countertopContainer.considerConstrainingEditableProps();

          var subcounterHeight = Container.getSubcounterHeight({container});

          if (companyKey === 'hb' && container.type === 'vanity') {
            subcounterHeight = _.get(container, 'customData.hasExpressedBox') ? 0.75 : 0.25;
          }

          sinkData = {
            ...countertopContainer.customData.sinks[product.id],
            // zIndex: Container.getZIndex({container: countertopContainer, elevation, viewKey}) + 1,
            product,
            faucetType: _.get(product, 'customData.faucetType'),
            countertopHeight: _.get(countertopContainer, 'dimensions.height'),
            subCountertopHeight: subcounterHeight,
          };
        }

        return sinkData;
      },
      getOpenUnitProductionMessage: () => {
        var productionMessage = '';
        var heightString;

        var subcounterHeight = Container.getSubcounterHeight({container});

        if (companyKey === 'vp' && subcounterHeight === 0.5) {
          if (product.productId === 762) {
            var applianceHeight = _.get(product, 'appliancesData[0].customHeight') || _.get(product, 'appliancesData[0].dimensions.height') || 14;

            //HINT appliance height + surrounding panels + subcounter - spacer
            var bayHeight = applianceHeight + (3 / 4 * 2);

            heightString = lib.number.toFraction(bayHeight, {normalscript: true});
          }
          else if (_.includes([1355, 901, 938, 691, 1516], product.productId)) {
            //HINT overall height + subcounter - spacer
            heightString = lib.number.toFraction(product.dimensions.height + subcounterHeight - 1 / 8, {normalscript: true});
          }

          productionMessage = ['Box', 'Height', `${heightString}"`, '+', '1/8"', 'spacer'];
        }

        return productionMessage;
      },
      getPowerPackHoodProductionMessage: () => {
        var appliancesData = metaProps.getApplianceData();
        var vendor = _.get(appliancesData, '[0].vendor', '');
        var customVendor = _.get(appliancesData, '[0].customVendor', '');

        var productionMessage = _.includes(['miele'], vendor) || _.lowerCase(customVendor) === 'miele'
          ? 'No Cover Panel'
          : '';

        return productionMessage;
      },
      getApplianceData: () => Product.getAppliancesDisplayData({product}),
      getOutletPullCanBeCentered: () => Product.getOutletPullCanBeCentered({product}),
      getPullData: (prefix='') => {
        var pullData = {};
        var pullType = product.details[`${prefix}pullType`] || product.details[`pullType`];
        var pullLocation = product.details[`${prefix}pullLocation`] || product.details[`pullLocation`] || {id: 'centered'};
        var pullOrientation = product.details[`${prefix}pullOrientation`] || product.details[`pullOrientation`] || {id: 'horizontal'};
        var leafDoorsCentered = product.details[`${prefix}leafDoorsCentered`] || product.details[`leafDoorsCentered`];
        //HINT used for tall units to center pulls off the floor
        var tallUnitPullHeightOffFloor, kickHeight;

        //HINT only used for drawers with i pulls
        var yPullLocation;

        if (companyKey === 'hb' && pullType && pullType.id === 17) {
          yPullLocation = product.details[`${prefix}yPullLocation`] || product.details[`yPullLocation`];
        }

        if (container.type === 'tall') {
          var roomPullAlignment = _.get(room, 'customData.pullAlignment');

          if (roomPullAlignment) {
            kickHeight = Container.getKickHeight({container});
            tallUnitPullHeightOffFloor = roomPullAlignment;
          }
        }

        if (pullType) {
          pullData = {companyKey, pullId: pullType.id, pullDimensions: CFG[companyKey].pullDimensions[pullType.id]};

          if (pullType && pullLocation) {
            var isFlush = false;
            var shape;

            if (companyKey === 'hb') {
              var pull = dependencies.pulls.byId[pullType.id];

              if (pull) isFlush = pull.isMortised;

              if (_.includes([18, 21, 22, 23, 25], pullType.id)) { //HINT pulls by owner, no pull, touch latch, or scooped bottom,
                shape = 'none';
              }
              else {
                shape = _.includes([6, 16], pullType.id) ? 'circle' : 'rect'; //TODO pullOrientation: vertical for staples
              }
            }
            else {
              if (_.includes([6, 7, 9], pullType.id)) { //HINT no pull option, pulls by others, or touch latch
                shape = 'none';
              }
              else {
                shape = _.includes([1, 2, 8], pullType.id) ? 'rect' : 'circle';
              }
            }

            pullData = {
              ...pullData, leafDoorsCentered: _.get(leafDoorsCentered, 'id') === 'centered',
              location: pullLocation.id,
              yPullLocation: _.get(yPullLocation, 'id'),
              customOffset: {
                x: _.toNumber(_.get(pullLocation, 'settings.offsetX', 0)),
                y: _.toNumber(_.get(pullLocation, 'settings.offsetY', 0))
              }, kickHeight, tallUnitPullHeightOffFloor,
              isFlush, shape, pullOrientation: _.get(pullOrientation, 'id')
            };
          }
        }

        return pullData;
      },
      getProductionId: () => {
        var id = '';

        if (showUnitNumbers && !isProjection) id = product.productionId || '';

        return id;
      },
      getLabel: () => {
        var label = '';

        if (showUnitLabels) {
          if (product.customData.planLabel) {
            label = (product.customData.planLabel || '').toLowerCase();

          }
          else {
            label = (productType.shortTitle || '').toLowerCase();

            if (Product.getHasSink({product})) label = 'sink';

            _.forEach(product.appliancesData, applianceData => {
              var appliance = _.find(appliances, {id: applianceData.id});

              var modelNumber = appliance ? appliance.modelNumber : applianceData.modelNumber;

              if (modelNumber) {
                label += ` ${modelNumber}`;
              }
            });
          }
        }

        return _.trim(label);
      },
      getFrontPanelType: (prefix='') => {
        var baseDetailKey = 'frontPanelType';
        var prefixedDetailKey = `${prefix}frontPanelType`;
        var frontPanelType = 'flat';

        if (Product.getIsApplianceStackComponent({product})) {
          frontPanelType = _.get(parentProduct, 'details.frontPanelType.id', 'flat');
        }
        else {
          var frontPanelTypeDetail = product.details[prefixedDetailKey] || product.details[baseDetailKey];

          frontPanelType = _.get(frontPanelTypeDetail, 'id', 'flat');
        }

        return frontPanelType;
      },
      getDoorAction: () => product.customData.doorAction,
      getOrientation: () => product.customData.orientation,
      getTopShelfOffset: () => _.toNumber(product.customData.topShelfOffset),
      getMiddleShelfOffset: () => _.toNumber(product.customData.middleShelfOffset),
      getLowerMiddleShelfOffset: () => _.toNumber(product.customData.lowerMiddleShelfOffset),
      getBottomShelfOffset: () => _.toNumber(product.customData.bottomShelfOffset),
      getUnderOvenShelfHeights: () => Product.getUnderOvenShelfHeights({product}),
      getStackData: () => Product.getStackData({product}),
      getLabelLeft: () => {
        var labelOffset = 2;
        var labelPosition = product.customData.productionIdPosition;
        var isCornerUnitOrientedToRight = _.includes(productType.title, 'corner') && _.get(product, 'customData.orientation', 'left') === 'right';
        var isCornerUnitDoorActionRight = _.includes([1359, 991, 181, 42], product.productId) && _.get(product, 'customData.doorAction', 'left') === 'right';
        var isLeafDoorWithRightDoorAndPullBottom = _.includes(['tall', 'wall', 'cornerCounterTransition'], container.type) && productType.hasDoorActionOption && product.dimensions.height <= 50 && product.customData.doorAction === 'right';
        var defaultPositionToRight = isCornerUnitOrientedToRight || isCornerUnitDoorActionRight || isLeafDoorWithRightDoorAndPullBottom;

        if (!labelPosition) labelPosition = defaultPositionToRight ? 'bottomRight' : 'bottomLeft';

        if (_.includes(['topRight', 'bottomRight'], labelPosition)) {
          labelOffset = '100% - 2';
        }

        if (product.productId === 1161 && product.dimensions.height <= 10) {
          labelOffset = _.includes(['topRight', 'bottomRight'], labelPosition) ? '100% - 6' : 8;
        }

        if (_.includes([1479, 1480, 1521], product.productId)) {
          if (!product.customData.productionIdPosition) labelPosition = 'center';

          labelOffset = {
            bottomRight: '100% + 2',
            bottomLeft: -2,
            center: '50%'
          }[labelPosition];
        }

        return labelOffset;
      },
      getLabelTop: () => {
        var labelPosition = product.customData.productionIdPosition;
        var isCornerUnitOrientedToRight = _.includes(productType.title, 'corner') && _.get(product, 'customData.orientation', 'left') === 'right';
        var isCornerUnitDoorActionRight = _.includes([1359, 991, 181, 42], product.productId) && _.get(product, 'customData.doorAction', 'left') === 'right';
        var isLeafDoorWithRightDoorAndPullBottom = _.includes(['tall', 'wall', 'cornerCounterTransition'], container.type) && productType.hasDoorActionOption && product.dimensions.height <= 50 && product.customData.doorAction === 'right';
        var defaultPositionToRight = isCornerUnitOrientedToRight || isCornerUnitDoorActionRight || isLeafDoorWithRightDoorAndPullBottom;

        if (!labelPosition) labelPosition = defaultPositionToRight ? 'bottomRight' : 'bottomLeft';

        return _.includes(['topLeft', 'topRight'], labelPosition) ? '2' : '100% - 1.25'
      },
      getLabelOrigin: () => {
        var labelOrigin = {x: 'left', y: 'bottom'};
        var labelPosition = product.customData.productionIdPosition;
        var isCornerUnitOrientedToRight = _.includes(productType.title, 'corner') && _.get(product, 'customData.orientation', 'left') === 'right';
        var isCornerUnitDoorActionRight = _.includes([1359, 991, 181, 42], product.productId) && _.get(product, 'customData.doorAction', 'left') === 'right';
        var isLeafDoorWithRightDoorAndPullBottom = _.includes(['tall', 'wall', 'cornerCounterTransition'], container.type) && productType.hasDoorActionOption && product.dimensions.height <= 50 && product.customData.doorAction === 'right';
        var defaultPositionToRight = isCornerUnitOrientedToRight || isCornerUnitDoorActionRight || isLeafDoorWithRightDoorAndPullBottom;


        if (_.includes([1479, 1480, 1521], product.productId)) {
          if (!labelPosition) labelPosition = 'center';

          labelOrigin = {
            bottomRight: {x: 'left', y: 'bottom'},
            bottomLeft: {x: 'right', y: 'bottom'},
            center: {x: 'center', y: 'bottom'}
          }[labelPosition];
        }
        else {
          if (!labelPosition) {
           labelPosition = defaultPositionToRight ? 'bottomRight' : 'bottomLeft';
          }

          if (_.includes(['topRight', 'bottomRight'], labelPosition)) {
            labelOrigin.x = 'right';
          }

          if (_.includes(['topLeft', 'topRight'], labelPosition)) {
            labelOrigin.y = 'top';
          }
        }

        return labelOrigin;
      },
      getShouldShowCenterline: () => {
        let shouldShowCenterline = false;
        const isSection = elevation && Elevation.getIsSection({elevation});

        if (viewKey === 'front' && sideKey === 'front' && elevation && !isSection && !_.includes(['schematic', 'rendering'], activeDetailLevel)) {

          const productCenterX = Product.getPositionInElevation({product, elevation}).x + product.dimensions.width / 2;

          var products = _.reject(unmanagedProducts, product);

          if (!(Product.getHasSink({product}) || Product.getIsCooktop({product}) || Product.getIsHood({product}))) products = _.filter(products, p2 => Product.getHasSink({product}) || Product.getIsCooktop({product: p2}) || Product.getIsHood({product: p2}));

          var alignedWithArchElement = _.some(archElements, archElement => {
            let shouldShow = false;
            const wall = ArchElement.get('wall', {archElement});
            const archElementXInElevation = archElement.position.x + Wall.getXOffsetInElevation({wall, elevation});
            const archElementSideKey = 'front';

            if (archElementSideKey === 'front') {
              shouldShow = _.includes([
                archElementXInElevation,
                archElementXInElevation + ArchElement.getSize({archElement, viewKey}).width / 2,
                archElementXInElevation + ArchElement.getSize({archElement, viewKey}).width
              ], productCenterX);
            }

            return shouldShow;
          });

          //HINT leaving commented out code because its possible the current code is too naive, but much more performant
          var productCenterLineInRoom = Product.getFootprintLines({product, container, includeCenterline: true}).center;
          var alignedWithProduct = _.some(products, p2 => {
            // const p2XInElevation = Product.getPositionInElevation({product: p2, elevation}).x;

            let shouldShow = false;
            // const p2SideKey = Product.getSideKey({elevation, product: p2, viewKey});

            shouldShow = _.some(_.pick(Product.getFootprintLines({product: p2, includeCenterline: true}), ['center', 'left', 'right']), line => {
              return _.some(line, point => {
                return lib.trig.isOnLine({point, line: productCenterLineInRoom});
              });
            });
            // if (p2SideKey === 'front') {
            //   shouldShow = _.includes([
            //     p2XInElevation,
            //     p2XInElevation + p2.dimensions.width / 2,
            //     p2XInElevation + p2.dimensions.width
            //   ], productCenterX);
            // }

            return shouldShow;
          });

          var containerCandidates = _.filter(containers, container => _.includes(['door', 'window', 'baseFreeStandingAppliance', 'wallFreestandingAppliance', 'tallFreestandingAppliance'], container.type) && container.position && !_.isEmpty(container.position))
          var alignedWithContainer = _.some(containerCandidates, c1 => {
            let shouldShow = false;

            shouldShow = _.some(_.pick(Container.getFootprintLines({container: c1, includeCenterline: true}), ['center', 'left', 'right']), line => {
              return _.some(line, point => {
                return lib.trig.isOnLine({point, line: productCenterLineInRoom});
              });
            });

            return shouldShow;
          });

          shouldShowCenterline = alignedWithArchElement || alignedWithProduct || alignedWithContainer;
        }

        return shouldShowCenterline;
      },
      getShouldInvertStroke: ({materialKey}={}) => {
        var shouldInvertStroke;

        if (!materialKey) {
          shouldInvertStroke = hatchFillData.shouldInvertStroke;
        }
        else {
          shouldInvertStroke = _.get(hatchFillData, `shouldInvertStrokeByMaterialKey.${materialKey}`);
        }

        return shouldInvertStroke;
      },
      getHasInternalLighting: () => {
        return _.some(productOptionInstances, productOptionInstance => {
          return productOptionInstance.productOptionId === 78;
        });
      },
      getHasKnifeBlock: () => {
        return _.some(productOptionInstances, productOptionInstance => {
          return productOptionInstance.productOptionId === 18;
        });
      },
      getBayWidths: () => Product.getBayWidths({product}),
      getHBBBottomThickness: () => product.dimensions.height - 4.625 - 0.75,
      getPosition: () => product.position,
      getKickHeight: () => Container.getKickHeight({container}),
      getTheme: () => 'light', //this.props.project.theme || 'light',
      getDrawingsMode: () => renderForDrawings ? global.drawingsMode : undefined,
      modelerModels: _.mapValues(_.keyBy(_.filter(models, {type: 'component'}), 'key'), model => {
        return _.mapValues(model.sides, side => {
          var script = '';

          try {
            script = side.script;//babel.transform(side.script).code;
          }
          catch (e) {
            console.log(e); // eslint-disable-line
          }

          return script;
        });
      }),
      getResourceProps: () => product,
      getContainer: () => container,
      getGrainData: () => CanvasProductHelper.getGrainData({product, productType, viewKey, showGrainFlow, hasAppliedBrass}),
      getGrainScript: ({grainGroups}={}) => CanvasProductHelper.getGrainScript({showGrainFlow, product, productType, overrideSideKey, elevation, viewKey, companyKey, dependencies, grainGroups}),
      getRevealsData: () => CanvasProductHelper.getRevealsData({product, showRevealSymbols}),
      getRevealsScript: () => CanvasProductHelper.getRevealsScript({product}),
      getFlybySizes: () => Product.getFlybySizes({product, viewKey}),
      getWrapSizes: () => Product.getWrapSizes({product}),
      getActiveDetailLevel: () => activeDetailLevel,
      getVisibilityLayers: () => ({...global.visibilityLayers, actions: _.includes(['production', 'fullDetail'], activeDetailLevel)}),
      defaultFontSize: CFG.defaultFontSize,
      getProductOptionsLabel: () => {
        var productOptionsLabel = [];
        _.forEach(productOptionInstances, productOptionInstance => {
          if (_.includes([63, 65, 76], productOptionInstance.productOptionId)) {
            var productOption = dependencies.productOptionTypes.byId[productOptionInstance.productOptionId];
            if (productOption) {
              var label = productOption.plural ? `${productOptionInstance.quantity}x ${productOption.proposalAlias}` : productOption.proposalAlias;
              productOptionsLabel.push(label);
            }
          }
          else if (productOptionInstance.productOptionId === 105) {
            productOptionsLabel.push('BKP');
          }
          else if (container.type !== 'vanity' && productOptionInstance.productOptionId === 1) {
            productOptionsLabel.push('WD');
          }
        });

        return _.join(productOptionsLabel, '\n');
      },
      getProductTitle: () => {
        var productTitle = '';

        if (global.visibilityLayers.productTitles) {
          return productType.proposalAlias || productType.title;
        }

        return productTitle;
      },
      getRandomNumberFromSeed: ({productInstanceId, subproductIndex, componentIndex}) => {
        var randomNumberFromSeed = seedrandom(productInstanceId * 1000 + (subproductIndex + componentIndex)).quick();

        return randomNumberFromSeed;
      },
      getShouldShowColorVariation: () => {
        return (activeFillMode === 'materialColors' && (activeDetailLevel === 'rendering' || hasAppliedBrass));
      },
      getHasAppliedBrass: () => {
        return hasAppliedBrass;
      }
    };

    if (Product.getIsAnyOpencasePanel({product})) {
      metaProps.getMargin = () => Opencase.getMargin({product});
      metaProps.getCellCount = () => Opencase.getCellCounts({product});
      metaProps.getHideUnusedFittings = () => !(isSelected && !isProjection);//isProjection || renderForDrawings;
      metaProps.getActiveFittingOpacity = () => 1;
      metaProps.getActiveFittingPositions = () => Opencase.getActiveFittings({product});
    }

    metaProps.getCustomData = () => product.customData;
    return metaProps;
  },

  getGrainData({product, productType, viewKey, showGrainFlow, hasAppliedBrass}) {
    var {grainDirection, grainContinuity} = product.details;
    var isShowing = showGrainFlow && viewKey === 'front' && _.get(grainDirection, 'id', 'vertical') !== 'hidden' && !hasAppliedBrass;
    var direction = _.get(grainDirection, 'id', 'vertical');
    var continuity = _.get(grainContinuity, 'id', 'none');

    return {isShowing, direction, continuity};
  },

  getGrainScript({showGrainFlow, product, productType, overrideSideKey, elevation, viewKey, companyKey, dependencies, grainGroups = [{}]}) {
    if (!showGrainFlow) return 'group({}, [])';

    var productOwnedCompatibleDetals = Product.getOwnedCompatibleDetails({product});

    var ownedProductDetailValues = {};

    _.forEach(productOwnedCompatibleDetals, (productOwnedCompatibleDetail) => {
      var key = productOwnedCompatibleDetail.key;
      ownedProductDetailValues[key] = _.get(product, `details.${key}`) || _.get(productOwnedCompatibleDetail, 'options[0]');
    });

    var frontMaterialData = _.get(ownedProductDetailValues, 'frontMaterial') || _.get(ownedProductDetailValues, 'panelMaterial');

    var exposedBoxProductIds = [
      37, 691, 85, 699, 120, 694, 119, 174, 175, 183, 1148, 1610,
      262, 404, //valets
      960, 1400, 1486, 611, 612, 176, 987, 992, //open units
      977, 160, //sliding glass
      //assemblies?
      1188, //opencase trim cap, not sure why this is using box materialKey,
      986, 1581, 1519, 1587, 901, 938, 960, 961, 985, 986, 1355,//ST bookcases
      1564,//ST shelfbank shelf
      1643, 1663, //ST functional wood boxes
      1407,1524, 1525 //vbb spice drawer, using box material key
    ];

    if (_.includes(exposedBoxProductIds, product.productId)) {
      frontMaterialData = _.get(ownedProductDetailValues, 'boxMaterial');
    }

    var sideKey = overrideSideKey || Product.getSideKey({product, elevation, viewKey});

    var frontMaterial = _.find(_.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials), {id: _.get(frontMaterialData, 'id')});

    var isNewApplianceFrame = _.includes(CFG[companyKey].ids.tallCustomApplianceFrameProductIds, product.productId) && product.id >= 1014316;
    var isSculptedPanel = _.includes(CFG[companyKey].ids.sculptedPanels, product.productId);
    var isShelf = _.includes([21], productType.categoryId);
    var isProductWithoutGrainflow = _.includes([1533, 1102, 517, 525, 1661], product.productId);
    var isHiddenPanel = _.includes(CFG[companyKey].ids.hiddenPanels, product.productId);
    var isHorizontalBarblock = Product.getIsHorizontalBarblock({product});
    var isHorizontalBarblockOrComponent = Product.getIsHorizontalBarblockComponent({product}) || isHorizontalBarblock;

    var isCornerUnitOrientedToLeft = ! _.includes([1359, 991, 181, 42], product.productId) && _.includes(productType.title, 'corner') && _.get(product, 'customData.orientation', 'left') === 'left';
    var isCornerUnitDoorActionLeft = _.includes([1359, 991, 181, 42], product.productId) && _.get(product, 'customData.doorAction', 'left') === 'left';

    var shouldHideBasedOnProduct =  isSculptedPanel || isShelf || isProductWithoutGrainflow || isNewApplianceFrame || isHiddenPanel;

    return shouldHideBasedOnProduct ? 'group({}, [])' :
      //only show grain direction for wood fronts
      isHorizontalBarblockOrComponent || (frontMaterialData && frontMaterial && frontMaterialData.id !== 331 && (_.includes([
        191, 194, 1, 158, 166, 176, 103, 104, 105, 239, 1, 3, 4, 5, 6, 8,
        135, 151, 152, 153, 154, 155, 170, 171, 172, 173, 174,
        175, 176, 177, 178, 180, 279, 303, 8, 9, 136, 143, 144, 145, 146, 147, 148, 149, 150, 158,
        160, 161, 162, 163, 164, 165, 166, 167, 168, 179, 191, 192, 193, 194, 240, 249, 250, 251,
        262, 304, 316, 317, 319, 320, 321, 325, 329, 330, 332, 337, 346, 349, 339, 350, 351, 352, 353, 358, 368, 369, 370, 405, 410, 412, 413, 431
      ], frontMaterial.id) || _.includes([1, 3, 12], frontMaterial.materialTypeId))) ? `
      (() => {
        var grainGroups = ${JSON.stringify(grainGroups)};
        var lineGroups = [];
        var arrowWidth = 3;
        var useDepth = ${['left', 'right'].includes(sideKey)};

        lodash.forEach(grainGroups, (groupProps, groupIndex) => {
          var {height: groupHeight, top, grainDirection, isFramed} = groupProps;
          var {direction, continuity, isShowing} = _.getGrainData ? _.getGrainData() : {};
          var {width, height} = _.dimensions;
          if (grainDirection) direction = grainDirection;

          //HINT hbb has vertical grain on the sides, horizontal everywhere else
          if (${isHorizontalBarblock} && useDepth) direction = 'vertical';
          else if (${isHorizontalBarblockOrComponent}) direction = 'horizontal';

          if (useDepth) {
            var wrapPanelWidths = _.getWrapPanelWidths ? _.getWrapPanelWidths() : {};
            width = wrapPanelWidths['${sideKey}'] || _.dimensions.depth;
          }

          var shouldPositionCenter = direction === 'vertical' ? (width <= arrowWidth * 2) : (height <= arrowWidth * 2);

          if (shouldPositionCenter) {
            arrowWidth = Math.min(width / 2, height / 2);
          }

          var pos = {
            flush: {from: arrowWidth / 2, to: '100%'.concat('-', arrowWidth / 2)},
            inset: {from: arrowWidth, to: '100%'.concat('-', arrowWidth)}
          };

          var hasNoneOrToContinuty = lodash.includes(['none', 'to'], continuity) || groupIndex > 0;
          var hasNoneOrFromContinuty = lodash.includes(['none', 'from'], continuity) || (groupIndex < grainGroups.length - 1);
          var offset = arrowWidth / 2;
          var lines = [];

          var shouldPositionLeft = direction === 'vertical' && ${isCornerUnitOrientedToLeft || isCornerUnitDoorActionLeft};

          if (shouldPositionLeft) {
            pos.inset.to = '' + arrowWidth;
          }

          if (isShowing && direction !== 'none') {
            var mainLineProps = direction === 'vertical' ?
              {
              x1: pos.inset.to, x2: pos.inset.to,
              y1: hasNoneOrToContinuty ? pos.flush.from : 0, y2: hasNoneOrFromContinuty ? pos.flush.to : '100%',
              stroke: 'red'
            } :
            {
              x1: hasNoneOrFromContinuty ? pos.flush.to : '100%', x2: hasNoneOrToContinuty ? pos.flush.from : 0,
              y1: pos.inset.from, y2: pos.inset.from,
              stroke: 'red'
            };

            if (hasNoneOrFromContinuty) {
              if (direction === 'vertical') {
                lines.push(line({
                  x1: pos.inset.to,
                  x2: shouldPositionLeft ? arrowWidth * 3/2 : pos.flush.to,
                  y1: pos.flush.to,
                  y2: shouldPositionLeft ? '100%'.concat('-', arrowWidth) : pos.inset.to,
                  stroke: 'red'
                }));
              }
              else {
                lines.push(line({
                  x1: pos.flush.to,
                  x2: pos.inset.to,
                  y1: pos.inset.from,
                  y2: pos.flush.from,
                  stroke: 'red'
                }));
              }
            }

            if (hasNoneOrToContinuty) {
              if (direction === 'vertical') {
                lines.push(line({
                  x1: pos.inset.to,
                  x2: pos.inset.to.concat('-', offset),
                  y1: pos.flush.from,
                  y2: pos.inset.from,
                  stroke: 'red'}));
              }
              else {
                lines.push(line({
                  x1: pos.flush.from,
                  x2: pos.inset.from,
                  y1: pos.flush.from + offset,
                  y2: pos.inset.from + offset,
                  stroke: 'red'
                }));
              }
            }

            if (direction === 'vertical' && isFramed) {
              lodash.forEach(lines, ({nodeProps}, lineIndex) => {
                lodash.forEach(nodeProps, (value, key) => {
                  if (lodash.includes(['x1', 'x2'], key)) {
                    lodash.isString(value)
                      ? nodeProps[key] = value.concat('-2')
                      : nodeProps[key] = value - 2;
                  }

                  if (lodash.includes(['y1', 'y2'], key)) {
                    lodash.isString(value)
                      ? nodeProps[key] = value.concat((lineIndex === 0 ? '-2' : '+2'))
                      : nodeProps[key] = value + (lineIndex === 0 ? '-2' : '+2');
                  }
                })
              })

              lodash.forEach(mainLineProps, (value, key) => {
                if (lodash.includes(['x1', 'x2'], key)) {
                  lodash.isString(value)
                    ? mainLineProps[key] = value.concat('-2')
                    : mainLineProps[key] = value - 2;
                }

                if (lodash.includes(['y1'], key)) {
                  lodash.isString(value)
                    ? mainLineProps[key] = value.concat('+2')
                    : mainLineProps[key] = value + 2;
                }

                if (lodash.includes(['y2'], key)) {
                  lodash.isString(value)
                    ? mainLineProps[key] = value.concat('-2')
                    : mainLineProps[key] = value - 2;
                }
              })
            }

            if (direction === 'horizontal' && isFramed) {
              lodash.forEach(lines, ({nodeProps}, lineIndex) => {
                lodash.forEach(nodeProps, (value, key) => {
                  if (lodash.includes(['x1', 'x2'], key)) {
                    lodash.isString(value)
                      ? nodeProps[key] = value.concat(lineIndex === 0 ? '- 2' : '2')
                      : nodeProps[key] = value + (lineIndex === 0 ? -2 : 2);
                  }

                  if (lodash.includes(['y1', 'y2'], key)) {
                    lodash.isString(value)
                      ? nodeProps[key] = value.concat('+ 2')
                      : nodeProps[key] = value + 2;
                  }
                })
              })

              lodash.forEach(mainLineProps, (value, key) => {
                if (lodash.includes(['x1'], key)) {
                  lodash.isString(value)
                    ? mainLineProps[key] = value.concat('-2')
                    : mainLineProps[key] = value - 2;
                }

                if (lodash.includes(['x2'], key)) {
                  lodash.isString(value)
                    ? mainLineProps[key] = value.concat('2')
                    : mainLineProps[key] = value + 2;
                }

                if (lodash.includes(['y1', 'y2'], key)) {
                  lodash.isString(value)
                    ? mainLineProps[key] = value.concat('+ 2')
                    : mainLineProps[key] = value + 2;
                }
              })
            }

            lines.push(line(mainLineProps));
          }

          lineGroups.push(group({top: top || 0, height: groupHeight || '100%'}, lines));
        });

        return group({}, lineGroups);
      })()
    ` : 'group({}, [])';
  },

  getRevealsData({product, showRevealSymbols}) {
    //TODO is 1 render behind
    var isShowing = showRevealSymbols;

    return {isShowing, reveals: _.get(product, 'customData.reveals', {})};
  },

  getRevealsScript({product}) {
    return `
      (() => {
        var {reveals={}, isShowing} = _.getRevealsData ? _.getRevealsData() : {};
        var {shape, location} = _.getPullData ? _.getPullData() : {};
        var customData = _.getCustomData ? _.getCustomData() : {};
        var revealSymbols = [];
        var topRevealOffset = '- 25%';
        var bottomRevealOffset = '+ 25%';
        var shouldOffsetTopReveal = !${_.includes([1345, 1347, 1349, 1351, 138, 144, 1301], product.productId)} && !(shape === 'none' || (_.dimensions.width < 15 && reveals.top === 'flyBy'));
        var shouldOffsetBottomReveal = !${_.includes([1345, 1347, 1349, 1351, 138, 144, 1301], product.productId)} &&  !(shape === 'none' || _.dimensions.width < 15 && reveals.bottom === 'flyBy');
        var productWidth = ${product.dimensions.width};

        if (location === 'offsetRight') {
          bottomRevealOffset = '';
        }
        else if (location === 'offsetLeft' || (customData.outletSide === 'left' && customData.alignPullWithLowerDrawers)) {
          topRevealOffset = '+ 25%'
        }

        if (!shouldOffsetTopReveal) topRevealOffset = '';
        if (!shouldOffsetBottomReveal) bottomRevealOffset = '';

        if (isShowing) {
          var symbolRadius = 1;
          var symbolOffset = 0.5;
          var propsForSideKeys = {
            left: {
              top: '50% - ' + symbolRadius,
              left: symbolOffset + symbolRadius
            },
            right: {
              top: '50% -' + symbolRadius,
              left: '100% - ' + symbolOffset + ' - ' + symbolRadius
            },
            top: {
              top: symbolOffset + symbolRadius,
              left: '50%' + topRevealOffset
            },
            bottom: {
              top: '100% - ' + symbolOffset + ' - ' + symbolRadius,
              left: '50%' + bottomRevealOffset
            }
          };

          var textPropsForSideKeys = lodash.mapValues(propsForSideKeys, (propsForSideKey, sideKey) => {
            var origin = {
              x: lodash.includes(['top', 'bottom'], sideKey) ? 'center' : sideKey,
              y: lodash.includes(['left', 'right'], sideKey) ? 'center' : sideKey
            };

            return {
              left: propsForSideKey.left, // + (lodash.includes(['top', 'bottom'], sideKey) ? '' : sideKey === 'left' ? '+2' : '-2'),
              top: propsForSideKey.top, // + (lodash.includes(['left', 'right'], sideKey) ? '' : sideKey === 'top' ? '+1' : '-1'),
              origin,
              zIndex: 1000000,
              fontSize: 11/3,
              fill: 'red',
              stroke: ''
            }
          });

          lodash.forEach(['left', 'right', 'top', 'bottom'], sideKey => {
            var revealSymbol = reveals[sideKey];
            var sideProps = propsForSideKeys[sideKey];
            var textSideProps = textPropsForSideKeys[sideKey];

            if (productWidth <= 12 && revealSymbol === 'flyBy') {
              if (sideKey === 'right') {
                textSideProps.left = productWidth + 5;
              }
              if (sideKey === 'left') {
                textSideProps.left = -5;
              }
            };

            if (revealSymbol === 'oneEighthReveal') {
              revealSymbols.push(...[
                circle({
                  radius: symbolRadius,
                  fill: 'red',
                  stroke: 'red',
                  isFillable: true,
                  zIndex: 1000000,
                  opacity: 1,
                  ...sideProps
                }),
                // circle({
                //   radius: symbolRadius - .7,
                //   fill: 'white',
                //   stroke: 'white',
                //   isFillable: true,
                //   zIndex: 1000001,
                //   opacity: 1,
                //   ...sideProps
                // }),
              ]);
            }
            else if (revealSymbol === 'fullOverlay') {
              revealSymbols.push(
                circle({
                  radius: symbolRadius,
                  fill: 'white',
                  stroke: 'red',
                  isFillable: true,
                  zIndex: 1000000,
                  opacity: 1,
                  ...sideProps
                }));
            }
            else if (revealSymbol === 'flyBy') {
              revealSymbols.push(...[
                // circle({
                //   radius: symbolRadius,
                //   stroke: 'red',
                //   zIndex: 1000000,
                //   opacity: 1,
                //   ...sideProps
                // }),
                text({
                  text: 'Fly-By',
                  ...textSideProps
                }),
              ]);
            }
          });
        }

        return group({}, revealSymbols);
      })()
    `;
  }
};

export default CanvasProductHelper;
