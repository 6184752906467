import React from 'react';

import Icon from './icon';
import Counter from './counter';

import InfoIcon from '../../../assets/info-icon.png';
import DeleteIcon from '../../../assets/trash-icon-black.png';

function AccessoriesItem(props) {
  var {accessories, handleChangeQuantity, handleDelete, showInfoPopup} = props;

  return (
    <div className='accessories-item'>
      {/* <div className='product-icon'>
        <img src={accessories.thumbnail.url} alt={accessories.title} />
      </div> */}
      <p style={{width: 200}} className='product-title'>{accessories.title}</p>
      <div className='info-icon-container'>
        <Icon
          onClick={() => showInfoPopup({accessoriesId: accessories.id})}
          className="info-icon"
          src={InfoIcon}
          alt="Info Icon"
          style={{fontSize: '20px'}}
        />
      </div>
      <Counter id={accessories.id} count={accessories.quantity} changeQuantity={handleChangeQuantity} />
      <div className='product-price' style={{width: 60}}>{accessories.price}</div>
      <button
        style={{ backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
        onClick={() => handleDelete(accessories.id)}
      >
        <Icon height="20" width="20" src={DeleteIcon} alt="Trash Icon button to delete." />
      </button>
    </div>
  );
}

export default AccessoriesItem;
