import React, {useState, useRef, useEffect, forwardRef, useCallback} from 'react';
import _ from 'lodash';
import lib from 'lib';
import Elevation from 'project-helpers/elevation';
import Drawings from 'project-helpers/drawings';
import Room from 'project-helpers/room';

import CanvasView from 'canvas/canvas-view';
import CanvasElevation from 'project-components/canvas-elevation';

import {Context} from '@symbolic/svgcanvas';

const ConfiguratorGraphic = ({
  //HINT drawings state
  containerDimensions, project, mode, visibilityLayers, activeProjectGraphicsLayer, activeDimensionsLayer, detailLevel, fillMode,
  //HINT graphic data
  id, size, isEmpty, position, scale = 1, type, model, indicators, upperHeightPadding=0, lowerHeightPadding=0, containerSize
}) => {
  const [forceUpdateKey, setForceUpdateKey] = useState(lib.string.uuid());
  const [isLoading, setIsLoading] = useState(false);
  const [renderCanvasViewChildren, setRenderCanvasViewChildren] = useState(true);
  const [isRendered, setIsRendered] = useState(false);
  const [svgString, setSvgString] = useState(null);

  // const canvasSize = isEmpty ? lib.object.multiply(containerDimensions, 1 / 6) : Drawings.getSizeForPrintArea({printArea: {contentSize: size}, scale, project});
  // const positionInPage = Drawings.getPositionInPage({position, size: canvasSize, containerDimensions});

  const styles = {
    canvas: {
      width: `60%`,
      height: `100%`,
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignSelf: 'center'
    },
    wrapper: {
      // position: 'absolute',
      // top: `${positionInPage.top}px`,
      // left: `${positionInPage.left}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      // opacity,
      opacity: 1,
      cursor: 'inherit',
    },
    error: {
      position: 'absolute',
      // top: `${positionInPage.top}px`,
      // left: `${positionInPage.left}px`,
      // width: `${canvasSize.width}px`,
      // height: `${canvasSize.height}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }
  };

  const classNames = [
    // 'graphic-image',
    // // `${type}-${id}`,
    // `${!isLoading ? '' : 'show-loader loader-dark'}`,
  ].join(' ');

  const handleForceUpdate = () => setForceUpdateKey(lib.string.uuid());
  const handleLoadingStart = () => setIsLoading(true);
  const handleLoadingEnd = () => setIsLoading(false);

  let viewOffset = {x: 0, y: 0};
  var size = {width: 0, height: 0};

  if (type === 'room') {
    const {min, max} = Room.getContentOutline({room: model});
    size = {width: max.x - min.x, height: max.y - min.y};

    viewOffset = lib.object.difference({x: -min.x - size.width * 0.5, y: -min.y - size.height * 0.5}, model.plan.position);
  }
  else if (type === 'elevation') {
    const {min, max} = Elevation.getContentOutline({elevation: model, activeDetailLevel: detailLevel});
    size = {width: max.x - min.x, height: max.y - min.y};

    viewOffset = {x: -min.x - size.width * 0.5, y: max.y - size.height * 0.5 + (upperHeightPadding - lowerHeightPadding)};
  }

  var xyScale = {
    //HINT only using 60% of available width for canvas
    width: ((containerSize.width * 0.6) * .8) / (size.width),
    height: (containerSize.height * .8) / (size.height),
  };

  scale = parseFloat(_.min([xyScale.width, xyScale.height]).toFixed(2));

  const projectData = {
    ..._.pick(project, ['dimensionsData', 'companyKey', 'versionId', 'id', 'isEmployee']),
    activeDimensionsLayer,
    isEditingDimensions: false,
    activeProjectGraphicsLayer,
    countertopsAreSelectable: false,
    pushToUndoQueue: null,
    toggleTolerancePopupShowing: false,
    showingAlignmentIndicators: false,
  };

  return (
    <>
      <div
        key={`standard-page-graphic-${id}`}
        style={styles.wrapper}
      >
        <CanvasView
          key={model.id}
          className={classNames}
          style={styles.canvas}
          isStatic
          {...{projectData, scale}}
        >
          <CanvasElevation
            key={id}
            renderForDrawings
            hideTitle
            {...{elevation: model, visibilityLayers, activeProjectGraphicsLayer, viewOffset, activeDimensionsLayer, activeDetailLevel: detailLevel, viewOffset, activeFillMode: fillMode}}
          />
        </CanvasView>
      </div>
    </>
  );
};
export default ConfiguratorGraphic;