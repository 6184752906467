import React, { useContext } from 'react';

import { Path } from 'react-konva';

import CanvasHelper from 'helpers/canvas-helper';
import CanvasDataContext from 'contexts/canvas-data-context';

export default function CanvasPath({...props}) {
  const canvasData = useContext(CanvasDataContext);

  const {points, closed} = props;

  if (!props.data && points?.length > 0) props.data = CanvasHelper.pathDataFrom({points, scale: canvasData.scale, closed});

  return <Path ref={props.innerRef} shadowForStrokeEnabled={false} perfectDrawingEnabled={false} {...props}/>;
}
