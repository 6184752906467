import _ from 'lodash';

export default class BKey {
  constructor(key) {
    this.key = key;

    this.typeMap = {
      side: ['left', 'top', 'right', 'bottom'],
      axis: ['x', 'y'],
      size: ['width', 'height'],
      endpoint: ['from', 'to'],
      index: ['first', 'last']
    };

    _.forEach(this.typeMap, (examples, type) => {
      if (_.includes(examples, this.key)) {
        this.type = type;
        this.analog = examples.indexOf(this.key);
      }
    });

    this.invertedAnalog = this.analog % 2 === 0 ? (this.analog + 1) : (this.analog - 1);
  }

  to({type, invert}) {
    var analog = (invert ? this.invertedAnalog : this.analog) % this.typeMap[type].length;

    return this.typeMap[type][analog];
  }

  toString() {
    return this.key;
  }

  toSide(invert) {
    return this.to({type: 'side', invert});
  }

  toOppositeSide() {
    return this.typeMap.side[(this.analog + 2) % 4];
  }

  toAxis(invert) {
    return this.to({type: 'axis', invert});
  }

  toSize(invert) {
    return this.to({type: 'size', invert});
  }

  toEndpoint(invert) {
    return this.to({type: 'endpoint', invert});
  }

  toIndex(invert) {
    return this.to({type: 'index', invert});
  }

  invert() {
    return this.to({type: this.type, invert: true});
  }

  eq(otherString) {
    return this.toString() === otherString;
  }

  includedIn(array) {
    return _.includes(array, this.toString());
  }

  static forEach(keys, fn) {
    return _.forEach(_.map(keys, key => new BKey(key)), fn);
  }
}

BKey.sides = [new BKey('left'), new BKey('top')];
BKey.axes = [new BKey('x'), new BKey('y')];
BKey.sizes = [new BKey('width'), new BKey('height')];

BKey.toSide = (key) => new BKey(key).toSide();
BKey.toSize = (key) => new BKey(key).toSize();
