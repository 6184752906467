import _ from 'lodash';

import { store } from 'redux/index.js';

export default function getDependencies({dependencyKeys, state}, callback) {
  var isSingular = typeof(dependencyKeys) === 'string';

  var useDependency = (dependencyKey) => isSingular ? dependencyKey === dependencyKeys : _.includes(dependencyKeys, dependencyKey);

  state = state || store.getState();

  var getters = callback({state, useDependency});

  if (isSingular) {
    var dependencyKey = dependencyKeys;

    return getters[dependencyKey]();
  }
  else {
    var dependencies = {};

    _.forEach(dependencyKeys, dependencyKey => {
      dependencies[dependencyKey] = getters[dependencyKey]();
    });

    return dependencies;
  }
}
