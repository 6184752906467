import _ from 'lodash';
import lib from 'lib';
import Elevation from 'project-helpers/elevation';
import Wall from 'project-helpers/wall';
import Product from 'project-helpers/product';
import ArchElement from 'project-helpers/arch-element';
import Container from 'project-helpers/container';
import Volume from 'project-helpers/volume';
import getProcessedDimensionSets from '../getProcessedDimensionSets';
import considerUpdatingSummarizedDimensionEdits from '../considerUpdatingSummarizedDimensionEdits';

import addElevationWallDimensions from './helpers/addElevationWallDimensions';
import addElevationProductDimensions from './helpers/addElevationProductDimensions';
import addElevationContainerDimensions from './helpers/addElevationContainerDimensions';
import addElevationContainerLightingDimensions from './helpers/addElevationContainerLightingDimensions';
import addElevationCountertopDistanceOffFloorDimensions from './helpers/addElevationCountertopDistanceOffFloorDimensions';
import addElevationArchElementsDimensions from './helpers/addElevationArchElementsDimensions';
import addElevationArchElementContainersDimensions from './helpers/addElevationArchElementContainersDimensions';
import addElevationVolumeDimensions from './helpers/addElevationVolumeDimensions';

export default function getElevationDimensionSets({elevation, projectData, canvasData, showProjections, activeDetailLevel, considerSummarizationChange, cachedDimensionSets, resourceActions}) {
  var dimensionSets = [];

  try {
    var {containers, products, archElements, walls, volumes, room, dependencies} = Elevation.get(['containers', 'products', 'archElements', 'walls', 'room', 'volumes', 'dependencies'], {elevation});

    var productTypes = dependencies.productTypes.byId;

    //HINT filter arch element containers out of normal containers
    var archElementContainers = _.filter(containers, container => Container.getTypeDataFor({container}).isArchElement);
    containers = _.filter(containers, container => !Container.getTypeDataFor({container}).isOrnament && !Container.getTypeDataFor({container}).isArchElement);

    var isSection = Elevation.getIsSection({elevation});
    var pointsByWall;

    if (!isSection) {
      var parallelWalls = _.filter(walls, wall => {
        var elevationWallTheta = Elevation.getWallTheta({elevation, wall});

        //HINT radians have rounding issues and need to include the 'back' side of walls
        return _.includes([0, 360], _.round(lib.trig.radiansToDegrees(elevationWallTheta)));
      });

      pointsByWall = _.mapValues(_.keyBy(parallelWalls, 'id'), wall => {
        var outlinePoints = Wall.getOutlinePoints({elevation, wall});

        return _.map(outlinePoints, point => point);
      });
    }

    products = _.reject(products, product => {
      var isManaged = Product.getIsManaged({product}) && _.get(product, 'managedData.managedKey') !== 'autofilledStorage';

      return isManaged;
    });

    // var volumes = []; //TODO represent as rectangles/like ctrs
    // var archElements = []; //TODO represent as rectangles/like ctrs

    var outsideAlphas = [0, Math.PI, Math.PI * 0.5, Math.PI * 1.5];

    var isProjection = false; //TODO
    var projectionY = 0; //TODO
    var minWallX = Elevation.getMinWallX({elevation});

    var entities = [
      ..._.map(_.filter(products, product => !Product.getIsApplianceStackFrame({product})), product => ({key: `product-${product.id}`, type: 'product', product, lines: Product.getWallprintLines({product, elevation, isProjection, projectionY})})),
      ..._.map(archElements, archElement => {
        return {
          key: `archElement-${archElement.id}`, archElement, type: 'archElement',
          lines: _.mapValues(_.cloneDeep(ArchElement.getWallprintLines({archElement, elevation})), line => {
            return _.mapValues(line, vertex => ({...vertex, y: -vertex.y}));
          })
        };
      }),
      ..._.map(_.filter(containers, container => !_.includes(['capPanel', 'endPanel'], container.type) && Container.getSideKey({container, elevation, viewKey: 'front'}) === 'front'), container => {
        var wallprint = _.map(Container.getWallprint({container, elevation, minWallX}), point => lib.object.sum({x: point.x + minWallX, y: -point.y}));

        var wallprintLines = {
          left: {to: wallprint[0], from: wallprint[1]},
          right: {to: wallprint[2], from: wallprint[3]},
          top: {to: wallprint[1], from: wallprint[2]},
          bottom: {to: wallprint[3], from: wallprint[0]}
        };

        var relevantWallprintLines = wallprintLines;

        //HINT in the future might want to consider only certain sides of the container when checking trapped
        //IE sections might only trap on certain axes
        // if (Container.getSideKey({container, elevation, viewKey: 'front'}) !== 'front') {
        //   relevantWallprintLines = _.pick(wallprintLines, ['top', 'bottom']);
        // }

        return {
          key: `container-${container.id}`, container, type: 'container',
          lines: relevantWallprintLines
        };
      }),
      ..._.map(_.filter(archElementContainers, archElementContainer => Container.getSideKey({container: archElementContainer, elevation, viewKey: 'front'}) === 'front'), archElementContainer => {
        var wallprint = _.map(Container.getWallprint({container: archElementContainer, elevation, minWallX}), point => lib.object.sum({x: point.x + minWallX, y: -point.y}));

        var wallprintLines = {
          left: {to: wallprint[0], from: wallprint[1]},
          right: {to: wallprint[2], from: wallprint[3]},
          top: {to: wallprint[1], from: wallprint[2]},
          bottom: {to: wallprint[3], from: wallprint[0]}
        };

        var relevantWallprintLines = wallprintLines;

        //HINT in the future might want to consider only certain sides of the container when checking trapped
        //IE sections might only trap on certain axes
        // if (Container.getSideKey({container, elevation, viewKey: 'front'}) !== 'front') {
        //   relevantWallprintLines = _.pick(wallprintLines, ['top', 'bottom']);
        // }

        return {
          key: `arch-element-container-${archElementContainer.id}`, archElementContainer, type: 'archElementContainer',
          lines: relevantWallprintLines
        };
      }),
      ..._.map(volumes, volume => {
        var wallprint = _.map(Volume.getWallprint({volume, elevation}), point => lib.object.sum({x: point.x + minWallX, y: -point.y}));

        var wallprintLines = {
          left: {to: wallprint[0], from: wallprint[1]},
          right: {to: wallprint[2], from: wallprint[3]},
          top: {to: wallprint[1], from: wallprint[2]},
          bottom: {to: wallprint[3], from: wallprint[0]}
        };

        return {
          key: `volume-${volume.id}`, volume, type: 'volume',
          lines: wallprintLines
        };
      }),
      //volumes
    ];

    var allPoints = _.flatten(_.values(pointsByWall));

    var outsideDimensionSets = _.map(outsideAlphas, (alpha, index) => ({type: 'extrudeOutside', id: `outside-set-${index}`, alpha, targets: [], allPoints}));

    var normalizePoint = ({point, alpha}) => lib.trig.rotate({point, byRadians: -alpha});
    var normalizeLine = ({line, alpha}) => _.mapValues(line, point => lib.trig.rotate({point, byRadians: -alpha}));

    var getIsTrapped = ({line, entityType, entityId}, {filterEntities, excludeEquivalentRanges, locally, returnData} = {}) => {
      var alpha = lib.trig.alpha({p1: line.from, p2: line.to});
      var normalLine = normalizeLine({line, alpha});
      var relevantEntities = _.reject(entities, {key: `${entityType}-${entityId}`});

      if (filterEntities) relevantEntities = _.filter(relevantEntities, filterEntities);

      if (entityType !== 'volume') relevantEntities = _.filter(relevantEntities, entity => entity.type !== 'volume');
      if (entityType !== 'product') relevantEntities = _.filter(relevantEntities, entity => entity.type !== 'product');

      var getIsTrappedForEntity = ({entity, locally}) => {
        return _.some(entity.lines, line2 => {
          var normalLine2 = normalizeLine({line: line2, alpha});
          var isLessThan = normalLine2.from.y > normalLine.from.y;
          var isLessThanOrEqualTo = normalLine2.from.y >= normalLine.from.y;
          var xRangesEquivalent = _.isEqual([_.mapValues(normalLine, 'x'), _.mapValues(normalLine2, 'x')]);

          return ((locally) ? isLessThanOrEqualTo : ((xRangesEquivalent && isLessThanOrEqualTo) || isLessThan)) && (locally ? (Math.abs(normalLine2.from.y - normalLine.from.y) < 7) : true) && lib.number.rangesOverlap({r1: _.mapValues(normalLine, 'x'), r2: _.mapValues(normalLine2, 'x'), inclusive: false}) && ((excludeEquivalentRanges && !locally) ? !xRangesEquivalent : true);
        });
      }

      if (returnData) {
        var isTrappedLocally = _.some(relevantEntities, entity => {
          return getIsTrappedForEntity({entity, locally: true});
        });

        var isTrapped = isTrappedLocally || _.some(relevantEntities, entity => {
          return getIsTrappedForEntity({entity, locally: false});
        });

        return {isTrapped, isTrappedLocally};
      }
      else {
        return _.some(relevantEntities, entity => {
          return getIsTrappedForEntity({entity, locally});
        });
      };
    };

    var containersData = _.orderBy(_.map(containers, container => {
      var wallprint = _.map(Container.getWallprint({container, elevation, minWallX}), point => lib.object.sum({x: point.x + minWallX, y: -point.y}));

      var wallprintLines = {
        left: {to: wallprint[0], from: wallprint[1]},
        right: {to: wallprint[2], from: wallprint[3]},
        top: {to: wallprint[1], from: wallprint[2]},
        bottom: {to: wallprint[3], from: wallprint[0]}
      };

      return {
        ...container,
        wallprint,
        wallprintLines,
        dropzoneSize: Container.getDropzoneSize({container, viewKey: 'front'}),
        scribesData: Container.getScribesData({container}),
        trappedDataBySide: _.mapValues(wallprintLines, line => {
          var trappedData = getIsTrapped({line, entityType: 'container', entityId: container.id}, {returnData: true});

          return trappedData;
        })
      };
    }), ['wallprint[0].x'], ['asc']);

    var deps = {allPoints, outsideDimensionSets, dimensionSets, getIsTrapped, elevation, pointsByWall, products, productTypes, containers, archElementContainers, containersData, volumes, isSection, archElements, room, showProjections, activeDetailLevel, minWallX};

    addElevationWallDimensions(deps);
    addElevationProductDimensions(deps);
    addElevationContainerDimensions(deps);
    addElevationContainerLightingDimensions(deps);
    addElevationCountertopDistanceOffFloorDimensions(deps);
    addElevationArchElementsDimensions(deps);
    addElevationArchElementContainersDimensions(deps);
    addElevationVolumeDimensions(deps);

    dimensionSets = [...outsideDimensionSets, ...dimensionSets];

    var activeLayerDimensionsData = _.defaults(projectData.dimensionsData[projectData.activeDimensionsLayer], {
      disabledPositionIds: {},
      disabledLineIds: {},
      customDimensionsById: {},
      tolerancesById: {},
      shouldHoldTosById: {},
      offsetsById: {},
      swapShortDimSideById: {}
    });

    var relevantCustomDimensions = _.pickBy(activeLayerDimensionsData.customDimensionsById, {contextType: 'elevation', contextId: elevation.id});

    _.forEach(relevantCustomDimensions, customDimension => {
      if (!_.isEqual(customDimension.line.from, customDimension.line.to)) {
        var {line} = customDimension;

        var lineInElevation = _.mapValues(line, position => {
          return Elevation.getPosition2d({elevation, position3d: position});
        });

        dimensionSets.push({
          type: 'extrudeLocally',
          id: customDimension.id,
          isCustomDimension: true,
          customDimension,
          offset: -customDimension.offset,
          alpha: lib.trig.alpha({p1: lineInElevation.from, p2: lineInElevation.to}),
          targets: [
            {position: lineInElevation.from, id: `custom-dimension-${customDimension.id}-from`},
            {position: lineInElevation.to, id: `custom-dimension-${customDimension.id}-to`}
          ]
        });
      }
    });

    dimensionSets = getProcessedDimensionSets({dimensionSets, allPoints, activeLayerDimensionsData, canvasData});

    if (projectData && considerSummarizationChange && cachedDimensionSets) {
      considerUpdatingSummarizedDimensionEdits({projectData, considerSummarizationChange, dimensionSets, cachedDimensionSets, resourceActions});
    }
  }
  catch (error) {
    global.handleError({error, info: {componentStack: error.stack}, message: `generating elevation ${_.get(elevation, 'id')} dimensions`});
  }

  return dimensionSets;
}