import React from 'react';
import _ from 'lodash';
import * as THREE from 'three';

/* eslint-disable react/no-unknown-property */

function Cube3D({position, dimensions, fill, receiveShadow, rotation, ref}) {
  position = [position.x || 0, position.y || 0, position.z || 0];
  dimensions = [dimensions.width || 0, dimensions.height || 0, dimensions.depth || 0];

  //HINT hex value
  if (_.includes(fill, '#')) {
    //HINT convert 3 digit hex to 6 digit hex
    if (fill.length === 4) fill = `#${fill[1]}${fill[1]}${fill[2]}${fill[2]}${fill[3]}${fill[3]}`;

    //HINT convert hex string to hexadecimal
    fill = _.parseInt(_.replace(fill, '#', '0x'), 16);
  }

  return (
    <mesh
      rotation-y={rotation}
      {...{receiveShadow, ref, position}}
    >
      <boxBufferGeometry attach='geometry' args={dimensions} />
      <meshStandardMaterial attach='material' color={fill} />
      {/* <Edges
        scale={1.0001}
        threshold={0} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
        color="black"
      /> */}
    </mesh>
  );
}

export default Cube3D;
