import React, { useState, useEffect } from 'react';
import K from 'k';
import _ from 'lodash';
import Error from './error';

const Errors = ({isClientFacing, errors}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <React.Fragment>
      <div style={{cursor: 'pointer', position: 'fixed', right: isExpanded ? (350 + K.spacing * 2) : 150, top: 12.5, zIndex: 1000, borderRadius: 30, backgroundColor: isClientFacing ? 'white' : 'red', width: 25, height: 25}} onClick={() => setIsExpanded(!isExpanded)}></div>
      <div style={{position: 'fixed', right: K.spacing, top: K.spacing, zIndex: 1000, width: 350, maxHeight: 250}}>
        {isExpanded ? (
          _.map(errors, (error, index) => {
            return (<Error key={error.id} error={error}/>);
          })
        ) : (
          null
        )}

      </div>
    </React.Fragment>

  );
}

export default Errors;