import React, { Component } from 'react';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import Popup from 'components/popup';
import TextInput from 'components/text-input';

export default class DxfPopup extends React.PureComponent {
  state = {roomTitle: '', floorTitle: ''};

  render() {
    return (
      <Popup onClose={() => this.props.close()}>
        <div style={{marginBottom: K.spacing}}>
          <div style={{width: '182px', marginRight: K.margin, display: 'inline-block', textAlign: 'right'}}>New floor title:</div>
          <TextInput value={this.state.floorTitle} onInput={(value) => {this.setState({floorTitle: value})}} autoFocus={true}/>
        </div>
        <div>
          <div style={{width: '182px', marginRight: K.margin, display: 'inline-block'}}>Title of a room on this floor:</div>
          <TextInput value={this.state.roomTitle} onInput={(value) => {this.setState({roomTitle: value})}} submitOnEnter
            onSubmit={() => {
              this.props.handleAddFloor({title: this.state.floorTitle, roomTitle: this.state.roomTitle});
              this.props.close();
            }}
          />
        </div>
        <div style={{marginTop: K.spacing, justifyContent: 'flex-end', display: 'flex'}}>
          <button onClick={() => this.props.close()} style={{marginRight: 5}}>Cancel</button>
          <button onClick={() => {
            this.props.handleAddFloor({title: this.state.floorTitle, roomTitle: this.state.roomTitle});
            this.props.close();
          }}>Create</button>
        </div>
      </Popup>
    );
  }
}