import React, { useContext, Fragment } from 'react';

import { CanvasDataContext, CanvasPointHandle, CanvasPortal, CanvasLine, CanvasPath, CanvasCursorObject, NumericInputContext } from 'canvas';
import { Group } from 'react-konva';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import PositionHelper from 'helpers/position-helper';
import CanvasDimensionLine from 'canvas/dimensions/canvas-dimension-line';
import getProcessedDimensionSets from 'dimensions/getProcessedDimensionSets';
import CanvasErrorFallback from 'canvas/canvas-error-fallback';

import { withErrorBoundary } from 'react-error-boundary';

class EditableCanvasPolyline extends React.PureComponent {
  constructor(props) {
    super(props);

    //TODO in the future, replace this when we implement selectedObjectIds
    this.id = lib.string.uuid();

    this.state = {
      inAddMode: false,
      shiftAltMoveModeData: {inShiftAltMoveMode: false, arrowKeyCode: false},
      inPseudoMode: false,
      selectedHandleIndex: null,
      addModeCursorPosition: null,
      points: _.cloneDeep(props.points),
      position: _.cloneDeep(props.position),
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);

    if ((!this.props.closed || !this.props.position) && this.props.canvasData.stage && !this.props.renderForDrawings) {
      this.enterAddMode();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.loadedAddMode && (!this.props.closed || !this.props.position) && this.props.canvasData.stage && !this.props.renderForDrawings) {
      this.enterAddMode();
    }

    if (prevProps.isSelected && !this.props.isSelected) {
      this.setState({
        inPseudoMode: false,
        selectedHandleIndex: null,
        inAddMode: false,
      });
    }
    else if (!this.state.isDragging && !this.state.isDraggingPoint) {
      this.considerUpdatingState(prevProps);
    }

    if (!_.get(prevProps, 'numericInputData.isNumericInputSubmitted') && _.get(this.props, 'numericInputData.isNumericInputSubmitted') && _.get(this.props, 'numericInputData.numericInputValue') && this.state.inAddMode) {
      this.addPointAtMousePosition();
    }
    if (!_.get(prevProps, 'numericInputData.isNumericInputSubmitted') && _.get(this.props, 'numericInputData.isNumericInputSubmitted') && _.get(this.props, 'numericInputData.numericInputValue') && this.state.shiftAltMoveModeData.inShiftAltMoveMode) {
      this.movePolygonAtMousePosition();
    }
  }

  considerUpdatingState(prevProps) {
    const {points: prevPoints, position: prevPosition} = prevProps;

    if (!_.isEqual(prevPoints, this.props.points)) {
      this.setState({
        points: _.cloneDeep(this.props.points)
      });
    }
    else if (!_.isEqual(prevPosition, this.props.position)) {
      this.setState({
        position: _.cloneDeep(this.props.position)
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);

    if (this.state.inAddMode) this.exitAddMode();
  }

  select = () => {
    if (this.props.isEnabled || this.props.isLocked) {
      if (this.props.onSelect) this.props.onSelect();
    }
  };

  addPoint = ({position, atIndex}) => {
    const points = _.cloneDeep(this.state.points);
    const point = {id: lib.string.uuid(), isPseudoPoint: false, ...position};

    if (atIndex === undefined) atIndex = points.length;
    if (atIndex > 0 && this.state.inPseudoMode) points[atIndex - 1].isPseudoPoint = true;

    points.splice(atIndex, 0, point);

    if (this.state.inAddMode) {
      this.setState({points});
    } else if (this.props.onAddPoint) {
      this.props.onAddPoint({points});
    }
  };

  open = () => {
    if (this.props.closed && this.props.onOpen) this.props.onOpen();
  };

  close = () => {
    if (!this.props.closed) {
      let points = _.cloneDeep(this.state.points);

      _.last(points).isPseudoPoint = this.state.inPseudoMode;

      points = this.makeClockwise(points);

      this.exitAddMode();

      if (this.props.onClose) this.props.onClose({position: this.state.position, points});
    }
  };

  //HINT doesn't use lib.polygon.makeClockwise because we can simply reverse the array of points, since polyline enforces CW or CCW
  makeClockwise = (points) => {
    const isClockwise = lib.math.polygon.clockwise({points});

    if (!isClockwise) {
      const reversedPoints = _.cloneDeep(points).reverse();

      points = _.map(reversedPoints, (point, p) => {
        return {...point, isPseudoPoint: lib.array.prev(points, points.length - 1 - p).isPseudoPoint};
      });
    }

    return points;
  };

  enterAddMode = () => {
    let inAddMode = this.state.inAddMode;

    if (!this.state.inAddMode) {
      const {stage} = this.props.canvasData;

      if (stage) {
        stage.on('click', this.handleStageOnClick);

        stage.on('mousemove', this.setCursorPosition);
      }

      inAddMode = true;

      if (this.props.canvasData.onEnterAddMode) this.props.canvasData.onEnterAddMode();

      if (inAddMode) {
        this.props.numericInputData.toggleNumericInputVisibility(this.state.points.length > 0);
      }
    }

    this.setState({loadedAddMode: true, inAddMode});
  };

  exitAddMode = () => {
    if (this.state.inAddMode) {
      const {stage} = this.props.canvasData;

      if (stage) {
        stage.off('click', this.handleStageOnClick);

        stage.off('mousemove', this.setCursorPosition);
      }

      this.setState({
        inAddMode: false,
      });

      if (this.props.canvasData.onExitAddMode) this.props.canvasData.onExitAddMode();

      this.props.numericInputData.toggleNumericInputVisibility(false);
    }
  };

  enterShiftAltMoveMode = ({arrowKeyCode}) => {
    this.setState({shiftAltMoveModeData: {inShiftAltMoveMode: true, arrowKeyCode}});
    this.props.numericInputData.toggleNumericInputVisibility(true);
  }

  exitShiftAltMoveMode = () => {
    this.setState({shiftAltMoveModeData: {inShiftAltMoveMode: false, arrowKeyCode: null}});
    this.props.numericInputData.toggleNumericInputVisibility(false);
  }

  setCursorPosition = () => {
    const {canvasData} = this.props;
    const {getLastMousePosition} = canvasData;

    if (getLastMousePosition) {
      this.setState({
        cursorPosition: getLastMousePosition()
      });
    }
  };

  handleStageOnClick = (event) => {
    if (this.state.inAddMode && _.includes(['BODY', 'INPUT'], document.activeElement.tagName)) {
      var cursorPositionInCanvas = this.addPointPosition();

      var cursorPosition = PositionHelper.toReal(cursorPositionInCanvas, this.props.canvasData);

      var clickedPointIndex = _.findIndex(this.state.points, point => {
        point = lib.object.sum(point, this.state.position, this.props.offset);

        return point.x === cursorPosition.x && point.y === cursorPosition.y;
      });

      if (clickedPointIndex !== -1) {
        this.handlePointClick({atIndex: clickedPointIndex});

        return;
      }

      return this.addPointAtMousePosition();
    }
  };

  addPointAtMousePosition = () => {
    const {numericInputValue, isNumericInputSubmitted, disableIsNumericInputSubmitted, toggleNumericInputVisibility} = this.props.numericInputData;

    if (isNumericInputSubmitted) {
      disableIsNumericInputSubmitted();
    }

    let numericInput;
    if (numericInputValue) {
      numericInput = numericInputValue;
    }

    const pointPositionInCanvas = this.addPointPosition({numericInput});
    const valid = this.addPointIsValid({position: pointPositionInCanvas});

    if (valid) {
      let position = PositionHelper.toReal(pointPositionInCanvas, this.props.canvasData);

      position = this.normalizePosition(position);

      if (!this.state.position) {
        //HINT this is needed to support drawing room after, but not ideal because it causes room position not to be normalized
        // this.setState({
        //   position: {x: 0, y: 0},
        //   points: [{id: lib.string.uuid(), isPseudoPoint: this.state.inPseudoMode, ...position}],
        // });
        this.setState({
          position,
          points: [{id: lib.string.uuid(), isPseudoPoint: this.state.inPseudoMode, x: 0, y: 0}],
        });

        toggleNumericInputVisibility(true);

        this.select();
      }
      else {
        position = lib.object.difference(position, this.state.position);

        //hint need to add this to handle closing using the numeric input
        var clickedPointIndex = _.findIndex(this.state.points, point => {
          return point.x === position.x && point.y === position.y;
        });

        if (clickedPointIndex !== -1) {
          this.handlePointClick({atIndex: clickedPointIndex});

          return;
        }

        this.addPoint({position});
      }
    }
  };

  movePolygonAtMousePosition = () => {
    const {numericInputValue, isNumericInputSubmitted, disableIsNumericInputSubmitted, toggleNumericInputVisibility} = this.props.numericInputData;
    var {arrowKeyCode} = this.state.shiftAltMoveModeData;

    if (isNumericInputSubmitted) {
      this.setState({shiftAltMoveModeData: {inShiftAltMoveMode: false, arrowKeyCode: null}});
      disableIsNumericInputSubmitted();
      toggleNumericInputVisibility(false);
    }

    let numericInput;
    if (numericInputValue) {
      numericInput = numericInputValue;
    }

    // var transformAmount = lib.object.multiply({x: numericInput, y: numericInput}, this.props.canvasData.precision);
    var transformAmount = {x: 20, y: 20};

    if (arrowKeyCode === 'right') transformAmount = {x: numericInput, y: 0};
    else if (arrowKeyCode === 'up') transformAmount = {x: 0, y: -numericInput};
    else if (arrowKeyCode === 'left') transformAmount = {x: -numericInput, y: 0};
    else if (arrowKeyCode === 'down') transformAmount = {x: 0, y: numericInput};

    if (this.state.selectedHandleIndex != null) {
      this.handleTransformPoint(transformAmount);
    }
    else {
      if (this.props.onDragEnd) this.props.onDragEnd(lib.object.sum(this.props.position, transformAmount));
    }
  }

  addPointPosition = ({numericInput} = {}) => {
    const {canvasData} = this.props;

    var {getLastMousePosition, getSnapData, orthoMode} = canvasData;

    const {candidateSnapPositions} = getSnapData();

    if (this.state.points.length > 0 && this.state.position) {
      candidateSnapPositions.push(..._.map(this.state.points, point => lib.object.sum(point, this.state.position, this.props.offset)));
    }

    let lastPosition, position = getLastMousePosition();

    if (!position && !numericInput) return {x: 0, y: 0};

    const pointPosition = _.mapValues(PositionHelper.toReal(position, canvasData), value => {
      return lib.number.round(value, {toNearest: canvasData.precision});
    });

    const snappedDelta = {x: 0, y: 0};

    lastPosition = _.clone(pointPosition);

    const {position: snappedPosition, snapped} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition: lib.object.sum(_.last(this.state.points), this.state.position, this.props.offset), position: pointPosition, orthoMode: this.state.points.length > 0 && orthoMode}, canvasData);
    const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

    //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
    if (snapped.x !== undefined) {
      snappedDelta.x = snappedPointDelta.x;
    }
    if (snapped.y !== undefined) {
      snappedDelta.y = snappedPointDelta.y;
    }

    position = PositionHelper.toCanvas(lib.object.sum(pointPosition, snappedDelta), canvasData);

    if (numericInput) {
      if (this.state.points.length > 0) lastPosition = PositionHelper.toCanvas(lib.object.sum(_.last(this.state.points), this.state.position, this.props.offset), canvasData);

      const alpha = lib.trig.alpha({p1: lastPosition, p2: position});

      const numericInputPosition = lib.object.sum(lastPosition, lib.trig.rotate({
        point: {x: numericInput * canvasData.scale, y: 0},
        byRadians: alpha
      }));

      return numericInputPosition;
    }

    return position;
  };

  addPointIsValid = ({position}) => {
    let valid = true, appearsValid = true;

    const offsetByPosition = lib.object.sum(_.defaults(this.state.position, {x: 0, y: 0}), this.props.offset);
    const addPointPositionInReal = lib.object.difference(PositionHelper.toReal(position, this.props.canvasData), offsetByPosition);
    const existingPointPositions = _.cloneDeep(this.state.points);
    const pointPositions = _.concat(existingPointPositions, addPointPositionInReal);
    const lines = [];

    pointPositions.forEach((point, index) => {
      if (index > 0) lines.push({from: point, to: pointPositions[index - 1]});
    });

    //HINT Intersecting lines
    _.forEach(lines, l1 => _.forEach(lines, l2 => {
      if (l1 !== l2 && lib.math.linesIntersect({l1, l2})) {
        valid = false;
      }
    }));

    if (!valid) return false;

    lines.pop();

    //HINT Overlapping lines
    _.forEach(lines, line => {
      const firstPosition = pointPositions[0];
      const isFirstPoint = lib.trig.pointsAreEqual({p1: firstPosition, p2: addPointPositionInReal});
      if (!isFirstPoint && lib.math.pointIsOnLine({point: addPointPositionInReal, line})) {
        valid = false;
      }
    });

    if (!valid) return false;

    //HINT Overlapping points
    _.forEach(existingPointPositions, (existingPosition, index) => {
      const isClosing = (index === 0 && existingPointPositions.length >= 2);
      const positionsMatch = lib.trig.pointsAreEqual({p1: existingPosition, p2: addPointPositionInReal});

      if (positionsMatch) {
        valid = false;

        if (!isClosing) {
          appearsValid = false;
        }
      }
    });

    return valid || appearsValid;
  };

  handleTransformPoint = (transformAmount) => {
    const points = _.cloneDeep(this.props.points);
    const pointToTransform = points[this.state.selectedHandleIndex];
    const transformedPoint = {...pointToTransform, ...lib.object.sum(pointToTransform, transformAmount)};

    points.splice(this.state.selectedHandleIndex, 1, transformedPoint);

    if (this.props.onPointDragEnd) this.props.onPointDragEnd(points);
  };

  handleKeyDown = (event) => {
    var arrowKeyCode = lib.event.arrowKeyCode(event);

    if (lib.event.keyPressed(event, 'alt') && this.props.isSelected && this.props.isEnabled && this.props.permitPseudoPoints) {
      this.setState({inPseudoMode: true});
    }
    else if (lib.event.keyPressed(event, 'delete') && this.state.selectedHandleIndex === null && this.props.isSelected && this.props.isEnabled && document.activeElement.tagName === 'BODY') {
      this.handleDelete();
    }
    else if (lib.event.keyPressed(event, 'esc') && (this.state.inAddMode || this.state.shiftAltMoveModeData.inShiftAltMoveMode)) {
      this.exitAddMode();
      this.exitShiftAltMoveMode();
    }
    else if (lib.event.keyPressed(event, 'ctrlcmd') && lib.event.keyPressed(event, 'z') && this.state.inAddMode && !this.state.selectedHandleIndex && this.props.isSelected && this.props.isEnabled) {
      this.handleDelete();
    }
    else if (arrowKeyCode && !this.state.inAddMode && this.props.isSelected && this.props.isEnabled && document.activeElement.tagName === 'BODY') {
      var transform;

      if (arrowKeyCode === 'right') transform = {x: 1, y: 0};
      else if (arrowKeyCode === 'left') transform = {x: -1, y: 0};
      else if (arrowKeyCode === 'up') transform = {x: 0, y: -1};
      else if (arrowKeyCode === 'down') transform = {x: 0, y: 1};

      var transformAmount = lib.object.multiply(transform, this.props.canvasData.precision);

      if (lib.event.keyPressed(event, 'shift') || lib.event.keyPressed(event, 'alt')) {
        this.enterShiftAltMoveMode({arrowKeyCode});
      }

      if (!this.state.shiftAltMoveModeData?.inShiftAltMoveMode) {
        if (this.state.selectedHandleIndex != null && !(lib.event.keyPressed(event, 'shift') || lib.event.keyPressed(event, 'alt'))) {
          this.handleTransformPoint(transformAmount);
        }
        else {
          if (this.props.onDragEnd) this.props.onDragEnd(lib.object.sum(this.props.position, transformAmount));
        }
      }
    }
  };

  handleKeyUp = () => {
    if (this.props.isSelected && this.state.inPseudoMode && this.props.isEnabled) {
      this.setState({inPseudoMode: false});
    }
  };

  handleDragStart = (event) => {
    if (!this.state.isDraggingPoint) {
      this.setState({
        isDragging: true,
        initialPosition: {x: event.target.x(), y: event.target.y()},
      });
    }
  };

  handleDragMove = (event) => {
    if (!this.state.isDraggingPoint) {
      const {canvasData, points, offset} = this.props;
      const {initialPosition} = this.state;
      const {getSnapData, orthoMode} = canvasData;

      const initial = _.clone(this.props.position);
      const pointPositions = _.map(points, point => lib.object.sum(point, initial, offset));
      const newPosition = {x: event.target.x(), y: event.target.y()};
      const {candidateSnapPositions = []} = getSnapData();

      const deltaInCanvas = lib.object.difference(newPosition, initialPosition);

      const deltaInReal = _.mapValues(deltaInCanvas, value => {
        return lib.number.round(value / canvasData.scale, {toNearest: canvasData.precision});
      });

      const snappedDelta = _.clone(deltaInReal);
      const leastSnapDistance = {x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER};

      //Iterate through points and check if any of them snap
      //If so, update the transform to be the snapped transform
      pointPositions.forEach(pointPosition => {
        const lastPosition = _.clone(pointPosition);

        pointPosition = lib.object.sum(pointPosition, deltaInReal);

        //HINT ortho doesn't really make sense when moving
        const {position: snappedPosition, snapped, snapData} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode: false}, canvasData);
        const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

        //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
        if (snapped.x !== undefined && (snapData.x.candidateData?.distance < leastSnapDistance.x || orthoMode)) {
          leastSnapDistance.x = orthoMode ? Number.MAX_SAFE_INTEGER : snapData.x.candidateData.distance;
          snappedDelta.x = snappedPointDelta.x;
        }
        if (snapped.y !== undefined && (snapData.y.candidateData?.distance < leastSnapDistance.y || orthoMode)) {
          leastSnapDistance.y = orthoMode ? Number.MAX_SAFE_INTEGER : snapData.y.candidateData.distance;
          snappedDelta.y = snappedPointDelta.y;
        }
      });

      //HINT controls the Konva event target's position while snapping movement
      if (!_.isEqual(deltaInReal, snappedDelta)) {
        const snappedDeltaInCanvas = _.mapValues(snappedDelta, value => {
          return value * canvasData.scale;
        });

        event.target.position(lib.object.sum(initialPosition, snappedDeltaInCanvas));
      }

      const position = lib.object.sum(initial, snappedDelta);

      this.setState({
        position,
      });

      if (this.props.onDragMove) this.props.onDragMove(position);
    }
  };

  handleDragEnd = () => {
    if (!this.state.isDraggingPoint) {
      this.setState({
        isDragging: false,
        cachedPositions: undefined,
        initialPosition: undefined,
      });

      if (this.props.onDragEnd) this.props.onDragEnd(this.state.position);
    }
  };

  handleMouseUp = (event) => {
    if (event.evt.button === 0 && !this.state.isDragging) {
      this.select(event);
    }
    else if (this.props.isSelected && this.props.isEnabled && this.state.selectedHandleIndex != null) {
      this.setState({
        selectedHandleIndex: null,
      });
    }
  };

  handleDoubleClick = (event, index) => {
    if (!this.state.isDraggingPoint && this.props.closed && this.props.isSelected && this.props.isEnabled && document.activeElement.tagName === 'BODY') {
      const positionInCanvas = event.target.getStage().getPointerPosition();
      const atIndex = 1 + index;
      const lineFromIndex = atIndex === 0 ? this.props.points.length - 1 : atIndex - 1;
      const lineToIndex = atIndex >= this.props.points.length ? 0 : atIndex;

      let position = lib.object.difference(PositionHelper.toReal(positionInCanvas, this.props.canvasData), this.props.position);

      position = this.normalizePosition(position);

      const closestPosition = _.mapValues(lib.math.trig.nearestPoint({
        point: position,
        onLine: {from: this.props.points[lineFromIndex], to: this.props.points[lineToIndex]},
      }), value => {
        return lib.number.round(value, {toNearest: this.props.canvasData.precision});
      });

      this.addPoint({position: closestPosition, atIndex});
    }
  };

  handleDeletePoint = ({atIndex}) => {
    this.setState({selectedHandleIndex: null});

    const points = _.cloneDeep(this.props.points);

    points.splice(atIndex, 1);

    if (this.props.onDeletePoint) this.props.onDeletePoint(points);
  };

  handleDelete = () => {
    if (this.state.inAddMode) {
      this.setState({points: _.dropRight(this.state.points)});
    }
    else if (this.props.onDelete) this.props.onDelete();
  };

  handleSelectPoint = ({atIndex}) => {
    if (!this.state.inAddMode) this.setState({selectedHandleIndex: atIndex});
  };

  handleDeselectPoint = () => {
    if (!this.state.inPseudoMode) this.setState({selectedHandleIndex: null});
  };

  handleMoveStartPoint = (atIndex) => (event) => {
    if (!this.state.isDraggingPoint) {
      this.setState({
        isDraggingPoint: true,
        initialPosition: this.state.points[atIndex],
      });
    }
  };

  handleMovePoint = (atIndex) => (event) => {
    const {canvasData, position: origin, offset} = this.props;
    const {points, initialPosition} = this.state;
    const newPosition = {x: event.target.x(), y: event.target.y()};
    const {getSnapData, orthoMode} = canvasData;

    const pointPosition = _.mapValues(PositionHelper.toReal(newPosition, canvasData), value => {
      return lib.number.round(value, {toNearest: canvasData.precision});
    });
    const {candidateSnapPositions = []} = getSnapData();

    //HINT adds sibling points to snap candidates
    const siblingPoints = points.slice();

    siblingPoints.splice(atIndex, 1);

    candidateSnapPositions.push(..._.map(siblingPoints, point => lib.object.sum(origin, point, offset)));

    const snappedDelta = {x: 0, y: 0};
    const lastPosition = lib.object.sum(origin, initialPosition, offset);
    const {position: snappedPosition, snapped} = PositionHelper.snap({snapPositions: candidateSnapPositions, snapLines: [], lastPosition, position: pointPosition, orthoMode}, canvasData);
    const snappedPointDelta = lib.object.difference(snappedPosition, _.clone(pointPosition));

    //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
    if (snapped.x !== undefined) {
      snappedDelta.x = snappedPointDelta.x;
    }
    if (snapped.y !== undefined) {
      snappedDelta.y = snappedPointDelta.y;
    }

    const position = lib.object.sum(lib.object.difference(pointPosition, offset, origin), snappedDelta);

    //HINT control Konva positioning while snapping
    if (!_.isEqual(position, pointPosition)) {
      const positionInCanvas = PositionHelper.toCanvas(lib.object.sum(position, offset, origin), canvasData);

      event.target.x(positionInCanvas.x);
      event.target.y(positionInCanvas.y);
    }

    const originalPoint = points[atIndex];

    points.splice(atIndex, 1, {...originalPoint, ...position});

    this.setState({points: points.slice()});

    if (this.props.onPointDragMove) this.props.onPointDragMove(points);
  };

  handleMoveEndPoint = () => {
    const {points} = this.state;

    this.setState({
      isDraggingPoint: false,
      initialPosition: undefined
    });

    if (this.props.onPointDragEnd) this.props.onPointDragEnd(points);
  };

  handlePointClick = ({atIndex}) => {
    if (this.state.inAddMode && atIndex === 0 && this.state.points.length > 2) {
      this.close();
    }
    else if (this.state.inPseudoMode) {
      const points = _.cloneDeep(this.props.points);

      points[atIndex].isPseudoPoint = !points[atIndex].isPseudoPoint;

      if (this.props.onPointsChange) this.props.onPointsChange(points);
    }
  };

  normalizePosition = (position) => {
    if (!this.props.offset) return position;

    return lib.object.difference(position, this.props.offset);
  };

  render() {
    const {closed, isEnabled, isSelected, isDraggable, offset, isLocked, renderForDrawings, canvasData} = this.props;
    const {inAddMode, inPseudoMode} = this.state;

    var isExportingDxf = _.get(global.visibilityLayers, 'isExportingDxf');

    const position = lib.object.sum(_.defaultsDeep(this.state.position, this.props.position), offset);
    const points = _.defaultsDeep(this.state.points, this.props.points);

    const dragProps = {
      draggable: isEnabled && isSelected && isDraggable,
      onDragStart: this.handleDragStart,
      onDragMove: this.handleDragMove,
      onDragEnd: this.handleDragEnd
    };

    const appearanceProps = {stroke: this.props.stroke || 'black', fill: 'rgba(255, 255, 255, 0.2)'};
    const strokeWidth = (this.props.strokeWidth || 1) * (this.props.shouldScaleStrokeWidth ? canvasData.scale : 1);
    const strokeWidthOffset = strokeWidth * _.get(this.props, 'strokeWidthOffset', 0.5);
    const positionInCanvas = PositionHelper.toCanvas(position, canvasData);

    let pointsInCanvas = _.map(points, (point) => ({
      ...point,
      ...lib.object.multiply({x: point.x, y: point.y}, canvasData.scale)
    }));

    let strokePolylinePoints = _.cloneDeep(pointsInCanvas);
    let strokeLines = [];

    _.forEach(strokePolylinePoints, (point, index) => {
      var nextPoint = lib.array.next(strokePolylinePoints, index);
      var alpha = lib.trig.alpha({p1: point, p2: nextPoint});
      var previousPoint = lib.array.prev(strokePolylinePoints, index);
      var previousAlpha = lib.trig.alpha({p1: previousPoint, p2: point});
      // var nextNextPoint = lib.array.next(strokePolylinePoints, (index + 1) % strokePolylinePoints.length);
      // var nextAlpha = lib.trig.alpha({p1: nextPoint, p2: nextNextPoint});

      if (closed || index < strokePolylinePoints.length - 1) {
        var line = {from: point, to: nextPoint};

        if (this.props.usePathStroke) {
          strokeLines.push({...line, isPseudoLine: point.isPseudoPoint});
        }
        else {
          if (!isExportingDxf) {
            var lineOffset = lib.trig.translate({point: {x: 0, y: 0}, by: strokeWidthOffset, alpha: alpha - Math.PI / 2});

            line = {from: lib.object.sum(point, lineOffset), to: lib.object.sum(nextPoint, lineOffset)};

            line = lib.trig.extend({line, by: Math.sin(alpha - previousAlpha) * ((strokeWidth / 2) + strokeWidthOffset), rangeKey: 'from'});
            // line = lib.trig.extend({line, by: isExportingToDXF ? strokeWidth / 2 - 0.5 : -Math.sin(alpha - nextAlpha) * ((strokeWidth / 2) + strokeWidthOffset), rangeKey: 'to'});
          }

          strokeLines.push({...line, isPseudoLine: point.isPseudoPoint});
        }
      }
    });

    let addPointLine;
    let addPointCursor;

    if (inAddMode) {
      var cursorPositionInCanvas = this.addPointPosition();

      addPointCursor = <CanvasCursorObject {...{cursorPositionInCanvas, siblingPoints: _.map(points, point => lib.object.sum(point, position))}}/>;

      if (cursorPositionInCanvas && pointsInCanvas.length > 0) {
        const pointLine = {from: _.last(pointsInCanvas), to: lib.object.difference(cursorPositionInCanvas, positionInCanvas)};
        const valid = this.addPointIsValid({position: cursorPositionInCanvas});

        addPointLine = (
          <CanvasLine
            {...positionInCanvas}
            listening={false}
            stroke={valid ? '#CCCCCC' : '#ED7B84'}
            dash={inPseudoMode ? [3, 6] : [3, 3]}
            points={[pointLine.from.x - 0.5, pointLine.from.y - 0.5, pointLine.to.x - 0.5, pointLine.to.y - 0.5]}
          />
        );
      }
    }
    return (
      <Fragment key={`fragment-polyline-${this.id}`}>
        {addPointLine}
        {addPointCursor}
        {!!(pointsInCanvas.length && isSelected) && (
          <CanvasLine
            {...{closed, ...positionInCanvas}}
            key={`polyline-${this.id}`}
            points={_.flatMap(pointsInCanvas, (point) => [point.x, point.y])}
            fill={appearanceProps.fill}
            stroke={isSelected ? '#9BCCE1' : 'transparent'}
            strokeWidth={2}
            listening={false}
          />
        )}
        <Group opacity={_.toNumber(this.props.opacity || 1)}>
          {this.props.closed && (this.props.fill || this.props.fillProps) && (
            <CanvasPath
              points={points}
              {...positionInCanvas}
              fill={this.props.fill}
              opacity={_.toNumber(this.props.fillOpacity || 1)}
              closed
              listening={(isEnabled && !this.props.preventFillSelect) ? true : false}
              onClick={this.handleMouseUp}
              {...dragProps}
              {...(this.props.fillProps || {})}
            />
          )}
          {this.props.usePathStroke && this.props.closed && (
            <CanvasPath
              points={points}
              {...positionInCanvas}
              strokeWidth={strokeWidth}
              opacity={_.toNumber(this.props.strokeOpacity || 1)}
              closed
              dash={this.props.dashed ? (this.props.strokeDashArray || [5 * (strokeWidth * 2), 5 * (strokeWidth * 2)]) : undefined}
              fill={'transparent'}
              stroke={appearanceProps.stroke}
              listening={false}
            />
          )}
          {/* {this.props.closed && (this.props.fill || this.props.fillProps) && (
            <CanvasPath
              points={_.map(_.dropRight(points), (point, index) => {
                var nextPoint = index === _.dropRight(points).length - 1 ? _.first(points) : lib.array.next(points, index);
                var alpha = lib.trig.alpha({p1: point, p2: nextPoint});
                var previousPoint = index === 0 ? _.last(_.dropRight(points)) : lib.array.prev(points, index);
                var previousAlpha = lib.trig.alpha({p1: previousPoint, p2: point});
                var averageAlpha = Math.atan((Math.sin(alpha)+Math.sin(previousAlpha)) / (Math.cos(alpha)+Math.cos(previousAlpha)));

                var lineOffset = null//lib.trig.translate({point: {x: 0, y: 0}, by: 5, alpha: averageAlpha});
                console.log(point, nextPoint, previousPoint, lineOffset, alpha, previousAlpha, averageAlpha);
                return lib.object.sum(point, lineOffset);
              })}
              {...positionInCanvas}
              // fill={this.props.fill}
              opacity={_.toNumber(this.props.strokeOpacity || 1)}
              closed
              listening={(isEnabled && !this.props.preventFillSelect) ? true : false}
              onClick={this.handleMouseUp}
              {...dragProps}
              {...(this.props.fillProps || {})}
              stroke={this.props.stroke}
              strokeWidth={this.props.strokeWidth}
              strokeOpacity={this.props.strokeOpacity || 1}
            />
          )} */}
          {_.map(_.orderBy(strokeLines, 'isPseudoLine', 'desc'), line => {
            var isRightAngle = lib.trig.alpha({p1: line.from, p2: line.to}) % (Math.PI / 2) === 0;

            return (<CanvasLine
              {...{strokeWidth, closed, ...dragProps, ...positionInCanvas}}
              key={`${this.id}-stroke-line-${JSON.stringify(line.from)}-${JSON.stringify(line.to)}`}
              points={[line.from.x, line.from.y, line.to.x, line.to.y]}
              dash={this.props.dashed ? (this.props.strokeDashArray || [5 * (strokeWidth * 2), 5 * (strokeWidth * 2)]) : undefined}
              stroke={this.props.usePathStroke ? '' : (isSelected && !isRightAngle) ? (line.isPseudoLine ? '#d5a1e3' : '#aa6eba') : (line.isPseudoLine ? (renderForDrawings ? 'transparent' : '#eeeeee') : appearanceProps.stroke)}
              hitStrokeWidth={_.max([strokeWidth, 3])}
              opacity={_.toNumber(this.props.strokeOpacity || 1)}
              onClick={this.handleMouseUp}
              onDblClick={(event) => this.handleDoubleClick(event, _.findIndex(strokeLines, line))}
            />);
          })}
        </Group>
        {isSelected && !isLocked &&
          _.map(points, (point, index) => {
            const isHandleSelected = index === this.state.selectedHandleIndex;

            let next = lib.array.next(points, index);
            let previous = lib.array.prev(points, index);

            point = {...point, ...PositionHelper.toCanvas(lib.object.sum(point, position), canvasData)};
            next = PositionHelper.toCanvas(lib.object.sum(next, position), canvasData);
            previous = PositionHelper.toCanvas(lib.object.sum(previous, position), canvasData);

            return (
              <Fragment key={index}>
                <CanvasPortal portalSelector={".hud-layer"}>
                  <CanvasPointHandle
                    {...{point, isEnabled}}
                    key={`polyline-handle-${index}`}
                    radius={3.5}
                    isSelected={isHandleSelected}
                    onDelete={() => this.handleDeletePoint({atIndex: index})}
                    onSelect={() => this.handleSelectPoint({atIndex: index})}
                    onDeselect={this.handleDeselectPoint}
                    onMove={this.handleMovePoint(index)}
                    onMoveStart={this.handleMoveStartPoint(index)}
                    onMoveEnd={this.handleMoveEndPoint}
                    onClick={() => this.handlePointClick({atIndex: index})}
                  />
                  {isHandleSelected && (
                    _.map([previous, next], (adjacentPoint, i) => {
                      var isRightAngle = lib.trig.alpha({p1: point, p2: adjacentPoint}) % (Math.PI / 2) === 0;

                      return (
                        <CanvasLine
                          key={`canvas-polyline-handle-select-lines-${index}-${i}`}
                          stroke={isRightAngle ? '#9BCCE1' : '#aa6eba'}
                          strokeWidth={2}
                          from={point} to={adjacentPoint}
                        />
                      );
                    })
                  )}
                </CanvasPortal>
              </Fragment>
          );
        })}
        {this.state.inAddMode && !this.props.hideDimensions &&  (
          _.map(this.state.points, (point, p) => {
            var from = lib.object.sum(point, this.state.position);
            var to = lib.object.sum(lib.array.next([
              ...this.state.points,
              lib.object.difference(lib.object.difference(PositionHelper.toReal(cursorPositionInCanvas, canvasData), this.state.position), this.props.offset)
            ], p), this.state.position);

            var dimensionSet = getProcessedDimensionSets({dimensionSets: [{
              alpha: lib.trig.alpha({p1: from, p2: to}),
              offset: 0,
              targets: [
                {position: from, id: 'polyline-add-mode-' + point.id + '-from'},
                {position: to, id: 'polyline-add-mode-' + point.id + '-to'}
              ]
            }], canvasData})[0];

            return dimensionSet && (
              <CanvasDimensionLine
                key={p}
                viewOffset={this.props.offset}
                dimensionSet={dimensionSet}
              />
            );
          })

        )}
      </Fragment>
    );
  }
}

function EditableCanvasPolylineWithContext(props) {
  let canvasData = useContext(CanvasDataContext);
  let numericInputData = useContext(NumericInputContext);

  return <EditableCanvasPolyline {...props} {...{canvasData, numericInputData}} />;
}

export default withErrorBoundary(EditableCanvasPolylineWithContext, {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Canvas Polyline'})
});
