import K from 'k';
import lib from 'lib';
import _ from 'lodash';

var PositionHelper = {
  //TODO mouse position, canvas position (top/left) - current just handling a delta
  toReal: ({x, y, ...restPosition}, {scale, offset, containerSize, infinitePrecision=false}) => {
    var position = {x, y};

    position = lib.object.difference(position, {x: containerSize.width / 2, y: containerSize.height / 2});
    position = lib.object.sum(position, offset);
    position = lib.object.multiply(position, 1 / scale);

    if (!infinitePrecision) position = lib.round(position, {toNearest: K.minPrecision});

    return {...position, ...restPosition};
  },
  toCanvas: ({x, y, ...restPosition}, {scale, offset, containerSize}) => {
    x = x * scale - offset.x + containerSize.width / 2;
    y = y * scale - offset.y + containerSize.height / 2;

    var position = {x, y};

    return {...position, ...restPosition};
  },
  snapPositionFor({position, onLine}) {
    return lib.trig.nearestPoint({point: position, onLine});
  },
  snap({snapPositions, snapLines, position, lastPosition, orthoMode, threshold = 7}, canvasData) {
    var {scale} = canvasData;

    snapPositions = [...snapPositions, ..._.map(snapLines, onLine => PositionHelper.snapPositionFor({position, onLine}))];

    var snapData = lib.object.fromKeys(['x', 'y'], axisKey => {
      var candidatesData = lib.waterfall(snapPositions, [
        [_.map, snapPosition => ({snapPosition})],
        [_.map, data => ({...data, thresholdDistance: Math.abs(data.snapPosition[axisKey] - position[axisKey])})],
        [_.filter, ({thresholdDistance}) => thresholdDistance <= threshold / scale],
        [_.map, data => ({...data, distance: lib.trig.distance({fromPoint: position, toPoint: data.snapPosition})})],
        [_.sortBy, 'distance']
      ]);

      var candidateData = candidatesData[0];
      var isSnapped = !!candidateData;// && candidateData.snapPosition[axisKey] !== position[axisKey];

      if (!isSnapped) candidateData = undefined;

      return {candidatesData, isSnapped, candidateData, positionValue: _.get(candidateData, `snapPosition.${axisKey}`)};
    });

    var snapped = _.mapValues(snapData, 'positionValue');
    var orthoSnapped = {x: false, y: false};

    if (orthoMode && lastPosition) {
      var axis = Math.abs(position.x - lastPosition.x) < Math.abs(position.y - lastPosition.y) ? 'x' : 'y';

      snapped[axis] = lastPosition[axis];
      orthoSnapped[axis] = true;
    }

    return {position: snapped, snapped: _.mapValues(_.pickBy(snapped, s => s !== undefined), () => true), orthoSnapped, snapData};
  }
};

var { toCanvas } = PositionHelper;

export default PositionHelper;
export { toCanvas };
