import React, { useContext, useEffect, useState } from 'react';

import { CanvasTransformer, CanvasDataContext, CanvasSelectionContext } from 'canvas';
import { connect, resourceActions } from 'redux/index.js';

import Container from 'project-helpers/container';
import Volume from 'project-helpers/volume';
import Elevation from 'project-helpers/elevation';
import Wall from 'project-helpers/wall';

import lib from 'lib';
import _ from 'lodash';
import K from 'k';
import { pluralize } from 'inflection';

class CanvasMultiselectTransformer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isRotating: false
    };
  }
  get multiSelectTransformerProps() {
    var maxX, minX, maxY, minY, transformerProps;

    if (this.props.activeEntities && this.props.activeEntities.length > 1) {
      if (this.state.isRotating && this.state.cachedTransformerProps) return this.state.cachedTransformerProps;

      var nearestEntity = this.props.nearestEntity;

      if (nearestEntity?.resourceKey === 'elevation') {
        var elevation = nearestEntity.entity;
        var activeEntityFootprints = _.flatMap(this.props.activeEntities, (entity, i) => {
          var {resourceKey} = this.props.activeEntitiesData[i];
          var Resource = {
            container: Container,
            volume: Volume
          }[resourceKey];

          return Resource.getWallprintInElevation({[resourceKey]: entity, elevation});
        });
        var walls = Elevation.get('walls', {elevation});

        maxX = _.max(_.map(activeEntityFootprints, 'x'));
        minX = _.min(_.map(activeEntityFootprints, 'x'));
        maxY = _.max(_.map(activeEntityFootprints, 'y'));
        minY = _.min(_.map(activeEntityFootprints, 'y'));

        var wallsData = _.filter(_.map(walls, wall => {
          var wallX = Elevation.getWallX({elevation, wall});
          var outlinePoints = Wall.getOutlinePoints({elevation, wall});

          return {wall, wallX, outlinePoints};
        }), wallData => !_.isEmpty(wallData.outlinePoints));

        const fromIsInRoom = lib.polygon.pointInsidePolygon({point: nearestEntity.entity.lineInRoom.from, polygon: this.props.room.plan.points});

        var firstWallXInset = fromIsInRoom ? 0 : _.min(_.map(wallsData, wallData => _.min(_.map(wallData.outlinePoints, 'x'))));

        transformerProps = {
          shapeProps: {
            size: {width: maxX - minX, height: maxY - minY},
            position: lib.object.sum({x: minX, y: minY - (maxY - minY)}, this.props.viewOffset, {x: firstWallXInset}),
            rotation: 0,
          },
          isRotatable: false
        };
      }
      else if (nearestEntity?.resourceKey === 'room') {
        var activeEntityFootprints = _.flatMap(this.props.activeEntities, (entity, i) => {
          var {resourceKey} = this.props.activeEntitiesData[i];
          var Resource = {
            container: Container,
            volume: Volume
          }[resourceKey];

          return Resource.getFootprintInRoom({[resourceKey]: entity});
        });

        maxX = _.max(_.map(activeEntityFootprints, 'x'));
        minX = _.min(_.map(activeEntityFootprints, 'x'));
        maxY = _.max(_.map(activeEntityFootprints, 'y'));
        minY = _.min(_.map(activeEntityFootprints, 'y'));

        //TODO rotate transformer if all selected items are at a specific rotation, tricky math
        // var rotationMap = _.uniq(_.map(this.props.activeEntities, 'rotation'));

        // var rotation = rotationMap.length === 1 ? rotationMap[0] : 0;

        // if (rotation !== 0) {

        // }

        transformerProps = {
          shapeProps: {
            size: {width: maxX - minX, height: maxY - minY},
            position: lib.object.sum({x: minX, y: minY}, nearestEntity.entity.plan.position, this.props.viewOffset),
            rotation: 0,
          },
          isRotatable: true
        };
      }

      if (nearestEntity) {
        return transformerProps;
      }
    }

    return {shapeProps: {size: {width: 50, height: 50}, position: {x: 0, y: 0}, rotation: 0}};
  };

  handleTransform = (transformerProps) => {
    if (!this.state.cachedTransformerProps) {
      this.setState({cachedTransformerProps: this.multiSelectTransformerProps, cachedActiveEntities: _.clone(this.props.activeEntities)});
    }

    var isRotating = transformerProps.anchorKey === 'rotater';

    //HINT we want to calculate the transformer props as if the rotation isn't taking place
    if (isRotating !== this.state.isRotating) this.setState({isRotating});

    this.props.onTransform(transformerProps);
  }

  handleTransformEnd = (transformerProps) => {
    this.setState({cachedTransformerProps: undefined, cachedActiveEntities: undefined, multiSelectKey: lib.string.uuid(), isRotating: false});

    this.props.onTransformEnd(transformerProps);
  }

  render() {
    return (
      <CanvasTransformer
        onTransform={this.handleTransform}
        onTransformEnd={this.handleTransformEnd}
        key={this.state.multiSelectKey}
        isSelected={true}
        hideSelectFill={true}
        isMultiSelectTransformer={true}
        {...{...(this.multiSelectTransformerProps || {})}}
      />
    );
  }
}

function CanvasMultiselectTransformerWithContext(props) {
  let selectionData = useContext(CanvasSelectionContext);

  return (
    <CanvasMultiselectTransformer {...props} {...{selectionData}}/>
  );
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData} = ownProps;

    var activeEntities = _.map(activeEntitiesData, activeEntityData => {
      return _.get(state.resources, `[${pluralize(activeEntityData.resourceKey)}].byId[${activeEntityData.id}]`);
    });

    return {activeEntities};
  },
  mapDispatch: {
  }
})(CanvasMultiselectTransformerWithContext);