import React from 'react';

import Popup from 'components/popup';

export default class AccessoriesGuidePopup extends React.PureComponent {
  get accessoriesGuideLink() {
    if (this.props.companyKey === 'vp') {
      return 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/design-engine/Space+Theory+Accessories+Guide.pdf';
    }
    else {
      return 'hblink'; //TODO hb link
    }
  }

  render() {
    return (
      <Popup onClose={() => this.props.close()} type={'fitted'}>
        <div style={{maxWidth: 'fit-content', textAlign: 'center'}}>
            Outfit your kitchen drawers and Opencase panels with additional accessories. <br />
            Visit the accessories guide below for sizes, drawer capacity and recommendations. <br />
            Select accessories on the left to add them to your order.
        </div>
        <br/>
        <div style={{textAlign: 'center'}}>
          <a className='button' style={{textDecoration: 'underline'}} href={this.accessoriesGuideLink} target='_blank' rel="noreferrer">Accessories Guide</a>
        </div>
      </Popup>
    );
  }
}