import React, {useState} from 'react';

import Popup from 'components/popup';
import Dropdown from 'components/dropdown';


import K from 'k';

const EnableAutogenerationPopup = ({onClose, onSubmit, autogenerateStrategy}) => {
  const [hoveredPopupKey, setHoveredPopupKey] = useState(null);
  const [activeAutogenerateStrategy, setActiveAutogenerateStrategy] = useState(autogenerateStrategy || 'standard');

  const styles = {
    popupButton: (key) => ({
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      cursor: 'pointer',
      ...K.fonts.label,
      height: 20,
      opacity: key === hoveredPopupKey ? 0.7 : 0.9
    }),
    popupButtonContainer: {
      marginTop: K.spacing,
      display: 'flex',
      gap: K.spacing,
      justifyContent: 'flex-end',
      flex: 1,
      alignItems: 'flex-end'
    },
    message: {
      maxWidth: 200,
      overflowWrap: 'break-word',
      color: 'black',
    },
    autogenerateStrategyLabel: {
      marginTop: K.spacing * 1.5,
      fontWeight: 'bold'
    }
  };

  const handleMouseEnterPopupButton = (key) => () => {
    setHoveredPopupKey(key);
  };

  const handleMouseLeavePopupButton = () => {
    setHoveredPopupKey(null);
  };

  const handleActiveAutogenerateStrategyChange = (value) => setActiveAutogenerateStrategy(value);

  return (
    <Popup style={{borderRadius: 3}} {...{onClose}}>
      <div style={styles.message}><b>Do you want to enable auto-generation for drawings?</b> Existing page edits will be lost, but pages with be automatically generated upon page-load.</div>
      <div style={styles.autogenerateStrategyLabel}>Autogeneration Strategy</div>
      <Dropdown
        options={[{value: 'standard', title: 'Standard'}, {value: 'overviewsOnly', title: 'Overviews Only'}, {value: 'excludeOverviews', title: 'Exclude Overviews'}]}
        value={activeAutogenerateStrategy}
        onChange={handleActiveAutogenerateStrategyChange}
        hasFixedHeight={true}
      />
      <div style={styles.popupButtonContainer}>
        <button
          style={styles.popupButton('enable-continuous-autogeneration-yes')}
          onMouseEnter={handleMouseEnterPopupButton('enable-continuous-autogeneration-yes')}
          onMouseLeave={handleMouseLeavePopupButton}
          onClick={() => onSubmit({enableContinuousAutogeneration: true, autogenerateStrategy: activeAutogenerateStrategy})}
        >Yes</button>
        <button
          style={styles.popupButton('enable-continuous-autogeneration-no')}
          onMouseEnter={handleMouseEnterPopupButton('enable-continuous-autogeneration-no')}
          onMouseLeave={handleMouseLeavePopupButton}
          onClick={() => onSubmit({enableContinuousAutogeneration: false, autogenerateStrategy: activeAutogenerateStrategy})}
        >No</button>
      </div>
    </Popup>
  );
};

export default EnableAutogenerationPopup;