import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Elevation from 'project-helpers/elevation';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addElevationCountertopDistanceOffFloorDimensions({elevation, dimensionSets, containersData}) {
  var countertops = _.filter(containersData, {type: 'countertop'});
  var accountedForYDims = [];

  var ctopDimensionSetTargets = [];

  _.forEach(countertops, countertop => {
    var topLine = countertop.wallprintLines.top;
    var countertopDistanceOffFloor = topLine.from.y;

    if (!_.includes(accountedForYDims, countertopDistanceOffFloor)) {
      var dimX = topLine.from.x + ((topLine.to.x - topLine.from.x) * 0.2);

      ctopDimensionSetTargets.push(
        {position: {x: dimX, y: 0}, id: countertop.id < 68723 ? `countertop-${countertop.id}-off-floor-from` : `countertop-${countertop.id}-elevation-${elevation.id}-off-floor-from`},
        {position: {x: dimX, y: countertopDistanceOffFloor}, id: countertop.id < 68723 ? `countertop-${countertop.id}-off-floor-to` : `countertop-${countertop.id}-elevation-${elevation.id}-off-floor-to`}
      );

      accountedForYDims.push(countertopDistanceOffFloor);
    }
  });

  if (ctopDimensionSetTargets.length > 0) {
    dimensionSets.push({
      type: 'standalone',
      id: `elevation-${elevation.id}-countertop-y-dimensions`,
      alpha: Math.PI / 2,
      offset: 0,
      showLabelBorder: true,
      targets: ctopDimensionSetTargets
    });
  }
}
