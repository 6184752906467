import ManyKeysMap from 'many-keys-map';

export default function memo(fn, {getRelevantMemoData}={}) {
  var cache = new ManyKeysMap();

  var memoizedFn = (...args) => {
    var relevantMemoData = getRelevantMemoData ? getRelevantMemoData(args[0]) : args[0];

    // if (getRelevantMemoData) {
    //   //IE convert container object to relevant container props {position, dimensions}
    //   relevantData = getRelevantMemoData({relevantData});
    // }

    //TODO support multiple args
    var cacheKeyArray = Object.values(relevantMemoData).map(arg => {
      if (typeof(arg) === 'object') {
        arg = JSON.stringify(arg);
      }

      return arg;
    });

    var value;

    if (cache.has(cacheKeyArray)) {
      value = cache.get(cacheKeyArray);
    }
    else {
      value = fn(...args);

      cache.set(cacheKeyArray, value);
    }

    return value;
  };

  memoizedFn.cache = cache;


  return memoizedFn;
};
