import _ from 'lodash';
import Elevation from 'project-helpers/elevation';
import Wall from 'project-helpers/wall';
import Product from 'project-helpers/product';
import ArchElement from 'project-helpers/arch-element';

var CanvasArchElementHelper = {
  getMetaProps({archElement, elevation, viewKey, sideKey, activeDetailLevel}) {
    return {
      getShouldShowCenterline: () => {
        let shouldShowCenterline = false;
        const isSection = elevation && Elevation.getIsSection({elevation});

        if (!_.includes(['schematic', 'rendering'], activeDetailLevel) && elevation && viewKey === 'front' && sideKey === 'front' && !isSection) {
          const wall = ArchElement.get('wall', {archElement});

          //HINT handle initial dragging
          if (wall) {
            var products = Elevation.get('products', {elevation});
            const archElementCenterX = archElement.position.x + Wall.getXOffsetInElevation({wall, elevation}) + ArchElement.getSize({archElement, viewKey}).width / 2;

            shouldShowCenterline = _.some(products, product => {
              let shouldShow = false;

              if (Product.getSideKey({product, elevation, viewKey}) === 'front') {
                const productPositionInElevation = Product.getPositionInElevation({product, elevation});

                shouldShow = _.includes([
                  productPositionInElevation.x,
                  productPositionInElevation.x + product.dimensions.width / 2,
                  productPositionInElevation.x + product.dimensions.width
                ], archElementCenterX);
              }

              return shouldShow;
            });
          }
        }

        return shouldShowCenterline;
      }
    };
  },
};

export default CanvasArchElementHelper;