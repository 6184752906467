import React, {createRef} from 'react';

import { Circle } from 'react-konva';

import lib from 'lib';

export default class CanvasPointHandle extends React.PureComponent {
  constructor(props) {
    super(props);

    this.id = props.point.id || lib.string.uuid();

    this.handleRef = createRef();

    this.state = {
      isDragging: false,
      isSelecting: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);

    if (this.handleRef.current) this.handleRef.current.moveToTop();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  select = () => {
    if (!this.props.isSelected && this.props.isEnabled && this.props.onSelect) {
      this.props.onSelect();
    }
  };

  deselect = () => {
    if (this.props.isSelected && this.props.isEnabled && this.props.onDeselect) {
      this.props.onDeselect();
    }
  };

  handleDelete = () => {
    if (this.props.onDelete && this.props.isEnabled) this.props.onDelete();
  };

  handleKeyDown = (event) => {
    if (lib.event.keyPressed(event, 'delete') && this.props.isSelected && this.props.isEnabled && document.activeElement.tagName === 'BODY') {
      this.handleDelete();
    }
  };

  handleMouseUp = () => {
    if (!this.state.isDragging && !this.state.isSelecting && this.props.isSelected && this.props.isEnabled) {
      this.deselect();
    }
    else {
      this.setState({isSelecting: false});
    }

    if (this.props.onClick) this.props.onClick();
  };

  handleMouseDown = (event) => {
    if (event.evt.button === 0 && !this.state.isDragging && !this.props.isSelected && this.props.isEnabled) {
      this.setState({isSelecting: true});

      this.select();
    }
  };

  handleMouseEnter = (event) => {
    if (this.props.isEnabled) {
      const container = event.target.getStage().container();

      container.style.cursor = 'pointer';
    }
  };

  handleMouseLeave = (event) => {
    if (this.props.isEnabled) {
      const container = event.target.getStage().container();

      container.style.cursor = 'inherit';
    }
  };

  handleDragStart = (event) => {
    this.setState({
      isDragging: true,
      lastMousePosition: {x: event.target.x(), y: event.target.y()},
    });

    if (this.props.onMoveStart) this.props.onMoveStart(event);
  };

  handleDragMove = (event) => {
    if (this.props.onMove) this.props.onMove(event);
  };

  handleDragEnd = () => {
    this.setState({isDragging: false, lastMousePosition: null});

    if (this.props.onMoveEnd) this.props.onMoveEnd();
  };

  render() {
    const {point, ...rest} = this.props;
    const radius = this.props.radius || 3.5;

    return (
      <Circle
        {...{radius, ...rest}}
        ref={this.handleRef}
        x={point.x}
        y={point.y}
        fill={!this.props.isSelected ? 'white' : 'black'}
        stroke={!this.props.isSelected ? 'black' : 'white'}
        strokeWidth={this.props.strokeWidth || 1}
        onMouseUp={this.handleMouseUp}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onDragStart={this.handleDragStart}
        onDragMove={this.handleDragMove}
        onDragEnd={this.handleDragEnd}
        draggable={this.props.isSelected && this.props.isEnabled}
      />
    );
  }
}
