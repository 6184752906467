import React, { Component } from 'react';

import TextInput from './text-input';
import Color from 'color';
import K from 'k';

class ColorInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  constrain(value) {
    //HINT colors need to be validly parsed by Color
    try {
      Color(value).alpha(1).hexa();

      return value;
    }
    catch (e) {
      return this.props.defaultColor || 'black';
    }
  }

  render() {
    return (
      <TextInput
        {..._.omit(this.props, ['defaultColor'])}
        ref={this.props.inputRef}
        type='text'
        style={{border: 'none', outline: 'none', ...this.props.style}}//...(this.state.invalidColor ? {color: K.colors.invalid} : {}),
        onChange={({value}) => {
          if (value === this.constrain(value)) {
            // this.setState({invalidColor: false})

            if (this.props.onChange) this.props.onChange({value: this.constrain(value)});
          }
          else {
            // this.setState({invalidColor: true})
          }
        }}
      />
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <ColorInput inputRef={ref} {...props} />;
});
