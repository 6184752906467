import { singularize, pluralize } from 'inflection';

var apiKeyFor = ({key}) => {
  let pKey = pluralize(key);

  const isPlural = key === pKey;

  let apiPKey = {
    containers: 'containerInstances',
    archElements: 'archElementInstances',
    products: 'productInstances',
    productTypes: 'products',
    containerTypes: 'container',
    productOptionTypes: 'productOption',
    productOptions: 'productOptionInstance',
    productCategories: 'bottomLevelProductCategory'
  }[pKey] || pKey;

  return isPlural ? apiPKey : singularize(apiPKey);
}

export default apiKeyFor;