import React from 'react';

import Popup from 'components/popup';
import K from 'k';

export default class AccessoriesGuidePopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHoveringPanelGuideButton: false
    };
  }
  render() {
    var styles = {
      button: {
        color: K.colors.white,
        background: K.colors.black,
        border: '0px solid transparent',
        ...K.fonts.label,
        height: 20,
        opacity: this.state.isHoveringPanelGuideButton ? 0.4 : 0.8,
        borderRadius: 2,
        cursor: 'pointer'
      },
      buttonContainer: {
        marginTop: K.spacing,
        display: 'flex',
        gap: K.spacing,
        justifyContent: 'flex-end',
        flex: 1,
        alignItems: 'flex-end'
      },
      lineItem: {
        marginTop: K.spacing,
        marginLeft: K.spacing * 2
      }
    }

    return (
      <Popup
        onClose={() => this.props.toggleMaterialFactorsPopup()} type={'fitted'}
        style={{width: K.paneWidth * 2}}
      >
        <div style={{fontWeight: 'bold', marginBottom: K.spacing * 2}}>Material Factors:</div>
        {this.props.companyKey === 'hb' && (
          <div>
            <div style={styles.lineItem}>A - HPL w. colormatched edge</div>
            <div style={styles.lineItem}>B - Veneer, HPL on Black Core, Soft Touch HPL w. colormatched edge, Anodized Aluminum, Primed for Paint</div>
            <div style={styles.lineItem}>C - Pigmented 2k Satin</div>
            <div style={styles.lineItem}>D - Solid Surface Acrylic, Stainless Steel Front with HPL Back, Powdercoated Metal Base Panel, Paperstone Panel</div>
          </div>
        )}
        {this.props.companyKey === 'vp' && (
          <div style={{marginTop: K.spacing * 3, marginBottom: K.spacing}}>
            <div style={styles.lineItem}>ST Tier 1 - Technical Laminate w. colormatched edge, Muted Oak, Natural Walnut</div>
            <div style={styles.lineItem}>ST Tier 2 - Black Oak, Grey Oak, Ivory Oak, Muted Walnut, Roasted Walnut, Black Walnut, Soft Touch Laminate w. colormatched edge</div>
            <div style={styles.lineItem}>ST Tier HB - Henrybuilt Laminate on black core, Veneer Finish</div>
          </div>
        )}
        <div style={{marginTop: K.spacing * 2, marginBottom: K.spacing * 2, fontWeight: 'bold'}}>Units never include countertop pricing</div>
        <div
          onMouseEnter={() => this.setState({isHoveringPanelGuideButton: true})}
          onMouseLeave={() => this.setState({isHoveringPanelGuideButton: false})}
        >
          <a href={'https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/files/pricing_tool/information/pt_panel_guide.pdf'} target={'_blank'}>
            <button style={styles.button}>Panel Guide</button>
          </a>
        </div>
      </Popup>
    );
  }
}