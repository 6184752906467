import _ from 'lodash';
import getDependencies from 'helpers/get-dependencies';
import Room from 'project-helpers/room';

var Floor = {
  get(dependencyKeys, {floor}) {
    return getDependencies({dependencyKeys}, ({state}) => {
      return {
        rooms: () => _.values(state.resources.rooms.byFieldKeyIndex.floorId[floor.id]),
        project: () => state.resources.projects.byId[floor.projectId],
        dependencies: () => state.resources
      };
    });
  },

  destroy({floor, resourceActions}) {
    let destructions = {};

    _.forEach(Floor.get('rooms', {floor}), room => {
      let roomDestructions = Room.destroy({room, resourceActions, isBatched: true});

      _.forEach(roomDestructions, (destroyedIds, resourceKey) => {
        destructions[resourceKey] = [...(destructions[resourceKey] || []), ...(destroyedIds || [])];
      });
    });

    _.forEach(destructions, (destroyedIds, resourceKey) => {
      resourceActions[`destroy${_.upperFirst(resourceKey)}`]({ids: destroyedIds});
    });

    resourceActions.destroyFloor({id: floor.id});
  }
};

export default Floor;
