import React from 'react';
import _ from 'lodash';

import lib from 'lib';
import { connect } from 'redux/index.js';
import Project from 'project-helpers/project';
import Product from 'project-helpers/product';
import Room from 'project-helpers/room';

class PriceElement extends React.PureComponent {
  render() {
    var {project, pricingRules, materialClasses, productOptionTypes, productTypes} = this.props;

    var projectDataForPricing = {
      ...project,
      productInstances: _.map(Project.get('products', {project}), product => ({
        productOptionInstances: Product.get('productOptionInstances', {product}),
        ...product
      }))
    };

    var deliveryAddress = {
      streetAddress: project.deliveryStreetAddress,
      streetAddress2: project.deliveryStreetAddress2,
      city: project.deliveryCity,
      state: project.deliveryState,
      zip: project.deliveryZip
    };

    var hasValidAddress = deliveryAddress.state && deliveryAddress.zip;

    var projectPriceData = lib.memo(({data, refs}) => {
      var {projectDataForPricing} = data;
      var {pricingRules, productTypes, materialClasses, productOptionTypes} = refs;

      return lib.price.priceDataFor('project', projectDataForPricing, {pricingRules, products: productTypes, materialClasses, productOptions: productOptionTypes})
    }, {
      data: {projectDataForPricing},
      refs: {
        pricingRules, productTypes, materialClasses, productOptionTypes
      }
    });

    var priceText;

    if (hasValidAddress) {
      priceText = projectPriceData.total.toFormat('$0,0.00');
    }
    else {
      priceText = projectPriceData.subtotals.product.toFormat('$0,0.00');
    }

    var isLoading = Room.waitingForUpdateManagedResources;

    return (isLoading ? (
        <div style={{width: '2rem', height: '2rem'}} className="show-loader loader-dark"></div>
      ): (
        <div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <div>{priceText}</div>
            <div style={{opacity: 0.5, marginTop: '0.5rem', textTransform: 'uppercase', fontSize: '0.9em'}}>
              + Tax & Shipping
            </div>
          </div>
        </div>
      )
    );
  }
}

export default connect({
  mapState: state => {
    return {
      productOptionTypes: state.resources.productOptionTypes.byId,
      project: _.values(state.resources.projects.byId)[0],
      materialClasses: state.resources.materialClasses.byId,
      pricingRules: state.resources.pricingRules.byId,
      productTypes: state.resources.productTypes.byId,
      products: state.resources.products.byId
    };
  },
  mapDispatch: {}
})(PriceElement);
