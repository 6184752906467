import React, {useCallback, useEffect, useState, memo, useRef} from 'react';
import _ from 'lodash';
import K from 'k';
import { useDrop } from 'react-dnd';

import lib from 'lib';
import Elevation from 'project-helpers/elevation';
import Room from 'project-helpers/room';

import StandardPageSidePanel from '../drawings-components/standard-page-side-panel';
import StandardPageGraphic from '../drawings-components/standard-page-graphic';
import Drawings from 'project-helpers/drawings';
import Loader from '../drawings-components/drawings-loader';
import AddGraphicPopup from '../drawings-components/drawings-page-add-graphic-popup';
import Draggable from '../drawings-components/standard-page-draggable';
import Annotations from '../drawings-components/drawing-page-annotations';

const StandardPage = ({
  isBindingDimensionsPage, page, mode, dimensionsVisible,
  project, floors, rooms, elevations, imageMode, handleUpdateDrawingsData, materialFrequencyGroups,
  isEditing, visibilityLayers, activeDimensionsLayer, activeProjectGraphicsLayer, containerDimensions, detailLevel, fillMode, notes, handleUpdateNotes
}) => {
  var {layout, scale: pageScale, id: pageId, notes: pageNotes} = page;
  if (visibilityLayers.isExportingDxf) pageScale = 4;

  const [graphics, setGraphics] = useState(null);
  const [replaceGraphicId, setReplaceGraphicId] = useState(false);
  const [hoveringAnnotations, setHoveringAnnotations] = useState(false);

  const pageRef = useRef(page);

  pageRef.current = page;

  const handleDeleteGraphic = async ({id}) => {
    const graphicIndex = id.split('+')[1];
    const {layout: updatedLayout} = _.cloneDeep(pageRef.current);

    updatedLayout.splice(graphicIndex, 1);

    pageRef.current = {...pageRef.current, layout: updatedLayout};

    handleUpdateDrawingsData({pageId, updatedPage: pageRef.current});
  };

  const handleUpdatePageNotes = ({notes}) => {
    pageRef.current = {...pageRef.current, notes};

    handleUpdateDrawingsData({pageId, updatedPage: pageRef.current});
  }

  const getGraphicData = ({roomId, elevationId, floorId, position, scale = pageScale, isContext, index}) => {
    const floor = _.find(floors, {id: floorId});
    const room = _.find(rooms, {id: roomId});
    const elevation = _.find(elevations, {id: elevationId});
    const model = floor || room || elevation;
    const type = floor ? 'floor' : room ? 'room' : 'elevation';
    const isEmpty = _.isEmpty(model);

    if (visibilityLayers.isExportingDxf) scale = 4;

    let graphicData = {
      id: `${floorId || roomId || elevationId}+${index}+${page.id}`,
      isContext, isEmpty, position, scale, type, indicators: []
    };

    if (!isEmpty) {
      const {min, max, minNonGraphicY, maxNonGraphicY} = type === 'floor' ? {min: 0, max: 0} :  type === 'room' ? Room.getContentOutline({room}) : Elevation.getContentOutline({elevation});
      const size = {width: max.x - min.x, height: max.y - min.y};

      //HINT still adding padding when dimensions are off to make sure unit numbers aren't cut off
      //HINT add extra padding for width in room/floor because text is a width
      var dimensionWidthPadding = visibilityLayers.dimensions ? (type === 'room' || type === 'floor' ? 70 : 60) : 10;
      var dimensionHeightPadding = visibilityLayers.dimensions ? 60 : 10;

      if (type === 'elevation' && detailLevel === 'rendering' && imageMode !== 'vector') {
        dimensionWidthPadding = 130;
        dimensionHeightPadding = 50;
      }

      var upperHeightPadding = dimensionHeightPadding / 2;
      var lowerHeightPadding = dimensionHeightPadding / 2;

      if (type === 'elevation' && visibilityLayers.dimensions && (minNonGraphicY || maxNonGraphicY)) {
        if ((minNonGraphicY || minNonGraphicY === 0) && min.y !== minNonGraphicY) {
          lowerHeightPadding = min.y <= (minNonGraphicY - 30) ? 10 : _.max([10, Math.abs(min.y - (minNonGraphicY - 30))]);
        }

        if ((maxNonGraphicY || maxNonGraphicY === 0) && max.y !== maxNonGraphicY) {
          upperHeightPadding = max.y >= (maxNonGraphicY + 30) ? 10 : _.max([10, Math.abs(max.y - (maxNonGraphicY + 30))]);
        }

        dimensionHeightPadding = upperHeightPadding + lowerHeightPadding;
      }

      graphicData = {
        ...graphicData,
        model,
        size,
        upperHeightPadding,
        lowerHeightPadding,
        title: type === 'floor' ? (floor.title) : type === 'room'
          ? `${model.title}`
          : `${Elevation.getTitle({elevation})} - ${_.find(rooms, {id: elevation.roomId}).title}`
      };

      if (type === 'elevation' && detailLevel !== undefined) {
        graphicData.size = lib.object.sum(graphicData.size, {width: dimensionWidthPadding, height: dimensionHeightPadding});
      }
      if (type === 'room') {
        graphicData.size = lib.object.sum(graphicData.size, {width: dimensionWidthPadding, height: dimensionHeightPadding});

        graphicData.indicators = _.map(_.filter(layout, 'elevationId'), ({elevationId}) => ({entity: _.find(elevations, {id: elevationId})}));

        graphicData.indicators = _.filter(graphicData.indicators, ({entity}) => {
          return _.get(entity, 'roomId') === roomId;
        });
      }

      if (type === 'floor') {
        var floorRooms = _.filter(rooms, {floorId});

        var getDataForOutlines = (outlines) => {
          var points = _.flatten(outlines);
          var xs = _.map(points, 'x'), ys = _.map(points, 'y');
          var minX = _.min(xs), minY = _.min(ys);
          var maxX = _.max(xs), maxY = _.max(ys);
          var size = {width: maxX - minX, height: maxY - minY};

          return {offset: {x: lib.round((-minX - size.width * 0.5)) || 0, y: lib.round((-minY - size.height * 0.5)) || 0}, size};
        };
        var {offset: viewOffset, size: floorSize} = getDataForOutlines(_.map(floorRooms, floorRoom => {
          return _.map(Room.getContentOutline({room: floorRoom}), point => {
            return lib.object.sum(floorRoom.plan.position, point);
          });
        }));

          graphicData.size = floorSize;
          graphicData.size = lib.object.sum(graphicData.size, {width: dimensionWidthPadding, height: dimensionHeightPadding});

          graphicData.floorRooms = floorRooms;
          graphicData.floorViewOffset = viewOffset;
      }
    }

    return graphicData;
  };

  const styles = {
    loader: {
      display: 'flex',
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }
  };

  const handleReplaceGraphic = async (value) => {
    const [type, stringId] = value.split('-');
    const id = parseInt(stringId);
    const graphic = {[`${type}Id`]: id};
    const [, replaceIndex] = replaceGraphicId.split('+'); //`${model.id}+${index}+${page.id}`

    var updatedPage = _.cloneDeep(pageRef.current);

    updatedPage.layout.splice(replaceIndex, 1, _.defaults(graphic, updatedPage.layout[replaceIndex]));

    pageRef.current = updatedPage;

    await handleUpdateDrawingsData({pageId, updatedPage});
  };

  const moveGraphic = useCallback(
    (id, left, top) => {
      const [entityId, index, pageId] = id.split('+');
      const indexOfId = index;

      if (indexOfId !== -1) {
        var updatedLayout = _.cloneDeep(layout);
        const graphic = updatedLayout[indexOfId];

        const graphicData = getGraphicData({...graphic});
        const {size, scale} = graphicData;

        const canvasSize = Drawings.getSizeForPrintArea({printArea: {contentSize: size}, scale, project});
        const newPosition = Drawings.getPositionInRelative({top, left, size: canvasSize, containerDimensions});

        _.set(graphic, 'position', newPosition);

        updatedLayout.splice(indexOfId, 1, graphic);

        pageRef.current = {...pageRef.current, layout: updatedLayout}

        handleUpdateDrawingsData({pageId, updatedPage: pageRef.current});
      }
    },
    [layout, containerDimensions],
  );

  const openGraphicPopup = (id) => {
    setReplaceGraphicId(id);
  };

  const closeGraphicPopup = () => {
    setReplaceGraphicId(null);
  };

  var graphicsDependencies = [mode, pageScale,
    ..._.flatten(_.map(layout, ({floorId, roomId, elevationId, position, scale, isContext}) => [floorId, roomId, elevationId, position.x, position.y, scale, isContext])),
    ..._.values(visibilityLayers), imageMode, activeDimensionsLayer, activeProjectGraphicsLayer,
    detailLevel, fillMode, isEditing, ..._.values(containerDimensions)
  ];

  useEffect(() => {
    setGraphics(null);

    const items = _.map(layout, ({roomId, elevationId, floorId, position, scale, isContext}, index) => {
      const graphicData = getGraphicData({roomId, elevationId, floorId, position, scale, isContext, index});

      return (
        <Draggable
          key={`standard-page-graphic-${graphicData.id}`}
          {...{...graphicData, containerDimensions, imageMode, isEditing, project, mode, visibilityLayers, activeDimensionsLayer, activeProjectGraphicsLayer, detailLevel, fillMode, moveGraphic}}
          children={
            <StandardPageGraphic
              {...{...graphicData, containerDimensions, imageMode, isEditing, project, mode, visibilityLayers, activeDimensionsLayer, activeProjectGraphicsLayer, detailLevel, fillMode, moveGraphic}}
              key={`drawing-id-${graphicData.id}`}
              onDelete={handleDeleteGraphic}
              onReplaceClick={openGraphicPopup}
            />
          }
        >
        </Draggable>
      );
    });

    setGraphics(items);
  }, graphicsDependencies);//, [project, page]);

  return (<>
    {replaceGraphicId?.length > 0 && (
      <AddGraphicPopup
        {...{project, elevations, rooms, floors}}
        onClose={closeGraphicPopup}
        onChange={handleReplaceGraphic}
      />
    )}
    <div className="standard-page-container drawings-side-panel-container clearfix">
      <div className="drawings-page-main-content clearfix">
        <div
          style={{display: 'flex', flexDirection: 'column', position: 'absolute'}}
          onMouseEnter={() => setHoveringAnnotations(true)}
          onMouseLeave={() => setHoveringAnnotations(false)}
        >
          <Annotations
            {...{notes, handleUpdateNotes}}
            textColor={'red'}
            placeholder={'Add Project Note'}
            hovered={hoveringAnnotations}
          />
          <Annotations
            notes={pageNotes}
            handleUpdateNotes={handleUpdatePageNotes}
            textColor={'red'}
            placeholder={'Add Page Note'}
            hovered={hoveringAnnotations}
          />
        </div>
        <div
          className="standard-page-elements"
          // ref={drop}
        >
          {graphics ? graphics : <div style={styles.loader}><Loader /></div>}
        </div>
      </div>
      <StandardPageSidePanel {...{isBindingDimensionsPage, project, page, mode, rooms, elevations, dimensionsVisible, detailLevel, materialFrequencyGroups, showUnitNumbers: visibilityLayers.unitNumbers, bindingDimensionsShowing: visibilityLayers.bindingDimensions, fillMode}}/>
    </div>
  </>
  );
};

export default memo(StandardPage, (prevProps, nextProps) => {
  var difference = (obj1, obj2) => {
    if (!obj2) return [undefined];

    const diffProperties = _.reduce(obj1, (result, value, key) => {
      return _.isEqual(value, obj2[key]) ?
        result : result.concat(key);
    }, []);

    return diffProperties || [];
  };

  var nonObjectPropKeys = [
    'isBindingDimensionsPage', 'mode', 'dimensionsVisible', 'imageMode',
    'isEditing', 'activeDimensionsLayer', 'activeProjectGraphicsLayer', 'detailLevel', 'fillMode'
  ];

  var objectPropKeys = [
    'page', 'containerDimensions', 'floors', 'rooms', 'elevations', 'visibilityLayers', 'materialFrequencyGroups'
  ];

  var nonObjectPropKeysAreEqual = _.isEqual(_.pick(prevProps, nonObjectPropKeys), _.pick(nextProps, nonObjectPropKeys));

  if (!nonObjectPropKeysAreEqual) {
    return false;
  }
  else {
    var notesDifferent = _.get(nextProps, 'notes.length') !== _.get(prevProps, 'notes.length') || _.some(nextProps.notes, (note, index) => {
      return note !== prevProps.notes[index];
    });

    if (notesDifferent) {
      return false;
    }
    else {
      var objectPropsDifferent = !_.some(_.pick(nextProps, objectPropKeys), (value, key) => {
        return difference(value, prevProps[key]).length > 0;
      });

      return objectPropsDifferent;
    }
  }
});