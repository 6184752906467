import K from 'k';
import React from 'react';

const Loader = () => {
  const styles = {
    loader: {
      border: '0.25rem solid',
      borderTop: `0.25rem solid ${K.colors.gray}`,
      borderLeft: '0.25rem solid linear-gradient(to right, black, #00000025)',
      borderRight: '0.25rem solid linear-gradient(to right, #00000025, #00000050)',
      borderBottom: '0.25rem solid linear-gradient(to right, #00000050, #00000075)',
      borderRadius: '50%',
      width: '2rem',
      height: '2rem',
      animation: 'spin 1s linear infinite',
    }
  };

  return (
    <div style={styles.loader}/>
  );
};

export default Loader;