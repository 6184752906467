import React from 'react';
import { resourceActions, connect } from 'redux/index.js';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import HudElement from './hud-element';

import { pluralize } from 'inflection';

import TextInput from 'components/text-input';
import Dropdown from 'components/dropdown';
import Parameter from './parameter-components/parameter';

import xIconBlack from '../../../assets/x-icon-black.png';
import createIconBlack from '../../../assets/create-plus-icon-black.png';
import upArrowIcon from '../../../assets/up-arrow-black.png';

import 'styles/parameter-hud-element.scss';

class ParameterEditorHudElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHoveringAddParameterButton: false,
      expandedParameterId: -1,
      parameterTypeDropdownVisible: false,
      expandedParameterGroup: '',
      appliedOptionTypeFieldsVisible: false
    };
  }

  handleAddParameter({type}) {
    var updatedParameters = _.cloneDeep(_.get(this.props.archetype, 'parameters') || []);
    var newParameterId = lib.string.uuid();

    updatedParameters.push({
      id: newParameterId,
      type,
      title: 'Untitled Parameter',
      options: [],
      instances: []
    })

    this.props.updateArchetype({id: this.props.archetype.id, props: {parameters: updatedParameters}});

    this.setState({parameterTypeDropdownVisible: false, expandedParameterGroup: type, expandedParameterId: newParameterId});
  }

  handleParameterChange({id, props}) {
    var updatedParameters = _.cloneDeep(_.get(this.props.archetype, 'parameters', []));
    var updatedParameter = _.find(updatedParameters, {id});
    var updatedParameterIndex = _.indexOf(updatedParameters, updatedParameter);

    _.set(updatedParameter, _.keys(props)[0], _.cloneDeep(props[_.keys(props)[0]]));

    updatedParameters[updatedParameterIndex] = updatedParameter;

    this.props.updateArchetype({id: this.props.archetype.id, props: {parameters: updatedParameters}});
  }

  handleArchetypeChange(props) {
    this.props.updateArchetype({id: this.props.archetype.id, props});
  }

  handleUpdateExpandedParameter({parameter}) {
    this.setState({expandedParameterId: _.get(parameter, 'id', -1)});
  }

  handleSetAppliedOptionTypeFieldsVisible({value}){
    this.setState({appliedOptionTypeFieldsVisible: value});
  }

  handleToggleHideConfiguratorPricing(){
    var isHidden = _.get(this.props.archetype, 'hideConfiguratorPricing', 0);

    this.props.updateArchetype({id: this.props.archetype.id, props: {hideConfiguratorPricing: isHidden === 0 ? 1 : 0}});
  }

  render() {
    var {archetype, isVisible} = this.props;
    var styles = {
      uppercase: {
        transform: 'uppercase',
      },
      primaryHeader: {
        ...K.fonts.header, fontSize: 16, fontWeight: 500, opacity: 0.9, textAlign: 'left'
      },
      secondaryHeader: {
        ...K.fonts.header, fontSize: 14, fontWeight: 500, opacity: 0.9, textAlign: 'left'
      },
      tertiaryHeader: {
        ...K.fonts.header, fontSize: 13, fontWeight: 500, opacity: 0.9, textAlign: 'left'
      }
    }

    if (!isVisible) {
      return (
        <div
          onClick={this.props.show}
          style={{cursor: 'pointer', position: 'absolute', zIndex: 2, bottom: 26, right: K.spacing * 2.5, width: 36, height: 36, backgroundColor: 'black', color: 'white', borderRadius: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          <img src={xIconBlack} style={{width: 16, height: 16}}/>
        </div>
      );
    }

    var archetypeTypes = [{value: 'archetype', title: 'ARCHETYPE'}, {value: 'commonConfiguration', title: 'COMMON CONFIGURATION'}, {value: 'buildingBlock', title: 'BUILDING BLOCK'}, {value: 'atlanticFields', title: 'ATLANTIC FIELDS'}];
    var parameterTypes = [{value: 'material', title: 'MATERIAL'}, {value: 'pull', title: 'PULL'}, {value: 'conditionalResources', title: 'CONDITIONAL RESOURCES'}, {value: 'dynamicSize', title: 'DYNAMIC SIZE'}];

    return <>
      <HudElement x={'right'} y={'bottom'} style={{width: K.paneWidth * 2.75, borderRight: K.grayBorder, zIndex: 1000, height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', padding: 0}}>
        <div
          onClick={this.props.hide}
          style={{cursor: 'pointer', position: 'absolute', top: K.spacing * 2, right: `calc(100% + ${K.spacing}px)`, width: '50px'}}
        >
          <img src={xIconBlack} style={{width: 24, height: 24, opacity: 0.8}} />
        </div>
        <div style={{height: '100%', marginTop: K.spacing / 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', overflowX: 'hidden', overflowY: 'auto'}}>
          <div style={{flexDirection: 'column', flex: 1}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <div style={{marginLeft: K.spacing * 2, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <TextInput
                  label={'Title'}
                  value={archetype.title}
                  placeholder={'title'}
                  onChange={({value}) => this.handleArchetypeChange({title: value})}
                  style={{display: 'flex', ...styles.primaryHeader, fontSize: 24, marginBottom: K.spacing * 2, width: '100%', textAlign: 'left', marginTop: K.spacing * 2, backgroundColor: 'transparent'}}
                />
                {(
                  (_.get(_.find(archetype.parameters, {id: this.state.expandedParameterId}), 'instances', []).length > 0)
                  || (
                       _.get(_.find(archetype.parameters, {id: this.state.expandedParameterId}), 'type', '') === 'conditionalResources'
                       && _.get(_.find(archetype.parameters, {id: this.state.expandedParameterId}), 'options', []).length > 0
                     )
                ) && (
                  <div
                    onClick={() => this.handleSetAppliedOptionTypeFieldsVisible({value: !this.state.appliedOptionTypeFieldsVisible})}
                    style={{...styles.secondaryHeader, opacity: (this.state.appliedOptionTypeFieldsVisible ? 0.8 : 0.4), width: 400, marginTop: K.spacing * 3, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', cursor: 'pointer'}}
                  >Apply Parameter Options</div>
                )}
              </div>
              <div style={{display: 'flex', flexDirection: 'row', marginTop: K.spacing * 4, width: '100%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div
                    className={'uppercase'}
                    style={{...styles.primaryHeader, marginLeft: K.spacing * 3.5, marginBottom: K.spacing}}
                  >
                    PARAMETERS
                  </div>
                  <div
                    className={'uppercase'}
                    style={{
                      ...styles.primaryHeader, cursor: 'pointer', marginLeft: K.spacing, marginBottom: K.spacing,
                      ...(this.state.isHoveringAddParameterButton ? {opacity: 0.6} : {opacity: 0.4}),
                      alignItems: 'center', display: 'flex', flexDirection: 'row'
                    }}
                    onClick={() => this.setState({parameterTypeDropdownVisible: true})}
                    onMouseEnter={() => this.setState({isHoveringAddParameterButton: true})}
                    onMouseLeave={() => this.setState({isHoveringAddParameterButton: false})}
                  >
                    <img src={createIconBlack} style={{width: 16, height: 16, marginTop: 0, marginRight: K.spacing / 2}} />
                    ADD
                  </div>
                  {this.state.parameterTypeDropdownVisible && (
                    <Dropdown
                      showHeader={false}
                      isCollapsedDefault={false}
                      options={parameterTypes}
                      onCollapse={() => this.setState({parameterTypeDropdownVisible: false})}
                      onChange={(value) => this.handleAddParameter({type: value})}
                      hasFixedHeight
                      style={{...K.fonts.label, opacity: 1, marginLeft: K.spacing * 2, marginTop: -K.spacing * 2, position: 'fixed', marginLeft: 160}}
                      contentContainerStyle={{
                        position: 'relative', textAlign: 'start', backgroundColor: K.colors.gray, borderRadius: 5, color: 'white',
                        ...(this.state.parameterTypeDropdownVisible ? {backgroundColor: 	'black'} : {})
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div style={{width: '100%'}}>
              {_.map(parameterTypes, ({value, title}) => {
                var parameters = _.filter(archetype.parameters, {type: value});

                if (parameters.length !== 0) {
                  return (
                    <div key={value} style={{marginLeft: -K.spacing / 2}}>
                      <div style={{marginLeft: K.spacing * 3.5, marginBottom: K.spacing, marginTop: K.spacing, display: 'flex', flexDirection: 'row', flex: 1, marginRight: K.spacing, justifyContent: 'space-between'}}>
                        <div className={'uppercase'} style={{...styles.secondaryHeader, marginLeft: K.spacing / 2}}>{title === 'CONDITIONAL RESOURCES' ? title : `${title}S`}</div>
                        <img
                          src={upArrowIcon}
                          style={{...K.defaultIconSize, cursor: 'pointer', marginRight: K.spacing * 3 + 1, ...(this.state.expandedParameterGroup === value ? {transform: 'rotate(180deg)'} : {transform: 'rotate(90deg)'})}}
                          onClick={() => this.setState({expandedParameterGroup: this.state.expandedParameterGroup === value ? '' : value})}
                        />
                      </div>
                      {this.state.expandedParameterGroup === value && (
                        <div>
                          {_.map(parameters, (parameter) => (
                            <Parameter
                              key={parameter.id}
                              handleParameterChange={(props) => this.handleParameterChange({id: parameter.id, props})}
                              handleUpdateExpandedParameter={({parameter}) => this.handleUpdateExpandedParameter({parameter})}
                              isExpanded={parameter.id === this.state.expandedParameterId}
                              parameterGroup={value}
                              {...{parameter, archetype, styles}}
                              materialTypes={this.props.materialTypes}
                              activeEntitiesData={this.props.activeEntitiesData}
                              activeEntities={this.props.activeEntities}
                              handleToggleAppliedOptionSelectorPopup={({appliedOptionType}) => this.handleToggleAppliedOptionSelectorPopup({appliedOptionType})}
                              appliedOptionTypeFieldsVisible={this.state.appliedOptionTypeFieldsVisible}
                              handleSetAppliedOptionTypeFieldsVisible={({value}) => this.handleSetAppliedOptionTypeFieldsVisible({value})}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )
                }
              })}
            </div>
          </div>
          <div style={{marginTop: K.spacing * 2.5, width: '100%', marginBottom: K.spacing * 10, marginLeft: K.spacing * 2.5}}>
            <div className={'uppercase'} style={{...styles.primaryHeader, width: '100%', marginLeft: K.spacing, marginBottom: K.spacing}}>MORE SETTINGS</div>
            <div style={{marginLeft: K.spacing, ...styles.secondaryHeader, fontWeight: 400}} className={'uppercase'}>DESCRIPTION</div>
            <div style={{minHeight: 50}}>
              <TextInput
                label={'Archetype Description'}
                value={archetype.description}
                placeholder={'Description...'}
                onChange={({value}) => this.handleArchetypeChange({description: value})}
                style={{display: 'flex', width: '100%', marginBottom: K.spacing, alignText: 'left'}}
              />
            </div>
            <div style={{marginLeft: K.spacing, ...styles.secondaryHeader, fontWeight: 400}} className={'uppercase'}>TYPE</div>
            <div>
              <Dropdown
                options={archetypeTypes}
                onChange={(value) => this.handleArchetypeChange({type: value})}
                value={archetype.type}
                isControlled
                placeholder={'SELECT'}
                hasFixedHeight
                style={{marginLeft: K.spacing, minWidth: 200, display: 'flex', ...K.fonts.label, opacity: 1}}
                contentContainerStyle={{backgroundColor: K.colors.gray, marginTop: K.spacing * 2, textAlign: 'start'}}
              />
            </div>
            <div
              style={{
                marginLeft: K.spacing, marginTop: K.spacing * 2, ...K.fonts.label, cursor: 'pointer',
                opacity: _.get(this.props.archetype, 'hideConfiguratorPricing', 0) === 0 ? 0.4 : 0.8
              }}
              onClick={() => this.handleToggleHideConfiguratorPricing()}
            >
              HIDE PRICING FOR CLIENT
            </div>
          </div>
        </div>
      </HudElement>
    </>;
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData, room} = ownProps;

    var activeEntities = _.map(activeEntitiesData, (activeEntity) => {
      return _.get(state.resources, `[${pluralize(activeEntity.resourceKey)}].byId[${activeEntity.id}]`);
    });

    return {
      scopesByRoomId: state.resources.scopes.byFieldKeyIndex.roomId,
      rooms: state.resources.rooms.byId,
      project: _.values(state.resources.projects.byId)[0],
      containerTypes: state.resources.containerTypes.byId,
      productTypes: state.resources.productTypes.byId,
      activeEntities,
      activeEntitiesData,
      archetype: _.find(state.resources.archetypes.byId, {roomId: room.id}),
      materialClasses: state.resources.materialClasses.byId,
      materials: state.resources.materials.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['createRoom', 'trackRooms', 'updateRoom', 'destroyRoom', 'destroyRooms']),
    ..._.pick(resourceActions.archetypes, ['updateArchetype', 'destroyArchetype']),
    ..._.pick(resourceActions.products, ['updateProducts', 'modifyProducts']),
    ..._.pick(resourceActions.containers, ['updateContainers', 'modifyContainers']),
  }
})(ParameterEditorHudElement);
