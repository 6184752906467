import _ from 'lodash';
import lib from 'lib';

export default function addLineToNearestSide({allPoints, outsideDimensionSets, candidateLines, idPrefix}) {
  var {candidateLine, candidateLineAlpha} = _.maxBy(_.map(candidateLines, candidateLine => {
    var entityPoint = candidateLine.from;
    var candidateLineAlpha = lib.trig.alpha({p1: candidateLine.from, p2: candidateLine.to});
    var tangentAlpha = candidateLineAlpha + Math.PI / 2;
    var normalizedEntityPoint = lib.trig.rotate({point: entityPoint, byRadians: -tangentAlpha});
    var allNormalizedPoints = _.map(allPoints, point => lib.trig.rotate({point: lib.object.difference(point), byRadians: -tangentAlpha}));
    var distance = _.max(_.map(_.filter(allNormalizedPoints, ({x}) => x < normalizedEntityPoint.x), otherPoint => Math.abs(normalizedEntityPoint.x - otherPoint.x))) || 0;

    return {candidateLine, candidateLineAlpha, distance};
  }), 'distance');

  var dimensionSet = _.find(outsideDimensionSets, ({alpha}) => lib.trig.anglesAreEqual({a1: alpha, a2: candidateLineAlpha}));

  if (dimensionSet) dimensionSet.targets.push(
    {position: candidateLine.from, id: `${idPrefix}-from`},
    {position: candidateLine.to, id: `${idPrefix}-to`}
  );
}
