import React from 'react';
import K from 'k';

export default class Popup extends React.PureComponent {
  render() {

    return (
      <div
        ref={ref => this.wrapperRef = ref}
        onClick={event => event.target === this.wrapperRef && this.props.onClose && this.props.onClose()}
        style={{position: 'fixed', backgroundColor: 'rgba(0, 0, 0, 0.4)', display: 'flex', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10000, alignItems: 'center', justifyContent: 'center', ...this.props.wrapperStyle}}
      >
        <div style={{backgroundColor: 'white', padding: K.spacing * 2, ...this.props.style}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
