import _ from 'lodash';

var genericCacheMap = {};

export default function memoObject(object, cacheKey, {log = false, cacheMap: customCacheMap} = {}) {
  var cacheMap = customCacheMap || genericCacheMap;

  if (!cacheMap[cacheKey] || (_.isEmpty(object) && !_.isEmpty(cacheMap[cacheKey])) || _.some(object, (value, objectKey) => value !== cacheMap[cacheKey][objectKey])) {
    if (log && cacheMap[cacheKey]) {
      console.log(
        _.findKey(object, (value, objectKey) => value !== cacheMap[cacheKey][objectKey]),
        _.find(object, (value, objectKey) => value !== cacheMap[cacheKey][objectKey])
      );
    }

    cacheMap[cacheKey] = object;
  }

  return cacheMap[cacheKey];
}
