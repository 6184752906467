import React from 'react';
import lib from 'lib';
import 'styles/dimension_constraints.scss';

let defaultProps = {
  theme: 'light',
  dimensions: {
    width: {min: 0, max: 0, step: 0, fixed: []},
    height: {min: 0, max: 0, step: 0, fixed: []},
    depth: {min: 0, max: 0, step: 0, fixed: []}
  }//dimensions presumes values for min, max, step, and fixed
};

class UIDimensionConstraints extends React.Component {
  render() {
    let dimensions = _.defaultsDeep(this.props.dimensions, defaultProps.dimensions);

    return (
      <div className={`ui-dimension-constraints theme-light`}>
        {['width', 'height', 'depth'].map(dimensionKey => {
          let toFraction = value => lib.number.toFraction(value, {normalscript: true});
          let properties = dimensions[dimensionKey];
          let dimensionValues;

          if (properties.fixed.length > 0) {
            dimensionValues = (<>{properties.fixed.map(fixedValue => {
              return (<div
                className="dimension-value fixed"
                key={toFraction(fixedValue)}
              >
                {toFraction(fixedValue)}
              </div>);
            })}</>);
          }
          else if (properties.min && properties.max) {
            //WARNING intentional use of additional strings because of display inline-block
            dimensionValues = (
              <>
                <div className="dimension-value min">{toFraction(properties.min)}</div>
                <div className="step-line"></div>
                <span className="dimension-value step">{toFraction(properties.step)}</span>
                <div className="step-line"></div>
                <div className="dimension-value max">{toFraction(properties.max)}</div>
              </>
            );
          }

          return (
            <div className={`dimension ${dimensionKey}`} key={dimensionKey}>
              <div className="dimension-title">{dimensionKey[0].toUpperCase()}</div>
              <div className="dimension-values">{dimensionValues}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default UIDimensionConstraints;