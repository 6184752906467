import React from 'react';
import _ from 'lodash';
import * as THREE from 'three';

/* eslint-disable react/no-unknown-property */

function Mesh3D({vertices, fill, position}) {
  var vector3s = _.map(vertices, point => new THREE.Vector3(point.x, point.y, point.z));
  var geometry = new THREE.BufferGeometry().setFromPoints(vector3s);
  var position = [position.x || 0, position.y || 0, position.z || 0];

  //HINT hex value
  if (_.includes(fill, '#')) {
    //HINT convert 3 digit hex to 6 digit hex
    if (fill.length === 4) fill = `#${fill[1]}${fill[1]}${fill[2]}${fill[2]}${fill[3]}${fill[3]}`;

    //HINT convert hex string to hexadecimal
    fill = _.parseInt(_.replace(fill, '#', '0x'), 16);
  }

  return (
    <mesh geometry={geometry} {...{position}}>
      <meshBasicMaterial args={[{color: fill}]}/>
    </mesh>
  );
}

export default Mesh3D;
