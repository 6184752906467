import DetailsHelper from 'helpers/details-helper';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';
import { handleContainerPropertyChange } from 'properties-view-data/container-properties-view-helpers';
import { handleProductPropertyChange } from 'properties-view-data/product-properties-view-helpers';

//HINT function must be async so that we can create containers, then create the products inside the container
var applyArchetypeParameterSelection = async ({room, archetypeId, parameter, value, path, resourceActions, updatingSourceRoom= false, isInAdminMode=false}) => {
  //Queue approach like managed products
  //while updating, wait until the selection is fully applied, then apply the next parameter

  // var parameters = room.parameters[archetypeId];
  // var currentlyAppliedParameters = room.selectedParameters[archetypeId];

  //validate parameter and value
  var shouldApply = true;

  if (shouldApply) {
    var hitApi = true;
    var updatedSelectedParameters = _.cloneDeep(room.selectedParameters);

    _.set(updatedSelectedParameters, `${archetypeId}.${parameter.id}${path ? `.${path}` : ''}`, value);

    var updatesMap = {
      rooms: {creations: [], deletedIds: [], updates: [], tracks: []},
      containers: {creations: [], deletedIds: [], updates: [], tracks: []},
      products: {creations: [], deletedIds: [], updates: [], tracks: []},
      productOptions: {creations: [], deletedIds: [], updates: [], tracks: []}
    };

    //apply parameter based on type
    //end with array of CRUD updates to resources
    if (parameter.type === 'material' || parameter.type === 'pull') {
      var {products, containers} = Room.get(['products', 'containers'], {room});

      //HINT not sure this will work if applying something to the container and product
      //might override the updates
      //might need to do more work to combine the updates into one update
      //TODO pull type + material updates
      _.forEach(parameter.instances, instance => {
        var instanceMaterialKeys = [];

        if (parameter.type === 'pull') instanceMaterialKeys = [path];
        else if (parameter.type === 'material') instanceMaterialKeys = (instance.materialKeys && instance.materialKeys.length > 0) ? instance.materialKeys : (parameter.defaultMaterialKeys || []);

        _.forEach(instanceMaterialKeys, materialKey => {
          if (instance.resourceKey === 'container') {
            var container = _.find(containers, {persistentId: instance.resourcePersistentId});

            if (container) {
              UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleContainerPropertyChange({activeEntityId: container.id, activeEntity: container, path: `details.${materialKey}.id`, value, isBatched: true}));
            }
          }
          else if (instance.resourceKey === 'product') {
            var product = _.find(products, {persistentId: instance.resourcePersistentId});

            if (product) {
              UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleProductPropertyChange({activeEntityId: product.id, activeEntity: product, path: `details.${materialKey}.id`, value, isBatched: true}));
            }
          }
        });
      });
    }
    else if (parameter.type === 'conditionalResources') {
      hitApi = false;
      var {products, containers, project} = Room.get(['products', 'containers', 'project'], {room});

      var apiBody = {
        projectId: project.id, versionId: project.versionId, roomId: room.id, archetypeId, parameterId: parameter.id, value, ...(isInAdminMode ? {updatingSourceRoom, parameter} : {})
      };

      var conditionalResourcesUpdateResponse = await lib.api.request({uri: 'de-project/apply-conditional-resources-parameter', body: {...apiBody}});

      _.forEach(conditionalResourcesUpdateResponse.data, (resourceUpdateData, resourceKey) => {
        _.forEach(resourceUpdateData.deletedIds, resourceId => {
          if (resourceKey === 'containers') {
            var container = _.find(containers, {id: resourceId});

            if (container) {
              var {managedUpdatesMap, containerCacheUpdate} = Container.updateManagedResources({container, actionKey: 'destroy', resourceActions, isBatched: true});

              if (containerCacheUpdate) updatesMap.containers.updates.push({where: {id: container.id}, props: {customData: {...container.customData, cachedManagedData: containerCacheUpdate}}});

              updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);

            }
          }
          else if (resourceKey === 'products') {
            var product = _.find(products, {id: resourceId});

            if (product) {
              var {managedUpdatesMap} = Product.updateManagedResources({product, actionKey: 'destroy', resourceActions, isBatched: true});

              updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);
            }
          }
        });
      });

      updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, conditionalResourcesUpdateResponse.data);
    }

    resourceActions.updateRoom({id: room.id, props: {selectedParameters: updatedSelectedParameters}});

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, resourceActions, hitApi});

    Room.updateManagedResources({room, resourceActions});
  }
};

export default applyArchetypeParameterSelection;