import _ from 'lodash';
import lib from 'lib';

//old data used to be like {from, to} rather than {line: {from, to}, context...} or {x, y} rather than {position: {x, y}, context...}
//old positions used to be absolute as well
export default function cleanOldDimensionsData({dimensionsData, elevations, rooms}) {
  if (!dimensionsData) dimensionsData = {};

  if (!dimensionsData.client) dimensionsData.client = {};
  if (!dimensionsData.production) dimensionsData.production = {};
  if (!dimensionsData.installation) dimensionsData.installation = {};

  _.forEach(dimensionsData, (layerDimensionsData, layerDimensionKey) => {
    if (_.includes(['client', 'production', 'installation'], layerDimensionKey)) {
      _.forEach(['disabledPositions', 'disabledLines', 'disabledSummaryLines', 'bindingDimensions', 'customDimensions'], key => {
        layerDimensionsData[key] = _.map(layerDimensionsData[key], entity => {
          var parentModel;
          var entityType = entity.line || entity.from ? 'line' : 'position';
  
          if (!(entity.line || entity.position)) {
            entity = {[entityType]: entity, contextId: 'none', contextType: 'none'};
          }
          else if (!entity.isRelative) {
            if (entity.contextType === 'elevation') {
              parentModel = _.find(elevations, elevation => elevation.id === entity.contextId || elevation.generatedId === entity.contextId);
            }
            else {
              parentModel = _.find(rooms, room => room.id === entity.contextId);
            }
  
            if (parentModel) {
              var {origin} = parentModel;
              var {position, line} = entity;
              var relativeValue = line ? {from: lib.object.difference(line.from, origin), to: lib.object.difference(line.to, origin)} : lib.object.difference(position, origin);
  
              entity = {...entity, isRelative: true, [entityType]: relativeValue};
            }
          }
  
          return entity;
        });
      });
    }
    else {
      delete dimensionsData[layerDimensionKey];
    }
  });

  return dimensionsData;
}
