import _ from 'lodash';
import K from 'k';

var HatchHelper = {
  getHatchKeys() {
    return [
      'blank',
      'diagonalLinesRight',
      'diagonalLinesBoth',
      'diagonalLinesBothDashed',
      'crosshair',
      'diagonalLinesLeftDashed',
      'diagonalLinesLeft',
      'diagonalLinesRightDashed',
      'waffle',
      'dashX',
      'dashY',
      'dot',
    ];
  },

  forHatch({key, appearanceProps = {}}) {
    var sourceCanvas = document.createElement('canvas');
    var color = K.colors.hatches;
    var size = 10;
    sourceCanvas.width = size;
    sourceCanvas.height = size;
    var canvasContext = sourceCanvas.getContext('2d');

    var drawLine = ({x1, x2, y1, y2, dashed, opacity}) => {
      if (dashed) canvasContext.setLineDash([size / 2, size / 2]);
      if (opacity) canvasContext.globalAlpha = opacity;
      canvasContext.strokeStyle = color;
      canvasContext.lineWidth = 1;
      canvasContext.beginPath();
      canvasContext.moveTo(x1, y1);
      canvasContext.lineTo(x2, y2);
      canvasContext.stroke();
    };

    var drawCircle = ({radius}) => {
      canvasContext.strokeStyle = color;
      canvasContext.fillStyle = color;
      canvasContext.lineWidth = 1;
      canvasContext.beginPath();
      canvasContext.arc(size / 2, size / 2, radius, 0, Math.PI * 2, true);
      canvasContext.stroke();
      canvasContext.fill();
    };

    var diagonalLineRight = props => drawLine({
      x1: 0, y1: 0, x2: size, y2: size, ...props
    });

    var diagonalLineLeft = props => drawLine({
      x1: size, y1: 0, x2: 0, y2: size, ...props
    });

    var hatchGenerators = {
      blank: () => {},
      diagonalLinesRight: () => diagonalLineRight(),
      diagonalLinesLeft: () => diagonalLineLeft(),
      diagonalLinesBoth: () => {
        diagonalLineRight({opacity: 0.7});
        diagonalLineLeft({opacity: 0.7});
      },
      diagonalLinesRightDashed: () => diagonalLineRight({dashed: true}),
      diagonalLinesLeftDashed: () => diagonalLineLeft({dashed: true}),
      diagonalLinesBothDashed: () => {
        diagonalLineRight({dashed: true});
        diagonalLineLeft({dashed: true});
      },
      crosshair: () => {
        drawLine({
          x1: size / 2, y1: size / 4, x2: size / 2, y2: size * (3 / 4),
        });
        drawLine({
          x1: size / 4, y1: size / 2, x2: size * (3 / 4), y2: size / 2,
        });
      },
      waffle: () => {
        drawLine({
          x1: size / 4, y1: size / 4, x2: size / 4, y2: size * (3 / 4),
        });
        drawLine({
          x1: size / 4, y1: size / 4, x2: size * (3 / 4), y2: size / 4,
        });
      },
      dot: () => drawCircle({radius: 1}),
      dashX: () => drawLine({x1: 0, y1: size / 2, x2: size, y2: size / 2, dashed: true}),
      dashY: () => drawLine({x1: size / 2, y1: 0, x2: size / 2, y2: size, dashed: true}),
      invalid: () => {
        canvasContext.fillStyle = 'red';
        canvasContext.fillRect(0, 0, size, size);
      }
    };

    var generator = hatchGenerators[key] || hatchGenerators.invalid;

    generator();

    return sourceCanvas;
  }
};

export default HatchHelper;